import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { TopBar } from "../components/topBar";
import { SubUserInfo } from "../sub-routes/sub-userInfo";
import { SubUserPassword } from "../sub-routes/sub-userPassword";
import { useLocation } from "react-router-dom";

export const UpUserData = () => {
  // 회원정보 변경
  const success = useLocation().state;
  const [tabs, setTabs] = useState(Number(success) || 0);

  useEffect(() => {
    pageIndicator("개인정보 수정");
  }, []);
  return (
    <div className="wrap h-full flex flex-col overflow-auto ">
      <header className="w-full">
        <TopBar home={true} arrow={true} heading={true} shop={true} />
      </header>
      <main className="max-w-screen-break w-full h-full flex-1 flex flex-wrap flex-col">
        <div className="topBox bg-white flex flex-wrap content-center justify-between h-10 border-t-[0.5px]  w-full">
          <button
            className={`w-1/2 h-full border-b-[1.5px] ${
              tabs === 0 && " border-mainIcon"
            }`}
            onClick={() => {
              // window.history.pushState(null, "", "?cancel=0");
              setTabs(0);
            }}
          >
            회원정보 변경
          </button>
          <button
            className={`w-1/2 h-full border-b-[1.5px] ${
              tabs === 1 && " border-mainIcon"
            }`}
            onClick={() => {
              // window.history.pushState(null, "", "?cancel=1");
              setTabs(1);
            }}
          >
            비밀번호 변경
          </button>
        </div>
        <div className="flex-1 w-full">
          {tabs === 0 && <SubUserInfo />}
          {tabs === 1 && <SubUserPassword kg={Number(success)} />}
        </div>
      </main>
    </div>
  );
};
