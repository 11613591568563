import { gql, useLazyQuery } from "@apollo/client";
import { ListLinkServiceSelectQuery } from "../gql/graphql";

const LIST_LINKSERVICE_SELECT_QUERY = gql`
  query listLinkServiceSelect {
    listLinkServiceSelect {
      ok
      err
      linkservice {
        id
        name
        link
      }
    }
  }
`;

export const useListLinkServiceSelect = (onCompleted?: (data: ListLinkServiceSelectQuery) => void) => {
  return useLazyQuery<ListLinkServiceSelectQuery>(LIST_LINKSERVICE_SELECT_QUERY, {
    onCompleted,
  });
};
