import { useEffect, useState } from "react";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import { Slide } from "@mui/material";
import moment from "moment";
import backwardArrow from "../assets/icons/backwardArrow.svg";
import arrow from "../assets/icons/arrow_right.svg";
import { useNavigate, useParams } from "react-router-dom";
import star_yellow from "../assets/icons/star_yellow.svg";
import star_gray from "../assets/icons/star_gray.svg";
import { FootMenu } from "../components/FootMenu";
import { useListGoodReview } from "../hooks/useListGoodReview";
import { emailMasking } from "../constants/returnFunction";
import { useMe } from "../hooks/useME";
import { ListGoodReviewInput, ListGoodReviewQuery } from "../gql/graphql";
export interface dateFilterBtnList {
  text: string;
  active: boolean;
}

export const ListGoodReview = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [row, setRow] = useState<number>(20);
  const { data: me } = useMe();
  // listMyReview
  const onListGoodReview = (data: ListGoodReviewQuery) => {
    const {
      listGoodReview: { ok, err, goodReviews },
    } = data;
    if (ok) {
    } else {
    }
  };
  const [listMyGoodReview, { data: listGoodReview, loading: goodReviewLoading, refetch }] = useListGoodReview(onListGoodReview);
  const ListGoodReviewHandler = (goodId: number) => {
    const input: ListGoodReviewInput = {
      page: 1,
      row,
      goodId,
    };
    listMyGoodReview({ variables: { input } });
  };

  // useeffect
  useEffect(() => {
    pageIndicator("상품 후기");
    if (param.goodId) {
      ListGoodReviewHandler(Number(param.goodId));
    }
  }, []);

  return (
    <div className="h-full relative flex flex-wrap flex-col bg-bg">
      <header className="border-b">
        <TopBar arrow={true} home={true} heading={true} exit={true} />
      </header>
      <Slide in={true} direction="left">
        <main className="w-full p-4 flex-1 overflow-auto pb-[72px] space-y-4">
          {listGoodReview?.listGoodReview.goodReviews &&
            listGoodReview?.listGoodReview.goodReviews.map((review) => (
              <div className="bg-white" key={`review-${review.id}`}>
                <div className="review border-2 rounded-lg p-2 fit:p-4">
                  <div className="text-small leading-4 flex flex-wrap content-center justify-between">
                    <p className="flex flex-wrap content-center justify-center">
                      {review.createdAt && <span className="font-bold">{review.good.title}</span>}
                    </p>
                    {review?.author?.id === me?.me.id && (
                      <button
                        onClick={() => {
                          navigate(`/editReview/${review.id}`);
                        }}
                        className="text-grayFont rounded-lg border border-btnLightLine px-2 py-1"
                      >
                        수정
                      </button>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-x-1 pt-1">
                    <p className="py-1 px-1 fit:px-4 flex flex-wrap content-center gap-x-1">
                      <img className="w-4 h-4" src={review.rating >= 1 ? star_yellow : star_gray} alt="별점" />
                      <img className="w-4 h-4" src={review.rating >= 2 ? star_yellow : star_gray} alt="별점" />
                      <img className="w-4 h-4" src={review.rating >= 3 ? star_yellow : star_gray} alt="별점" />
                      <img className="w-4 h-4" src={review.rating >= 4 ? star_yellow : star_gray} alt="별점" />
                      <img className="w-4 h-4" src={review.rating >= 5 ? star_yellow : star_gray} alt="별점" />
                    </p>
                    <p className="text-darkFont text-xs flex flex-wrap content-center px-1 fit:px-2 rightDivide">
                      {emailMasking(String(review?.author?.username))}
                    </p>
                    <p className="text-grayFont text-[10px] flex flex-wrap content-center px-1">{`${moment(review.createdAt).format(
                      "YYYY.MM.DD"
                    )}`}</p>
                  </div>
                  <div className="orderBox space-y-4">
                    {/* map component order */}
                    <div>
                      <div
                        className={`pt-1 grid  gap-x-2 overflow-hidden
                       grid-cols-2
                    `}
                      >
                        {review?.goodReviewPosters &&
                          review?.goodReviewPosters.map((poster, index, posterArray) => (
                            <div key={`review-poster-${index}`} className={`p-1 relative `}>
                              <img
                                src={`${poster.upload?.url}`}
                                alt={`${review.author} 리뷰`}
                                className={`rounded-lg  aspect-[140/100]
                                `}
                              />
                            </div>
                          ))}
                      </div>
                      <div className="mt-1 py-2">
                        <p className={`px-1 text-small overflow-hidden whitespace-pre-wrap`}>{`${review.note}`}</p>
                      </div>
                    </div>
                    {/* order end */}
                  </div>
                </div>
              </div>
            ))}
        </main>
      </Slide>
      <button
        onClick={() => {
          navigate(-1);
        }}
        className="absolute w-12 h-12 rounded-full border-2 border-hintFont left-2 bottom-20 flex flex-wrap content-center justify-center"
      >
        <img src={backwardArrow} alt="뒤로가기" />
      </button>
      <button
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        className="absolute w-12 h-12 rounded-full border-2 border-hintFont bottom-20 right-2"
      >
        <img src={arrow} alt="위로가기" className="-mt-1 mx-auto trnasform -rotate-90" />
        <p className="text-center text-xs leading-tight text-hintFont">TOP</p>
      </button>
      {goodReviewLoading && <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-20"></div>}
      <FootMenu />
    </div>
  );
};
