import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  IChangePasswordForm,
  useUpUserPassword,
} from "../hooks/useUpUserPassword";
import { useGetUpUserCertData } from "../hooks/useGetUpUserCert";

import { useMe } from "../hooks/useME";
import { IMP_ID } from "../constants/imp";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import { AlertComponent } from "../components/alert";
import {
  GetUpUserCertDataMutation,
  GetUpUserCertInput,
  UpUserPasswordInput,
  UpUserPasswordMutation,
} from "../gql/graphql";

interface Pass {
  kg?: number | null;
}

export const SubUserPassword: React.FC<Pass> = ({ kg }) => {
  const [kgCert, setKgCert] = useState<number>(kg || 0);
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const closeAlert = () => {
    setAlertOpen(false);
    window.location.href = "/mypage";
  };

  const { data: me } = useMe();
  // 비밀번호 변경
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<IChangePasswordForm>({
    mode: "onChange",
  });
  const onChangePass = (data: UpUserPasswordMutation) => {
    const {
      upUserPassword: { ok, err },
    } = data;
    if (ok) {
      setAlertText("비밀번호가 변경되었습니다.");
      setAlertOpen(true);
      // alert("비밀번호가 변경되었습니다.");
      // window.location.reload();
    } else {
      alert(err);
    }
  };
  const [upUserPassword] = useUpUserPassword(onChangePass);
  const onPwdSubmit = () => {
    if (kgCert !== 1) {
      setKgCert(2);
      return;
    }
    // const { password, confirm_password, newPassword } = getValues();
    const { confirm_password, newPassword } = getValues();
    if (newPassword !== confirm_password) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }
    const upUserPasswordInput: UpUserPasswordInput = {
      // password,
      newPassword,
      username: me?.me.username,
    };
    upUserPassword({
      variables: {
        input: upUserPasswordInput,
      },
    });
  };
  // 인증
  const onCertCompleted = (data: GetUpUserCertDataMutation) => {
    const {
      getUpUserCertData: { ok, err, user },
    } = data;
    if (ok && user) {
      setKgCert(1);
    } else {
      alert(err);
    }
  };
  const [getCertData] = useGetUpUserCertData(onCertCompleted);
  const getCertDataHandler = (imp_uid: string) => {
    const getUpUserCertInput: GetUpUserCertInput = {
      imp_uid,
    };
    getCertData({
      variables: {
        input: getUpUserCertInput,
      },
    });
  };
  const certificationHandler = () => {
    const IMP = window.IMP;
    IMP?.init(IMP_ID);
    IMP?.certification(
      {
        popup: false,
        m_redirect_url:
          process.env.REACT_APP_URI === "dev"
            ? `http://localhost:3000/passMobileCertData/${me?.me.id}`
            : process.env.REACT_APP_URI === "test"
            ? `https://usertest.nilivery.com/passMobileCertData/${me?.me.id}`
            : `https://www.nilivery.com/passMobileCertData/${me?.me.id}`,
      },
      (rsp) => {
        // callback
        if (rsp.success) {
          getCertDataHandler(rsp.imp_uid);
        } else {
          setKgCert(2);
        }
      }
    );
  };
  return (
    <form
      onSubmit={handleSubmit(onPwdSubmit)}
      className="h-full flex flex-col justify-between pt-20"
    >
      <div className="flex flex-col flex-wrap justify-center gap-y-10 px-5">
        <div className=" flex flex-wrap flex-col px-4 gap-y-4 ">
          <div className="py-2 flex">
            <span className="py-2 pl-1 font-bold text-[20px] leading-6">
              비밀번호 변경
            </span>
          </div>
          <div className={`${kgCert != 1 ? "" : "hidden"} p-2`}>
            <p className="py-2 px-1 text-darkFont text-appSmall ">
              비밀번호 변경을 위해서
              <br /> 본인인증을 진행해주세요
            </p>
          </div>
          <div
            className={`${
              kgCert === 1 ? "flex flex-wrap flex-col gap-y-4" : "hidden"
            }`}
          >
            {/* <div>
              <p className="w-full py-1 pl-2 ">
                <span className="p-1 text-appSmall text-darkFont">
                  기존 비밀번호
                </span>
                <span className=" text-[#EC8C7F] text-Xsmall align-top">*</span>
              </p>
              <input
                type="password"
                {...register("password", {
                  required: `비밀번호를 입력해주세요`,
                })}
                className="text-standard text-darkFont w-full  h-10 py-2 px-5 font-bold rounded-lg border border-btnLightLine bg- focus:outline-mainIcon"
              />
            </div> */}
            <div>
              <p className="w-full py-1 pl-2 ">
                <span className="p-1 text-appSmall text-darkFont">
                  새 비밀번호
                </span>
                <span className=" text-[#EC8C7F] text-Xsmall align-top">*</span>
              </p>
              <input
                type="password"
                {...register("newPassword", {
                  required: `비밀번호를 입력해주세요`,
                  pattern: {
                    value:
                      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
                    message:
                      "• 영문, 숫자, 특수기호 포함 8~20자리로 작성해주세요",
                  },
                })}
                className="text-standard text-darkFont w-full  h-10 py-2 px-5 font-bold rounded-lg border border-btnLightLine bg- focus:outline-mainIcon"
              />
              {errors.newPassword?.message ? (
                <p className="text-shade1 text-Xsmall py-1 px-2">
                  {errors.newPassword?.message}
                </p>
              ) : (
                <p className="text-mainIcon text-Xsmall py-1 px-2">
                  • 영문, 숫자, 특수기호 포함
                </p>
              )}
            </div>
            <div>
              <p className="w-full py-1 pl-2 ">
                <span className="p-1 text-appSmall text-darkFont">
                  비밀번호 확인
                </span>
                <span className=" text-[#EC8C7F] text-Xsmall align-top">*</span>
              </p>
              <input
                type="password"
                {...register("confirm_password", {
                  validate: (value) =>
                    value === watch("newPassword") ||
                    "비밀번호가 일치하지 않습니다",
                })}
                className="text-standard text-darkFont w-full  h-10 py-2 px-5 font-bold rounded-lg border border-btnLightLine bg- focus:outline-mainIcon"
              />
              {errors.confirm_password?.message ? (
                <p className="text-shade1 text-Xsmall py-1 px-2">
                  {errors.confirm_password?.message}
                </p>
              ) : (
                <p className="text-mainIcon text-Xsmall py-1 px-2">
                  • 비밀번호가 일치합니다
                </p>
              )}
            </div>
          </div>
        </div>
        {kgCert !== 1 && (
          <div className="w-full py-2">
            <button
              onClick={certificationHandler}
              className="border border-mainIcon h-10 block w-full max-w-screen-fit text-mainIcon text-[20px] leading-6 font-bold  py-[7px] mx-auto rounded-[20px]"
            >
              본인인증하기
            </button>
          </div>
        )}
        {kgCert === 1 && (
          <div className="w-full flex flex-wrap max-w-screen-fit py-2 text-center mx-auto gap-x-4">
            <button
              type="button"
              className="flex-auto border h-10 border-btnLightLine  text-hintFont bg-[#fff] text-standard  rounded-full py-2 mx-auto"
              onClick={() => navigate(-1)}
            >
              취소
            </button>
            <button className="flex-auto h-10 text-white bg-mainIcon  text-standard rounded-full ">
              저장
            </button>
          </div>
        )}
      </div>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        className="relative"
      >
        <AlertComponent
          title={`알림`}
          desc={alertText}
          closeFunction={closeAlert}
        ></AlertComponent>
      </Dialog>
      {/* <div className="px-4 border border-btnLightLine rounded-lg">
        <div className={`py-4 ${kgCert != 1 ? "block" : "hidden"}`}>
          <div className="py-2 flex">
            <span className="py-2 pl-1 font-bold text-[20px] leading-6">
              비밀번호 변경
            </span>
          </div>
          {kgCert !== 1 && (
            <button
              onClick={certificationHandler}
              className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon"
            >
              인증하기
            </button>
          )}
          {kgCert === 1 && (
            <p className="text-standard break:text-large text-mainIcon p-2">
              인증완료
            </p>
          )}
          {kgCert === 2 && (
            <p className="text-standard break:text-large text-red-300 p-2">
              인증이 되지 않았습니다.
            </p>
          )}
        </div>
        <div className={`pt-2 ${kgCert === 1 ? "block" : "hidden"}`}>
          <div className="pt-2">
            <p className="text-standard break:text-large text-darkFont p-2">
              기존 비밀번호
            </p>
            <input
              type="password"
              {...register("password", {
                required: `비밀번호를 입력해주세요`,
              })}
              className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon"
            />
          </div>
          <div className="pt-2">
            <p className="text-standard break:text-large text-darkFont p-2">
              변경할 비밀번호
            </p>
            <input
              type="password"
              {...register("newPassword", {
                required: `비밀번호를 입력해주세요`,
                pattern: {
                  value:
                    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
                  message:
                    "• 영문, 숫자, 특수기호 포함 8~20자리로 작성해주세요",
                },
              })}
              className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon"
            />
            {errors.newPassword?.message ? (
              <p className="text-shade1 text-h5 mt-1 px-2">
                {errors.newPassword?.message}
              </p>
            ) : (
              <p className="text-mainIcon text-h5 mt-1 px-2">
                • 영문, 숫자, 특수기호 포함
              </p>
            )}
          </div>
          <div className="pt-2">
            <p className="text-standard break:text-large text-darkFont p-2">
              비밀번호 확인
            </p>
            <input
              type="password"
              {...register("confirm_password", {
                validate: (value) =>
                  value === watch("newPassword") ||
                  "비밀번호가 일치하지 않습니다",
              })}
              className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon"
            />
            {errors.confirm_password?.message ? (
              <p className="text-shade1 text-h5 mt-1 px-2">
                {errors.confirm_password?.message}
              </p>
            ) : (
              <p className="text-mainIcon text-h5 mt-1 px-2">
                비밀번호가 일치합니다
              </p>
            )}
          </div>

          <div className="py-4">
            <button className="px-8 py-2 text-white bg-darkFont font-bold text-small break:text-standardall rounded-lg mx-auto block">
              비밀번호 변경
            </button>
          </div>
        </div>
      </div> */}
    </form>
  );
};
