import { ApolloError } from "@apollo/client";
import Google from "../assets/icons/Google.svg";
import Kakao from "../assets/icons/Kakao.svg";
import { useForm } from "react-hook-form";
import { useLogIn } from "../hooks/useLogin";
import { logIn } from "../token";
import { useGoogleLogin } from "@react-oauth/google";
import main_logo from "../assets/icons/logo.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSnsLogIn } from "../hooks/useSnsLogin";
import { Dialog } from "@mui/material";
import { AlertComponent } from "../components/alert";
import {
  LoginInput,
  LoginMutation,
  Sns,
  SnsLoginInput,
  SnsLoginMutation,
} from "../gql/graphql";
export interface ILoginForm extends LoginInput {}

export const LogIn = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [text, setText] = useState("");
  const closeModal = () => {
    setAlertOpen(false);
  };

  const onSnsLogin = (data: SnsLoginMutation) => {
    const {
      snsLogin: { ok, err, token, exist, username },
    } = data;
    if (ok && exist && token) {
      logIn(token);
    } else if (ok && !exist) {
      const info = {
        email: username,
        sns: Sns.Google,
      };
      const state = JSON.stringify(info);
      navigate("/join/sns", { state });
    } else {
      setText(String(err));
      setAlertOpen(true);
    }
  };
  const [snsLogin] = useSnsLogIn(onSnsLogin);

  // 카카오 로그인
  const kakaoLogin = () => {
    const redirect_uri =
      process.env.REACT_APP_URI === "dev"
        ? "http://localhost:3000/kakaoLogin"
        : process.env.REACT_APP_URI === "test"
        ? "https://usertest.nilivery.com/kakaoLogin"
        : "https://nilivery.com/kakaoLogin";
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${redirect_uri}&response_type=code`;
    window.location.href = KAKAO_AUTH_URL;
  };
  // 구글 로그인
  const googleLoginFunc = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        }
      );
      if (userInfo.data.email) {
        const googleInput: SnsLoginInput = {
          username: userInfo.data.email,
          sns: Sns.Google,
        };
        snsLogin({
          variables: { input: googleInput },
        });
      } else {
        setText("잠시 후 다시 시도해주세요");
        setAlertOpen(true);
      }
    },
    onError: (errorResponse) => {
      setText("로그인에 실패하였습니다.");
      setAlertOpen(true);
    },
  });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<ILoginForm>({ mode: "onChange" });

  const onCompleted = (data: LoginMutation) => {
    const {
      login: { ok, token, err, id },
    } = data;
    if (ok && token) {
      logIn(token);
    } else {
      alert(err);
      if (id) {
        navigate(`/join/certification/${id}`);
      }
      setValue("password", "");
      return;
    }
  };

  const onError = (error: ApolloError) => {};

  const [loginMutation, { loading }] = useLogIn(onCompleted);

  const onSubmit = () => {
    if (!loading) {
      const { username, password } = getValues();
      const loginInput: LoginInput = { username, password };
      loginMutation({
        variables: {
          loginInput,
        },
      });
    }
  };

  const onInvalid = () => {};
  useEffect(() => {
    pageIndicator("로그인");
  }, []);
  return (
    <div className="w-full overflow-auto bg-bg relative h-full">
      <div className="flex flex-col h-full">
        <div className="bg-white">
          <TopBar arrow={true} heading={true} home={true} />
        </div>
        <div className="flex-1 px-6 flex flex-wrap  py-4">
          <img
            src={main_logo}
            alt="닐리버리 로고"
            className=" mx-auto max-w-[240px] object-contain"
          />
          <div className="py-4 w-full">
            <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
              <div className="mx-auto w-full  py-2 max-w-screen-break">
                <div className="fieldBox w-full max-w-[312px] mx-auto">
                  <label
                    htmlFor="login_id"
                    className="text-h3 w-full block text-darkFont mb-1 px-1"
                  >
                    <span>이메일주소</span>
                    <span className="ml-1 text-[#EC8C7F] text-sm leading-none align-top">
                      *
                    </span>
                  </label>

                  <input
                    placeholder="이메일을 입력해주세요"
                    {...register("username", {
                      required: "이메일은 필수 입력 정보입니다.",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "이메일 형식에 맞게 입력해주세요",
                      },
                    })}
                    type="text"
                    className="px-4 py-2 border border-btnDarkLine text-h3 w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                    id="login_id"
                  />
                  <div className="h-5">
                    {errors.username?.message && (
                      <p className="text-shade1 text-Xsmall py-1 px-2">
                        {errors.username?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="fieldBox w-full max-w-[312px] mx-auto">
                  <label
                    htmlFor="login_pwd"
                    className="text-h3 w-full block text-darkFont mb-1 px-1"
                  >
                    <span>비밀번호</span>
                    <span className="ml-1 text-[#EC8C7F] text-sm leading-none align-top">
                      *
                    </span>
                  </label>
                  <input
                    placeholder="비밀번호를 입력해주세요"
                    {...register("password", {
                      required: `비밀번호는 필수 입력 정보입니다.`,
                      minLength: 8,
                      maxLength: 20,
                    })}
                    type="password"
                    className="px-4 py-2 border border-btnDarkLine text-h3 w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                    id="login_pwd"
                  />
                  {errors.password?.message ? (
                    <p className="text-shade1 text-Xsmall py-1 px-2">
                      {errors.password?.message}
                    </p>
                  ) : (
                    <p className="text-mainIcon text-Xsmall py-1 px-2">
                      • 영문, 숫자, 특수기호 포함 8~20자리로 작성해주세요
                    </p>
                  )}
                </div>
              </div>
              <div className="py-2 mx-auto max-w-[312px] grid grid-cols-2 gap-x-4 justify-center">
                <button
                  type="button"
                  onClick={() => navigate("/join/agree")}
                  className="bg-white border border-mainIcon font-bold text-mainIcon rounded-full text-base w-full py-2"
                >
                  회원가입
                </button>
                <button
                  type="submit"
                  className="bg-mainIcon border border-mainIcon font-bold text-white rounded-full text-base w-full  py-2"
                >
                  로그인
                </button>
              </div>
              <div className="w-full max-w-[320px] mx-auto h-[26px] py-2 flex flex-wrap content-center">
                <p className="pt-2 w-full text-small break:text-standardall text-right px-4">
                  <Link
                    to="/findUserInfo"
                    state={1}
                    className="hover:underline"
                  >
                    아이디 찾기
                  </Link>{" "}
                  /{" "}
                  <Link
                    to="/findUserInfo"
                    state={2}
                    className="hover:underline"
                  >
                    비밀번호 찾기
                  </Link>
                </p>
              </div>
            </form>
            <div className="pt-2 text-center px-4 flex flex-col w-full gap-y-2">
              <button
                onClick={kakaoLogin}
                type="button"
                className="px-5 gap-x-4 flex flex-wrap content-center w-full max-w-[320px] h-[45px] mx-auto rounded-md bg-[#FEE500] text-black"
              >
                <img src={Kakao} alt="카카오 아이콘" />
                <p className="flex-1 text-sm">카카오톡으로 시작</p>
              </button>
              <button
                onClick={() => googleLoginFunc()}
                type="button"
                className="px-5 gap-x-4 flex flex-wrap content-center w-full max-w-[320px] h-[45px] mx-auto rounded-md bg-[#fff] text-[#0000008A] inner-border inner-border-btnLightLine"
              >
                <img src={Google} alt="구글 아이콘" />
                <p className="flex-1 text-sm">Google로 시작</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <AlertComponent
          title={`알림`}
          desc={`${text}`}
          closeFunction={closeModal}
        ></AlertComponent>
      </Dialog>
    </div>
  );
};
