import React, { useEffect, useState } from "react";
import { ICrUserFormProps, useCrUser } from "../hooks/useCrUser";
import { Dialog } from "@mui/material";
import { useForm } from "react-hook-form";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import { useNavigate, useParams } from "react-router-dom";
import { useDelUser } from "../hooks/useDelUser";
import { AlertComponent } from "../components/alert";
import { CrUserInput, CrUserMutation, DelUserInput, DelUserMutation } from "../gql/graphql";

export const JoinDataform = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [certModal, setCertModal] = useState<boolean>(false);
  const [idExist, setIdExist] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [alertId, setAlertId] = useState<number | null>(0);
  const closeAlert = () => {
    setAlertOpen(false);
    if (alertId) navigate(`/join/certification/${alertId}`);
    // navigate(-1);
  };

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<ICrUserFormProps>({
    mode: "onChange",
  });

  const onCompleted = async (data: CrUserMutation) => {
    const {
      crUser: { ok, err, id },
    } = data;
    if (ok) {
      setAlertText("계정정보가 저장되었습니다.");
      setAlertId(id != undefined ? id : null);
      setAlertOpen(true);
    } else {
      if (id) {
        setCertModal(true);
        setIdExist(id);
      } else {
        alert(err);
      }
    }
  };
  const [crUser, { data: crUserResult, loading }] = useCrUser(onCompleted);

  const onSubmit = () => {
    const { name, password, confirmPassword, username, phone } = getValues();
    if (password !== confirmPassword) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }
    const crUserInput: CrUserInput = {
      password,
      name,
      phone,
      username,
    };
    crUser({
      variables: {
        input: crUserInput,
      },
    });
  };
  const onInvalid = () => {};

  // 계정 다시 생성
  const delCompleted = (data: DelUserMutation) => {
    const {
      delUser: { ok, err },
    } = data;
    if (ok) {
      document.location.href = "/join/dataform";
    } else {
      alert(err);
    }
  };
  const [delUser, { data: delUserData }] = useDelUser(delCompleted);
  const delUserHandler = () => {
    const delUserInput: DelUserInput = {
      id: idExist,
    };
    delUser({
      variables: {
        input: delUserInput,
      },
    });
  };
  useEffect(() => {
    pageIndicator("회원가입");
  }, []);

  return (
    <div className="w-full  h-full overflow-auto">
      <form onSubmit={handleSubmit(onSubmit, onInvalid)} className="relative h-full flex flex-col">
        <div className="relative flex-1 flex flex-wrap flex-col">
          <div className="border-b-[0.5px]">
            <TopBar arrow={true} heading={true} exit={true} />
          </div>

          <div className="px-9 flex flex-col flex-wrap flex-1 gap-y-5 pt-[34px]">
            <div className="fieldBox w-full">
              <label htmlFor="join_email" className="text-h3 w-full block text-darkFont pl-2 py-1">
                <div className="flex flex-wrap">
                  <span className="p-1  text-appSmall">이메일주소</span>
                  <span className=" text-[#EC8C7F] text-Xsmall align-top">*</span>
                </div>
              </label>
              <input
                {...register(`username`, {
                  required: "• 이메일을 입력해주세요",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "• 이메일을 입력해주세요",
                  },
                })}
                placeholder="이메일 주소를 입력해주세요"
                type="email"
                className="pl-5 pr-[6px] py-2 border border-btnDarkLine text-standard w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                id="join_email"
              />
              {errors.username?.message ? (
                <p className="text-shade1 text-Xsmall py-1 px-2">{errors.username?.message}</p>
              ) : (
                <p className="text-mainIcon text-Xsmall py-1 px-2">• 아이디로 사용됩니다.</p>
              )}
            </div>
            <div className="fieldBox w-full">
              <label htmlFor="join_password" className="text-h3 w-full block text-darkFont pl-2 py-1">
                <div className="flex flex-wrap">
                  <span className="p-1  text-appSmall">비밀번호</span>
                  <span className=" text-[#EC8C7F] text-Xsmall align-top">*</span>
                </div>
              </label>
              <input
                {...register(`password`, {
                  required: "• 비밀번호는 필수 입력 정보입니다.",
                  pattern: {
                    value: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*\-_])[a-zA-Z0-9!@#$%^&*\-_]{8,20}$/,
                    message: `특수문자 < > ( ) " ' + = 는 사용할수 없습니다.`,
                  },
                })}
                placeholder="숫자,영문,특수문자 조합 최소 8자"
                type="password"
                className="pl-5 pr-[6px] py-2 border border-btnDarkLine text-standard w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                id="join_password"
              />
              <div className="h-5">
                {
                  errors.password?.message && <p className="text-shade1 text-Xsmall py-1 px-2">{errors.password?.message}</p>
                  //  : (
                  //   <p className="text-mainIcon text-Xsmall py-1 px-2">
                  //     • 영문, 숫자, 특수기호 포함
                  //   </p>
                  // )
                }
              </div>
            </div>
            <div className="fieldBox w-full">
              <label htmlFor="join_password_check" className="text-h3 w-full block text-darkFont pl-2 py-1">
                <div className="flex flex-wrap">
                  <span className="p-1  text-appSmall">비밀번호 확인</span>
                  <span className=" text-[#EC8C7F] text-Xsmall align-top">*</span>
                </div>
              </label>
              <input
                {...register("confirmPassword", {
                  validate: (value) => value === watch("password") || "비밀번호가 일치하지 않습니다",
                })}
                placeholder="비밀번호 재입력"
                type="password"
                className="pl-5 pr-[6px] py-2 border border-btnDarkLine text-standard w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                id="join_password_check"
              />
              {errors.confirmPassword?.message ? (
                <p className="text-shade1 text-Xsmall py-1 px-2">{errors.confirmPassword?.message}</p>
              ) : (
                <p className="text-mainIcon text-Xsmall py-1 px-2">비밀번호가 일치합니다</p>
              )}
            </div>
            {/*  */}
            {/* <div className="fieldBox w-full">
              <label
                htmlFor="join_name"
                className="text-h3 w-full block text-darkFont mb-2 px-1"
              >
                본인인증
              </label>
              {kgCert !== 1 && (
                <button
                  onClick={certificationHandler}
                  className="text-small break:text-standard text-white bg-mainIcon rounded-lg h-10 py-2 px-4 font-bold "
                >
                  인증하기
                </button>
              )}
              {kgCert === 1 && (
                <p className="text-standard break:text-large text-mainIcon p-2">
                  인증완료
                </p>
              )}
              {kgCert === 2 && (
                <p className="text-standard break:text-large text-red-300 p-2">
                  인증이 되지 않았습니다.
                </p>
              )}
            </div> */}
            {/*  */}
            <div className="fieldBox w-full">
              <label htmlFor="join_name" className="text-h3 w-full block text-darkFont pl-2 py-1">
                <div className="flex flex-wrap">
                  <span className="p-1  text-appSmall">이름</span>
                  <span className=" text-[#EC8C7F] text-Xsmall align-top">*</span>
                </div>
              </label>
              <input
                {...register(`name`, {
                  required: "• 이름은 필수 입력 정보입니다.",
                })}
                placeholder="이름을 입력해주세요"
                type="text"
                className="pl-5 pr-[6px] py-2 border border-btnDarkLine text-standard w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                id="join_name"
              />
              <div className="h-5">{errors.name?.message && <p className="text-shade1 text-Xsmall py-1 px-2">{errors.name?.message}</p>}</div>
            </div>
            <div className="fieldBox w-full">
              <label htmlFor="join_phone" className="text-h3 w-full block text-darkFont pl-2 py-1">
                <div className="flex flex-wrap">
                  <span className="p-1  text-appSmall">전화번호</span>
                  <span className=" text-[#EC8C7F] text-Xsmall align-top">*</span>
                </div>
              </label>
              <input
                {...register("phone", {
                  pattern: {
                    value: /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/,
                    // value: /^[0-9]{10,11}$/,
                    message: "-빼고 번호만 입력해주세요",
                  },
                  required: "전화번호는 필수 입력 정보입니다.",
                })}
                placeholder={`"-" 빼고 숫자만 입력해주세요`}
                type="tel"
                maxLength={11}
                onKeyUp={(e) => (e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, ""))}
                className="pl-5 pr-[6px] py-2 border border-btnDarkLine text-standard w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                id="join_phone"
              />
              <div className="h-5"> {errors.phone?.message && <p className="text-shade1 text-Xsmall py-1 px-2">{errors.phone.message}</p>}</div>
            </div>
          </div>
        </div>
        <div className="w-full px-9 py-2">
          <button
            type="submit"
            className="border border-mainIcon block w-full max-w-screen-fit text-mainIcon text-[20px] leading-6 font-bold  py-[7px] mx-auto rounded-[20px]"
          >
            회원가입
          </button>
        </div>
      </form>
      <Dialog
        open={certModal}
        onClose={() => {
          setCertModal(!certModal);
        }}
      >
        <div className="w-full max-w-[304px]  bg-white  rounded-2xl">
          <div className="flex flex-wrap">
            <h1 className="w-full text-center py-6 text-[#00B0EB] text-[16px] leading-5 font-bold ">안내</h1>
            <div className="w-full p-4">
              <p id="innerHtml" className="w-full text-center px-4 pb-6 text-[16px] leading-5 keep-all ">
                이미 가입되어 있습니다. <br /> 하지만 인증되지 않은 계정입니다.
              </p>
              <p className="w-full text-center px-4 pb-6 text-[16px] leading-5 keep-all font-bold">계정인증을 진행해주세요</p>
            </div>
            <div className="w-full">
              <button
                onClick={() => setCertModal(!certModal)}
                className="w-1/2 py-4  text-grayFont text-[15px] leading-5  border-t-[1px] border-r-[1px] border-[#DCDCE7]"
              >
                취소
              </button>
              <button
                onClick={() => {
                  navigate(`/join/certification/${idExist}`);
                }}
                className="w-1/2 py-4  text-[#00B0EB] text-[15px] leading-5  border-t-[1px] border-[#DCDCE7] font-bold"
              >
                확인
              </button>
            </div>
          </div>
        </div>
        {/* <div className="w-[304px] flex flex-wrap flex-col  bg-white absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-2xl">
          <div className="w-full flex-1 flex flex-wrap content-center justify-center p-10 font-bold text-center">
            <p className="py-1 text-lg text-mainIcon">
              안내
            </p>
            <p className="py-1 text-sm">이미 가입되어있습니다.</p>
            <p className="py-1 text-sm">하지만 인증되지 않은 계정입니다.</p>
          </div>
          <div className="w-full">
            <button
              onClick={() => {
                delUserHandler();
              }}
              className=" w-1/2 py-4 rounded-bl-2xl  font-bold border-t border-r"
            >
              다시 생성하기
            </button>
            <button
              onClick={() => {
                navigate(`/join/certification/${idExist}`);
              }}
              className="  w-1/2 py-4 rounded-br-2xl font-bold border-t"
            >
              계정 인증하기
            </button>
          </div>
        </div> */}
      </Dialog>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        className="relative"
      >
        <AlertComponent title={`알림`} desc={alertText} closeFunction={closeAlert}></AlertComponent>
      </Dialog>
    </div>
  );
};
