import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUpUserCertData } from "../hooks/useGetUpUserCert";
import logo from "../assets/icons/logo.png";
import { GetUpUserCertDataMutation, GetUpUserCertInput } from "../gql/graphql";
export const PassMobileCertification = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [kgCert, setKgCert] = useState<number>(1);
  const [tabs, setTabs] = useState<number>(1);
  var useOnce = false;

  const onCertCompleted = (data: GetUpUserCertDataMutation) => {
    const {
      getUpUserCertData: { ok, err, user },
    } = data;
    if (ok && user) {
      navigate("/mypage/change", { state: kgCert });
    } else {
      alert(err);
      navigate("/mypage/change");
    }
  };
  const [getCertData] = useGetUpUserCertData(onCertCompleted);
  const getCertDataHandler = (imp_uid: string) => {
    const getUpUserCertInput: GetUpUserCertInput = {
      imp_uid,
    };
    getCertData({
      variables: {
        input: getUpUserCertInput,
      },
    });
  };

  useEffect(() => {
    if (useOnce === false) {
      useOnce = true;
      var success = params.cert
        ? window.location.search.split("&")[1].split("=")[1]
        : "";
      if (success === "false") {
        navigate("/certificationFail", { state: kgCert });
        // document.location.href = "/certificationFail";
        return;
      } else {
        var imp_uid = params.cert
          ? window.location.search.split("&")[0].split("=")[1]
          : "";
        const userId = Number(params.cert);

        if (!userId || !imp_uid || imp_uid === "") alert("잘못된 접근입니다");
        if (imp_uid) {
          getCertDataHandler(imp_uid);
        }
      }
    }
  }, []);
  return (
    <div className="wrap max-w-screen-break min-h-screen mx-auto w-full pb-32 bg-slate-100 bg-opacity-100">
      <main className="max-w-screen-break relative pt-12 px-8">
        <img src={logo} alt="결제완료 닐리버리 로고" className="mx-auto " />
        <p className="pt-20 text-center text-mainIcon font-bold text-large">
          인증 데이터를 불러오고 있습니다.
        </p>
      </main>
    </div>
  );
};
