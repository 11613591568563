import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import chevron_right from "../assets/icons/chevron_right.svg";
import drone_off from "../assets/icons/drone_off.svg";
import drone_on from "../assets/icons/drone_on.svg";
import done_off from "../assets/icons/done_off.svg";
import done_on from "../assets/icons/done_on.svg";
import last_on from "../assets/icons/last_on.svg";
import last_off from "../assets/icons/last_off.svg";
import storage_off from "../assets/icons/storage_off.svg";
import storage_on from "../assets/icons/storage_on.svg";
import main_h_back from "../assets/images/main_h_back.svg";

import { pageIndicator } from "../apollo";
import { TopBar } from "../components/topBar";
import { useGetOrder } from "../hooks/useGetOrder";
import { DeliveryTracking } from "../components/deliveryTracking";
import { GetOrderInput, GetOrderQuery, OrderStatus, Redelivery } from "../gql/graphql";

export const GetOrderStatus = () => {
  const navigate = useNavigate();

  const params = useParams();
  // orderstatus
  const onGetComplete = async (data: GetOrderQuery) => {
    const {
      getOrder: { ok, err },
    } = data;
    if (ok) {
    } else {
      alert(err);
    }
  };
  const [getOrder, { data: order, loading: upStatusLoading }] = useGetOrder(onGetComplete);
  const getOrderHandler = (orderId: number) => {
    const input: GetOrderInput = {
      orderId,
    };
    getOrder({ variables: { input } });
  };
  // useeffect
  useEffect(() => {
    pageIndicator("배송조회");
    if (params.orderId) {
      getOrderHandler(Number(params.orderId));
    }
  }, []);

  return (
    <div id="orderStatus" className="relative h-full overflow-y-auto removeScroll">
      <TopBar arrow={true} arrowColor={"#777B7D"} heading={true} headingColor={"text-darkFont"} shop={true} />
      <div className="header relative">
        <div className="niliveryHeaderBack absolute left-0 top-0 w-full h-[200px] bg-mainIcon"></div>
        <div className="relative px-[10px] yeonL py-5">
          <div className="relative">
            <img src={main_h_back} alt="헤더 배경: 구름 두 조각" className="mx-auto" />
            <h2 className="absolute left-0 bottom-0 w-full text-center text-white font-bold text-2xl">닐리버리</h2>
          </div>
          <h3 className="mt-4 py-2 px-1 text-center text-white text-sm">
            <span className="font-bold">리얼퀵배송</span> 드론으로 경험하는 스마트한 배송의 시작
          </h3>
        </div>
      </div>
      <div className="icons relative px-4">
        <div className="bg-main pt-6 pb-4 rounded-2xl flex flex-wrap justify-center">
          <div className="flex flex-col gap-y-2">
            {order?.getOrder.order.status === OrderStatus.Pending ||
            order?.getOrder.order.status === OrderStatus.Inspect ||
            order?.getOrder.order.status === OrderStatus.Bound ? (
              <>
                <img className="w-8 mx-auto" src={storage_on} alt="상품준비중 아이콘 - 활성화" />
                <p className="py-[6px] px-1 text-mainIcon text-xs leading-[14px] font-bold">상품준비중</p>
              </>
            ) : (
              <>
                <img className="w-8 mx-auto" src={storage_off} alt="상품준비중 아이콘 - 비활성화" />
                <p className="py-[6px] px-1 text-darkFont text-xs leading-[14px]">상품준비중</p>
              </>
            )}
          </div>
          <div className="pt-1">
            <img src={chevron_right} alt="" />
          </div>
          <div className="flex flex-col gap-y-2">
            {order?.getOrder.order.status === OrderStatus.Stored ||
            order?.getOrder.order.status === OrderStatus.Drone ||
            order?.getOrder.order.status === OrderStatus.Port ? (
              <>
                <img className="w-8 mx-auto" src={drone_on} alt="드론 아이콘 - 활성화" />
                <p className="py-[6px] px-1 text-mainIcon text-xs leading-[14px] font-bold">드론배송중</p>
              </>
            ) : (
              <>
                <img className="w-8 mx-auto" src={drone_off} alt="드론 아이콘 - 비활성화" />
                <p className="py-[6px] px-1 text-darkFont text-xs leading-[14px]">드론배송중</p>
              </>
            )}
          </div>
          <div className="pt-1">
            <img src={chevron_right} alt="" />
          </div>
          <div className="flex flex-col gap-y-2">
            {order?.getOrder.order.status === OrderStatus.HandOut || order?.getOrder.order.status === OrderStatus.PendingDelivered ? (
              <>
                <img className="w-8 mx-auto" src={last_on} alt="라스트배송 아이콘 - 활성화" />
                <p className="py-[6px] px-1 text-mainIcon text-xs leading-[14px] font-bold">라스트배송</p>
              </>
            ) : (
              <>
                <img className="w-8 mx-auto" src={last_off} alt="라스트배송 아이콘 - 비활성화" />
                <p className="py-[6px] px-1 text-darkFont text-xs leading-[14px]">라스트배송</p>
              </>
            )}
          </div>
          <div className="pt-1">
            <img src={chevron_right} alt="" />
          </div>
          <div className="flex flex-col gap-y-2">
            {order?.getOrder.order.status === OrderStatus.Delivered ? (
              <>
                <img className="w-8 mx-auto" src={done_on} alt="상품준비중 아이콘 - 활성화" />
                <p className="py-[6px] px-1 text-mainIcon text-xs leading-[14px] font-bold">배송완료</p>
              </>
            ) : (
              <>
                <img className="w-8 mx-auto" src={done_off} alt="상품준비중 아이콘 - 비활성화" />
                <p className="py-[6px] px-1 text-darkFont text-xs leading-[14px]">배송완료</p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="orderStatus px-4 pt-6">
        <div className="p-4 rounded-xl border border-btnLightLine">
          <div className="flex flex-col gap-y-2">
            <div className={`flex flex-warp gap-x-4`}>
              {order?.getOrder.order.orderReceipt?.good_poster_url && (
                <img
                  src={`${order?.getOrder.order.orderReceipt?.good_poster_url}`}
                  alt={`${order.getOrder.order.orderReceipt.good_title} 이미지`}
                  onClick={() => {
                    navigate(`/detail/${order.getOrder.order.good?.id}`);
                  }}
                  className="h-[72px] aspect-square rounded-lg cursor-pointer"
                />
              )}
              <div className="flex-1 flex flex-wrap flex-col ">
                <p className="h-[46px] px-1 py-2 text-xs overflow-hidden ellipsis2">{`${order?.getOrder.order.orderReceipt?.good_title}`}</p>
                <div className="flex flex-wrap justify-between px-2 py-1 bg-[#FAFAFF] rounded-[20px]">
                  <p className="flex flex-wrap gap-x-2">
                    <span className="py-[3px] font-[#777B7D] text-Xsmall">수량</span>
                    <span className="font-bold py-[1px] px-4 rounded-[20px] bg-[#fff] text-smallBtn">
                      {order?.getOrder.order.orderReceipt?.order_quantity}
                    </span>
                  </p>
                  <p className=" text-smallBtn font-darkFont">
                    {order?.getOrder.order.redelivery === Redelivery.Redelivery ? "재배송" : order?.getOrder.order.amount.toLocaleString() + " 원"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-8 px-3">
            <DeliveryTracking orderStatus={order?.getOrder.order.status} />
          </div>
        </div>
      </div>

      {/* order end */}
    </div>
  );
};
