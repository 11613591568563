import "./App.css";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar, isMobileVar } from "./apollo";
import { LoggedInRouter } from "./routers/logged-in-router";
import { LoggedOutRouter } from "./routers/logged-out-router";
import phoneImage from "./assets/images/phoneImage.png";
import droneImage from "./assets/images/droneImage.png";
import androidImage from "./assets/images/androidApp.png";
import appleImage from "./assets/images/appleApp.png";
import niliveryLogo from "./assets/images/niliveryLogo.svg";
import niliveryLogoWhite from "./assets/images/niliveryLogo_white.svg";
import backLogo from "./assets/icons/backLogos.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";

import "swiper/css";
import { useEffect, useRef, useState } from "react";
import cloud1 from "./assets/images/cloud1.svg";
import { Footer } from "./components/footer";
import { Link } from "react-router-dom";
export interface cloud {
  left?: number;
  top?: number;
  width?: number;
  aspectRatio?: any;
}

function App() {
  // console.log(crypto.getRandomValues(new Uint32Array(1))[0] / 4294967296);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const ref = useRef(null);

  const [Clouds, setClouds] = useState<cloud[]>([]);
  var speed: number[] = [];

  for (var i = 0; i < 9; i++) {
    var cssValue: cloud = {
      left: Math.ceil(
        (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967296) *
          window.innerWidth -
          300
      ),
      top: Math.ceil(
        (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967296) *
          window.innerHeight
      ),
      width: Math.ceil(
        (crypto.getRandomValues(new Uint32Array(1))[0] / 4294967296) * 200 + 100
      ),
      aspectRatio: 0.3,
    };
    speed.push(crypto.getRandomValues(new Uint32Array(1))[0] / 4294967296);
    var newLists = Clouds;
    if (Clouds.length < 9) {
      newLists.push(cssValue);
      setClouds(newLists);
    }
  }
  const eventHandler = () => {
    window.location.href = "event";
  };

  useEffect(() => {
    setInterval(() => {
      let clouds = document.querySelectorAll(".cloud");
      for (let i = 0; i < clouds.length; i++) {
        var styleLeft = clouds[i]
          .getAttribute("style")
          ?.split(";")[0]
          .split(":")[1]
          .split("px")[0];
        if (Number(styleLeft) > 2000) {
          var newStyle = clouds[i]
            .getAttribute("style")
            ?.replace(String(styleLeft), String(Number(-300) + 0.8));
        } else {
          var newStyle = clouds[i]
            .getAttribute("style")
            ?.replace(String(styleLeft), String(Number(styleLeft) + speed[i]));
        }
        clouds[i].setAttribute("style", String(newStyle));
      }
    }, 20);
  }, [Clouds]);

  useEffect(() => {
    //@ts-ignore
    // console.log(ref.current.offsetWidth);
    //@ts-ignore
    if (ref?.current?.offsetWidth < 500) isMobileVar(true);
  }, []);
  return (
    <div
      ref={ref}
      id="backBoard"
      className=" w-full break:min-w-[1500px] h-full overflow-auto "
    >
      <div className="hidden break:block ">
        <div className="w-full h-full overflow-auto removeScroll flex flex-col">
          <div className="bg-[#32B2DF] h-[929px] flex w-full">
            <div className="flex flex-col pl-[105px] pt-20 gap-y-9 items-center">
              <img src={niliveryLogoWhite} className="w-[230px] flex" />
              <img
                src={phoneImage}
                className="  max-w-[475px] min-h-[1000px] "
              />
            </div>

            <div className="flex flex-col gap-y-12 pt-[150px] pl-20  ">
              <p className="text-[55px] leading-[70px] font-bold">
                언제 어디서나,
                <br /> 맛있는 음식이 생각날때,
                <br /> 계획하지 않아도,
                <br /> 문득 땡긴다면,
                <br /> 닐리, 닐리, 닐리리야,
                <br /> 닐리버리 하세요!
              </p>
              <p className="text-[30px] leading-10 font-medium">
                NILIVERY는
                <br />
                안정적인 비행과 속도로
                <br />
                언제 어디서든 ‘콜’한다면
                <br />
                멋지게 날아가 당신께 맛있는 음식을
                <br />
                전달할거에요.
                <br />
                드론 전문배송 서비스 - 닐리버리
              </p>
            </div>
            <div className="flex-1 relative">
              <img
                src={droneImage}
                className="absolute bottom-[80px] right-0 min-w-[400px] "
              />
            </div>
          </div>
          <div className="relative h-[675px] bg-white  z-10">
            <div className="flex flex-col justify-center items-center pt-[74px] gap-x-8">
              <div className="flex">
                <img src={androidImage} className="h-[72px]" />
                <div className="border-black h-[72px] border-l"></div>
                <img src={appleImage} className="h-[72px]" />
              </div>

              <div className="pt-[377px]">
                <img src={niliveryLogo} className="w-[284px] h-[65px]" />
                <div className="text-[#6F6F6F] font-medium text-[20px] leading-10 text-center">
                  Drone Delivery Service
                </div>
              </div>
            </div>
          </div>
          <div className="h-[200px] border-t border-[#C0C0C0]">
            <div className="bottom pt-4 px-4 text-small break:text-standardall leading-5 text-[#6c6c6c] ">
              <p className="font-bold text-center ">(주)니나노컴퍼니</p>
              <p className="text-center  ">
                <a
                  href="https://ninano.company"
                  target={"_blank"}
                  className="hover:underline"
                >
                  회사소개
                </a>{" "}
                |{" "}
                <a href="/personalInfo" className="hover:underline">
                  개인정보취급방침
                </a>{" "}
                |{" "}
                <a href="/useInfo" className="hover:underline">
                  서비스이용약관
                </a>
              </p>
              <p className="text-center keep-all">
                사업자번호 : 828-87-00831 | 대표 : 김건홍 | 연락처 :
                070-4759-3025 | 통신판매번호 : 2020-경북김천-0017호
              </p>
              <address className="block text-center not-italic">
                주소 : 경북 김천시 율곡동 1105 헥스테크노피아 2층
              </address>
            </div>
            <small className="block text-center text-[#bfbfbf]">
              Copyright (주)니나노컴퍼니 Inc. All Rights Reserved.
            </small>
          </div>
        </div>
      </div>

      <div className="block break:hidden max-w-screen-break break:w-full break:max-w-screen-fit break:py-16 h-full overflow-hidden relative">
        <div className="w-full h-full bg-white overflow-hidden break:rounded-2xl relative">
          {isLoggedIn ? <LoggedInRouter /> : <LoggedOutRouter />}
        </div>
      </div>
    </div>
  );
}

export default App;
