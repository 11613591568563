import { useEffect, useState } from "react";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import { FootMenu } from "../components/FootMenu";
import { SubListOrder } from "../sub-routes/sub-listOrder";
import { SubListOrderCancel } from "../sub-routes/sub-list-order-cancel";

export const OrderList = () => {
  const [page, setPage] = useState<number>(1);
  const [tabs, setTabs] = useState(window.location.search ? Number(window.location.search.split("=")[1]) : 0);

  // useeffect
  useEffect(() => {
    pageIndicator("주문 / 배송 내역조회");
  }, [page]);

  return (
    <div className="wrap flex flex-wrap flex-col  h-full relative">
      <header>
        <TopBar arrow={true} heading={true} shop={true} />
      </header>
      <main className="max-w-screen-break w-full flex-1 mx-auto overflow-auto removeScroll pb-[72px]">
        <div className="topBox bg-white flex flex-wrap content-center justify-between h-10 border-t-[0.5px]  w-full">
          <button
            className={`w-1/2 h-full border-b-[1.5px] ${tabs === 0 && " border-mainIcon"}`}
            onClick={() => {
              // window.history.pushState(null, "", "?cancel=0");
              setTabs(0);
            }}
          >
            주문 내역
          </button>
          <button
            className={`w-1/2 h-full border-b-[1.5px] ${tabs === 1 && " border-mainIcon"}`}
            onClick={() => {
              // window.history.pushState(null, "", "?cancel=1");
              setTabs(1);
            }}
          >
            주문 취소 내역
          </button>
        </div>

        {tabs === 0 && <SubListOrder />}
        {tabs === 1 && <SubListOrderCancel />}
      </main>

      <FootMenu />
    </div>
  );
};
