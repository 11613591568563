import main_logo from "../assets/icons/logo.png";
import { useForm } from "react-hook-form";
import { Dialog, Slide } from "@mui/material";
import { Helmet } from "react-helmet-async";
import {
  ISendChangePasswordEmailForm,
  useSendChangePasswordEmail,
} from "../hooks/useSendChangePasswordEmail";
import { TopBar } from "../components/topBar";
import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { AlertComponent } from "../components/alert";
import { useNavigate } from "react-router-dom";
import {
  SendChangePasswordEmailInput,
  SendChangePasswordEmailMutation,
} from "../gql/graphql";
export const FindPassword = () => {
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const closeAlert = () => {
    setAlertOpen(false);
    window.location.reload();
  };
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ISendChangePasswordEmailForm>({ mode: `all` });

  const onCompleted = (data: SendChangePasswordEmailMutation) => {
    const {
      sendChangePasswordEmail: { ok, err },
    } = data;
    if (ok) {
      setAlertText(
        `임시 비밀번호를 전달하였습니다. <br/> 이메일을 확인해주세요`
      );
      setAlertOpen(true);
    } else {
      alert(err);
    }
  };

  const [sendChangePasswordEmail, { loading }] =
    useSendChangePasswordEmail(onCompleted);

  const onSubmit = () => {
    if (!loading) {
      const { username } = getValues();

      const sendChangePasswordEmailInput: SendChangePasswordEmailInput = {
        username,
      };

      sendChangePasswordEmail({
        variables: { input: sendChangePasswordEmailInput },
      });
    }
  };
  useEffect(() => {
    pageIndicator("비밀번호 찾기");
  }, []);
  return (
    <div className="w-full h-full relative overflow-hidden">
      <Helmet title="닐리버리 - 비밀번호 찾기" />
      <div className="flex flex-col h-full">
        <TopBar
          arrow={true}
          arrowColor={`#B5C1D0`}
          heading={true}
          exit={true}
        />
        <nav className="w-full h-10 grid grid-cols-2">
          <button
            onClick={() => {
              navigate("/findMail");
            }}
            className="h-full w-full border-b-2 border-btnDarkLine "
          >
            아이디 찾기
          </button>
          <button className="h-full w-full border-b-2  border-mainIcon">
            비밀번호 찾기
          </button>
        </nav>
        <div className="flex-1 flex flex-wrap justify-center content-center">
          <Slide direction="left" in={true}>
            <div className="w-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mx-auto w-full px-6 py-2 pb-20 max-w-screen-break">
                  <h3 className="px-4 font-bold text-xl py-4">비밀번호 찾기</h3>
                  <div className="fieldBox px-4 mt-10 w-full">
                    <label
                      htmlFor="findPwd_username"
                      className="text-h3 w-full block text-darkFont px-2 py-1"
                    >
                      이메일 주소
                    </label>
                    <input
                      {...register("username", {
                        required: "가입하신 이메일 주소를 입력해주세요",
                      })}
                      type="text"
                      placeholder="이메일을 입력해주세요"
                      className="px-4 py-2 border border-btnDarkLine text-h3 w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                      id="findPwd_username"
                    />
                    <div className="h-5">
                      {errors.username?.message && (
                        <p className="text-shade1 text-Xsmall py-1 px-2">
                          {errors.username?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-4 flex flex-wrap justify-center gap-x-4">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="bg-white block border h-10 w-[136px] border-btnLightLine text-hintFont text-base font-bold rounded-full"
                  >
                    취소
                  </button>
                  <button
                    type="submit"
                    className="bg-mainIcon block border h-10 w-[136px] border-mainIcon text-white text-base font-bold rounded-full"
                  >
                    확인
                  </button>
                </div>
              </form>
            </div>
          </Slide>
        </div>
      </div>
      {loading && (
        <div className="absolute left-0 top-0 w-full h-full bg-slate-200 flex flex-wrap content-center justify-center">
          <p className="text-standar font-bold">메일을 전송 중입니다.</p>
        </div>
      )}
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        className="relative"
      >
        <AlertComponent
          title={`알림`}
          desc={alertText}
          closeFunction={closeAlert}
        ></AlertComponent>
      </Dialog>
    </div>
  );
};
