import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useListReceipt } from "../hooks/useListReceipt";
import { useCancelReceipt } from "../hooks/useUpReceiptCancel";
import { useEditOrderStatus } from "../hooks/useUpOrderStatus";
import { pageIndicator } from "../apollo";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import { Dialog } from "@mui/material";
import { AlertComponent, ConfirmComponent } from "../components/alert";
import { HowToReceive } from "../constants/props";
import {
  CancelReceiptMutation,
  EditOrderStatusInput,
  EditOrderStatusMutation,
  GetPgOptionInput,
  GetPgOptionQuery,
  ListReceiptInput,
  ListReceiptQuery,
  OrderStatus,
  ReceiptStatus,
  Redelivery,
  UpReceiptCancelInput,
} from "../gql/graphql";
import { IReceipt } from "../constants/types";
import { useGetPgOption } from "../hooks/useGetPgOption";
export const SubListOrder = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [cancelId, setCancelId] = useState<number>(0);
  const [orderId, setOrderId] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const closeConfirm = () => {
    setConfirmOpen(false);
  };
  const confirmFunction = () => {
    setConfirmOpen(false);
    if (cancelId && cancelId !== 0) {
      cancelReceiptHandelr(cancelId);
    }
    if (orderId && orderId !== 0) {
      editOrderStatusHandler(orderId);
    }
  };

  const closeAlert = () => {
    setAlertOpen(false);
    window.location.reload();
  };

  // collapse

  const pageHandler = (event: any, value: any) => {
    setPage(value);
  };

  // listreceipt by date
  const [listByDate, setListByDate] = useState<string[]>([]);
  const onListReceipt = (data: ListReceiptQuery) => {
    const {
      listReceipt: { ok, err, receipts },
    } = data;
    if (ok && receipts) {
      let uniqueDate: string[] = [];
      receipts.forEach((receipt) => {
        let payDates =
          receipt.paid_at && moment(+receipt.paid_at).format("YYYY.MM.DD");
        if (payDates && !uniqueDate.includes(payDates)) {
          uniqueDate.push(payDates);
        }
      });
      setListByDate(
        uniqueDate.sort((a, b) => {
          if (a > b) return -1;
          else if (b > a) return 1;
          else return 0;
        })
      );
    } else {
      console.log(err);
    }
  };
  const [
    listReceipt,
    { data: listReceiptData, loading: listReceiptLoading, refetch },
  ] = useListReceipt(onListReceipt);
  const listReceiptHandler = (page: number) => {
    const input: ListReceiptInput = {
      page,
    };
    listReceipt({ variables: { input } });
  };

  // 환불
  const IsPossibleToCancel = (receipt: IReceipt) => {
    var orders = receipt.orders;
    if (orders && orders.length > 0) {
      for (var order of orders) {
        if (order.status !== OrderStatus.Inspect) {
          return false;
        }
      }
    }
    return true;
  };

  const onRefunCompleted = (data: CancelReceiptMutation) => {
    const {
      cancelReceipt: { ok, err },
    } = data;
    if (ok) {
      setAlertText("주문이 취소되었습니다.");
      setAlertOpen(true);

      // alert("주문이 취소되었습니다.");
      // window.location.reload();
    } else {
      alert(err);
    }
  };
  const [cancelReceipt, { loading: cancelLoading }] =
    useCancelReceipt(onRefunCompleted);
  const cancelReceiptHandelr = (id: number) => {
    const input: UpReceiptCancelInput = {
      id,
    };
    cancelReceipt({ variables: { input } });
  };
  // orderstatus
  const onEditOrderStatus = async (data: EditOrderStatusMutation) => {
    const {
      editOrderStatus: { ok, err },
    } = data;
    if (ok) {
      setAlertText("구매 확정 되었습니다.");
      setAlertOpen(true);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [editOrderStatus, { loading: upStatusLoading }] =
    useEditOrderStatus(onEditOrderStatus);
  const editOrderStatusHandler = (orderId: number) => {
    const input: EditOrderStatusInput = {
      orderId,
      status: OrderStatus.Complete,
    };
    editOrderStatus({ variables: { input } });
  };

  /// 영수증 링크
  const onGetPgOption = (data: GetPgOptionQuery) => {
    const {
      getPgOption: { ok, err, pgOption },
    } = data;
    if (ok && pgOption) {
      if (pgOption.receipt_url)
        window.open(pgOption.receipt_url, "_blank", "noopener, noreferrer");
      console.log(pgOption.receipt_url);
    } else {
      console.log(err);
    }
  };
  const [getPgOption, { loading: getPgOptionLoading, data }] =
    useGetPgOption(onGetPgOption);
  const pgOptionHandler = (receiptId: number) => {
    const input: GetPgOptionInput = {
      receiptId,
    };
    getPgOption({ variables: { input } });
  };
  // useeffect
  useEffect(() => {
    pageIndicator("주문 / 배송 내역조회");
    listReceiptHandler(page);
  }, [page]);

  return (
    <section id="orderList" className="pb-8 pt-4">
      {!listReceiptLoading &&
        listByDate.map((date) => (
          <div className="px-4 pb-4" key={`receiptDate-${date}`}>
            <div className="receipt ">
              <div className="flex py-[3.25px] h-[40px] content-center bg-[#E8E8F4] rounded-t-2xl border-2  ">
                <p className="flex flex-wrap content-center flex-1 gap-x-1 px-6">
                  <span className="font-bold px-1 py-2">{`${date}`}</span>
                </p>
              </div>
              <div className="orderBox space-y-4 p-4 border-b border-x rounded-b-2xl">
                {/* map component order */}
                {listReceiptData?.listReceipt.receipts &&
                  listReceiptData?.listReceipt.receipts
                    .filter((list) => {
                      return (
                        moment(Number(list.paid_at)).format("YYYY.MM.DD") ===
                        date
                      );
                    })
                    .map((receipt) => (
                      <div
                        key={`receiptmap-${receipt.receiptNum}`}
                        className="space-y-4"
                      >
                        {receipt &&
                          receipt.orders?.map((order, index, array) => (
                            <div
                              key={`receipt-order-${order.id}`}
                              className="flex flex-col gap-y-2"
                            >
                              <div
                                className={`flex flex-warp gap-x-4 overflow-hidden ${
                                  index + 1 !== array.length &&
                                  "border-b border-[#F0F0F4]"
                                }`}
                              >
                                {order.good?.goodPosters && (
                                  <img
                                    src={`${order.good?.goodPosters[0].upload?.url}`}
                                    alt={`${order.goodName} 이미지`}
                                    onClick={() => {
                                      navigate(`/detail/${order.good?.id}`);
                                    }}
                                    className="h-[72px] aspect-square rounded-lg cursor-pointer"
                                  />
                                )}
                                <div className="flex-1 flex flex-wrap flex-col ">
                                  <p className="h-[46px] px-1 py-2 text-small break:text-standard flex flex-wrap content-center">
                                    <span className="overflow-hidden ellipsis2">{`${order.orderReceipt?.good_title}`}</span>
                                  </p>
                                  <div className="flex flex-wrap justify-between px-2 py-1 bg-[#FAFAFF] rounded-[20px]">
                                    <p className="flex flex-wrap gap-x-2">
                                      <span className="py-[3px] font-[#777B7D] text-Xsmall">
                                        수량
                                      </span>
                                      <span className="font-bold py-[1px] px-4 rounded-[20px] bg-[#fff] text-smallBtn">
                                        {order.orderReceipt?.order_quantity}
                                      </span>
                                      <span className="py-[3px] font-[#777B7D] text-Xsmall">
                                        개
                                      </span>
                                    </p>
                                    <p className=" text-smallBtn font-darkFont">
                                      {order.redelivery ===
                                      Redelivery.Redelivery
                                        ? "재배송"
                                        : order.amount.toLocaleString() + " 원"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-wrap gap-x-4">
                                {order.status === OrderStatus.Inspect && (
                                  <button className="px-[10px] py-1 rounded-lg text-smallBtn font-bold break:text-smallBtn bg-hintFont text-white border border-btnLightLine">
                                    배송준비중
                                  </button>
                                )}
                                {/* {order.status === OrderStatus.Exchange && (
                                  <button className="px-[10px] py-1 rounded-lg text-smallBtn font-bold break:text-smallBtn bg-hintFont text-white border border-btnLightLine">
                                    교환
                                  </button>
                                )}
                                {order.status === OrderStatus.Refunded && (
                                  <button className="px-[10px] py-1 rounded-lg text-smallBtn font-bold break:text-smallBtn bg-hintFont text-white border border-btnLightLine">
                                    환불
                                  </button>
                                )} */}
                                {order.status === OrderStatus.Delivered && (
                                  <button className="px-[15px] py-1 rounded-lg text-smallBtn font-bold break:text-smallBtn  text-mainIcon border border-mainIcon">
                                    {receipt.howToReceive ===
                                    HowToReceive.PickUp
                                      ? "픽업완료"
                                      : "배송완료"}
                                  </button>
                                )}
                                <div className="flex flex-wrap flex-1">
                                  {order.status !== OrderStatus.Exchange &&
                                    order.status !== OrderStatus.Refunded &&
                                    order.status !== OrderStatus.Canceled &&
                                    order.status !== OrderStatus.Complete &&
                                    order.status !== OrderStatus.Delivered &&
                                    order.status !== OrderStatus.Inspect &&
                                    order.status !== OrderStatus.Stored && (
                                      <button
                                        onClick={() => {
                                          if (
                                            receipt.howToReceive ===
                                            HowToReceive.PickUp
                                          ) {
                                            navigate(
                                              `/mypage/orderList/pickup/${order.id}`
                                            );
                                          } else {
                                            navigate(
                                              `/mypage/orderList/status/${order.id}`
                                            );
                                          }
                                        }}
                                        className="flex-1 py-1 rounded-lg text-smallBtn font-bold break:text-smallBtn bg-mainIcon text-white border border-mainIcon"
                                      >
                                        닐리버리 배송 위치 보기
                                      </button>
                                    )}
                                  {order.status === OrderStatus.Stored &&
                                    receipt.howToReceive ===
                                      HowToReceive.PickUp && (
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `/mypage/orderList/pickup/${order.id}`
                                          )
                                        }
                                        className="flex-1 py-1 rounded-lg text-smallBtn font-bold break:text-smallBtn bg-[#E5F1FA] text-mainIcon "
                                      >
                                        닐리포트 도착했습니다. 픽업해주세요
                                      </button>
                                    )}
                                  {order.status === OrderStatus.Stored &&
                                    receipt.howToReceive !==
                                      HowToReceive.PickUp && (
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `/mypage/orderList/status/${order.id}`
                                          )
                                        }
                                        className="flex-1 py-1 rounded-lg text-smallBtn font-bold break:text-smallBtn bg-[#E5F1FA] text-mainIcon "
                                      >
                                        닐리버리 배송 위치 보기
                                      </button>
                                    )}
                                  {order.status === OrderStatus.Inspect &&
                                    order.redelivery !==
                                      Redelivery.Redelivery && (
                                      <button
                                        onClick={() => {
                                          if (
                                            IsPossibleToCancel(
                                              receipt as IReceipt
                                            ) &&
                                            receipt.receiptStatus !==
                                              ReceiptStatus.Canceled
                                          ) {
                                            setCancelId(receipt.id);
                                            setAlertText(
                                              "주문을 취소하시겠습니까?"
                                            );
                                            setConfirmOpen(true);
                                            // cancelReceiptHandelr(receipt.id);
                                          }
                                        }}
                                        // disabled={
                                        //   !IsPossibleToCancel(receipt) &&
                                        //   order.status !== OrderStatus.Inspect
                                        // }
                                        className="py-1 rounded-lg content-center text-smallBtn flex-auto border border-btnLightLine  text-grayFont cursor-pointer"
                                      >
                                        주문 취소
                                      </button>
                                    )}
                                  {order.status === OrderStatus.Inspect &&
                                    order.redelivery ===
                                      Redelivery.Redelivery && (
                                      <button
                                        onClick={() => {
                                          if (
                                            receipt.howToReceive ===
                                            HowToReceive.PickUp
                                          ) {
                                            navigate(
                                              `/mypage/orderList/pickup/${order.id}`
                                            );
                                          } else {
                                            navigate(
                                              `/mypage/orderList/status/${order.id}`
                                            );
                                          }
                                        }}
                                        className="flex-1 py-1 rounded-lg text-smallBtn font-bold break:text-smallBtn bg-mainIcon text-white border border-mainIcon"
                                      >
                                        닐리버리 배송 위치 보기
                                      </button>
                                    )}
                                  {order.status === OrderStatus.Delivered && (
                                    <button
                                      onClick={() => {
                                        setOrderId(order.id);
                                        setAlertText("구매 확정 하시겠습니까?");
                                        setConfirmOpen(true);

                                        // editOrderStatusHandler(order.id);
                                      }}
                                      className="py-1  rounded-lg content-center text-smallBtn flex-auto bg-mainIcon text-white "
                                    >
                                      구매 확정
                                    </button>
                                  )}
                                  {order.status === OrderStatus.Complete &&
                                    !order.review && (
                                      <div className="flex flex-wrap w-full">
                                        <button
                                          onClick={() => {
                                            navigate("/crReview", {
                                              state: order.id,
                                            });
                                          }}
                                          className="py-1 w-full rounded-lg content-center text-smallBtn flex-auto text-mainIcon border border-mainIcon"
                                        >
                                          상품 잘 받아보셨나요? 리뷰를
                                          작성해주세요.
                                        </button>
                                        <button
                                          onClick={() => {
                                            pgOptionHandler(receipt.id);
                                          }}
                                          className="mt-2 w-full py-1 rounded-lg content-center text-smallBtn flex-auto text-mainIcon border border-mainIcon"
                                        >
                                          영수증
                                        </button>
                                      </div>
                                    )}
                                  {order.status === OrderStatus.Complete &&
                                    order.review && (
                                      <button
                                        onClick={() => {
                                          navigate("/listReview", {
                                            state: 1,
                                          });
                                        }}
                                        className="content-center text-smallBtn py-1 rounded-lg flex-auto border-btnLightLine border text-grayFont "
                                      >
                                        나의 구매 후기
                                      </button>
                                    )}
                                  {order.status === OrderStatus.Refunded && (
                                    <p className=" text-center text-smallBtn py-1 rounded-lg flex-auto border-btnLightLine border text-grayFont">
                                      환불
                                    </p>
                                  )}
                                  {order.status === OrderStatus.Exchange && (
                                    <p className=" text-center text-smallBtn py-1 rounded-lg flex-auto border-btnLightLine border text-grayFont">
                                      교환신청
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}

                {/* order end */}
              </div>
            </div>
          </div>
        ))}
      {!listReceiptLoading &&
        listReceiptData?.listReceipt.receipts?.length === 0 && (
          <p className="py-10 text-darkFont text-standard break:text-large text-center font-bold">
            주문내역이 비어있어요..
          </p>
        )}
      <Stack spacing={2} className="flex-wrap content-center pt-8">
        <Pagination
          onChange={pageHandler}
          count={
            listReceiptData?.listReceipt?.totalPages
              ? listReceiptData?.listReceipt?.totalPages
              : 1
          }
          color={`primary`}
        ></Pagination>
      </Stack>
      <Dialog
        open={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
        }}
        className="relative"
      >
        <ConfirmComponent
          title={"안내"}
          desc={alertText}
          closeFunction={closeConfirm}
          confirmFunction={confirmFunction}
        ></ConfirmComponent>
      </Dialog>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        className="relative"
      >
        <AlertComponent
          title={"알림"}
          desc={alertText}
          closeFunction={closeAlert}
        ></AlertComponent>
      </Dialog>
    </section>
  );
};
