import { useEffect, useLayoutEffect } from "react";
import { pageIndicator } from "../apollo";
import { Footer } from "../components/footer";
import { TopBar } from "../components/topBar";

export const DeliveryRefundInfo = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    pageIndicator("배송 및 교환/환불 정책");
  }, []);
  return (
    <div className="max-w-screen-break mx-auto h-full overflow-auto">
      <div className="sticky left-0 top-0 bg-white shadow">
        <TopBar arrow={true} arrowColor={"#B5C1D0"} exit={true} home={true} heading={true} />
      </div>
      <div className="px-4 pt-4 pb-5 bg-main">
        <div className="rounded-xl p-4 bg-white border-btnLightLine">
          <p className="text-darkFont font-bold text-sm">배송정책</p>
          <ul className="pl-4 pt-4 list-disc text-sm">
            <li>배송가능지역: 전국</li>
            <li>기본배송비:무료</li>
            <li>
              배송기간 :<p>- 김천 지역은 출발 당일에서 하루, 이 외 전국 지역은 출발일 기준 2~5일 소요됩니다.</p>
              <p>- 오후 12시 주문 건까지 당일 출고됩니다. (주말 및 공휴일 제외)</p>
              <p>- 당사 일정에 따라 공휴일 전날에는 택배 발송이 어려울 수 있습니다.</p>
            </li>
            <li>반품 신청시, 당사에서 직접 도와드립니다. 문의에 교환문의를 남겨주세요.</li>
          </ul>
        </div>
      </div>
      <div className="px-4 py-5 bg-main">
        <div className="rounded-xl p-4 bg-white border-btnLightLine">
          <p className="text-darkFont font-bold text-sm">교환 정책 안내</p>
          <ul className="pl-4 pt-4 list-disc text-sm">
            <li>배송확정 상품은 교환이 불가능합니다.</li>
            <li>배송 완료일부터 3일 이내 교환 신청 가능합니다. (3일 이후 자동 배송 확정)</li>
            <li>
              단순 변심 등 고객 사유로 인한 교환의 경우 왕복배송비를 지불해야하며, 제품 및 포장이 재판매 가능한 상태여야 합니다. (상품 및 구성품, 포장
              훼손, 분실 시 교환 불가)
            </li>
            <li>반품 신청시, 당사에서 직접 도와드립니다. 문의에 교환문의를 남겨주세요.</li>
          </ul>
        </div>
      </div>
      <div className="px-4 py-5 bg-main">
        <div className="rounded-xl p-4 bg-white border-btnLightLine">
          <p className="text-darkFont font-bold text-sm">환불 정책 안내</p>
          <ul className="pl-4 pt-4 list-disc text-sm">
            <li>배송 완료일로부터 3일 이내 환불 요청 가능합니다. (3일 이후 자동 배송확정)</li>
            <li>제품 또는 포장이 훼손되어 재판매가 불가한 경우, 환불을 거절할 수 있습니다.</li>
            <li>환불 신청시, 당사에서 직접 도와드립니다. 문의에 환불문의를 남겨주세요.</li>
          </ul>
        </div>
      </div>
      <div className="px-4 pt-4 pb-10 bg-main">
        <div className="rounded-xl p-4 bg-white border-btnLightLine">
          <p className="text-darkFont font-bold text-sm">반품/교환 불가능 사유</p>
          <ul className="pl-4 pt-4 list-disc text-sm">
            <li>반품요청기간이 지난 경우</li>
            <li>구매자의 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우 (단, 상품의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외)</li>
            <li>구매자의 책임있는 사유로 포장이 훼손되어 상품 가치가 현저히 상실된 경우</li>
            <li>구매자의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우</li>
            <li>시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우</li>
            <li>복제가 가능한 상품 등의 포장을 훼손한 경우</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
