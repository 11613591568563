import { ApolloError, gql, useMutation } from "@apollo/client";
import { DelInquiryPosterMutation } from "../gql/graphql";

const DEL_INQUIRY_POSTER_MUTATION = gql`
  mutation delInquiryPoster($input: DelInquiryPosterInput!) {
    delInquiryPoster(input: $input) {
      ok
      err
    }
  }
`;

export const useDelInquiryPoster = (onCompleted?: (data: DelInquiryPosterMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<DelInquiryPosterMutation>(DEL_INQUIRY_POSTER_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
