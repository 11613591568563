import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useGetAddress } from "../hooks/useGetAddress";
import { useUpAddress } from "../hooks/useUpAddress";
import { useParams } from "react-router-dom";
import myPosition from "../assets/icons/myPosition.svg";
import { useNavigate } from "react-router-dom";
import help_circle from "../assets/icons/help-circle.png";
import { CircularProgress, Dialog, Slide } from "@mui/material";
import React from "react";
import { AlertComponent } from "../components/alert";
import { messageText } from "../constants/texts";
import { SetAddressByMap } from "./set-address-by-map";
import { AddressInfo } from "../constants/props";
import { ArrowRight, SearchIcon } from "../components/Icons";
import {
  GetAddressInput,
  GetAddressMutation,
  UpAddressInput,
  UpAddressMutation,
} from "../gql/graphql";

export interface UpAddressInputProps extends UpAddressInput {
  messageField?: string;
}

export const UpAddress = () => {
  const [textFieldOpen, setTextFieldOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [title, setTitle] = useState<string | undefined>("");
  const [info, setInfo] = useState<AddressInfo>();
  const [addrSearch, setAddrSearch] = useState("");
  const [openMap, setOpenMap] = useState(false);
  const [RestLoading, setRestLoading] = useState<boolean>(false);
  const closeMapHandler = () => {
    setOpenMap(false);
  };

  const closeModal = () => {
    setHelp(false);
    if (alertText === "배송지가 수정되었습니다.") {
      navigate(-1);
    }
  };

  const navigate = useNavigate();

  const params = useParams();
  const [help, setHelp] = React.useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    // formState: { errors, isValid },
  } = useForm<UpAddressInputProps>({
    mode: "onChange",
  });

  const onCompleted = async (data: UpAddressMutation) => {
    const {
      upAddress: { ok, err },
    } = data;
    if (ok) {
      setAlertText("배송지가 수정되었습니다.");
      setTitle("알림");
      setHelp(true);
    } else {
      alert(err);
    }
  };
  const [upAddress] = useUpAddress(onCompleted);
  const onGetCompleted = (data: GetAddressMutation) => {
    const {
      getAddress: { ok, err, address },
    } = data;
    if (ok && address) {
      setValue("name", String(address?.name));
      setValue("username", String(address?.username));
      setValue("phone", String(address?.phone));
      setValue("zoneCode", String(address?.zoneCode));
      setValue("mainAddress", String(address?.mainAddress));
      setValue("subAddress", String(address?.subAddress));
      setValue("pass", address.pass);
      setValue("region", String(address?.region));
      if (
        messageText.findIndex((text) => text.value === address.message) >= 0
      ) {
        setValue("message", address.message);
      } else {
        setTextFieldOpen(true);
        setValue("messageField", address.message ? address.message : "");
        setValue("message", "직접 입력하기");
      }
      setValue("isRep", address?.isRep);
    } else {
      console.log(err);
    }
  };
  const [getAddress] = useGetAddress(onGetCompleted);
  const getAddressHandler = (id: number) => {
    const getAddressinput: GetAddressInput = {
      id,
    };
    getAddress({ variables: { input: getAddressinput } });
  };
  const onSubmit = () => {
    const upAddressId = Number(params.key);
    const {
      name,
      username,
      phone,
      zoneCode,
      mainAddress,
      region,
      messageField,
      subAddress,
      pass,
      message,
      isRep,
    } = getValues();
    const upAddressInput: UpAddressInput = {
      upAddressId,
      name,
      username,
      phone,
      zoneCode,
      region,
      mainAddress,
      subAddress,
      pass: pass ? pass : undefined,
      message: message === "직접 입력하기" ? messageField : message,
      isRep,
    };
    upAddress({ variables: { input: upAddressInput } });
  };
  const onInvalid = () => {};
  // daum API
  const handlePostCode = (data: any) => {
    setRestLoading(true);
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setValue(`zoneCode`, data.zonecode);
    setValue(`mainAddress`, fullAddress);
    setValue(`region`, data.bname);
    setRestLoading(false);
  };
  const scriptUrl =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(scriptUrl);
  const handlerClick = () => {
    open({ onComplete: handlePostCode });
  };
  const handlerSearchClick = () => {
    open({ onComplete: handlePostCode, defaultQuery: addrSearch });
  };

  useEffect(() => {
    pageIndicator("배송지수정");
    getAddressHandler(Number(params.key));
  }, []);
  useEffect(() => {
    if (info) {
      setRestLoading(true);
      var mainAddress = info.mainAddress + "";
      var zoneCode = info.zoneCode + "";
      var regionKey = info.regionKey + "";
      setValue(`zoneCode`, zoneCode);
      setValue(`mainAddress`, mainAddress);
      setValue(`region`, regionKey);
      setRestLoading(false);
    }
  }, [info]);
  return (
    <div className="wrap h-full flex flex-col overflow-y-auto">
      <header className="border-b-[0.5px]">
        <TopBar
          arrow={true}
          home={true}
          heading={true}
          headingColor={`darkFont`}
          shop={true}
        />
      </header>
      <form
        onSubmit={handleSubmit(onSubmit, onInvalid)}
        className="flex flex-col flex-1"
      >
        <main className="flex-1 max-w-screen-break mx-auto">
          <div id="setPosition">
            <div className="findAddrBySearch flex flex-wrap gap-x-2 pt-4 px-4">
              <input
                type="text"
                onChange={(e) => {
                  setAddrSearch(e.target.value);
                }}
                placeholder="지번, 도로명, 건물명으로 검색"
                className="flex-1 rounded-full border border-mainIcon text-sm px-4 focus:outline-mainIcon"
              />
              <button
                type="button"
                onClick={handlerSearchClick}
                className="w-8 h-8 rounded-full bg-mainIcon flex flex-wrap content-center justify-center"
              >
                <SearchIcon color="#fff"></SearchIcon>
              </button>
            </div>
            <div className="pt-4 px-4">
              <div
                onClick={() => setOpenMap(true)}
                className="flex flex-wrap content-center gap-x-4 cursor-pointer py-1"
              >
                <img src={myPosition} alt="현재위치 설정 아이콘" />
                <p className="flex-1 text-sm flex flex-wrap content-center px-1">
                  현재 위치로 설정
                </p>
                <ArrowRight color="#00B0EB" />
              </div>
            </div>
          </div>
          <div className="pt-4 px-4 ">
            {/* <p className=" text-standard break:text-large text-darkFont py-1 px-2">
              배송지수정
            </p> */}
            <div>
              <p className="py-1 pl-2">
                <span className="text-standard break:text-large text-darkFont p-1">
                  배송지명
                </span>
              </p>
              <input
                type="text"
                {...register("name", {})}
                className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-5  border border-btnDarkLine focus:outline-mainIcon"
              />
            </div>
            <div className="pt-2">
              <p className="py-1 pl-2">
                <span className="text-standard break:text-large text-darkFont p-1">
                  수취인
                </span>
              </p>
              <input
                type="text"
                {...register("username", {})}
                className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4  border border-btnDarkLine focus:outline-mainIcon"
              />
            </div>
            <div className="pt-2">
              <p className="py-1 pl-2">
                <span className="text-standard break:text-large text-darkFont p-1">
                  연락처
                </span>
              </p>
              <input
                {...register("phone", {})}
                type="text"
                className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4  border border-btnDarkLine focus:outline-mainIcon"
              />
            </div>
            <div className="pt-2">
              <p className="py-1 pl-2">
                <span className="text-standard break:text-large text-darkFont p-1">
                  주소
                </span>
              </p>
              <p className="py-1 flex flex-wrap gap-x-2 ">
                <input
                  type="text"
                  {...register("zoneCode", {})}
                  onClick={handlerClick}
                  className=" w-[120px] text-standard break:text-large text-darkFont  rounded-lg h-10 py-2 px-4  border border-btnDarkLine focus:outline-mainIcon"
                ></input>
                <button
                  className="py-[9px] px-4 border border-btnDarkLine rounded-lg text-btn text-grayFont"
                  type="button"
                  onClick={handlerClick}
                >
                  우편번호찾기
                </button>
              </p>
              <input
                type="text"
                {...register("mainAddress", {})}
                onClick={handlerClick}
                className="mt-2 text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4  border border-btnDarkLine focus:outline-mainIcon"
              />
              <input
                type="text"
                {...register("subAddress", {})}
                className="mt-2 text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4  border border-btnDarkLine focus:outline-mainIcon"
              />
            </div>
            <div className="pt-2">
              <div className="flex flex-wraptext-standard break:text-large text-darkFont p-2">
                <span>공동현관 비밀번호 안내</span>
                <div
                  className="p-1"
                  onClick={() => {
                    setTitle("공동현관 비밀번호 안내");
                    setAlertText(`닐리버리의 빠른 배송을 위해서
                    <span className=""> 공동현관 비밀번호</span>를 공유해 <br />
                    주시거나, 기사님이 출입 가능한 방법을 알려주세요.
                    <br /> <br />
                    *작성해주신 비밀번호는 저장됩니다.`);
                    setHelp(true);
                  }}
                >
                  <img src={help_circle} className="w-4 h-4"></img>
                </div>
              </div>

              <input
                type="text"
                placeholder="#1234*,경비 열쇠1234 (최대30자)"
                {...register("pass")}
                className="text- break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4  border border-btnDarkLine focus:outline-mainIcon"
              />
              <p className="p-1 text-smallBtn">
                문 앞에 배송되도록 공동현관 비밀번호를 입력해 주세요 .
              </p>
            </div>
            <div className="pt-2">
              <p className="text-standard break:text-large text-darkFont p-2">
                배송 메시지 입력
              </p>
              <select
                {...register(`message`, {})}
                className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-5 border border-btnDarkLine focus:outline-mainIcon"
                onChange={(e) => {
                  var value = e.target.value;
                  if (value === "직접 입력하기") {
                    setTextFieldOpen(true);
                  } else {
                    setTextFieldOpen(false);
                  }
                }}
              >
                <option
                  defaultChecked
                  className="text-standard break:text-large text-hintFont"
                  value=""
                >
                  배송 메시지를 선택해주세요
                </option>
                {messageText.map((obj) => (
                  <option
                    key={obj.text}
                    className="text-darkFont text-standard break:text-large"
                    value={obj.value}
                  >
                    {obj.text}
                  </option>
                ))}
              </select>

              <div className="pt-2">
                <input
                  type="text"
                  {...register("messageField")}
                  className={`${
                    !textFieldOpen && "hidden"
                  } text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon`}
                />
              </div>
            </div>
            <div className="pt-2">
              <p className="text-standard break:text-large text-darkFont p-2">
                대표배송지 설정하기{" "}
                <input {...register("isRep")} type="checkBox" />
              </p>
            </div>
            {/* <div className="pt-2">
              <p className="text-standard break:text-large text-darkFont p-2">요청사항</p>
              <select className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4  border border-btnDarkLine focus:outline-mainIcon">
                <option value="">배송시 요청사항을 선택해주세요</option>
              </select>
            </div> */}
          </div>
        </main>
        <div className="w-full flex flex-wrap max-w-screen-fit px-9 pb-[25px] text-center mx-auto gap-x-4">
          <button
            type="button"
            className="flex-auto border border-btnLightLine  text-hintFont bg-[#fff] text-standard rounded-full py-2 mx-auto"
            onClick={() => navigate(-1)}
          >
            취소
          </button>
          <button className="flex-auto h-10 text-white bg-mainIcon  text-standard rounded-full ">
            수정
          </button>
        </div>
      </form>
      <Dialog
        open={help}
        onClose={() => {
          setHelp(false);
        }}
      >
        <AlertComponent
          title={title}
          desc={alertText}
          closeFunction={closeModal}
        ></AlertComponent>
      </Dialog>
      <Slide in={openMap} direction={"up"}>
        <div className="h-full w-full absolute left-0 top-0 z-10">
          <SetAddressByMap closeFun={closeMapHandler} callBack={setInfo} />
        </div>
      </Slide>
      {RestLoading && (
        <div className="absolute left-0 top-0 w-screen h-screen bg-black bg-opacity-20 z-10">
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <CircularProgress />
          </div>
        </div>
      )}
    </div>
  );
};
