import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TopBar } from "../components/topBar";
import { useListAddress } from "../hooks/useListAddress";
import setting_gray from "../assets/icons/setting_gray.svg";
import check_icon_white from "../assets/icons/check_icon_white.svg";

import { useChkAddressArea } from "../hooks/useChkAddressArea";
import { CircularProgress, Collapse, Fade } from "@mui/material";
import { HowToReceive } from "../constants/props";
import { BlockList } from "net";
import { useListPortPickup } from "../hooks/useListPortPickUp";
import { useUpReceiptAddress } from "../hooks/useUpReceiptAddress";
import { useMe } from "../hooks/useME";
import { IAddress, IPortPickup } from "../constants/types";
import {
  ChkAddressAreaInput,
  ChkAddressAreaQuery,
  ListAddressQuery,
  ListPortPickupQuery,
  UpReceiptAddressInput,
  UpReceiptAddressMutation,
} from "../gql/graphql";

export const SelectReceiveWays = () => {
  const navigate = useNavigate();
  const state = useLocation().state;
  const params = useParams();
  const { data: me } = useMe();
  const [available, setAvailable] = useState<number>(0);
  const [RestLoading, setRestLoading] = useState<boolean>(false);
  const [receiveWay, SetReceiveWay] = useState<HowToReceive>(available === 1 ? HowToReceive.Delivery : HowToReceive.PickUp);
  const [selectedAddress, setSelectedAddress] = useState<IAddress>();
  const [selectedPort, setSelectedPort] = useState<IPortPickup>();
  const [coll, setColl] = useState(false);
  const [collPort, setCollPort] = useState(false);
  const onListAddress = (data: ListAddressQuery) => {
    const {
      listAddress: { ok, address },
    } = data;
    if (ok && address) {
      var repAddress;
      if (state) {
        repAddress = address.find((a) => a.id === Number(state));
      } else {
        repAddress = address.find((a) => a.isRep === true);
      }
      setSelectedAddress(repAddress as IAddress);
      chkAddressAreaHandler();
    }
  };

  const [listAddress, { data: listAddressData, refetch }] = useListAddress(onListAddress);
  const listAddressHandler = () => {
    listAddress();
  };

  // 배송가능여부 체크
  const onChkArea = (data: ChkAddressAreaQuery) => {
    const {
      chkAddressArea: { ok, err, deliveryArea },
    } = data;
    if (ok) {
      deliveryArea ? setAvailable(1) : setAvailable(2);
    } else {
      console.log(err);
    }
    setRestLoading(false);
  };
  const [chkAddressArea] = useChkAddressArea(onChkArea);
  const chkAddressAreaHandler = () => {
    setRestLoading(true);
    const chkAddressAreaInput: ChkAddressAreaInput = {
      region: selectedAddress?.region,
    };
    chkAddressArea({
      variables: {
        input: chkAddressAreaInput,
      },
    });
  };

  // 포트 리스트
  const onPortList = (data: ListPortPickupQuery) => {
    const {
      listPortPickup: { ok, err, ports },
    } = data;
    if (err) {
      console.log(err);
    }
  };
  const [listPortPickup, { data: portList }] = useListPortPickup(onPortList);
  useEffect(() => {
    pageIndicator("배송지 선택");
    listAddressHandler();
    if (receiveWay === HowToReceive.PickUp) {
      listPortPickup();
      setCollPort(true);
    } else {
      setCollPort(false);
    }
  }, []);
  // receipt 저장
  const onUpReceiptAddress = (data: UpReceiptAddressMutation) => {
    const {
      upReceiptAddress: { ok, err },
    } = data;
    console.log(ok);
    if (ok) {
      console.log("ok");
      navigate(`/payment/${params.receiptUuid}`, {
        state: receiveWay === HowToReceive.Delivery ? selectedAddress?.id : null,
      });
    } else {
      alert(err);
    }
  };
  const [upReceiptAddress] = useUpReceiptAddress(onUpReceiptAddress);
  const upReceiptAddressHandler = () => {
    if (receiveWay === HowToReceive.PickUp && !selectedPort) {
      alert("포트를 선택해주세요");
      return;
    }
    const input: UpReceiptAddressInput = {
      receiptUuid: `${params.receiptUuid}`,
      howToReceive: receiveWay,
      portId: selectedPort?.id,
      address:
        receiveWay === HowToReceive.Delivery
          ? `${selectedAddress?.zoneCode ? `[${selectedAddress?.zoneCode}] ` : ``}${selectedAddress?.mainAddress} ${selectedAddress?.subAddress}`
          : `${selectedPort?.address}`,
      receiver: selectedAddress?.username,
      phone: receiveWay === HowToReceive.Delivery ? selectedAddress?.phone : me?.me.phone,
      pass: selectedAddress?.pass,
      message: selectedAddress?.message,
      region: receiveWay === HowToReceive.Delivery ? selectedAddress?.region : null,
    };
    upReceiptAddress({ variables: { input } });
  };

  useEffect(() => {
    chkAddressAreaHandler();
  }, [selectedAddress]);
  useEffect(() => {
    if (available === 1) {
      SetReceiveWay(HowToReceive.Delivery);
    } else if (available === 2) {
      SetReceiveWay(HowToReceive.PickUp);
    }
  }, [available]);
  return (
    <div className="wrap h-full flex flex-wrap flex-col ">
      <header className="border-b border-btnLightLine">
        <TopBar arrow={true} arrowColor={"#B5C1D0"} heading={true} home={true} shop={true} />
      </header>
      <main className="flex-1 pb-10 overflow-auto removeScroll relative">
        <section className="pt-4 px-4">
          <div className="h-10 flex flex-wrap gap-x-2">
            <h3 className="text-xl font-bold flex-1 px-4 flex flex-wrap content-center">배송지</h3>
            <div className="flex flex-wrap content-center">
              <button
                onClick={() => {
                  setColl(true);
                }}
                className="py-1 px-2 rounded-lg border border-btnLightLine text-grayFont"
              >
                변경
              </button>
            </div>
          </div>
          <div id="listAddress" className=" pt-4">
            {selectedAddress && (
              <div
                onClick={() => {
                  setColl(false);
                }}
                className={`w-full p-4 border border-mainIcon rounded-2xl text-sm leading-6`}
              >
                <p className="flex flex-warp gap-x-4  font-bold text-darkFont">
                  <span className="rightDivide pl-1 pr-5">{selectedAddress.username}</span>
                  <span className="px-1 flex-1">{selectedAddress.phone}</span>
                  <button onClick={() => navigate(`/address/edit/${selectedAddress.id}`)}>
                    <img src={setting_gray} alt="수정하기" />
                  </button>
                </p>
                <div className="pt-4 px-1">
                  <p>
                    {selectedAddress.zoneCode ? `[${selectedAddress.zoneCode}] ` : ""} {selectedAddress.mainAddress}
                  </p>
                  <p>{selectedAddress.subAddress}</p>
                </div>
              </div>
            )}
            <Collapse in={coll} className="w-full">
              <>
                {listAddressData?.listAddress.address &&
                  listAddressData.listAddress.address
                    .filter((addr) => addr.id !== selectedAddress?.id)
                    .map((address) => (
                      <div
                        key={`address-${address.id}`}
                        onClick={() => {
                          setSelectedAddress(address as IAddress);
                          setSelectedPort(undefined);
                          setColl(false);
                        }}
                        className={`w-full mt-4 p-4 border border-btnDarkLine rounded-2xl text-sm leading-6 hover:bg-btnLightLine`}
                      >
                        <p className="flex flex-warp gap-x-4  font-bold text-darkFont">
                          <span className="rightDivide pl-1 pr-5">{address.username}</span>
                          <span className="px-1 flex-1">{address.phone}</span>
                          <button onClick={() => navigate(`/address/edit/${address.id}`)}>
                            <img src={setting_gray} alt="수정하기" />
                          </button>
                        </p>
                        <div className="pt-4 px-1">
                          <p>
                            {address.zoneCode ? `[${address.zoneCode}] ` : ""} {address.mainAddress}
                          </p>
                          <p>{address.subAddress}</p>
                        </div>
                      </div>
                    ))}
              </>
            </Collapse>
          </div>
        </section>
        {selectedAddress && (
          <Fade in={Boolean(selectedAddress)}>
            <div id="receiveType" className="pt-6 px-4">
              <div>
                <p className="h-10 text-xl font-bold px-4 flex flex-wrap content-center">수령방법 선택</p>
              </div>
              <div className="pt-4 px-4 flex flex-warp flex-col w-full gap-y-4">
                <label className="delivery w-full block px-2">
                  <div className="flex flex-wrap content-center gap-x-2">
                    <div
                      className={`radioButton h-6 w-6 rounded-full ${
                        receiveWay === HowToReceive.Delivery ? "bg-mainIcon" : "inner-border-2 inner-border-hintFont"
                      }`}
                    >
                      {receiveWay === HowToReceive.Delivery && <img src={check_icon_white} alt="check" className="w-full h-full p-1" />}
                    </div>
                    <p
                      className={`text-base font-bold
                  ${receiveWay === HowToReceive.Delivery ? "text-darkFont" : "text-hintFont"}
                  `}
                    >
                      집 앞으로 배송
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="receiveType"
                    disabled={available !== 1}
                    value={HowToReceive.Delivery}
                    onChange={() => {
                      SetReceiveWay(HowToReceive.Delivery);
                    }}
                    className="hidden"
                  />
                </label>
                <label className="pickUp w-full block px-2">
                  <div className="flex flex-wrap content-center gap-x-2">
                    <div
                      className={`radioButton h-6 w-6 rounded-full ${
                        receiveWay === HowToReceive.PickUp ? "bg-mainIcon" : "inner-border-2 inner-border-hintFont"
                      }`}
                    >
                      {receiveWay === HowToReceive.PickUp && <img src={check_icon_white} alt="check" className="w-full h-full p-1" />}
                    </div>
                    <p className={`text-base text-darkFont font-bold ${receiveWay === HowToReceive.PickUp ? "text-darkFont" : "text-hintFont"}`}>
                      닐리포트에서 직접 픽업
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="receiveType"
                    value={HowToReceive.PickUp}
                    onChange={() => {
                      SetReceiveWay(HowToReceive.PickUp);
                    }}
                    className="hidden"
                  />
                </label>
                {available !== 1 && (
                  <div className="px-4">
                    <p className="text-xs py-1 w-full text-center bg-block2 rounded-full text-mainIcon">
                      선택하신 주소로는 닐리포트 픽업만 가능합니다.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Fade>
        )}
        {receiveWay === HowToReceive.PickUp && (
          <Fade in={receiveWay === HowToReceive.PickUp}>
            <div id="portSelect" className="pt-4 px-4">
              <p className="h-10 text-xl font-bold px-4 flex flex-wrap content-center">
                <span className="flex-1">닐리포트 선택</span>
                <span className="flex flex-wrap content-center text-Xsmall py-1 px-2 rounded-full bg-mainIcon text-white ">직접픽업</span>
              </p>
              <div id="listPickUpPort" className="w-full">
                <Collapse in={collPort} className="w-full">
                  {portList?.listPortPickup?.ports &&
                    portList?.listPortPickup?.ports?.length > 0 &&
                    portList?.listPortPickup?.ports?.map((port) => (
                      <div
                        key={`port-${port.id}`}
                        onClick={() => {
                          setSelectedPort(port as IPortPickup);
                        }}
                        className={`mt-4 flex flex-wrap flex-col pt-1 px-4 pb-4 rounded-lg bg-bg w-full gap-y-4 ${
                          selectedPort === port && "inner-border inner-border-mainIcon"
                        }`}
                      >
                        <div className="flex flex-wrap gap-x-2 content-center ">
                          <div className="flex flex-wrap content-center ">
                            <div className="text-Xsmall h-5 leading-5 px-2 rounded-full bg-mainIcon text-white ">닐리포트</div>
                          </div>
                          <p className="font-bold text-sm leading-8">{port.name}</p>
                        </div>
                        <div className="bg-white rounded-lg">
                          <p className="py-1 text-sm flex flex-wrap">
                            <span className="min-w-[60px] text-right px-1">주소</span>
                            <span className="px-1 flex-1">{port.address}</span>
                          </p>
                          <p className="py-1 text-sm flex flex-wrap">
                            <span className="min-w-[60px] text-right px-1">연락처</span>
                            <span className="px-1 flex-1">{port.phone}</span>
                          </p>
                          <p className="py-1 text-sm flex flex-wrap">
                            <span className="min-w-[60px] text-right px-1">이용시간</span>
                            <span className="px-1 flex-1">{port.usageTime}</span>
                          </p>
                        </div>
                      </div>
                    ))}
                  {!portList?.listPortPickup.ports ||
                    (portList?.listPortPickup.ports.length === 0 && <div className="pt-4 px-4 text-center">픽업 포트가 없습니다</div>)}
                </Collapse>
              </div>
            </div>
          </Fade>
        )}
      </main>
      <div className="w-full flex flex-wrap justify-center max-w-screen-fit pb-[25px] text-center mx-auto gap-x-4">
        <button
          type="button"
          className="px-[37.5px] border border-mainIcon  text-mainIcon bg-[#fff] text-standard  rounded-full py-2 font-bold"
          onClick={() => navigate(`/addressInPay/${params.receiptUuid}`)}
        >
          배송지등록
        </button>
        <button onClick={upReceiptAddressHandler} className="px-[60px] h-10 text-white bg-mainIcon  text-standard  rounded-full ">
          확인
        </button>
      </div>
      {RestLoading && (
        <div className="absolute left-0 top-0 w-screen h-screen bg-black bg-opacity-20 z-10">
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <CircularProgress />
          </div>
        </div>
      )}
    </div>
  );
};
