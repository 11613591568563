import { ApolloError, gql, useLazyQuery } from "@apollo/client";
import { ChkAddressAreaQuery, ChkAddressAreaQueryVariables } from "../gql/graphql";

const CHECK_ADDRESS_AREA_QUERY = gql`
  query chkAddressArea($input: ChkAddressAreaInput!) {
    chkAddressArea(input: $input) {
      ok
      err
      deliveryArea
    }
  }
`;

export const useChkAddressArea = (onCompleted?: (data: ChkAddressAreaQuery) => void, onError?: (error: ApolloError) => void) => {
  return useLazyQuery<ChkAddressAreaQuery, ChkAddressAreaQueryVariables>(CHECK_ADDRESS_AREA_QUERY, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
