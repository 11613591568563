import logo from "../assets/icons/logo.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
export const GetDataFail = () => {
  const navigate = useNavigate();
  useEffect(() => {
    pageIndicator("카카오 로그인");
  });
  return (
    <div className="wrap relative h-full">
      <main className="max-w-screen-break mx-auto h-full">
        <TopBar home={true} arrow={true} heading={true} exit={true} />
        <div className="w-full h-full flex flex-wrap flex-col content-center justify-center border-t border-btnLightLine bg-main">
          <img src={logo} alt="니나노로고" className="px-6 " />
          <p className="mt-2 text-sm leading-4 px-1 py-2 text-center text-darkFont">정보를 불러오지 못했습니다. 다시 시도해주세요</p>
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="w-[200px] mx-auto mt-2 h-10 py-1"
          >
            <span className="text-grayFont font-bold flex flex-wrap content-center justify-center h-full w-full bg-white  border border-[#B5C1D0] rounded-full">
              뒤로
            </span>
          </button>
        </div>
        {/* <img src={logo} alt="결제완료 닐리버리 로고" className="mx-auto " />
        <p className="pt-20 text-center text-red-400 font-bold text-large">
          데이터를 불러오는데 실패하였습니다.
        </p>
        <div className="w-full flex flex-wrap absolute z-10 bottom-[80px] justify-center">
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="w-[200px] text-center py-2  text-[#fff]  bg-mainIcon font-bold text-btn break:text-standardall rounded-[20px] "
          >
            뒤로
          </button>
        </div> */}
      </main>
    </div>
    // <div className="wrap max-w-screen-break min-h-screen mx-auto w-full pb-32 bg-red-100 bg-opacity-100">
    //   <main className="max-w-screen-break relative pt-12 px-8">
    //     <img src={logo} alt="결제완료 닐리버리 로고" className="mx-auto " />
    //     <p className="pt-20 text-center text-red-400 font-bold text-large">
    //       데이터를 불러오는데 실패하였습니다.
    //     </p>
    //   </main>
    //   <div className="w-full flex flex-wrap absolute z-10 bottom-[80px] justify-center">
    //     <button
    //       onClick={() => {
    //         navigate("/login");
    //       }}
    //       className="w-[200px] text-center py-2  text-[#fff]  bg-mainIcon font-bold text-btn break:text-standardall rounded-[20px] "
    //     >
    //       뒤로
    //     </button>
    //   </div>
    // </div>
  );
};
