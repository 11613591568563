import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { pageIndicator } from "../apollo";
import { TopBar } from "../components/topBar";
import { useGetInquiry } from "../hooks/useGetInquiry";
import { EditInquiryInput, InquiryType, GetInquiryInput, GetInquiryQuery } from "../gql/graphql";
export const GetInquiry = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<EditInquiryInput>({
    mode: "onChange",
  });
  var params = useParams();
  const inquiryTypes = [
    { text: "--- 카테고리 선택 ---", type: "" },
    { text: "시스템문의", type: InquiryType.System },
    { text: "교환/환불문의", type: InquiryType.Refund },
    { text: "기타 질문", type: InquiryType.Question },
    { text: "상품 문의", type: InquiryType.Goods },
  ];
  const onCompleted = (data: GetInquiryQuery) => {
    const {
      getInquiry: { ok, err, inquiry },
    } = data;
    if (ok && inquiry) {
      setValue("title", inquiry?.title);
      setValue("type", inquiry?.type);
      setValue("note", inquiry?.note);
    }
  };

  const [getInquiry, { data, loading }] = useGetInquiry(onCompleted);
  const getInquiryHandler = () => {
    const getInquiryInput: GetInquiryInput = {
      inquiryId: Number(params.key),
    };
    getInquiry({ variables: { input: getInquiryInput } });
  };

  useEffect(() => {
    pageIndicator("나의 문의보기");
    getInquiryHandler();
  }, []);
  return (
    <div className="wrap flex flex-col h-full w-full overflow-auto">
      <header>
        <TopBar arrow={true} search={true} heading={true} shop={true} />
      </header>
      <main className="max-w-screen-break mx-auto w-full flex-1">
        {!loading && data?.getInquiry.ok && (
          <div className="pt-2 px-8 pb-10">
            <p className="text-standard break:text-large text-darkFont px-2">제목</p>
            <div className="pt-2">
              <input
                type="text"
                {...register("title")}
                disabled
                placeholder="제목을 적어주세요"
                className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon"
              />
            </div>
            <div className="pt-2">
              <select
                {...register("type")}
                disabled
                className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon"
              >
                {inquiryTypes.map((obj) => (
                  <option key={obj.text} className="text-darkFont text-standard break:text-large" value={obj.type}>
                    {obj.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="pt-2">
              <textarea
                {...register("note")}
                disabled
                placeholder="문의 내용을 적어주세요."
                className="px-4 py-2 text-standard break:text-large text-darkFont border border-btnDarkLine rounded-lg w-full min-h-[400px] resize-none focus:outline-mainIcon"
              ></textarea>
            </div>
            <div className="py-2 flex flex-wrap w-full">
              {data.getInquiry.inquiry?.inquiryPosters &&
                data.getInquiry.inquiry?.inquiryPosters.map((poster, index) => (
                  <div key={`inquiryPoster+${index}`} className="inline-block w-1/4 max-w-[88px] px-1">
                    <div className="relative inline-flex w-full aspect-square bg-gray-200 border rounded-md pointer flex-wrap">
                      <img src={`${poster.upload?.url}`} alt="이미지" className="absolute left-0 top-0 w-full h-full object-contain" />
                    </div>
                  </div>
                ))}
            </div>
            {data.getInquiry.inquiry?.comments?.map((obj) => (
              <div className="pt-4" key={obj.id}>
                <div className="w-full px-4 py-2 border rounded-lg text-mainIcon keep-all min-h-[120px]">{obj.note}</div>
              </div>
            ))}
          </div>
        )}
      </main>
      <div className="w-full">
        <div className="w-full max-w-screen-fit mx-auto">
          <button
            onClick={() => {
              data?.getInquiry.inquiry?.comments && data?.getInquiry.inquiry?.comments.length > 0
                ? alert("답변이 달린 글은 수정할 수 없습니다.")
                : navigate(`/mypage/inquiryEdit/${Number(params.key)}`);
            }}
            className="bg-mainIcon block w-full max-w-screen-fit text-white text-xl font-bold h-[56px] mx-auto rounded-t-[20px]"
          >
            문의 변경하기
          </button>
        </div>
      </div>
    </div>
  );
};
