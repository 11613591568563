import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { pageIndicator } from "../apollo";
import { TopBar } from "../components/topBar";
import { useGetInquiry } from "../hooks/useGetInquiry";

import { useEditInquiry } from "../hooks/useUpInquiry";
import { upload } from "../functions/uploads";
import { useCrInquiryPoster } from "../hooks/useCrInquiryPoster";
import { useDelInquiryPoster } from "../hooks/useDelInquiryPoster";
import plus from "../assets/icons/plus.svg";
import exit_white from "../assets/icons/exit_white.svg";
import { Dialog } from "@mui/material";
import { AlertComponent } from "../components/alert";
import {
  CrInquiryPosterInput,
  CrInquiryPosterMutation,
  DelInquiryPosterInput,
  DelInquiryPosterMutation,
  EditInquiryInput,
  EditInquiryMutation,
  GetInquiryInput,
  GetInquiryQuery,
  InquiryType,
} from "../gql/graphql";

export interface inquirys {
  url?: string | null;
  id: number;
  index: number;
  poster: boolean;
}
export const UpInquiry = () => {
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const closeAlert = () => {
    setAlertOpen(false);
    navigate(-1);
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<EditInquiryInput>({
    mode: "onChange",
  });
  var params = useParams();

  const [inquiryPosters, setInquiryPosters] = useState<inquirys[]>([
    { index: 0, poster: false, id: 0 },
    { index: 1, poster: false, id: 0 },
    { index: 2, poster: false, id: 0 },
    { index: 3, poster: false, id: 0 },
  ]);

  const inquiryTypes = [
    { text: "--- 카테고리 선택 ---", type: "" },
    { text: "시스템문의", type: InquiryType.System },
    { text: "교환/환불문의", type: InquiryType.Refund },
    { text: "기타 질문", type: InquiryType.Question },
    { text: "상품 문의", type: InquiryType.Goods },
  ];
  const onCompleted = (data: GetInquiryQuery) => {
    const {
      getInquiry: { ok, err, inquiry },
    } = data;
    if (ok && inquiry) {
      setValue("title", inquiry.title);
      setValue("type", inquiry.type);
      setValue("note", inquiry.note);
      if (inquiry.inquiryPosters && inquiry.inquiryPosters.length > 0) {
        for (let i = 0; i < inquiry.inquiryPosters.length; i++) {
          let getPosters = inquiry.inquiryPosters;
          let posters = inquiryPosters;
          let idx = posters.findIndex(
            (poster) => poster.index === inquiryPosters[i].index
          );
          if (idx >= 0) {
            posters[i].url = getPosters[idx].upload?.url;
            posters[i].poster = true;
            posters[i].id = getPosters[idx].id;
          }
          setInquiryPosters(posters);
        }
      }
    }
  };
  const onEditCompleted = (data: EditInquiryMutation) => {
    const {
      editInquiry: { ok, err },
    } = data;
    if (ok) {
      setAlertOpen(true);
      // alert("수정이 완료 되었습니다.");
      // navigate(-1);
    } else {
      alert(err);
    }
  };
  const [getInquiry, { data }] = useGetInquiry(onCompleted);
  const [editInquiry] = useEditInquiry(onEditCompleted);
  const getInquiryHandler = () => {
    const getInquiryInput: GetInquiryInput = {
      inquiryId: Number(params.key),
    };
    getInquiry({ variables: { input: getInquiryInput } });
  };
  const onSubmit = () => {
    const { note, title, type } = getValues();
    var inquiryPosterIds: number[] = [];
    for (var poster of inquiryPosters) {
      if (poster.poster) {
        inquiryPosterIds.push(poster.id);
      }
    }
    const editInquiryInput: EditInquiryInput = {
      note,
      title,
      type,
      inquiryId: Number(data?.getInquiry?.inquiry?.id),
      inquiryPosters: inquiryPosterIds,
    };
    editInquiry({ variables: { input: editInquiryInput } });
  };
  const onInvalid = () => {};

  //  ----------------   inquirypostser ----------------
  const [delposterIndex, setDelPosterIndex] = useState(9);

  const posterCompleted = (data: CrInquiryPosterMutation) => {
    const {
      crInquiryPoster: { ok, err, inquiryPoster },
    } = data;
    if (ok && inquiryPoster) {
      let idx = inquiryPoster.index;
      let potserList = inquiryPosters;
      potserList[idx].url = inquiryPoster.upload?.url;
      potserList[idx].poster = true;
      potserList[idx].id = inquiryPoster.id;
      setInquiryPosters(potserList);
    } else {
      alert(err);
    }
  };
  const [crInquiryPoster, { loading }] = useCrInquiryPoster(posterCompleted);
  const crInquiryPosterHandler = (
    idx: number,
    imageUuid: string | null | undefined
  ) => {
    const input: CrInquiryPosterInput = {
      index: idx,
      imageUuid,
    };
    crInquiryPoster({
      variables: { input: input },
    });
  };
  const upInquiryPosterHandler = async (idx: number, events: any) => {
    const result = await upload(events);
    if (result.data.ok) {
      crInquiryPosterHandler(idx, result.data.upload.uuid);
    } else {
      alert("파일 업로드 오류");
    }
  };
  const delCompleted = (data: DelInquiryPosterMutation) => {
    const {
      delInquiryPoster: { ok, err },
    } = data;
    if (ok) {
      let posterList = inquiryPosters;
      let idx = posterList.findIndex(
        (poster) => poster.index === delposterIndex
      );
      posterList[idx].poster = false;
      posterList[idx].id = 0;
      setInquiryPosters(posterList);
    } else {
      alert(err);
    }
  };
  const [delInquiryPoster] = useDelInquiryPoster(delCompleted);
  const delInquiryPosterHandler = (index: number, posterId: number) => {
    setDelPosterIndex(index);
    const DelInquiryPosterInput: DelInquiryPosterInput = {
      inquiryPosterId: posterId,
    };
    delInquiryPoster({
      variables: { input: DelInquiryPosterInput },
    });
  };
  useEffect(() => {
    pageIndicator("나의 문의 수정하기");
    getInquiryHandler();
  }, []);
  return (
    <div className="wrap flex flex-col overflow-auto h-full">
      <header className="border-b-[0.5px]">
        <TopBar arrow={true} home={true} heading={true} shop={true} />
      </header>
      <main className="max-w-screen-break mx-auto flex-1 w-full h-full">
        <form
          onSubmit={handleSubmit(onSubmit, onInvalid)}
          className="flex flex-col w-full h-full"
        >
          <div className="py-4 px-5 space-y-5 flex-1">
            <div className="flex flex-wrap flex-col gap-y-4 p-4 rounded-2xl border border-btnLightLine">
              <div className="flex flex-col flex-wrap">
                <p className="w-full py-1 pl-2 ">
                  <span className="p-1 text-appSmall">
                    문의 하실 내용의 제목을 입력해주세요
                  </span>
                </p>
                <input
                  type="text"
                  {...register(`title`, {
                    required: "• 이름은 필수 입력 정보입니다.",
                  })}
                  placeholder="제목을 입력해주세요."
                  className="text-standard  text-grayFont w-full rounded-lg py-2 px-5 h-10 border border-btnDarkLine focus:outline-mainIcon"
                />
              </div>
              <div>
                <select
                  {...register(`type`, {
                    required: "• 문의 종류를 선택해주세요",
                  })}
                  className="text-standard text-darkFont w-full rounded-lg h-10 py-2 px-5 border border-btnDarkLine focus:outline-mainIcon"
                >
                  <option
                    defaultChecked
                    className="text-standard text-hintFont"
                    value=""
                  >
                    카테고리를 선택해주세요
                  </option>
                  {inquiryTypes.map((obj) => (
                    <option
                      key={obj.text}
                      className="text-darkFont text-standard"
                      value={obj.type}
                    >
                      {obj.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-wrap flex-col gap-y-4 p-4 rounded-2xl border border-btnLightLine">
              <div className="flex flex-col flex-wrap h-[224px]">
                <p className="w-full py-1 pl-2 ">
                  <span className="p-1 text-appSmall">
                    문의 내용을 구체적으로 작성 해주세요.
                  </span>
                </p>
                <textarea
                  {...register(`note`, {
                    required: "• 내용을 입력해주세요.",
                  })}
                  placeholder="문의 내용을 적어주세요."
                  className="flex-1 px-5 py-2 text-standard text-darkFont border border-btnDarkLine rounded-lg w-full resize-none focus:outline-mainIcon"
                ></textarea>
              </div>
              <div className="py-2 flex flex-wrap w-full">
                {inquiryPosters.map((posters, index) => (
                  <div
                    key={`inquiryPoster+${index}`}
                    className="inline-block w-1/4 max-w-[88px] px-1"
                  >
                    {posters.poster && posters.url ? (
                      <div className="relative inline-flex w-full aspect-square bg-gray-200 border rounded-md pointer flex-wrap">
                        <img
                          src={posters.url}
                          alt="이미지"
                          className="absolute left-0 top-0 w-full h-full object-contain"
                        />
                        <button
                          type="button"
                          onClick={() => {
                            delInquiryPosterHandler(posters.index, posters.id);
                          }}
                          className="w-5 h-5 rounded-full bg-gray-500 absolute -right-[8px] -top-[8px]"
                        >
                          <img src={exit_white} alt="delete" />
                        </button>
                      </div>
                    ) : (
                      <label className="relative inline-flex w-full aspect-square border rounded-md pointer bg-gray-200 flex-wrap content-center justify-center">
                        <img src={plus} alt="사진추가" className="w-1/2" />
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={(event: any) => {
                            upInquiryPosterHandler(posters.index, event);
                          }}
                          className="hidden"
                        />
                      </label>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full flex flex-wrap max-w-screen-fit px-9 pb-[25px] text-center mx-auto gap-x-4">
            <button
              type="button"
              className="flex-auto border border-btnLightLine  text-hintFont bg-[#fff] text-standard break:text-Xlarge rounded-full py-2 mx-auto"
              onClick={() => navigate(-1)}
            >
              취소
            </button>
            <button className="flex-auto h-10 text-white bg-mainIcon  text-standard break:text-Xlarge rounded-full ">
              수정
            </button>
          </div>
        </form>
        <Dialog
          open={alertOpen}
          onClose={() => {
            setAlertOpen(false);
          }}
          className="relative"
        >
          <AlertComponent
            title={`알림`}
            desc={"수정이 완료 되었습니다."}
            closeFunction={closeAlert}
          ></AlertComponent>
        </Dialog>
      </main>
    </div>
  );
};
