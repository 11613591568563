import logo from "../assets/icons/logo.png";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetKakaoEmail } from "../hooks/useGetKakaoEmail";
import { logIn } from "../token";
import { Dialog } from "@mui/material";
import { AlertComponent } from "./alert";
import { useSnsLogIn } from "../hooks/useSnsLogin";
import { GetKakaoEmailInput, GetKakaoEmailQuery, Sns, SnsLoginInput, SnsLoginMutation } from "../gql/graphql";

export const KaKaoLogin = () => {
  var useOnce = false;
  const [alertOpen, setAlertOpen] = useState(false);
  const [text, setText] = useState("");
  const closeModal = () => {
    setAlertOpen(false);
    navigate("/login");
  };
  const navigate = useNavigate();
  const onCompleted = (data: GetKakaoEmailQuery) => {
    const {
      getKakaoEmail: { ok, err, email },
    } = data;

    if (err) {
      navigate("/getDataFail");
    }
    if (ok && email) {
      const snsLoginInput: SnsLoginInput = {
        username: email,
        sns: Sns.Kakao,
      };
      snsLogin({ variables: { input: snsLoginInput } });
    }
  };
  const [getKakaoEmail] = useGetKakaoEmail(onCompleted);
  const loginCompleted = (data: SnsLoginMutation) => {
    const {
      snsLogin: { ok, err, token, username, exist },
    } = data;
    if (ok && token && exist) {
      logIn(token);
    } else if (ok && !exist) {
      const info = {
        email: username,
        sns: Sns.Kakao,
      };
      const state = JSON.stringify(info);
      navigate("/Join/sns", { state });
    } else {
      setText(String(err));
      setAlertOpen(true);
    }
  };
  const [snsLogin] = useSnsLogIn(loginCompleted);
  useEffect(() => {
    if (useOnce === false) {
      const code = new URL(window.location.href).searchParams.get("code");
      if (!code) {
        setText("잘못된 경로입니다.");
        setAlertOpen(true);
        return;
      }
      useOnce = true;
      const input: GetKakaoEmailInput = {
        code,
      };
      getKakaoEmail({ variables: { input } });
      setTimeout(() => {
        setText("정보를 불러오지 못해 로그인 페이지로 돌아갑니다.");
        setAlertOpen(true);
      }, 10000);
    }
  }, []);
  return (
    <div className="wrap max-w-screen-break min-h-screen mx-auto w-full pb-32 bg-slate-100 bg-opacity-100">
      <main className="max-w-screen-break relative pt-12 px-8">
        <img src={logo} alt="결제완료 닐리버리 로고" className="mx-auto " />
        <p className="pt-20 text-center text-mainIcon font-bold text-large">사용자 데이터를 불러오고 있습니다.</p>
      </main>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <AlertComponent title={`안내`} desc={`${text}`} closeFunction={closeModal}></AlertComponent>
      </Dialog>
    </div>
  );
};
