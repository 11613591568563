import { useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { calculate, comma, pageIndicator, searchText } from "../apollo";
import { TopBar } from "../components/topBar";
import { useListGood } from "../hooks/useListGood";
import imageBox from "../assets/images/imageBox.png";
import { useNavigate } from "react-router-dom";
import { useCrBaksetItem } from "../hooks/useCrBasketItem";
import { useListBaksetItem } from "../hooks/useListBaksetMine";
import { Dialog } from "@mui/material";
import { AlertComponent } from "../components/alert";
import { ListGoodInput, GoodStatus, DetailCrBasketInput, CrBasketItemInput, CrBasketItemMutation } from "../gql/graphql";

export const ListGoodSearch = () => {
  const useSearchText = useReactiveVar(searchText);
  var useOnce = false;
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const closeAlert = () => {
    setAlertOpen(false);
  };

  const [listGood, { data: listgoods, loading }] = useListGood();
  const [page, setPage] = useState<number>(1);
  const listGoodHandler = (page: number | null, search: string | null) => {
    const input: ListGoodInput = {
      status: GoodStatus.GoodSales,
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listGood({ variables: { input } });
  };

  // 장바구니
  const [listBasketItem, { refetch }] = useListBaksetItem();
  const onMakeBasketItem = async (data: CrBasketItemMutation) => {
    const {
      crBasketItem: { ok, err },
    } = data;
    if (ok) {
      setAlertText("장바구니에 추가 되었습니다.");
      setAlertOpen(true);
      await refetch();

      // alert("장바구니에 추가 되었습니다.");
    } else {
      alert(err);
    }
  };
  const [crBasketItem, { loading: crBasketLoading }] = useCrBaksetItem(onMakeBasketItem);
  const crBasketItemHandler = (info: DetailCrBasketInput) => {
    if (!crBasketLoading) {
      var basketInfos: DetailCrBasketInput[] = [];
      basketInfos.push(info);
      const input: CrBasketItemInput = {
        basketInfos,
      };
      crBasketItem({ variables: { input } });
    }
  };

  useEffect(() => {
    if (useOnce === false) {
      useOnce = true;
      listGoodHandler(page, `${useSearchText}`);
    }
  }, [useSearchText]);
  useEffect(() => {
    pageIndicator("검색");
  }, []);
  return (
    <div className="relative wrap flex flex-col h-full w-full overflow-auto">
      <header>
        <TopBar arrow={true} arrowColor={`#B5C1D0`} search={true} shop={true} />
      </header>

      <div className="w-full flex-1 max-w-screen-break mx-auto bg-white ">
        <div className="h-full w-full flex flex-col overflow-auto removeScroll">
          <div className="w-full h-10 bg-main pl-6 pr-2 flex flex-wrap content-center justify-between">
            <p className="py-2 text-[10px] leading-4 text-grayFont">
              {listgoods?.listGood.goods ? listgoods?.listGood.goods.length : 0}
              개의 검색결과
            </p>
            {/* <button className="p-2">
              <img src={sort_down} alt="순서재정렬" />
            </button> */}
          </div>
          <div className="flex-1 px-4">
            {loading && (
              <div className="w-full h-full bg-[rgba(0,0,0,0)]">
                <p className="py-10">검색 중입니다.</p>
              </div>
            )}
            {!loading && listgoods?.listGood?.goods?.length == 0 && (
              <div className="w-full h-full ">
                <p className="py-10 text-center">검색 결과가 없습니다.</p>
              </div>
            )}
            {!loading &&
              listgoods?.listGood.goods &&
              listgoods?.listGood?.goods.map((good, index, searchGoodsArray) => (
                <div key={`suggestion-good-${good.id}`} className="flex flex-col gap-y-2">
                  <div
                    className={`flex flex-warp gap-x-4 overflow-hidden py-4 
                  ${index + 1 !== searchGoodsArray.length && "border-b border-[#F0F0F4]"}
                  `}
                  >
                    <img
                      src={`${
                        good?.goodPosters && good?.goodPosters.length > 0 && good?.goodPosters[0].upload ? good?.goodPosters[0].upload.url : imageBox
                      }
                    `}
                      alt={`${good.title} 이미지`}
                      onClick={() => {
                        navigate(`/detail/${good?.id}`);
                      }}
                      className="h-[104px] aspect-square rounded-lg cursor-pointer"
                    />
                    <div className="flex-1 flex flex-wrap flex-col">
                      <div className="flex flex-col py-1">
                        <div className="flex flex-wrap">
                          <p className="px-1 py-[6px] text-Xsmall min-h-[12px] flex-1">{good.corporation?.name ? good.corporation?.name : ""}</p>
                          {/* <button
                              onClick={() => {
                                crBasketItemHandler({
                                  goodId: good.id,
                                  goodOptionId: null,
                                  quantity: 1,
                                });
                              }}
                              className="px-[5.5px] py-[1.5px]"
                            >
                              <div className="border w-5 h-5 border-toast rounded-full flex flex-wrap content-center justify-center">
                                <img
                                  src={cart}
                                  alt="장바구니"
                                  className="w-full"
                                />
                              </div>
                            </button> */}
                        </div>
                        <p
                          onClick={() => {
                            navigate(`/detail/${good?.id}`);
                          }}
                          className="h-[36px] px-1 py-0.5 text-small leading-[16px] overflow-hidden ellipsis2 hover:underline cursor-pointer"
                        >
                          {`${good.title}`}
                        </p>
                      </div>
                      {/* 가격 */}
                      <div className="flex flex-wrap gap-x-1 ">
                        <span className="align-middle text-darkFont text-large break:text-Xlarge font-bold px-2">
                          {comma(calculate(Number(good.price), Number(good.discountRate)))} 원
                        </span>
                        {good.discountRate !== 0 && (
                          <span className="text-shade1 font-bold text-large break:text-Xlarge px-1">{good.discountRate}%</span>
                        )}
                        <div>
                          {good.discountRate !== 0 && (
                            <span className="align-middle line-through text-small break:text-standardall text-[#b6b6b6] px-1">
                              {comma(good.price != undefined ? good.price : 0)}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        className="relative"
      >
        <AlertComponent title={`알림`} desc={alertText} closeFunction={closeAlert}></AlertComponent>
      </Dialog>
    </div>
  );
};
