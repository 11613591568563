import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { calculate, comma, searchText } from "../apollo";
import { useForm } from "react-hook-form";
import { SearchIcon } from "./Icons";
import { useNavigate } from "react-router-dom";
import search_arrow from "../assets/icons/search_arrow.svg";
import imageBox from "../assets/images/imageBox.png";

import { Dialog } from "@mui/material";
import { AlertComponent } from "./alert";
import { useListGood } from "../hooks/useListGood";
import { GoodStatus, ListGoodInput, ListGoodQuery, Tags } from "../gql/graphql";

interface IGoodSearch {
  search: string;
  searchDown?: () => void;
}

interface ISearchFormProps {
  search: string;
}
export const GoodSearch: React.FC<IGoodSearch> = ({ search, searchDown }) => {
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const { getValues, register, handleSubmit, setValue } = useForm<ISearchFormProps>();
  const closeAlert = () => {
    setAlertOpen(false);
  };

  const onCompleted = (data: ListGoodQuery) => {
    const {
      listGood: { ok, err, goods },
    } = data;
    if (ok && goods) {
    } else {
      console.log(err);
    }
  };
  const [listGood, { data: listGoodData, loading, refetch }] = useListGood(onCompleted);

  const listGoodHandler = () => {
    const input: ListGoodInput = {
      page: 1,
      status: GoodStatus.GoodSales,
      tag: Tags.Suggestion,
    };
    listGood({ variables: { input } });
  };
  const onSearch = () => {
    const { search } = getValues();
    searchText(search);
    if (search) {
      navigate(`/listGood/:${search}`);
      searchText(search);
    } else {
      setAlertText("검색어를 입력해주세요");
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    listGoodHandler();
  }, []);
  return (
    <div className="w-full h-full max-w-screen-break mx-auto bg-white shadow-search rounded-t-3xl pt-2 border border-x-mainIcon">
      <div className="h-full w-full flex flex-col overflow-auto removeScroll">
        <div onClick={searchDown}>
          <img src={search_arrow} alt="검색창열림" className="w-[88px] mx-auto" />
        </div>
        <div className=" top">
          <form onSubmit={handleSubmit(onSearch)} className="w-full p-4 flex flex-wrap  gap-x-2">
            <div className="flex-1 relative flex flex-wrap content-center">
              <div className="w-full">
                <input
                  {...register("search", { required: true })}
                  type="text"
                  className="w-full h-8 rounded-full border border-mainIcon pl-4 text-small break:text-standard placeholder:text-hintFont"
                  placeholder="검색어를 입력해주세요"
                />
              </div>
            </div>
            <div className="w-8 flex flex-wrap content-center ">
              <button onClick={onSearch} className="w-full h-8 flex flex-wrap content-center justify-center rounded-full bg-mainIcon">
                <SearchIcon color={`#fff`} />
              </button>
            </div>
            <button onClick={searchDown} className="text-xs leading-[14px] text-grayFont p-2 rounded-lg border border-btnLightLine">
              닫기
            </button>
          </form>
        </div>
        <div className="flex-1 px-4">
          <p className="w-full py-1 px-1 font-bold text-mainIcon text-xl leading-normal">닐리버리 추천 상품</p>
          {/* 추천상품 maping */}
          {!loading &&
            listGoodData?.listGood.goods &&
            listGoodData?.listGood.goods.map((good, index, goodArray) => (
              <div key={`suggestion-good-${good.id}`} className="flex flex-col gap-y-2">
                <div
                  className={`flex flex-warp gap-x-4 overflow-hidden py-4 
                  ${index + 1 !== goodArray.length && "border-b border-[#F0F0F4]"}
                  `}
                >
                  <img
                    src={`${
                      good?.goodPosters && good?.goodPosters.length > 0 && good?.goodPosters[0].upload ? good?.goodPosters[0].upload.url : imageBox
                    }
                    `}
                    alt={`${good.title} 이미지`}
                    onClick={() => {
                      navigate(`/detail/${good?.id}`);
                    }}
                    className="h-20 aspect-square rounded-lg cursor-pointer"
                  />
                  <div className="flex-1 flex flex-wrap flex-col">
                    <div className="flex flex-col py-1">
                      <p className="px-1 text-Xsmall">{good.corporation?.name}</p>
                      <p
                        onClick={() => {
                          navigate(`/detail/${good?.id}`);
                        }}
                        className="h-[36px] px-1 py-0.5 text-small leading-[16px] overflow-hidden ellipsis2 hover:underline cursor-pointer"
                      >
                        {`${good.title}`}
                      </p>
                    </div>
                    {/* 가격 */}
                    <div className="flex flex-wrap gap-x-1 ">
                      <span className="align-middle text-darkFont text-large break:text-Xlarge font-bold px-2">
                        {comma(calculate(Number(good.price), Number(good.discountRate)))} 원
                      </span>
                      {good.discountRate !== 0 && (
                        <span className="text-shade1 font-bold text-large break:text-Xlarge px-1">{good.discountRate}%</span>
                      )}
                      <div>
                        {good.discountRate !== 0 && (
                          <span className="align-middle line-through text-small break:text-standardall text-[#b6b6b6] px-1">
                            {comma(good.price != undefined ? good.price : 0)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {!loading && listGoodData?.listGood?.goods?.length == 0 && (
            <div className="w-full h-full ">
              <p className="py-10 text-center">검색 결과가 없습니다.</p>
            </div>
          )}
        </div>
        <Dialog
          open={alertOpen}
          onClose={() => {
            setAlertOpen(false);
          }}
          className="relative"
        >
          <AlertComponent title="안내" desc={alertText} closeFunction={closeAlert}></AlertComponent>
        </Dialog>
      </div>
    </div>
  );
};
