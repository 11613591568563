import { gql, useLazyQuery } from "@apollo/client";
import { ListReceiptReviewQuery, ListReceiptReviewQueryVariables } from "../gql/graphql";

const LIST_RECEIPT_REVIEW_QUERY = gql`
  query listReceiptReview($input: ListReceiptReviewInput!) {
    listReceiptReview(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      receipts {
        id
        uuid
        status
        paid_at
        receiptStatus
        receiptNum
        orders {
          id
          orderReceipt {
            good_discountRate
            good_title
            goodOption_name
            good_poster_url
          }
          review {
            id
          }
          goodName
          goodOptionName
          quantity
          amount
          status
          exchangeStatus
          redelivery
          good {
            id
            title
            goodPosters {
              upload {
                url
              }
            }
          }
          goodOption {
            id
            name
          }
        }
      }
    }
  }
`;

export const useListReceiptReview = (onCompleted?: (data: ListReceiptReviewQuery) => void) => {
  return useLazyQuery<ListReceiptReviewQuery, ListReceiptReviewQueryVariables>(LIST_RECEIPT_REVIEW_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
