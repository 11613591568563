import { useForm } from "react-hook-form";
import { useMe } from "../hooks/useME";
import { useUpUser } from "../hooks/useUpUser";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../token";
import { authTokenVar } from "../apollo";
import { useDelUser } from "../hooks/useDelUser";
import { Dialog } from "@mui/material";
import { AlertComponent, ConfirmComponent } from "../components/alert";
import {
  DelUserInput,
  DelUserMutation,
  UpUserInput,
  UpUserMutation,
} from "../gql/graphql";

export const SubUserInfo = () => {
  // 회원정보 변경
  const { data: me } = useMe();
  const navigate = useNavigate();
  const [tabs, setTabs] = useState(0);
  const [kgCert, setKgCert] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const closeAlert = () => {
    setAlertOpen(false);
    if (
      alertText ===
      "성공적으로 탈퇴하였습니다.<br/> 그동안 이용해주셔서 감사합니다."
    )
      window.location.href = "/";
    if (alertText === "회원정보가 변경되었습니다.") window.location.reload();
  };
  const closeConfirm = () => {
    setConfirmOpen(false);
  };
  const confirmFunction = () => {
    setConfirmOpen(false);
    delUserHandler();
  };
  const {
    register: dataRegister,
    handleSubmit: dataHandleSubmit,
    getValues: dataGetValues,
    // formState: { errors: dataErrors, isValid: dataIsValid },
  } = useForm<UpUserInput>({
    mode: "onChange",
  });
  const onChangeData = (data: UpUserMutation) => {
    const {
      upUser: { ok, err },
    } = data;
    if (ok) {
      setAlertText("회원정보가 변경되었습니다.");
      setAlertOpen(true);
      // alert("회원정보가 변경되었습니다.");
      // window.location.reload();
    } else {
      alert(err);
    }
  };
  const [upUser] = useUpUser(onChangeData);
  const onDataSubmit = () => {
    const { name, phone } = dataGetValues();
    const upUserInput: UpUserInput = {
      name: name,
      phone: phone,
    };
    upUser({ variables: { input: upUserInput } });
  };

  // 회원 탈퇴
  const onDelUser = (data: DelUserMutation) => {
    const {
      delUser: { ok, err },
    } = data;
    if (ok) {
      removeToken();
      authTokenVar("");
      setAlertText(
        "성공적으로 탈퇴하였습니다.<br/> 그동안 이용해주셔서 감사합니다."
      );
      setAlertOpen(true);
      // alert("성공적으로 탈퇴하였습니다. 그동안 이용해주셔서 감사합니다.");
      // window.location.href = "/";
    } else {
      alert(err);
    }
  };
  const [delUser] = useDelUser(onDelUser);
  const delUserHandler = () => {
    const delUserInput: DelUserInput = {
      id: Number(me?.me.id),
    };
    delUser({
      variables: {
        input: delUserInput,
      },
    });
  };

  return (
    <form
      onSubmit={dataHandleSubmit(onDataSubmit)}
      className="h-full flex flex-col justify-between w-full"
    >
      <div className="flex flex-col flex-wrap justify-center gap-y-10 px-5 pt-20">
        <div className=" flex flex-wrap flex-col px-4 gap-y-4 ">
          <div className="py-2 flex">
            <span className="py-2 pl-1 font-bold text-[20px] leading-6">
              회원정보 변경
            </span>
          </div>
          <div>
            <p className="w-full py-1 pl-2 ">
              <span className="p-1 text-appSmall text-darkFont">아이디</span>
            </p>
            <input
              type="email"
              placeholder="메일을 적어주세요"
              value={me && String(me?.me.username)}
              disabled
              className="text-standard text-darkFont w-full  h-10 py-2 px-5 font-bold border-b border-b-btnLightLine bg- focus:outline-mainIcon disabled:bg-inherit"
            />
          </div>
          <div>
            <p className="w-full py-1 pl-2 ">
              <span className="p-1 text-appSmall text-darkFont">이름</span>
            </p>
            <input
              type="text"
              defaultValue={String(me?.me.name)}
              {...dataRegister("name", {
                required: true,
              })}
              className="text-standard text-darkFont w-full  h-10 py-2 px-5 font-bold rounded-lg border border-btnLightLine bg- focus:outline-mainIcon "
            />
          </div>
          <div>
            <p className="w-full py-1 pl-2 ">
              <span className="p-1 text-appSmall text-darkFont">전화번호</span>
            </p>
            <input
              type="text"
              {...dataRegister("phone", {
                pattern: {
                  value: /^[0-9]{10,11}$/,
                  message: "숫자만 10-11자리 입력해주세요",
                },
                required: "전화번호는 필수 입력 정보입니다.",
              })}
              placeholder="전화번호를 적어주세요"
              defaultValue={String(me?.me.phone)}
              onKeyUp={(e) =>
                (e.currentTarget.value = e.currentTarget.value.replace(
                  /[^0-9]/g,
                  ""
                ))
              }
              className="text-standard text-darkFont w-full  h-10 py-2 px-5 font-bold rounded-lg border border-btnLightLine bg- focus:outline-mainIcon"
            />
          </div>
          <div className="py-2 px-4">
            <span
              onClick={() => setConfirmOpen(true)}
              className="p-1 text-smallBtn underline"
            >
              회원탈퇴하기
            </span>
          </div>
        </div>
        <div className="w-full flex flex-wrap max-w-screen-fit py-2 text-center mx-auto gap-x-4">
          <button
            type="button"
            className="flex-auto border h-10 border-btnLightLine  text-hintFont bg-[#fff] text-standard  rounded-full py-2 mx-auto"
            onClick={() => navigate(-1)}
          >
            취소
          </button>
          <button className="flex-auto h-10 text-white bg-mainIcon  text-standard rounded-full ">
            저장
          </button>
        </div>
      </div>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        className="relative"
      >
        <AlertComponent
          title={`알림`}
          desc={alertText}
          closeFunction={closeAlert}
        ></AlertComponent>
      </Dialog>
      <Dialog
        open={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
        }}
        className="relative"
      >
        <ConfirmComponent
          title={`안내`}
          desc={`계정을 삭제하면 <br/> 모든 개인정보가 삭제됩니다.<br/> 정말 닐리버리를 탈퇴하시겠습니까?`}
          closeFunction={closeConfirm}
          confirmFunction={confirmFunction}
        ></ConfirmComponent>
      </Dialog>
      {/* <div className="border border-btnLightLine rounded-xl px-4">
          <div className="pt-2">
            <p className="text-standard break:text-large text-darkFont p-2">
              메일 주소
            </p>
            <input
              type="email"
              placeholder="메일을 적어주세요"
              value={me && String(me?.me.username)}
              disabled
              className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon"
            />
          </div>
          <div className="pt-2">
            <p className="text-standard break:text-large text-darkFont p-2">
              사용자명
            </p>
            <input
              type="text"
              defaultValue={String(me?.me.name)}
              {...dataRegister("name", {
                required: true,
              })}
              className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon"
            />
          </div>
          <div className="pt-2">
            <p className="text-standard break:text-large text-darkFont p-2">
              전화번호
            </p>
            <input
              type="text"
              {...dataRegister("phone", {
                pattern: {
                  value: /^[0-9]{10,11}$/,
                  message: "숫자만 10-11자리 입력해주세요",
                },
                required: "전화번호는 필수 입력 정보입니다.",
              })}
              placeholder="전화번호를 적어주세요"
              defaultValue={String(me?.me.phone)}
              onKeyUp={(e) =>
                (e.currentTarget.value = e.currentTarget.value.replace(
                  /[^0-9]/g,
                  ""
                ))
              }
              className="text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon"
            />
          </div>
          <div className="py-4">
            <button className="px-8 py-2 text-white bg-darkFont font-bold text-small break:text-standardall rounded-lg mx-auto block">
              회원 정보변경
            </button>
          </div>
        </div> */}
    </form>
  );
};
