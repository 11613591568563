import main_logo from "../assets/icons/logo.png";

export const Loading = () => {
  return (
    <div className="w-full bg-pointIcon min-h-screen grid grid-rows-1 relative">
      <div className="flex flex-col">
        <div className="flex-1 px-6 flex flex-wrap content-center">
          <img src={main_logo} alt="닐리버리 로고" className="w-full mx-auto max-w-[400px] px-6" />
        </div>
      </div>
    </div>
  );
};
