import { gql, useMutation } from "@apollo/client";
import { ApolloError } from "apollo-server";
import { CrGoodReviewMutation, CrGoodReviewMutationVariables } from "../gql/graphql";

const CR_GOOD_REVIEW_MUTATION = gql`
  mutation crGoodReview($input: CrGoodReviewInput!) {
    crGoodReview(input: $input) {
      ok
      err
    }
  }
`;

export const useCrGoodReview = (onCompleted?: (data: CrGoodReviewMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<CrGoodReviewMutation, CrGoodReviewMutationVariables>(CR_GOOD_REVIEW_MUTATION, {
    onCompleted,

    fetchPolicy: "network-only",
  });
};
