import logo from "../assets/icons/logo.png";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
export const PaymentFail = () => {
  const navigate = useNavigate();
  const params = useParams();

  // get receipt

  useEffect(() => {
    pageIndicator("주문 오류");
  }, []);
  return (
    <div className="wrap relative h-full">
      <main className="max-w-screen-break mx-auto h-full">
        <TopBar home={true} arrow={true} heading={true} exit={true} />
        <div className="w-full h-full flex flex-wrap flex-col content-center justify-center border-t border-btnLightLine bg-main">
          <img src={logo} alt="니나노로고" className="px-6 " />
          <p className="mt-2 text-sm leading-4 px-1 py-2 text-center text-darkFont">주문이 정상적으로 완료되지 않았습니다. 다시 시도해주세요.</p>
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="w-[200px] mx-auto mt-2 h-10 py-1"
          >
            <span className="text-grayFont font-bold flex flex-wrap content-center justify-center h-full w-full bg-white  border border-[#B5C1D0] rounded-full">
              뒤로
            </span>
          </button>
        </div>
      </main>
      {/* <div className="w-full flex flex-wrap absolute z-10 bottom-[80px] justify-center">
        <button
          onClick={() => {
            navigate("/");
          }}
          className="w-[200px] text-center py-2  text-[#fff]  bg-mainIcon font-bold text-btn break:text-standardall rounded-[20px] "
        >
          뒤로
        </button>
      </div> */}
    </div>
  );
};
