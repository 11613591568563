import { useEffect, useState } from "react";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import { useLocation } from "react-router-dom";
import { FootMenu } from "../components/FootMenu";
import { SubMyReview } from "../sub-routes/sub-MyReview";
import { SubListReview } from "../sub-routes/sub-listReview";
export interface dateFilterBtnList {
  text: string;
  active: boolean;
}

export const ListReview = () => {
  const state = useLocation().state;
  const [tabs, setTabs] = useState(state ? state : 0);
  // useeffect
  useEffect(() => {
    pageIndicator("나의 리뷰");
  }, []);

  return (
    <div className="h-full relative flex flex-wrap flex-col ">
      <header>
        <TopBar arrow={true} heading={true} shop={true} />
      </header>
      <main className="max-w-screen-break w-full flex-1 mx-auto overflow-auto removeScroll pb-[72px] bg-bg">
        <div className="topBox bg-white flex flex-wrap content-center justify-between h-10 border-t-[0.5px]  w-full">
          <button
            className={`w-1/2 h-full border-b-2 ${tabs === 0 && " border-mainIcon"}`}
            onClick={() => {
              // window.history.pushState(null, "", "?cancel=0");
              setTabs(0);
            }}
          >
            리뷰 목록
          </button>
          <button
            className={`w-1/2 h-full border-b-2 ${tabs === 1 && "border-mainIcon"}`}
            onClick={() => {
              // window.history.pushState(null, "", "?cancel=1");
              setTabs(1);
            }}
          >
            나의 리뷰 보기
          </button>
        </div>
        {tabs === 0 && <SubListReview />}
        {tabs === 1 && <SubMyReview />}
        {/* <div
          id="searchDateRange"
          className="mt-2 h-[34px] w-full pl-4 pr-14 overflow-x-scroll removeScroll relative flex flex-wrap gap-x-1"
        >
          {dateFilterBtnList.map((list, index) => (
            <button
              key={list.text}
              className={`px-4 py-1 text-standard break:text-large rounded-full ${
                list.active
                  ? "text-white bg-mainIcon"
                  : "text-mainIcon bg-white border border-btnLightLine "
              }`}
            >
              {list.text}
            </button>
          ))}

          <button
            onClick={scorllHandler}
            className="w-8 h-8 absolute right-4 top-0 bg-pointIcon rounded-lg flex flex-wrap content-center justify-center"
          >
            <ArrowRight color={`#00B0EB`} />
          </button>
        </div> */}
      </main>

      <FootMenu />
    </div>
  );
};
