import { useNavigate } from "react-router-dom";
interface Select {
  select: number;
}
export const SelectOne: React.FC<Select> = ({ select }) => {
  const navigate = useNavigate();
  return (
    <div className="mt-2 h-8 w-full flex flex-wrap justify-center gap-x-4 ">
      <button
        onClick={() => {
          if (select !== 1) {
            navigate("/niliveryitem");
          } else {
            navigate("/");
          }
        }}
        className={`text-standard break:text-large border font-bold rounded-full w-[104px] ${
          select === 1
            ? "text-white  bg-mainIcon  border-mainIcon"
            : "bg-white border-btnLightLine text-mainIcon"
        } `}
      >
        닐리버리
      </button>
      <button
        onClick={() => {
          if (select !== 2) {
            navigate("/musteatitem");
          } else {
            navigate("/");
          }
        }}
        className={`text-standard break:text-large border font-bold rounded-full w-[104px] ${
          select === 2
            ? "text-white  bg-mainIcon  border-mainIcon"
            : "bg-white border-btnLightLine text-mainIcon"
        } `}
      >
        머스트잍
      </button>
    </div>
  );
};
