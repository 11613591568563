import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pageIndicator } from "../apollo";
import { FootMenu } from "../components/FootMenu";
import { TopBar } from "../components/topBar";
import { useListInquiry } from "../hooks/useListInquiry";
import { SubListInquiry } from "../sub-routes/sub-list-Inquiry";
import { SubListInquiryComplete } from "../sub-routes/sub-list-Inquiry-complete";

export interface dateFilterBtnList {
  text: string;
  active: boolean;
}
export const ListInquiry = () => {
  const navigate = useNavigate();
  const [tabs, setTabs] = useState(0);
  const [dateFilterBtnList, setDateFilterBtnList] = useState<dateFilterBtnList[]>([
    { text: "최근내역", active: true },
    { text: "1개월", active: false },
    { text: "3개월", active: false },
    { text: "6개월", active: false },
    { text: "1년", active: false },
  ]);
  const scorllHandler = () => {
    var element = document.getElementById("searchDateRange");
    var left = element?.scrollLeft;
    element?.scrollTo({ left: Number(left) + 200, behavior: "smooth" });
  };

  const [listInquiry, { data, loading, refetch }] = useListInquiry();
  useEffect(() => {
    pageIndicator("나의 문의내역");
    listInquiry({ variables: { input: { page: 1 } } });
  }, []);
  return (
    <div className="wrap relative h-full ">
      <main className="max-w-screen-break mx-auto h-full flex flex-col">
        <TopBar arrow={true} heading={true} shop={true} home={true} />
        <div className="flex-1 overflow-auto pb-[72px] flex flex-col w-full bg-main">
          <div className="flex flex-wrap border-t border-btnLightLine bg-white">
            <button
              className={`w-1/2 h-10 border-b-2 ${tabs === 0 ? "border-mainIcon" : "border-btnDarkLine"}`}
              onClick={() =>
                setTabs(() => {
                  return 0;
                })
              }
            >
              문의진행중
            </button>
            <button
              className={`w-1/2 h-10 border-b-2 ${tabs === 1 ? "border-mainIcon" : "border-btnDarkLine"}`}
              onClick={() =>
                setTabs(() => {
                  return 1;
                })
              }
            >
              답변완료
            </button>
          </div>
          {/* <div className="mt-2 h-[34px] w-full pl-4 pr-14 overflow-hidden relative ">
            <div
              id="searchDateRange"
              className="w-full h-full overflow-auto removeScroll "
            >
              <div className="w-[400px] h-full space-x-1 ">
                {dateFilterBtnList.map((list, index) => (
                  <button
                    key={list.text}
                    className={`px-4 py-1 text-standard break:text-large rounded-full ${
                      list.active
                        ? "text-white bg-mainIcon"
                        : "text-mainIcon bg-white border border-btnLightLine "
                    }`}
                  >
                    {list.text}
                  </button>
                ))}
              </div>
            </div>
            <div className="block w-14 h-full absolute top-0 right-0 bg-white"></div>
            <button
              onClick={scorllHandler}
              className="w-8 h-8 absolute right-4 top-0 bg-pointIcon rounded-lg flex flex-wrap content-center justify-center"
            >
              <ArrowRight color={`#00B0EB`} />
            </button>
          </div> */}
          <div className="doorBox flex-1 p-4 bg-main">
            {/* map component door */}
            {tabs === 0 && <SubListInquiry />}
            {tabs === 1 && <SubListInquiryComplete />}
          </div>
        </div>
      </main>

      <div className="absolute bottom-0 left-0 w-full">
        <FootMenu />
      </div>
    </div>
  );
};
