import { ApolloError, gql, useMutation } from "@apollo/client";

import { CancelReceiptMutation } from "../gql/graphql";

const UP_RECEIPT_CANCEL = gql`
  mutation cancelReceipt($input: UpReceiptCancelInput!) {
    cancelReceipt(input: $input) {
      ok
      err
    }
  }
`;

export const useCancelReceipt = (
  onCompleted?: (data: CancelReceiptMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CancelReceiptMutation>(UP_RECEIPT_CANCEL, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
