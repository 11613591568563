import { gql, useLazyQuery } from "@apollo/client";
import { GetOrderQuery, GetOrderQueryVariables } from "../gql/graphql";

const GET_ORDER_QUERY = gql`
  query getOrder($input: GetOrderInput!) {
    getOrder(input: $input) {
      ok
      err
      order {
        id
        goodName
        goodOptionName
        orderReceipt {
          id
          order_quantity
          good_discountRate
          order_price
          goodOption_name
          good_title
          good_poster_url
        }
        receipt {
          howToReceive
          port {
            name
            phone
            address
            usageTime
          }
        }
        price
        quantity
        manager {
          id
          name
        }
        exchangeStatus
        redelivery
        status
        amount
        review {
          id
        }
        good {
          id
          category
          status
          title
          summary
          corporations {
            id
            name
          }
          goodPosters {
            id
            upload {
              url
            }
          }
          goodOptions {
            id
          }
        }
      }
    }
  }
`;

export const useGetOrder = (onCompleted?: (data: GetOrderQuery) => void) => {
  return useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GET_ORDER_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
