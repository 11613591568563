import React, { useEffect, useState } from "react";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import arrow from "../assets/icons/arrow.svg";
import logo from "../assets/images/logo.png";
import Collapse from "@mui/material/Collapse";
import { personalInfo, useRules } from "../constants/texts";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import { AlertComponent } from "../components/alert";

export const JoinAgree = () => {
  const [firstPara, setFirstPara] = useState<boolean>(false);
  const [secondPara, setSecondtPara] = useState<boolean>(false);
  const [allChk, setallChk] = useState<boolean>(false);
  const [clear, setClear] = useState<boolean>(false);
  const [chkArray, setChkArray] = useState([
    { checked: false },
    { checked: false },
    { checked: false },
  ]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const navigate = useNavigate();
  const firstParaHandler = () => {
    setFirstPara(!firstPara);
  };
  const secondParaHandler = () => {
    setSecondtPara(!secondPara);
  };
  const allChkHandler = () => {
    setallChk(!allChk);
    if (!allChk) {
      setChkArray([{ checked: true }, { checked: true }, { checked: true }]);
      setClear(true);
    } else {
      setChkArray([{ checked: false }, { checked: false }, { checked: false }]);
      setClear(false);
    }
  };
  const oneChk = (idx: number) => {
    setChkArray((prevState) => {
      const newState = prevState.map((obj, index) => {
        if (index === idx) {
          return { ...obj, checked: !chkArray[idx].checked };
        }
        console.log(idx, obj);
        return obj;
      });
      return newState;
    });
    return chkArray;
  };
  const chkclearHandler = () => {
    console.log(
      "1" + chkArray[0].checked + chkArray[1].checked + chkArray[2].checked
    );
    if (!chkArray.find((list) => list.checked === false)) {
      setClear(true);
    } else {
      setallChk(false);
      setClear(false);
    }
  };
  useEffect(() => {
    pageIndicator("이용약관 동의");
    chkclearHandler();
  }, [chkArray]);
  return (
    <div className="w-full bg-bg h-full relative">
      <div className="flex flex-col flex-wrap h-full">
        <TopBar
          arrow={true}
          close={false}
          home={true}
          heading={true}
          search={false}
          shop={false}
          homeRight={false}
          exit={true}
          headingColor={"#363a3d"}
        />
        <div className="px-4 flex flex-wrap overflow-auto removeScroll content-evenly gap-y-8 flex-1 max-w-screen-break mx-auto py-4">
          <div className="w-full mx-auto">
            <img
              src={logo}
              alt="이미지"
              className="max-w-[320px] w-full mx-auto aspect-[320/216]"
            ></img>
          </div>
          <div className="mx-auto">
            <div className="px-4 py-2 h-12 w-full flex">
              <label className="flex flex-wrap content-center cursor-pointer w-full h-full">
                <div className="h-full flex flex-wrap content-center">
                  <input
                    type="checkbox"
                    checked={allChk}
                    onChange={allChkHandler}
                    className={`chkBox w-[18px] h-[18px] m-[7px] appearance-none bg-white checked:bg-checkMark bg-center bg-contain bg-no-repeat checked:border-mainIcon focus:border-mainIcon border rounded-sm`}
                  />
                </div>
                <p className="flex-1 py-2 px-1 flex flex-wrap content-center text-[16px] leading-4 font-bold">
                  약관 전체동의
                </p>
              </label>
            </div>
            <div className="childBoxes border-black border rounded-2xl max-w-screen-fit w-full py-4 bg-[#fff]">
              <div className="px-4 h-8 w-full flex">
                <label className="flex flex-wrap content-center cursor-pointer w-full h-full">
                  <div className="h-full flex flex-wrap content-center">
                    <input
                      type="checkbox"
                      checked={chkArray[0].checked}
                      onChange={() => oneChk(0)}
                      className={`chkBox w-[18px] h-[18px] m-[7px] appearance-none bg-white checked:bg-checkMark bg-center bg-contain bg-no-repeat checked:border-mainIcon focus:border-mainIcon border rounded-sm`}
                    />
                  </div>
                  <p className="flex-1 py-2 px-1 flex flex-wrap content-center text-[14px] leading-4 ">
                    <span className="text-mainIcon">만 14세 이상</span>입니다.
                  </p>
                </label>
              </div>
              <div className="px-4 h-8 w-full flex mt-4">
                <label className="flex-1 flex flex-wrap content-center cursor-pointer w-full h-full">
                  <div className="h-full flex flex-wrap content-center">
                    <input
                      type="checkbox"
                      checked={chkArray[1].checked}
                      onChange={() => oneChk(1)}
                      className={`chkBox w-[18px] h-[18px] m-[7px] appearance-none bg-white checked:bg-checkMark bg-center bg-contain bg-no-repeat checked:border-mainIcon focus:border-mainIcon border rounded-sm`}
                    />
                  </div>
                  <p className="flex-1 py-2 px-1 flex flex-wrap content-center text-[14px] leading-4 ">
                    (필수) 서비스 이용약관 동의
                  </p>
                </label>
                <div className="w-8">
                  <button
                    onClick={firstParaHandler}
                    className="w-full h-full flex flex-wrap content-center justify-center"
                  >
                    <img src={arrow} alt="더보기" className="w-6 m-1" />
                  </button>
                </div>
              </div>
              <Collapse in={firstPara}>
                <div className="w-full px-5">
                  <div className="border rounded-md bg-white p-2 h-32 overflow-scroll whitespace-pre-wrap text-small break:text-standard">
                    {useRules}
                  </div>
                </div>
              </Collapse>
              <div className="px-4 h-8 w-full flex mt-4">
                <label className="flex-1 flex flex-wrap content-center cursor-pointer w-full h-full">
                  <div className="h-full flex flex-wrap content-center">
                    <input
                      type="checkbox"
                      checked={chkArray[2].checked}
                      onChange={() => oneChk(2)}
                      className={`chkBox w-[18px] h-[18px] m-[7px] appearance-none bg-white checked:bg-checkMark bg-center bg-contain bg-no-repeat checked:border-mainIcon focus:border-mainIcon border rounded-sm`}
                    />
                  </div>
                  <p className="flex-1 py-2 px-1 flex flex-wrap content-center text-[14px] leading-4 ">
                    (필수) 개인정보 처리방침
                  </p>
                </label>
                <div className="w-8">
                  <button
                    onClick={secondParaHandler}
                    className="w-full h-full flex flex-wrap "
                  >
                    <img src={arrow} alt="더보기" className="w-6 m-1" />
                  </button>
                </div>
              </div>
              <Collapse in={secondPara}>
                <div className="w-full px-5">
                  <div className="border rounded-md bg-white p-2 h-32 overflow-scroll whitespace-pre-wrap text-small break:text-standard">
                    {personalInfo}
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
        <div className="pt-4 pb-6 px-4 flex flex-wrap gap-x-4 justify-center">
          <button
            onClick={() => {
              clear
                ? navigate("/join/dataform")
                : setAlertText("모든 약관에 동의해주세요");
              setAlertOpen(true);

              // alert("모든 약관에 동의해주세요");
            }}
            className="bg-mainIcon border border-mainIcon font-bold text-white rounded-full text-base w-full max-w-screen-fit py-2"
          >
            동의하기
          </button>
        </div>
        <Dialog
          open={alertOpen}
          onClose={() => {
            setAlertOpen(false);
          }}
          className="relative"
        >
          <AlertComponent
            title={`알림`}
            desc={alertText}
            closeFunction={closeAlert}
          ></AlertComponent>
        </Dialog>
      </div>
    </div>
  );
};
