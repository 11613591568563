import { TopBar } from "../components/topBar";
import { useEffect, useState } from "react";
import moment from "moment";
import { calculate, comma, pageIndicator } from "../apollo";
import { CheckMark } from "../components/Icons";
import imageSample from "../assets/images/imageBox.png";
import minus from "../assets/icons/minus.svg";
import plus from "../assets/icons/plus.svg";
import { useDelBaksetItem } from "../hooks/useDelBasketItem";
import { useUpBaksetItem } from "../hooks/useUpBasketItem";
import { useNavigate, useParams } from "react-router-dom";
import { useCrReceipt } from "../hooks/useCrReceipt";
import { Dialog } from "@mui/material";
import { AlertComponent, ConfirmComponent } from "../components/alert";
import {
  GoodStatus,
  UpBasketItemInput,
  CrReceiptInput,
  DelBasketItemMutation,
  UpBasketItemMutation,
  CrReceiptMutation,
  ListLinkServiceSelectQuery,
  ListBasketItemServiceInput,
  ListBasketItemServiceQuery,
  GetLinkServiceInput,
  GetLinkServiceQuery,
} from "../gql/graphql";
import { useListLinkServiceSelect } from "../hooks/useListLinkServiceSelect";
import { useListBasketItemService } from "../hooks/useListBasketItemService";
import { useGetLinkService } from "../hooks/useGetLinkService";

export const Basket = () => {
  var useOnce = false;
  const navigate = useNavigate();
  var params = useParams();
  const onLinkCompleted = (data: ListLinkServiceSelectQuery) => {
    const {
      listLinkServiceSelect: { ok, err },
    } = data;
    if (ok) {
      if (data.listLinkServiceSelect.linkservice)
        setServiceId(data.listLinkServiceSelect.linkservice[0].id);
    }
  };
  const [listLinkServiceSelect, { data: services }] =
    useListLinkServiceSelect(onLinkCompleted);
  const [serviceId, setServiceId] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [minAmount, setMinAmount] = useState<number>(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState<Number>(0);
  const closeAlert = () => {
    setAlertOpen(false);
  };
  const closeConfirm = () => {
    setConfirmOpen(false);
  };
  const confirmFunction = () => {
    setConfirmOpen(false);
    let checkedListsIds: number[] = checkedLists.map(
      (chkList) => chkList.basketId
    );
    delBasketItem({
      variables: { input: { basketItemIds: checkedListsIds } },
    });
  };
  // 체크
  interface checkItems {
    basketId: number;
    deliveryDate?: string;
    isActive?: boolean;
    status?: GoodStatus;
    dateActive?: boolean;
    price?: number;
  }
  const [checkedLists, setCheckedLists] = useState<checkItems[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [cor, setCor] = useState<string[]>([]);
  const [ser, setSer] = useState<string[]>([]);
  // allChk
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);
    if (!allChecked && data?.listBasketItemService.basketItems) {
      setCheckedLists([]);
      for (const basketItem of data.listBasketItemService.basketItems) {
        setCheckedLists((checkedList) => [
          ...checkedList,
          {
            basketId: basketItem.id,
            deliveryDate: basketItem.goodOption?.deliveryDate,
            isActive: basketItem.goodOption?.isActive,
            status: basketItem.good?.status,
            dateActive: basketItem.goodOption?.dateActive,
            price: basketItem.goodOption?.price
              ? basketItem.goodOption.discountRate
                ? Number(basketItem.quantity) *
                  calculate(
                    basketItem.goodOption.price,
                    basketItem.goodOption.discountRate
                  )
                : Number(basketItem.quantity) *
                  calculate(basketItem.goodOption.price, 0)
              : basketItem.good.price
              ? basketItem.good.discountRate
                ? Number(basketItem.quantity) *
                  calculate(basketItem.good.price, basketItem.good.discountRate)
                : Number(basketItem.quantity) *
                  calculate(basketItem.good.price, 0)
              : 0,
          },
        ]);
      }
    }
  };

  // 개별 체크
  const setCheckedListHandler = (
    basketId: number,
    deliveryDate: string,
    isActive?: boolean,
    status?: GoodStatus,
    price?: number
  ) => {
    if (Boolean(checkedLists.find((items) => items.basketId === basketId))) {
      setCheckedLists(
        checkedLists.filter((items) => items.basketId !== basketId)
      );
    } else {
      setCheckedLists((originalList) => [
        ...originalList,
        {
          basketId,
          deliveryDate,
          isActive,
          status,

          price,
        },
      ]);
    }
  };

  // 개별 체크 확인
  const isIncluded = (basketId: number) => {
    return Boolean(checkedLists.find((item) => item.basketId === basketId));
  };

  // Delete
  const onCompleted = async (data: DelBasketItemMutation) => {
    const {
      delBasketItem: { ok, err },
    } = data;
    if (ok) {
      setAlertText("삭제되었습니다.");
      setAlertOpen(true);
      await window.location.reload();
    } else {
      alert(err);
    }
  };
  const [delBasketItem] = useDelBaksetItem(onCompleted);
  const delOneBasketItemHandler = (basketId: number) => {
    var array: number[] = [basketId];
    delBasketItem({ variables: { input: { basketItemIds: array } } });
  };
  const delSeveralBasketItemHandler = () => {
    if (checkedLists.length > 0) {
      setConfirmOpen(true);
    } else {
      setAlertText("선택된 목록이 없습니다");
      setAlertOpen(true);
    }
  };

  // Update

  const onUpCompleted = async (data: UpBasketItemMutation) => {
    const {
      upBasketItem: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    }
  };
  const [upBasketItem] = useUpBaksetItem(onUpCompleted);
  const upBasketItemHandlerPlus = (
    BasketId: number,
    qtt: number,
    goodId?: number
  ) => {
    var limitNumOrders = data?.listBasketItemService.basketItems?.filter(
      (item) => item.good.id === goodId
    )[0].good.limitNumOrders;

    var sum = data?.listBasketItemService.basketItems
      ?.filter((item) => item.good.id === goodId)
      .reduce((partitial, option) => partitial + Number(option.quantity), 1);

    if (limitNumOrders && limitNumOrders < Number(sum)) {
      return alert(`이 상품의 최대 구매 수량은 ${limitNumOrders}개 입니다.`);
    }
    const input: UpBasketItemInput = {
      basketItemId: BasketId,
      quantity: qtt + 1,
    };
    upBasketItem({ variables: { input } });
  };
  const upBasketItemHandlerMinus = (BasketId: number, qtt: number) => {
    if (qtt === 1) {
      setAlertText("수량은 최소 1개 입니다.");
      return setAlertOpen(true);
    }
    const input: UpBasketItemInput = {
      ...{ basketItemId: BasketId },
      ...{ quantity: qtt - 1 },
    };
    upBasketItem({ variables: { input: input } });
  };

  const onCrReceipt = (data: CrReceiptMutation) => {
    const {
      crReceipt: { ok, receipt },
    } = data;
    if (ok) {
      navigate(`/selectReceiveWays/${receipt?.uuid}`);
    } else {
      alert("구매 진행에 실패했습니다");
    }
  };
  const [crReceipt, { loading: crReceiptLoading }] = useCrReceipt(onCrReceipt);
  const crReceiptHandler = () => {
    if (checkedLists.length < 1) {
      setAlertText("구매할 상품이 없습니다.");
      setAlertOpen(true);
    } else {
      if (Number(totalAmount) < Number(minAmount)) {
        setAlertText(`주문 최소 금액은 ${minAmount}원 입니다 `);
        setAlertOpen(true);
      } else {
        if (!crReceiptLoading) {
          if (
            checkedLists.find(
              (list) =>
                (list.deliveryDate && !list.isActive) ||
                list.status !== GoodStatus.GoodSales ||
                (list.deliveryDate && new Date(list.deliveryDate) < new Date())
            )
          ) {
            setAlertText("주문할 수 없는 상품이 있습니다.");
            return setAlertOpen(true);
          }

          let checkedListsIds: number[] = checkedLists.map(
            (chkList) => chkList.basketId
          );
          const input: CrReceiptInput = {
            basketItemIds: checkedListsIds,
          };
          crReceipt({ variables: { input } });
        }
      }
    }
  };

  const onListBasketItemService = async (data: ListBasketItemServiceQuery) => {
    const {
      listBasketItemService: { ok, err },
    } = data;
    if (ok) {
    }
  };
  const [
    listBasketItemService,
    { data, loading: listBasketItemServiceLoading, refetch },
  ] = useListBasketItemService(onListBasketItemService);
  const listBasketItemServiceHandler = (serviceId: number) => {
    const input: ListBasketItemServiceInput = {
      serviceId,
    };
    listBasketItemService({ variables: { input } });
  };
  const setServiceIdHandler = (id: number) => {
    setServiceId(id);
  };
  const onGetComplete = (data: GetLinkServiceQuery) => {
    const {
      getLinkService: { ok, linkService },
    } = data;
    if (ok) {
      console.log(linkService);
      if (data.getLinkService.linkService?.minAmount) {
        setMinAmount(data.getLinkService.linkService.minAmount);
      }
    }
  };
  const [getLinkService, { data: linkservice, loading }] =
    useGetLinkService(onGetComplete);
  const getLinkServiceHandler = (linkServiceId: number) => {
    const getLinkServiceInput: GetLinkServiceInput = {
      linkServiceId: linkServiceId ? +linkServiceId : 0,
    };
    getLinkService({ variables: { input: getLinkServiceInput } });
  };
  // useEffect
  useEffect(() => {
    if (useOnce === false) {
      listBasketItemServiceHandler(Number(params.serviceId));
      getLinkServiceHandler(Number(params.serviceId));
      useOnce = true;
      checkedLists.length = 0;
    }
  }, [params.serviceId]);
  useEffect(() => {
    pageIndicator("장바구니");
    listBasketItemServiceHandler(Number(params.serviceId));
    getLinkServiceHandler(Number(params.serviceId));
    listLinkServiceSelect();
  }, []);
  useEffect(() => {
    let sum = 0;
    for (const check of checkedLists) {
      if (check.price) sum += check.price;
    }
    setTotalAmount(sum);
  }, [checkedLists]);
  return (
    <main
      className={`max-w-screen-break mx-auto ${
        checkedLists.length > 0 ? "pb-28" : "pb-14"
      } h-full overflow-auto removeScroll bg-lightFont`}
    >
      <div className="bg-white">
        <TopBar arrow={true} heading={true} shop={true} home={true} />
      </div>
      <div className="wrapper">
        <div className="topBox bg-white flex flex-wrap content-center justify-between h-10 border-t-[0.5px]  w-full">
          {services?.listLinkServiceSelect.linkservice &&
            services.listLinkServiceSelect.linkservice
              .filter(
                (stopService) =>
                  stopService.name !== "도공촌 · 산내들" &&
                  stopService.name !== "김천휴게소"
              )
              .map((service) => (
                <button
                  key={`service-${service.id}`}
                  className={`flex-1 h-full border-b-[1.5px] border-t-0 ${
                    Number(params.serviceId) === service.id &&
                    " border-mainIcon"
                  }`}
                  onClick={() => {
                    navigate(`/baskets/${service.id}`);
                    // window.location.href = `/baskets/${service.id}`;
                  }}
                >
                  {service.name}
                </button>
              ))}
        </div>
        <div className="topBox bg-white flex flex-wrap content-center justify-between h-10 border-t-[0.5px] border-b-[0.5px] w-full px-4">
          <label className=" cursor-pointer">
            <div
              className={`mx-[9px] w-[14px] h-[14px] rounded border align-middle inline-flex flex-wrap content-center justify-center px-[1px] ${
                allChecked
                  ? "bg-mainIcon border-mainIcon"
                  : "bg-white border-btnDarkLine"
              }`}
            >
              <CheckMark color="#fff" />
            </div>
            <input
              type="checkbox"
              id="allcheck"
              className="hidden"
              onChange={setAllCheckedHandler}
            />
            <span className="align-middle text-sm leading-4">전체 선택</span>
          </label>

          <button
            onClick={delSeveralBasketItemHandler}
            className="text-darkFont text-small break:text-standardall px-[6.5px] py-[3px] border rounded-lg"
          >
            선택삭제
          </button>
        </div>

        <div className="bodyBox px-4 ">
          {data?.listBasketItemService.basketItems?.length === 0 && (
            <p className="py-10 text-darkFont text-standard break:text-large text-center font-bold">
              현재 장바구니는 비어있어요..
            </p>
          )}

          {/* mapping component */}
          <div className="sectionWrapper pt-[10px] space-y-4">
            {
              data?.listBasketItemService.basketItems &&
                data.listBasketItemService.basketItems.length > 0 && (
                  <section id="niliveryCard" className="w=full">
                    <div className="niliveryHead h-[40px] rounded-t-2xl bg-shadow2 flex flex-wrap content-center pr-4"></div>
                    <div className="niliveryBody bg-white rounded-b-2xl border border-btnLightLine pb-4">
                      {data.listBasketItemService.basketItems &&
                        data.listBasketItemService.basketItems.map(
                          (obj, index, array) => (
                            <div
                              key={obj.good.title && obj.good.title + obj.id}
                              className={` ${
                                index + 1 === array.length ? "pt-4" : "py-4"
                              } ${index > 0 && "border-t border-btnLightLine"}`}
                            >
                              <div className="basketCard">
                                <div className="cardTop flex w-full">
                                  <label
                                    htmlFor={`chk${obj.id}`}
                                    className="chkBox  cursor-pointer px-[9px] py-1"
                                  >
                                    <div
                                      className={`w-[14px] h-[14px] rounded border inline-flex flex-wrap content-center justify-center ${
                                        isIncluded(obj.id)
                                          ? "bg-mainIcon border-mainIcon"
                                          : "bg-white border-btnDarkLine"
                                      }`}
                                    >
                                      <CheckMark color="#fff" />
                                    </div>
                                    <input
                                      type="text"
                                      id={`chk${obj.id}`}
                                      className="hidden"
                                      onClick={() => {
                                        if (obj.goodOption?.price) {
                                          if (obj.goodOption.discountRate) {
                                            setCheckedListHandler(
                                              obj.id,
                                              obj.goodOption?.deliveryDate,
                                              obj.goodOption?.isActive,
                                              obj.good.status,

                                              Number(obj.quantity) *
                                                calculate(
                                                  obj.goodOption.price,
                                                  obj.goodOption.discountRate
                                                )
                                            );
                                          } else {
                                            setCheckedListHandler(
                                              obj.id,
                                              obj.goodOption?.deliveryDate,
                                              obj.goodOption?.isActive,
                                              obj.good.status,

                                              Number(obj.quantity) *
                                                calculate(
                                                  obj.goodOption.price,
                                                  0
                                                )
                                            );
                                          }
                                        } else if (obj.good.price) {
                                          if (obj.good.discountRate) {
                                            setCheckedListHandler(
                                              obj.id,
                                              obj.goodOption?.deliveryDate,
                                              obj.goodOption?.isActive,
                                              obj.good.status,

                                              Number(obj.quantity) *
                                                calculate(
                                                  obj.good.price,
                                                  obj.good.discountRate
                                                )
                                            );
                                          } else {
                                            setCheckedListHandler(
                                              obj.id,
                                              obj.goodOption?.deliveryDate,
                                              obj.goodOption?.isActive,
                                              obj.good.status,

                                              Number(obj.quantity) *
                                                calculate(obj.good.price, 0)
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  </label>
                                  <div className="flex-1 flex">
                                    <img
                                      src={
                                        obj.good?.goodPosters
                                          ? String(
                                              obj.good?.goodPosters[0].upload
                                                ?.url
                                            )
                                          : imageSample
                                      }
                                      alt="이미지"
                                      className={`w-[72px] h-[72px] rounded-lg border border-btnLightLine ${
                                        (!obj.goodOption?.isActive &&
                                          "opacity-50") ||
                                        (obj.good.status !==
                                          GoodStatus.GoodSales &&
                                          "opacity-50")
                                      }`}
                                    />
                                    <div className="pl-2 pr-2 flex-1 flex flex-col justify-between">
                                      <div className="w-full flex">
                                        <p
                                          className={`text-darkFont text-small leading-4 break:text-standardall h-[32px] my-[7px] ellipsis2 flex-1 overflow-hidden ${
                                            (!obj.goodOption?.isActive &&
                                              "opacity-50") ||
                                            (obj.good.status !==
                                              GoodStatus.GoodSales &&
                                              "opacity-50")
                                          }`}
                                        >
                                          {obj.good?.title}
                                        </p>
                                        {obj.goodOption?.dateActive &&
                                          obj.goodOption.deliveryDate && (
                                            <h2 className="font-bold self-center text-center text-sm leading-4 px-1 flex-1">
                                              <p>
                                                {moment(
                                                  obj.goodOption?.deliveryDate
                                                ).format("M월 DD일")}
                                              </p>
                                              <p>
                                                <span className=" text-xs font-normal ">
                                                  {moment(
                                                    obj.goodOption?.deliveryDate
                                                  ).format("a") === "pm"
                                                    ? "오후"
                                                    : "오전"}
                                                  {moment(
                                                    obj.goodOption?.deliveryDate
                                                  ).format("h시")}{" "}
                                                  주문마감
                                                </span>
                                              </p>
                                            </h2>
                                          )}

                                        {obj.goodOption?.dateActive !==
                                          true && (
                                          <h2 className="text-darkFont text-center text-small leading-4 break:text-standardall h-[32px] my-[7px] ellipsis2 flex-1 overflow-hidden">
                                            <p>{obj.goodOption?.name}</p>
                                          </h2>
                                        )}
                                      </div>
                                      {obj.good.status ===
                                      GoodStatus.GoodSales ? (
                                        obj.goodOption?.isActive ? (
                                          <div className="flex h-[26px] bg-bg rounded-full pr-2 ">
                                            <div className="flex flex-wrap content-center text-[10px] break:text-standardall">
                                              수량
                                            </div>
                                            <div className="flex flex-wrap content-center">
                                              <button
                                                onClick={() =>
                                                  upBasketItemHandlerMinus(
                                                    obj.id,
                                                    Number(obj.quantity)
                                                  )
                                                }
                                                className="w-8 px-2 h-full"
                                              >
                                                <img
                                                  src={minus}
                                                  alt="수량 하나 빼기"
                                                  className="w-4"
                                                />
                                              </button>
                                              <div className="h-full flex flex-wrap content-center justify-center">
                                                <input
                                                  type="number"
                                                  value={Number(obj.quantity)}
                                                  disabled
                                                  className="text-center font-bold  h-[18px] w-12 bg-white rounded-full border border-btnLightLine text-small break:text-standardall "
                                                />
                                              </div>
                                              <button
                                                onClick={() => {
                                                  upBasketItemHandlerPlus(
                                                    obj.id,
                                                    Number(obj.quantity),
                                                    obj.good.id
                                                  );
                                                }}
                                                className="w-8 px-2 h-full"
                                              >
                                                <img
                                                  src={plus}
                                                  alt="수량 하나 더하기"
                                                  className="w-4"
                                                />
                                              </button>
                                            </div>
                                            <div className="flex-1 flex flex-wrap content-center justify-end font-bold text-small break:text-standardall">
                                              {obj.goodOption.price
                                                ? obj.goodOption.discountRate
                                                  ? comma(
                                                      Number(obj.quantity) *
                                                        calculate(
                                                          obj.goodOption.price,
                                                          obj.goodOption
                                                            .discountRate
                                                        )
                                                    )
                                                  : comma(
                                                      Number(obj.quantity) *
                                                        calculate(
                                                          obj.goodOption.price,
                                                          0
                                                        )
                                                    )
                                                : obj.good.discountRate
                                                ? comma(
                                                    Number(obj.quantity) *
                                                      calculate(
                                                        Number(obj.good.price),
                                                        obj.good.discountRate
                                                      )
                                                  )
                                                : comma(
                                                    Number(obj.quantity) *
                                                      Number(obj.good?.price)
                                                  )}
                                            </div>
                                          </div>
                                        ) : (
                                          <div className=" w-full h-[26px] bg-bg rounded-full pr-2 text-[10px] leading-tight flex flex-wrap content-center justify-center ">
                                            옵션/재고가 변경되어 주문이
                                            불가합니다.
                                          </div>
                                        )
                                      ) : (
                                        <div className=" w-full h-[26px] bg-bg rounded-full pr-2 text-[10px] leading-tight flex flex-wrap content-center justify-center ">
                                          옵션/재고가 변경되어 주문이
                                          불가합니다.
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </section>
                )
              // ))
            }

            {/* 임시 삭제 부분 */}
            {/* nilivery */}
            {/* {listBasketItems?.listBasketItem.basketItems &&
              listBasketItems?.listBasketItem.basketItems.filter(
                (list) => list.goodOption?.dateActive === false
              ).length > 0 && (
                <section id="niliveryCard" className="w-full">
                  <div className="niliveryHead h-[40px] rounded-t-2xl bg-block2 flex flex-wrap content-center">
                    <label className="cursor-pointer px-[9px] flex flex-warp content-center justify-center">
                      <div
                        className={`w-[14px] h-[14px] rounded border  inline-flex flex-wrap content-center justify-center p-[1px] ${
                          isIncludedAll()
                            ? "bg-mainIcon border-mainIcon"
                            : "bg-white border-btnDarkLine"
                        }`}
                      >
                        <CheckMark color="#fff" />
                      </div>
                      <input
                        type="checkbox"
                        id="allcheck"
                        className="hidden"
                        onChange={() => setCheckDateHandler("")}
                      />
                    </label>
                    <h2 className="font-bold text-sm leading-4 px-1 flex-1">
                      닐리버리
                    </h2>
                  </div>
                  <div className="niliveryBody bg-white rounded-b-2xl border border-btnLightLine">
                    {listBasketItems?.listBasketItem.basketItems
                      .filter((list) => list.goodOption?.dateActive === false)
                      .map((obj, index) => (
                        <div
                          key={obj.good.title && obj.good.title + obj.id}
                          className={`py-4 ${
                            index > 0 && "border-t border-btnLightLine"
                          }`}
                        >
                          <div className={`basketCard`}>
                            <div className="cardTop flex w-full relative">
                              <label
                                htmlFor={`chk${obj.id}`}
                                className="chkBox p-1 cursor-pointer px-[9px]"
                              >
                                <div
                                  className={`w-[14px] h-[14px]  rounded border inline-flex flex-wrap content-center justify-center p-[1px] ${
                                    isIncluded(obj.id)
                                      ? "bg-mainIcon border-mainIcon"
                                      : "bg-white border-btnDarkLine"
                                  }`}
                                >
                                  <CheckMark color="#fff" />
                                </div>
                                <input
                                  type="checkbox"
                                  id={`chk${obj.id}`}
                                  className="hidden"
                                  onClick={() => {
                                    setCheckedListHandler(
                                      obj.id,
                                      obj.goodOption?.deliveryDate,
                                      obj.goodOption?.isActive,
                                      obj.good.status
                                    );
                                  }}
                                />
                              </label>
                              <div className="flex-1 flex">
                                <img
                                  src={
                                    obj.good.goodPosters &&
                                    obj.good?.goodPosters?.length > 0
                                      ? String(
                                          obj.good?.goodPosters[0].upload?.url
                                        )
                                      : imageSample
                                  }
                                  alt="이미지"
                                  className={`w-[72px] h-[72px] rounded-lg border border-btnLightLine ${
                                    obj.good.status !== GoodStatus.GoodSales &&
                                    "opacity-50"
                                  }`}
                                />
                                <div className="pl-2 pr-2 pt-1 flex-1 flex flex-col justify-between">
                                  <div className="w-full flex">
                                    <p
                                      className={`text-darkFont text-small leading-4 break:text-standardall h-[32px] my-[7px] ellipsis2 flex-1 overflow-hidden 
                                    ${
                                      obj.good.status !==
                                        GoodStatus.GoodSales && "opacity-50"
                                    }
                                    `}
                                    >
                                      {obj.good?.title}
                                    </p>
                                    {obj.good.status !==
                                      GoodStatus.GoodSales && (
                                      <div>
                                        <button
                                          onClick={() => {
                                            delOneBasketItemHandler(obj.id);
                                          }}
                                          className="w-10 h-[26px] border rounded-lg mt-[7px]"
                                        >
                                          <span className="text-xs leading-4 ">
                                            삭제
                                          </span>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                  {obj.good.status === GoodStatus.GoodSales ? (
                                    <div className="flex h-[26px] bg-bg rounded-full pr-2 ">
                                      <div className="flex flex-wrap content-center text-[10px] break:text-standardall">
                                        수량
                                      </div>
                                      <div className="flex flex-wrap content-center">
                                        <button
                                          onClick={() =>
                                            upBasketItemHandlerMinus(
                                              obj.id,
                                              Number(obj.quantity)
                                            )
                                          }
                                          className="w-8 px-2 h-full"
                                        >
                                          <img
                                            src={minus}
                                            alt="수량 하나 빼기"
                                            className="w-4"
                                          />
                                        </button>
                                        <div className="h-full flex flex-wrap content-center justify-center">
                                          <input
                                            type="number"
                                            value={Number(obj.quantity)}
                                            disabled
                                            className="text-center h-[18px] w-12 bg-white rounded-full border border-btnLightLine text-small break:text-standardall "
                                          />
                                        </div>
                                        <button
                                          onClick={() => {
                                            upBasketItemHandlerPlus(
                                              obj.id,
                                              Number(obj.quantity),
                                              obj.good.id
                                            );
                                          }}
                                          className="w-8 px-2 h-full"
                                        >
                                          <img
                                            src={plus}
                                            alt="수량 하나 더하기"
                                            className="w-4"
                                          />
                                        </button>
                                      </div>
                                      <div className="flex-1 flex flex-wrap content-center justify-end font-bold text-small break:text-standardall">
                                        {obj.good?.discountRate
                                          ? comma(
                                              Number(obj.quantity) *
                                                calculate(
                                                  obj.good.price
                                                    ? obj.good.price
                                                    : 1,
                                                  obj.good.discountRate
                                                )
                                            )
                                          : comma(
                                              Number(obj.quantity) *
                                                Number(obj.good?.price)
                                            )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className=" w-full h-[26px] bg-bg rounded-full pr-2 text-[10px] leading-tight flex flex-wrap content-center justify-center ">
                                      옵션/재고가 변경되어 주문이 불가합니다.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </section>
              )} */}
          </div>

          {/*  */}
        </div>
      </div>
      <div className="absolute left-0 bottom-0 w-full">
        {checkedLists.length > 0 && (
          <div className="break:sticky absolute w-full bottom-16 left-1/2 transform -translate-x-1/2 break:transform break:translate-x-0 px-4 ">
            <div className=" bg-shadow2 h-[40px] rounded-2xl px-4 py-2">
              <div className="flex flex-wrap justify-between ">
                <p>총 금액: </p>
                <p>{Number(totalAmount)}</p>
              </div>
            </div>
          </div>
        )}
        <div className="break:sticky absolute w-full bottom-0 left-1/2 transform -translate-x-1/2 break:transform break:translate-x-0 px-4 py-2 h-[56px]">
          <button
            className="bg-mainIcon block w-full h-full text-white text-xl font-bold rounded-[20px]"
            onClick={crReceiptHandler}
          >
            구매하기
          </button>
        </div>
      </div>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        className="relative"
      >
        <AlertComponent
          title={`알림`}
          desc={alertText}
          closeFunction={closeAlert}
        ></AlertComponent>
      </Dialog>
      <Dialog
        open={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
        }}
        className="relative"
      >
        <ConfirmComponent
          title={`안내`}
          desc={`정말로 삭제하시겠습니까?`}
          closeFunction={closeConfirm}
          confirmFunction={confirmFunction}
        ></ConfirmComponent>
      </Dialog>
    </main>
  );
};
