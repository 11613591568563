import { gql, useMutation } from "@apollo/client";
import { GetAddressMutation, GetAddressMutationVariables } from "../gql/graphql";

const GET_ADDRESS_MUTATION = gql`
  mutation getAddress($input: GetAddressInput!) {
    getAddress(input: $input) {
      ok
      err
      address {
        id
        username
        name
        phone
        zoneCode
        region
        mainAddress
        subAddress
        message
        pass
        isRep
      }
    }
  }
`;

export const useGetAddress = (onCompleted?: (data: GetAddressMutation) => void) => {
  return useMutation<GetAddressMutation, GetAddressMutationVariables>(GET_ADDRESS_MUTATION, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
