import { ApolloError, gql, useMutation } from "@apollo/client";
import { CrInquiryMutation } from "../gql/graphql";

const CR_INQUIRY_MUTATION = gql`
  mutation crInquiry($input: CrInquiryInput!) {
    crInquiry(input: $input) {
      ok
      err
    }
  }
`;

export const useCrInquiry = (onCompleted?: (data: CrInquiryMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<CrInquiryMutation>(CR_INQUIRY_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
