import { listClasses } from "@mui/material";
import imageCompression from "browser-image-compression";
import { useState } from "react";
import Resizer from "react-image-file-resizer";
export const Test = () => {
  const [newfile, setNewFile] = useState<any>("");

  async function handleImageUpload(event: any) {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: 1,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      imageCompression
        .getDataUrlFromFile(compressedFile)
        .then((data) => setNewFile(data));
    } catch (error) {
      console.log(error);
      alert("파일 업로드에 실패하였습니다.");
    }
  }

  return (
    <div className="w-full h-full overflow-auto">
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={async (event: any) => {
          handleImageUpload(event);
        }}
      />
      <img src={newfile} alt="dw" className="w-full" />
    </div>
  );
};
