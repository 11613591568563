import { gql, useLazyQuery } from "@apollo/client";
import { ListRelatedGoodsQuery, ListRelatedGoodsQueryVariables } from "../gql/graphql";

const LIST_RELATED_GOOD_QUERY = gql`
  query listRelatedGoods($input: ListRelatedGoodInput!) {
    listRelatedGoods(input: $input) {
      ok
      err
      relatedGoods {
        id
        title
        goodPosters {
          upload {
            url
          }
        }
      }
    }
  }
`;

export const useListRelatedGoods = (onCompleted?: (data: ListRelatedGoodsQuery) => void) => {
  return useLazyQuery<ListRelatedGoodsQuery, ListRelatedGoodsQueryVariables>(LIST_RELATED_GOOD_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
