import { gql, useLazyQuery } from "@apollo/client";
import { ListBasketItemQuery } from "../gql/graphql";

const LIST_BASKET_ITEM_QUERY = gql`
  query listBasketItem {
    listBasketItem {
      ok
      err
      basketItems {
        id
        quantity
        user {
          id
        }
        good {
          id
          title
          price
          limitNumOrders
          discountRate
          status
          goodPosters {
            upload {
              url
            }
          }
          corporations {
            name
            regionKeyWord
            service
            linkService {
              id
              name
            }
          }
        }
        goodOption {
          name
          id
          deliveryDate
          isActive
          dateActive
          price
          discountRate
        }
      }
    }
  }
`;

export const useListBaksetItem = (
  onCompleted?: (data: ListBasketItemQuery) => void
) => {
  return useLazyQuery<ListBasketItemQuery>(LIST_BASKET_ITEM_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
