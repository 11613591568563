import { ApolloError, gql, useMutation } from "@apollo/client";
import { CrGoodReviewPosterMutation, CrGoodReviewPosterMutationVariables } from "../gql/graphql";

const CR_GOOD_REVIEW_POSTER_MUTATION = gql`
  mutation crGoodReviewPoster($input: CrGoodReviewPosterInput!) {
    crGoodReviewPoster(input: $input) {
      ok
      err
      goodReviewPoster {
        id
        upload {
          uuid
          url
        }
      }
    }
  }
`;

export const useCrGoodReviewPoster = (onCompleted?: (data: CrGoodReviewPosterMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<CrGoodReviewPosterMutation, CrGoodReviewPosterMutationVariables>(CR_GOOD_REVIEW_POSTER_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
