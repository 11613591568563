import { ApolloError, gql, useMutation } from "@apollo/client";
import { CrUserInput, CrUserMutation, CrUserMutationVariables } from "../gql/graphql";

export interface ICrUserFormProps extends CrUserInput {
  confirmPassword: string;
}

const CR_USER_MUTATION = gql`
  mutation crUser($input: CrUserInput!) {
    crUser(input: $input) {
      ok
      id
      err
    }
  }
`;

export const useCrUser = (onCompleted?: (data: CrUserMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<CrUserMutation, CrUserMutationVariables>(CR_USER_MUTATION, {
    onCompleted,
    onError,
  });
};
