import { gql, useLazyQuery } from "@apollo/client";
import { ListInquiryCompleteQuery } from "../gql/graphql";

const LIST_INQUIRY_COMPLETE_QUERY = gql`
  query listInquiryComplete($input: ListInquiryCompleteInput!) {
    listInquiryComplete(input: $input) {
      ok
      err
      totalPages
      totalResults
      inquiries {
        id
        title
        note
        status
        type
        createdAt
        author {
          id
          name
          username
        }
        comments {
          note
        }
        inquiryPosters {
          id
          upload {
            id
            url
          }
        }
      }
    }
  }
`;

export const useListInquiryComplete = (onCompleted?: (data: ListInquiryCompleteQuery) => void) => {
  return useLazyQuery<ListInquiryCompleteQuery>(LIST_INQUIRY_COMPLETE_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
