import { ApolloError, gql, useMutation } from "@apollo/client";
import { DelUserMutation, DelUserMutationVariables } from "../gql/graphql";

const DEL_USER_MUTATION = gql`
  mutation delUser($input: DelUserInput!) {
    delUser(input: $input) {
      ok
      err
    }
  }
`;

export const useDelUser = (onCompleted?: (data: DelUserMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<DelUserMutation, DelUserMutationVariables>(DEL_USER_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
