import main_logo from "../assets/icons/404.png";
import { useNavigate } from "react-router-dom";
import { TopBar } from "../components/topBar";
import { FootMenu } from "../components/FootMenu";
export const NotFounded = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full bg-pointIcon  flex flex-col flex-wrap">
      <div>
        <TopBar arrow={true} home={true} exit={true} backColor={`#FFE400`} />
      </div>

      <div className="px-6 mx-auto max-w-[400px] flex-1 flex flex-wrap content-center">
        <h2 className="pb-10 text-mainIcon w-full text-center">
          {/* <p className=" font-bold text-7xl pb-4">404</p> */}
          <p className="font-bold text-[20px] yeonR text-404 leading-5 px-1 py-2">
            페이지를 찾을 수 없습니다.
          </p>
        </h2>
        <img
          src={main_logo}
          alt="닐리버리 로고"
          className="pb-2 max-w-[280px] w-full mx-auto"
        />
      </div>
      <div className="w-full flex flex-wrap absolute z-10 bottom-[80px] justify-center">
        <button
          onClick={() => navigate(-1)}
          className="w-[200px] text-center py-2  text-[#fff]  bg-mainIcon font-bold text-btn break:text-standardall rounded-[20px] "
        >
          뒤로
        </button>
      </div>
      <div className="w-full">
        <FootMenu />
      </div>
    </div>
  );
};
