import { ApolloError, gql, useMutation } from "@apollo/client";
import { PayReceiptMutation, PayReceiptMutationVariables } from "../gql/graphql";

const PAY_RECEIPT_MUTATION = gql`
  mutation payReceipt($input: PayReceiptInput!) {
    payReceipt(input: $input) {
      ok
      err
      receipt {
        id
        uuid
      }
    }
  }
`;

export const usePayReceipt = (onCompleted?: (data: PayReceiptMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<PayReceiptMutation, PayReceiptMutationVariables>(PAY_RECEIPT_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
