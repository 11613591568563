import { useEffect, useLayoutEffect } from "react";
import { pageIndicator } from "../apollo";
import { Footer } from "../components/footer";
import { TopBar } from "../components/topBar";
import { useRules } from "../constants/texts";

export const UseInfo = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    pageIndicator("이용약관");
  }, []);
  return (
    <div className="max-w-screen-break mx-auto h-full overflow-auto">
      <div className="sticky left-0 top-0 bg-white shadow">
        <TopBar
          arrow={true}
          arrowColor={"#B5C1D0"}
          exit={true}
          home={true}
          heading={true}
        />
      </div>
      <div className="px-4 pt-4 pb-10 bg-main">
        <div className="rounded-xl p-4 bg-white border-btnLightLine">
          <p className="text-darkFont font-bold text-sm">닐리버리 이용약관</p>
          <div className="pl-4 pt-4 list-disc text-sm whitespace-pre-wrap">
            {useRules}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
