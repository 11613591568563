import { useEffect } from "react";
import { pageIndicator } from "../apollo";
import { TopBar } from "../components/topBar";
import TriangleArrow from "../assets/icons/TriangleArrow.svg";
// 사용
import { SelectOne } from "../components/selectOne";
import { FootMenu } from "../components/FootMenu";
import { Footer } from "../components/footer";
export const MustEatItem = () => {
  useEffect(() => {
    pageIndicator("머스트잍");
  }, []);
  return (
    <div className="overflow-auto h-full removeScroll pb-9">
      <TopBar shop={true} />
      <main className="content pb-4">
        <SelectOne select={2} />
        <div className=" max-w-screen-fit mx-auto w-full h-[150px] px-4 mt-2 relative">
          <div className="absolute left-1/2 bottom-1 transform -translate-x-1/2 w-24 h-24 rounded-full bg-[#A9B1FF]"></div>
          <div className="absolute right-[9%] top-[4.5%] w-12 h-12 rounded-full bg-[#A9B1FF]"></div>
          <div className=" w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 gilbert text-center text-mh4">
            <p>SNS 핫한 맛집! </p>
            <p>내 집앞으로 바로 배달?!</p>
          </div>
          <span className=" absolute left-1/2 bottom-6  transform -translate-x-1/2">
            <img src={TriangleArrow} alt="아래 화살 표시" />
          </span>
        </div>
        <div className="goods transform -translate-y-4 space-y-6">
          {/* {SNSFOOD.length > 0 &&
            SNSFOOD.map((obj: any, index) => (
              <GoodsNiliverySNS
                key={obj.brand + obj.title + index}
                id={obj.id}
                title={obj.title}
                subTitle={obj.subTitle}
                image={obj.image}
                brand={obj.brand}
                ordered={obj.ordered}
                desc={obj.desc}
                deliveryDate={obj.deliveryDate}
                endDate={obj.endDate}
                classes={obj.classes}
              />
            ))}
          {SNSFOOD.length === 0 && (
            <div className="max-w-screen-fit mx-auto px-4 relative pt-4">
              <p className="text-center text-large break:text-Xlarge py-[120px] font-bold">
                현재 진행 중인 상품이 없습니다.
              </p>
            </div>
          )} */}
        </div>
      </main>
      <Footer />
      <div className="absolute left-0 bottom-0 w-full">
        <FootMenu />
      </div>
    </div>
  );
};
