import { ApolloError, gql, useMutation } from "@apollo/client";
import { CrSnsUserMutation, CrSnsUserMutationVariables } from "../gql/graphql";

const CR_SNS_USER_MUTATION = gql`
  mutation crSnsUser($input: CrSnsUserInput!) {
    crSnsUser(input: $input) {
      ok
      err
      token
      id
    }
  }
`;

export const useCrSnsUser = (onCompleted?: (data: CrSnsUserMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<CrSnsUserMutation, CrSnsUserMutationVariables>(CR_SNS_USER_MUTATION, {
    onCompleted,
    onError,
  });
};
