import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import closeSearchIcon from "../assets/icons/exit.svg";
import niliveryLogo from "../assets/icons/niliveryLogo.svg";
import searchIcon from "../assets/icons/searchIcon.svg";
import addressArrow from "../assets/icons/arrow.svg";
import {
  isLoggedInVar,
  pageIndicator,
  searchText,
  test,
  serviceId,
} from "../apollo";
import { ArrowLeft, CartIcon, CloseIcon, HomeIcon, SearchIcon } from "./Icons";
import { useNavigate } from "react-router-dom";
import { useMe } from "../hooks/useME";
import { useListBaksetItem } from "../hooks/useListBaksetMine";
import { useForm } from "react-hook-form";
import join_exit from "../assets/images/join_exit.png";
import { Dialog } from "@mui/material";
import { AlertComponent } from "./alert";
import { useListAddress } from "../hooks/useListAddress";
import { ListAddressQuery, ListBasketItemQuery } from "../gql/graphql";

interface TopBarProps {
  arrow?: boolean;
  arrowColor?: string;
  close?: boolean;
  closeColor?: string;
  heading?: boolean;
  headingColor?: string;
  search?: boolean;
  home?: boolean;
  homeColor?: string;
  homeRight?: boolean;
  main?: boolean;
  searchColor?: string;
  shop?: boolean;
  shopColor?: string;
  exit?: boolean;
  backColor?: string;
  address?: boolean;
  emart?: boolean;
  arrowFunction?: () => void;
}

interface ISearchFormProps {
  search: string;
}

export const TopBar: React.FC<TopBarProps> = ({
  arrow,
  arrowColor,
  arrowFunction,
  close,
  closeColor,
  heading,
  headingColor,
  home,
  homeColor,
  homeRight,
  main,
  search,
  searchColor,
  address,
  shop,
  shopColor,
  exit,
  backColor,
  emart,
}) => {
  const { data: me } = useMe();
  const service = useReactiveVar(serviceId);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [myAddress, setMyAddress] = useState<string>();

  const addCompleted = (data: ListAddressQuery) => {
    const {
      listAddress: { ok, err, address },
    } = data;
    if (ok && address) {
      const repAddress = address.find((list) => list.isRep);
      if (repAddress) {
        const shortAddress = repAddress.mainAddress
          ?.split(" ")
          .filter((list, index) => index > 0 && index < 4)
          .join(" ");
        setMyAddress(shortAddress);
      }
    }
  };
  const [listAddress, { data: addressData }] = useListAddress(addCompleted);
  const goBack = () => {
    navigate(-1);
  };
  const [alertOpen, setAlertOpen] = useState(false);
  const closeAlert = () => {
    setAlertOpen(false);
    navigate("/login");
  };
  const searchTexts = useReactiveVar(searchText);
  const { getValues, register, handleSubmit, setValue } =
    useForm<ISearchFormProps>();
  const onCompleted = async (data: ListBasketItemQuery) => {
    const {
      listBasketItem: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    }
  };

  const [listBasketItem, { data: listBasketItemData, loading, refetch }] =
    useListBaksetItem(onCompleted);
  const usePageIndicator = useReactiveVar(pageIndicator);
  const navigate = useNavigate();
  const onSearch = () => {
    const { search } = getValues();
    searchText(search);
    if (search) {
      navigate(`/listGood/:${search}`);
      searchText(search);
    } else {
      setAlertText("검색어를 입력해주세요");
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    listBasketItem();
    if (searchTexts) {
      setValue("search", searchTexts);
    }
    if (address) {
      listAddress();
    }
  }, []);
  return (
    <div
      className={`max-w-screen-break break:max-w-screen-fit mx-auto w-full h-[48px] flex flex-wrap content-center relative z-[2] ${
        backColor ? `bg-[${backColor}]` : "bg-mainIcon"
      }`}
    >
      <div className="px-4 py-1 w-full h-full flex flex-wrap justify-between gap-x-[14px]">
        <div className="flex-1 flex flex-wrap gap-x-[14px]">
          {arrow && (
            <div className="w-8 h-8 my-1 flex flex-wrap content-center">
              <button
                onClick={arrowFunction ? arrowFunction : goBack}
                className="w-full h-8 "
              >
                {arrowColor ? <ArrowLeft color={arrowColor} /> : <ArrowLeft />}
              </button>
            </div>
          )}
          {close && (
            <div className="w-8 h-10 flex flex-wrap content-center ">
              <button onClick={() => navigate(-1)} className="w-full h-8 ">
                {closeColor ? <CloseIcon color={closeColor} /> : <CloseIcon />}
              </button>
            </div>
          )}
          {home && (
            <div
              className={`h-10 flex flex-wrap  content-center ${
                homeRight ? "flex-1 justify-end" : ""
              } `}
            >
              <button
                onClick={() => {
                  if (usePageIndicator === "emart24") {
                    window.location.href = "/emart";
                  } else if (usePageIndicator === "닐리버리") {
                    window.location.href = "/";
                  } else {
                    window.location.href = "/";
                  }
                }}
                // onClick={() =>  (window.location.href = "/")}
                className="w-8 h-8 "
              >
                {homeColor ? <HomeIcon color={homeColor} /> : <HomeIcon />}
              </button>
            </div>
          )}
          {heading && (
            <div className="px-1 py-2.5">
              <h2
                onClick={() => {
                  if (usePageIndicator === "emart24") {
                    window.location.href = "/emart";
                  } else if (usePageIndicator === "닐리버리") {
                    window.location.href = "/";
                  }
                }}
                className={` ${
                  main
                    ? "cafe24 text-[24px] leading-6 cursor-pointer "
                    : "text-standard font-bold"
                }  ${headingColor ? headingColor : `text-darkFont`}`}
              >
                <img src={niliveryLogo} alt="닐리버리 로고" className="" />
                {/* {service === 1 ? "이마트24" : usePageIndicator} */}
              </h2>
            </div>
          )}
          {search && (
            <div className="h-10 flex-1 mr-[9px] relative flex flex-wrap content-center">
              {/* <button
                onClick={() => {
                  setValue("search", "");
                }}
                className="w-6 h-6 absolute right-[7px] top-[8px] flex flex-wrap content-center justify-center"
              >
                <img src={closeSearchIcon} alt="검색창 닫기" />
              </button> */}
              <form onSubmit={handleSubmit(onSearch)} className="w-full flex ">
                <input
                  type="text"
                  {...register("search", { required: true })}
                  className="w-full h-8 rounded-full bg-[#ECECEC] border border-mainIcon pl-4 pr-10 text-small break:text-standard placeholder:text-grayFont"
                  placeholder="검색어를 입력해주세요"
                />
                <button type="submit" className="absolute right-3 top-3.5">
                  <img src={searchIcon} alt="검색 버튼" />
                </button>
              </form>
            </div>
          )}
          {address && (
            <div
              onClick={() => {
                isLoggedIn
                  ? myAddress
                    ? navigate("/address/")
                    : navigate("/address/enroll")
                  : setAlertOpen(true);
              }}
              className="h-10 flex-1 mr-[9px] relative flex flex-wrap content-center"
            >
              <button className="w-7 h-7 absolute right-[7px] top-[8px] flex flex-wrap content-center justify-center">
                <img src={addressArrow} alt="검색창 닫기" />
              </button>
              <p className="overflow-hidden w-full h-8 leading-7 ellipsis1 rounded-full border border-mainIcon pl-4 pr-10 text-small placeholder:text-grayFont">
                {myAddress ? myAddress : "주소를 입력해주세요"}
              </p>
            </div>
          )}
        </div>
        <div>
          {shop && (
            <div className="w-8 h-10 flex flex-wrap content-center ">
              <button
                onClick={() => {
                  if (me) {
                    navigate("/baskets/2");
                  } else {
                    setAlertOpen(true);
                    // alert("로그인/회원가입 후 이용해주세요.");
                    // navigate("/login");
                  }
                }}
                className="w-full h-8 relative"
              >
                <CartIcon color={shopColor} />
                {listBasketItemData?.listBasketItem?.basketItems &&
                  listBasketItemData?.listBasketItem?.basketItems.length >
                    0 && (
                    <span
                      className={`absolute flex flex-wrap content-center justify-center left-1/2 top-0 w-[17px] h-[16px] ${
                        shopColor ? `bg-${shopColor}` : `bg-white`
                      } text-center ${
                        shopColor ? `text-white` : `text-mainIcon`
                      } text-Xsmall rounded-full`}
                    >
                      {listBasketItemData?.listBasketItem?.basketItems.length
                        .toString()
                        .padStart(2, "0")}
                    </span>
                  )}
              </button>
            </div>
          )}
          {exit && (
            <div className="w-8 h-8 my-1 flex flex-wrap content-center">
              <button
                onClick={arrowFunction ? arrowFunction : goBack}
                className="w-full h-8 "
              >
                <img
                  src={join_exit}
                  alt="exit 이미지"
                  className="w-full h-full"
                />
              </button>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        className="relative"
      >
        <AlertComponent
          title={`안내`}
          desc={`로그인/회원가입 후 이용해주세요.`}
          closeFunction={closeAlert}
        ></AlertComponent>
      </Dialog>
    </div>
  );
};
