import { useEffect, useState } from "react";

import {
  isLoggedInVar,
  isMobileVar,
  pageIndicator,
  serviceId,
} from "../apollo";
import { TopBar } from "../components/topBar";
import main_h_back from "../assets/images/main_h_back.svg";
import saleImage from "../assets/icons/saleImage.svg";
import mainPageImage from "../assets/images/mainPageImage.png";
import eventIcon from "../assets/icons/eventIcon.svg";
import popularIcon from "../assets/icons/popularIcon.svg";
import {
  GoodsNiliveryEvent,
  GoodsNiliveryIn,
} from "../components/goodsNilivery";
import { FootMenu } from "../components/FootMenu";
import { Footer } from "../components/footer";
import { getToken } from "../token";

import { useNavigate } from "react-router-dom";
import { Banner } from "../components/slideBanner";
import { useListGood } from "../hooks/useListGood";
import { useReactiveVar } from "@apollo/client";
import { useGetBannerOptionActive } from "../hooks/useGetBannerOptionActive";
import { useListAddress } from "../hooks/useListAddress";
import { Fade } from "@mui/material";
import { ArrowRight } from "../components/Icons";
import { useListLinkServiceSelect } from "../hooks/useListLinkServiceSelect";
import { useListCorporationSelect } from "../hooks/useListCorporationSelect";
import {
  GoodCategoryFirst,
  GoodStatus,
  ListAddressQuery,
  ListCorporationSelectQuery,
  ListGoodInput,
  ListGoodQuery,
  Tags,
} from "../gql/graphql";
import { get_pushid } from "../assets/push";
import { useMe } from "../hooks/useME";

export const Home = () => {
  const { data: me } = useMe();
  var useOnce = false;
  const { data } = useGetBannerOptionActive();
  const isMobile = useReactiveVar(isMobileVar);
  const [region, setRegion] = useState<string[]>([]);
  // const [open, setOpen] = React.useState(false);
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const [listLinkServiceSelect, { data: services }] =
    useListLinkServiceSelect();

  const onGetRegionKeyWord = (data: ListCorporationSelectQuery) => {
    const {
      listCorporationSelect: { ok, err, corporations },
    } = data;
    if (ok && corporations) {
      let regionList: string[] = [];
      corporations.forEach((item) => {
        item.regionKeyWord?.forEach((test) => {
          if (test && !regionList.includes(test)) {
            regionList.push(test);
          }
        });
      });
      setRegion(regionList);
    }
  };
  const [listCorporationSelect, { data: corporations }] =
    useListCorporationSelect(onGetRegionKeyWord);

  const onCompleted = (data: ListGoodQuery) => {
    const {
      listGood: { ok, err, goods },
    } = data;

    if (ok && goods) {
    } else {
      console.log(err);
    }
  };
  const [listGood, { data: listGoodData, loading, refetch }] =
    useListGood(onCompleted);
  const [
    listGood1,
    { data: listGoodData1, loading: SoldoutLoading, refetch: SoldoutRefetch },
  ] = useListGood();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string | null>(null);
  const [category, setCategory] = useState<GoodCategoryFirst | null>(null);
  const [serviceName, setService] = useState<string | null>(null);
  const [test, setTest] = useState<string[]>([]);
  const [tag, setTag] = useState<Tags | null>(null);
  const [myAddress, setMyAddress] = useState<boolean>();
  const navigate = useNavigate();
  const scorllHandler = () => {
    var element = document.getElementById("categoryWrapper");

    var left = element?.scrollLeft;
    if (element?.offsetWidth) {
      if (element.offsetWidth + element.scrollLeft >= element.scrollWidth) {
        element.scrollLeft = 0;
      } else {
        element?.scrollTo({ left: Number(left) + 200, behavior: "smooth" });
      }
    }
    // if (element?.offsetWidth + element?.scrollLeft >= element?.scrollWidth) {
    // }
  };
  const serviceHander = (name: string) => {
    setService(name);
  };
  const tagHandler = (varTag: Tags) => {
    setCategory(null);
    setTag(varTag);
    // if (tag === varTag) {
    //   setTag(null);
    // } else {
    //   setTag(varTag);
    // }
  };
  const totalHandler = () => {
    setCategory(null);
    setTag(null);
  };
  const categoryHandler = (varCategory: GoodCategoryFirst) => {
    setTag(null);
    if (category === varCategory) {
      setCategory(null);
    } else {
      setCategory(varCategory);
    }
  };
  const addCompleted = (data: ListAddressQuery) => {
    const {
      listAddress: { ok, err, address },
    } = data;
    if (ok && address) {
      const repAddress = address.find((list) => list.isRep);
      if (repAddress) {
        setMyAddress(true);
      } else {
        setMyAddress(false);
      }
    }
  };
  const [listAddress, { data: addressData }] = useListAddress(addCompleted);
  const listGoodHandler = (
    page: number | null,
    search: string | null,
    categoryFirst?: GoodCategoryFirst | null,
    serivce?: string | null,
    tag?: Tags | null
  ) => {
    const input: ListGoodInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      status: GoodStatus.GoodSales,
      ...(categoryFirst && { categoryFirst }),
      service: serviceName,
      ...(tag && { tag: tag }),
    };
    listGood({ variables: { input } });
  };
  const listGoodHandler1 = (
    page: number | null,
    search: string | null,
    categoryFirst?: GoodCategoryFirst | null,
    service?: string | null,
    tag?: Tags | null
  ) => {
    const input: ListGoodInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      status: GoodStatus.GoodReady,
      ...(categoryFirst && { categoryFirst }),
      service: service,
      ...(tag && { tag: tag }),
    };
    listGood1({ variables: { input } });
  };
  // useEffect(() => {
  //   const test = () => {
  //     console.log("AA");
  //     localStorage.setItem("pushid", "1232");
  //     const result = JSON.parse(localStorage.getItem("pushid") || '""');
  //     alert(result);
  //   };

  //   const get_pushid = (pushid: string) => {
  //     console.log(pushid);
  //   };
  //   const handleTimeout = () => {
  //     window.webkit?.messageHandlers.cordova_iab.postMessage(
  //       JSON.stringify({ action: "getpushid", callback: "get_pushid" })
  //     );
  //     setTimeout(() => {
  //       if (
  //         window.webkit &&
  //         window.webkit.messageHandlers &&
  //         window.webkit.messageHandlers.cordova_iab
  //       ) {
  //         window.webkit?.messageHandlers.cordova_iab.postMessage(
  //           JSON.stringify({ action: "getpushid", callback: "get_pushid" })
  //         );
  //       } else {
  //         console.warn("webKit messageHandlers not available.");
  //         alert("webKit messageHandlers not available.");
  //       }
  //     }, 1500);
  //   };
  //   test();
  //   handleTimeout();
  // }, []);

  useEffect(() => {
    if (useOnce === false) {
      isLoggedInVar(Boolean(getToken()));
      pageIndicator("닐리버리");
      setTag(Tags.Chicken);
      setService("직지사");
      listGoodHandler(page, search, category, Tags.Chicken);
      listGoodHandler1(page, search, category, Tags.Chicken);
      // if (isMobile) setOpen(true);
      serviceId(2);
      listAddress();
      listLinkServiceSelect();
      listCorporationSelect();
    }
  }, []);
  useEffect(() => {
    useOnce = true;

    if (useOnce === true) {
      if (serviceName === "직지사") {
        setTag(Tags.Meal);
      } else if (serviceName === "산업단지") {
        setTag(Tags.Safety);
      } else if (serviceName === "김천혁신") {
        setTag(Tags.Meal);
      }
      listGoodHandler(page, search, category, serviceName, tag);
      listGoodHandler1(page, search, category, serviceName, tag);
    }
  }, [category, serviceName]);
  useEffect(() => {
    useOnce = true;

    if (useOnce === true) {
      listGoodHandler(page, search, category, serviceName, tag);
      listGoodHandler1(page, search, category, serviceName, tag);
    }
  }, [category, tag]);
  return (
    <div className="overflow-auto h-full removeScroll pb-9 abeezee" id="parent">
      <TopBar
        heading={true}
        main={true}
        search={true}
        headingColor={"text-mainIcon"}
        shop={true}
        emart={true}
      />
      {/* toast */}
      {/* <Modal
        open={open}
        container={() => document.getElementById("parent")}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="w-full aspect-square absolute flex-wrap bottom-0 rounded-t-3xl">
          {data?.getBannerOptionActive.bannerOption?.map((banner) => (
            <div className="w-full h-full flex flex-col">
              <div className="flex-1">
                <div className="w-full h-full relative">
                  <img
                    src={`${banner.upload?.url}`}
                    alt={`${banner.alt} 배너 이미지`}
                    className=" absolute top-0 left-0 w-full h-full"
                  />
                  <div className="absolute left-0 bottom-2 ">
                    <p className=" font-bold text-[20px] pt-3 pl-6 text-shadow pb-3">
                      {banner.title}
                    </p>

                    <p className="pl-6 pr-32 w-full text-[#fff] h-12 items-center flex">
                      {banner.note}
                    </p>
                    <div className="flex flex-wrap py-3 pl-6 gap-x-2 ">
                      {banner.isFree && (
                        <button
                          className={`bannerIcon bg-[#00B0EB] text-white`}
                        >
                          무료배송
                        </button>
                      )}

                      {banner.isRes && (
                        <button
                          className={`bannerIcon bg-[#FFE400] text-[#363A3D] `}
                        >
                          예약배송
                        </button>
                      )}

                      {banner.isEvent && (
                        <button
                          className={`bannerIcon bg-[#fff] text-mainIcon `}
                        >
                          이벤트
                        </button>
                      )}

                      {banner.isOnePlusOne && (
                        <button
                          className={`bannerIcon bg-[#00B0EB] text-white `}
                        >
                          1+1
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <img
                  src={exit}
                  alt="닫기"
                  className={`w-12 absolute right-0 top-0 px-2 py-1 text-white`}
                  onClick={() => setOpen(false)}
                ></img>
              </div>
              <div className="w-full px-4 py-2 bg-[#fff] flex flex-wrap justify-between">
                <p className="flex flex-wrap gap-x-4 py-[2px]  ">
                  <span
                    onClick={() => {
                      setHomeEventToken("homePopup");
                      setOpen(false);
                    }}
                    className="px-1 py-[2px] text-btn text-grayFont "
                  >
                    오늘 그만보기
                  </span>
                  <span className="w-[1px] my-[6px] bg-hintFont"></span>
                  <span
                    onClick={() => setOpen(false)}
                    className="px-1 py-[2px] text-btn text-grayFont "
                  >
                    닫기
                  </span>
                </p>
                <button
                  onClick={() => {
                    banner.link && navigate(banner.link);
                  }}
                  className="py-1 text-center w-[93px]  px-2 rounded-[20px] bg-mainIcon text-[#fff] text-btn"
                >
                  자세히보기
                </button>
              </div>
            </div>
          ))}
        </div>
      </Modal> */}
      <main className="content pb-4 relative">
        <div className="h-10 relative ">
          <div
            id="regionWrapper"
            className="border-t border-btnDarkLine flex flex-wrap whitespace-nowrap overflow-auto h-full"
          >
            {/* {region.map((keyword) => (
              <button
                key={`keyword-${keyword}`}
                onClick={() => {
                  if (keyword) navigate(`/${keyword}`);
                }}
                className={`flex-1 h-full inline-block border-b-2 text-sm ${
                  keyword === "율곡동"
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }
                
                 
                }`}
              >
                {keyword}
              </button>
            ))} */}
            {services?.listLinkServiceSelect.linkservice &&
              services.listLinkServiceSelect.linkservice
                .filter(
                  (stopService) =>
                    stopService.name !== "도공촌 · 산내들" &&
                    stopService.name !== "김천휴게소"
                  // stopService.name === "김천휴게소"
                )
                .map((service) => (
                  <button
                    key={`service-${service.id}`}
                    onClick={() => {
                      // if (service.name && service.name !== "김천혁신")
                      // serviceId(service.id);
                      serviceHander(service.name);
                      // navigate(`/${service.name}`);
                    }}
                    className={`flex-1 h-full inline-block border-b-2 text-sm ${
                      service.name === serviceName
                        ? "text-mainIcon border-mainIcon font-bold"
                        : "text-grayFont border-btnDarkLine"
                    }
                
                 
                }`}
                  >
                    {service.name}
                  </button>
                ))}
          </div>
        </div>
        <div className="max-w-scrrent-fit w-full aspect-square">
          <Banner serviceName={serviceName} />
        </div>
        {/* 카테고리 */}
        <div className="h-10 relative">
          {/* <div
            id="categoryWrapper"
            className="whitespace-nowrap overflow-auto h-full removeScroll pr-6 "
          > */}
          {serviceName == "김천혁신" && (
            <div
              id="categoryWrapper"
              className="whitespace-nowrap overflow-auto h-full removeScroll pr-6  "
            >
              <button
                onClick={() => {
                  tagHandler(Tags.Meal);
                }}
                className={`min-w-[80px] w-1/3 flex-1 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.Meal
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                식사
              </button>
              <button
                onClick={() => {
                  tagHandler(Tags.Cafe);
                }}
                className={`min-w-[80px] w-1/3 flex-1 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.Cafe
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                음료
              </button>
              <button
                onClick={() => {
                  tagHandler(Tags.Dessert);
                }}
                className={` min-w-[80px] w-1/3 flex-1 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.Dessert
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                디저트
              </button>
              <button
                onClick={() => {
                  tagHandler(Tags.EventTag);
                }}
                className={` min-w-[80px] w-1/3 flex-1 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.EventTag
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                이벤트
              </button>
            </div>
          )}
          {serviceName == "직지사" && (
            <div
              id="categoryWrapper"
              className="whitespace-nowrap overflow-auto h-full removeScroll pr-6 "
            >
              <button
                onClick={() => {
                  tagHandler(Tags.Meal);
                }}
                className={`min-w-[80px] w-1/3 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.Meal
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                식사
              </button>
              <button
                onClick={() => {
                  tagHandler(Tags.Cafe);
                }}
                className={`min-w-[80px] w-1/3 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.Cafe
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                음료
              </button>
              <button
                onClick={() => {
                  tagHandler(Tags.Dessert);
                }}
                className={`min-w-[80px] w-1/3 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.Dessert
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                디저트
              </button>
              <button
                onClick={() => {
                  tagHandler(Tags.EventTag);
                }}
                className={`min-w-[80px] w-1/3 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.EventTag
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                이벤트
              </button>
              <button
                onClick={() => {
                  tagHandler(Tags.OutdoorGear);
                }}
                className={`min-w-[80px] w-1/3 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.OutdoorGear
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                야외놀이 & 필수템
              </button>

              <button
                onClick={() => {
                  tagHandler(Tags.Subsidiary);
                }}
                className={`min-w-[80px] w-1/3 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.Subsidiary
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                부자재
              </button>
            </div>
          )}
          {serviceName == "산업단지" && (
            <div
              id="categoryWrapper"
              className="whitespace-nowrap overflow-auto h-full removeScroll pr-6 "
            >
              <button
                onClick={() => {
                  tagHandler(Tags.Safety);
                }}
                className={`min-w-[80px] w-1/2 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.Safety
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                안전용품
              </button>
              <button
                onClick={() => {
                  tagHandler(Tags.EventTag);
                }}
                className={`min-w-[80px] w-1/2 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.EventTag
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                이벤트
              </button>
              {/* <button
                onClick={() => {
                  tagHandler(Tags.Chicken);
                }}
                className={`min-w-[80px] w-1/2 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.Chicken
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                치킨
              </button>
              <button
                onClick={() => {
                  tagHandler(Tags.Cafe);
                }}
                className={`min-w-[80px] w-1/2 h-full inline-block border-b-2 text-sm ${
                  tag === Tags.Cafe
                    ? "text-mainIcon border-mainIcon font-bold"
                    : "text-grayFont border-btnDarkLine"
                }`}
              >
                음료
              </button> */}
            </div>
          )}
          {/* <button
              onClick={() => {
                totalHandler();
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === null && category === null
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              전체
            </button>
            <button
              onClick={() => {
                tagHandler(Tags.Suggestion);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === Tags.Suggestion
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              추천상품
            </button>

            <button
              onClick={() => {
                tagHandler(Tags.GroupPurchase);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === Tags.GroupPurchase
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              공동구매
            </button>

            <button
              onClick={() => {
                categoryHandler(GoodCategoryFirst.Toys);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                category === GoodCategoryFirst.Toys
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              장난감/완구
            </button> */}
          {/* </div> */}
          <button
            onClick={scorllHandler}
            className="absolute right-0 top-0 h-full bg-white"
          >
            <ArrowRight color={`#00B0EB`}></ArrowRight>
          </button>
        </div>
        {/* 상품리스트 */}
        <section id="niliveryItems" className="relative py-5">
          {/* <div className="niliveryHeaderBack absolute left-0 top-0 w-full h-[296px] bg-white"></div>
          <div className="relative px-[10px] yeonL py-5">
            <div className="relative">
              <img
                src={main_h_back}
                alt="헤더 배경: 구름 두 조각"
                className="mx-auto"
              />
              <h2 className="absolute left-0 bottom-0 w-full text-center text-white font-bold text-2xl">
                닐리버리
              </h2>
            </div>
            <h3 className="mt-4 py-2 px-1 text-center text-white text-sm">
              <span className="font-bold">리얼퀵배송</span> 드론으로 경험하는{" "}
              <br />
              스마트한 배송의 시작
            </h3>
          </div> */}
          <div className="goods transform">
            {listGoodData?.listGood.goods &&
              listGoodData?.listGood.goods.filter((suggest) =>
                suggest.tags?.includes(Tags.TodayMeal)
              ).length > 0 && (
                <div className="Poularity">
                  <div className="flex flex-col pl-[23px]">
                    <div className="flex gap-x-1 items-center">
                      <img
                        src={popularIcon}
                        alt="세일이미지"
                        className="w-[17px] h-[14px]"
                      />
                      <div className="text-[18px]  font-extrabold">
                        오늘의 식사
                      </div>
                    </div>
                    <div className="text-[14px] text-[#7A7A7A]">
                      닐리버리 회원을 위한 특별한 식사
                    </div>
                  </div>

                  <div className="overflow-x-auto flex gap-x-2.5 removeScroll px-[22px] pb-[34px] pt-[7px]">
                    {listGoodData?.listGood.goods &&
                      listGoodData?.listGood.goods
                        .filter((suggest) =>
                          suggest.tags?.includes(Tags.TodayMeal)
                        )
                        .map((obj, index) => {
                          return (
                            <GoodsNiliveryIn
                              key={`good-option-${index}`}
                              id={obj.id}
                              title={obj.title}
                              subTitle={obj.summary}
                              image={obj.goodPosters}
                              brand={obj}
                              desc={obj.summary}
                              status={obj.status}
                              classes={obj.title}
                              price={obj.price ? obj.price : 1}
                              discountRate={obj.discountRate}
                            />
                          );
                        })}
                  </div>
                </div>
              )}
            {listGoodData?.listGood.goods &&
              listGoodData?.listGood.goods.filter((suggest) =>
                suggest.tags?.includes(Tags.Suggestion)
              ).length > 0 && (
                <div className="Suggestion">
                  <div className="flex flex-col pl-[23px]">
                    <div className="flex gap-x-1 items-center">
                      <img
                        src={saleImage}
                        alt="세일이미지"
                        className="w-[17px] h-[14px]"
                      />
                      <div className="text-[18px]  font-extrabold">
                        닐리세일 Best 추천
                      </div>
                    </div>
                    <div className="text-[14px] text-[#7A7A7A]">
                      닐리버리 회원을 위한 특별한 추가 할인
                    </div>
                  </div>
                  <div className="overflow-x-auto flex gap-x-2.5 removeScroll px-[22px] pb-[34px] pt-[7px]">
                    {listGoodData?.listGood.goods &&
                      listGoodData?.listGood.goods
                        .filter((suggest) =>
                          suggest.tags?.includes(Tags.Suggestion)
                        )
                        .map((obj, index) => {
                          return (
                            <GoodsNiliveryIn
                              key={`good-option-${index}`}
                              id={obj.id}
                              title={obj.title}
                              subTitle={obj.summary}
                              image={obj.goodPosters}
                              brand={obj}
                              desc={obj.summary}
                              status={obj.status}
                              classes={obj.title}
                              price={obj.price ? obj.price : 1}
                              discountRate={obj.discountRate}
                            />
                          );
                        })}
                  </div>
                </div>
              )}

            <div className="w-full mb-5 ">
              <img src={mainPageImage} className="" />
            </div>
            {listGoodData?.listGood.goods &&
              listGoodData?.listGood.goods.filter((suggest) =>
                suggest.tags?.includes(Tags.EventProduct)
              ).length > 0 && (
                <div className="Poularity">
                  <div className="flex flex-col pl-[23px] pt-8">
                    <div className="flex gap-x-1 items-center">
                      <img
                        src={popularIcon}
                        alt="세일이미지"
                        className="w-[17px] h-[14px]"
                      />
                      <div className="text-[18px]  font-extrabold">
                        회원 특가 상품
                      </div>
                    </div>
                    <div className="text-[14px] text-[#7A7A7A]">
                      닐리버리 회원을 위한 특별한 추가 할인
                    </div>
                  </div>
                  <div className="overflow-x-auto flex gap-x-2.5 removeScroll px-[22px] pb-[34px] pt-[7px]">
                    {listGoodData?.listGood.goods &&
                      listGoodData?.listGood.goods
                        .filter((suggest) =>
                          suggest.tags?.includes(Tags.EventProduct)
                        )
                        .map((obj, index) => {
                          return (
                            <GoodsNiliveryIn
                              key={`good-option-${index}`}
                              id={obj.id}
                              title={obj.title}
                              subTitle={obj.summary}
                              image={obj.goodPosters}
                              brand={obj}
                              desc={obj.summary}
                              status={obj.status}
                              classes={obj.title}
                              price={obj.price ? obj.price : 1}
                              discountRate={obj.discountRate}
                            />
                          );
                        })}
                  </div>
                </div>
              )}

            <div className="BestGood ">
              <div className="flex flex-col pl-[23px]">
                <div className="flex gap-x-1 items-center">
                  <img
                    src={eventIcon}
                    alt="세일이미지"
                    className="w-[17px] h-[14px]"
                  />
                  <div className="text-[18px]  font-extrabold">전체 상품</div>
                </div>
                {/* <div className="text-[14px] text-[#7A7A7A]">
                  닐리버리 회원을 위한 특별한 추가 할인
                </div> */}
              </div>
              <div className="goods transform gap-y-10  break:grid-cols-1 grid sm:grid-cols-2 sm:gap-x-4">
                {listGoodData?.listGood.goods &&
                  listGoodData?.listGood.goods.map((obj, index) => {
                    return (
                      <GoodsNiliveryEvent
                        key={`good-option-${index}`}
                        id={obj.id}
                        title={obj.title}
                        subTitle={obj.summary}
                        image={obj.goodPosters}
                        brand={obj}
                        desc={obj.summary}
                        review={obj.goodReviews?.length}
                        status={obj.status}
                        classes={obj.title}
                        price={obj.price ? obj.price : 1}
                        discountRate={obj.discountRate}
                      />
                    );
                  })}
              </div>
            </div>
            {/* {listGoodData?.listGood.goods &&
              listGoodData?.listGood.goods.filter((suggest) =>
                suggest.tags?.includes(Tags.EventProduct)
              ).length > 0 && (
                <div className="BestGood ">
                  <div className="flex flex-col pl-[23px]">
                    <div className="flex gap-x-1 items-center">
                      <img
                        src={eventIcon}
                        alt="세일이미지"
                        className="w-[17px] h-[14px]"
                      />
                      <div className="text-[18px]  font-extrabold">
                        닐리세일 추천 특가
                      </div>
                    </div>
                    <div className="text-[14px] text-[#7A7A7A]">
                      닐리버리 회원을 위한 특별한 추가 할인
                    </div>
                  </div>
                  <div className="goods transform gap-y-10  break:grid-cols-1 grid sm:grid-cols-2 sm:gap-x-4">
                    {listGoodData?.listGood.goods &&
                      listGoodData?.listGood.goods
                        .filter((event) =>
                          event.tags?.includes(Tags.EventProduct)
                        )
                        .map((obj, index) => {
                          return (
                            <GoodsNiliveryEvent
                              key={`good-option-${index}`}
                              id={obj.id}
                              title={obj.title}
                              subTitle={obj.summary}
                              image={obj.goodPosters}
                              brand={obj}
                              desc={obj.summary}
                              review={obj.goodReviews?.length}
                              status={obj.status}
                              classes={obj.title}
                              price={obj.price ? obj.price : 1}
                              discountRate={obj.discountRate}
                            />
                          );
                        })}
                  </div>
                </div>
              )} */}

            {/* {listGoodData1?.listGood.goods &&
              listGoodData1?.listGood.goods.map((obj, index) => {
                let arr = [];
                return (
                  <GoodsNiliveryIn
                    key={`good-option-${index}`}
                    id={obj.id}
                    title={obj.title}
                    subTitle={obj.summary}
                    image={obj.goodPosters}
                    brand={obj}
                    desc={obj.summary}
                    status={obj.status}
                    classes={obj.title}
                    price={obj.price ? obj.price : 1}
                    discountRate={obj.discountRate}
                  />
                );
              })} */}
            {tag === Tags.Subsidiary && (
              <div className="goods transform gap-y-10  break:grid-cols-1 grid sm:grid-cols-2 sm:gap-x-4">
                {listGoodData?.listGood.goods &&
                  listGoodData?.listGood.goods.map((obj, index) => {
                    return (
                      <GoodsNiliveryEvent
                        key={`good-option-${index}`}
                        id={obj.id}
                        title={obj.title}
                        subTitle={obj.summary}
                        image={obj.goodPosters}
                        brand={obj}
                        desc={obj.summary}
                        review={obj.goodReviews?.length}
                        status={obj.status}
                        classes={obj.title}
                        price={obj.price ? obj.price : 1}
                        discountRate={obj.discountRate}
                      />
                    );
                  })}
              </div>
            )}

            {listGoodData1?.listGood.goods?.length === 0 &&
              listGoodData?.listGood.goods?.length === 0 && (
                <div className="max-w-screen-fit mx-auto px-4 relative pt-4 ">
                  <p className="text-center text-large break:text-Xlarge py-10 font-bold">
                    현재 판매하는 상품이 없습니다.
                  </p>
                </div>
              )}
          </div>
        </section>
      </main>
      <Footer />
      {(!isLoggedIn && (
        <Fade in={true}>
          <div
            onClick={() => {
              if (!isLoggedIn) {
                navigate("/login");
              }
              if (addressData?.listAddress.address?.length === 0) {
                navigate("/address/enroll");
              }
            }}
            className="absolute left-0 bottom-20 botom-20 text-center w-full"
          >
            <button className="w-[200px] h-8 inner-border inner-border-mainIcon text-mainIcon bg-white rounded-full text-sm">
              받으실 주소를 입력해주세요
            </button>
          </div>
        </Fade>
      )) ||
        (!myAddress && (
          <Fade in={true}>
            <div
              onClick={() => {
                if (!isLoggedIn) {
                  navigate("/login");
                }
                if (addressData?.listAddress.address?.length === 0) {
                  navigate("/address/enroll");
                } else {
                  navigate("address");
                }
              }}
              className="absolute left-0 bottom-20 botom-20 text-center w-full"
            >
              <button className="w-[200px] h-8 inner-border inner-border-mainIcon text-mainIcon bg-white rounded-full text-sm">
                받으실 주소를 입력해주세요
              </button>
            </div>
          </Fade>
        ))}
      <FootMenu />
    </div>
  );
};
