import moment, { min } from "moment";
import { Dialog } from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { calculate, comma, serviceId } from "../apollo";
import { TopBar } from "../components/topBar";
import cart from "../assets/icons/cart_white.svg";
import pay_plus from "../assets/icons/pay-plus.svg";
import pay_minus from "../assets/icons/pay-minus.svg";
import { useGetGood } from "../hooks/useGetGood";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import drop_down from "../assets/icons/drop_down.svg";
import { useCrBaksetItem } from "../hooks/useCrBasketItem";
import { useMe } from "../hooks/useME";
import { useListBaksetItem } from "../hooks/useListBaksetMine";
import { useCrReceipt } from "../hooks/useCrReceipt";
import { AttrInfoSet, saveJson } from "../constants/props";
import toast from "../assets/icons/toast_bar.svg";
import "swiper/css";
import { Thumbnails } from "../components/detail-Thumbnails";
import share from "../assets/icons/share.svg";
import React from "react";
import { useListGoodReview } from "../hooks/useListGoodReview";
import star_yellow from "../assets/icons/star_yellow.svg";
import star_gray from "../assets/icons/star_gray.svg";
import { useListRelatedGoods } from "../hooks/useListRelatedGood";
import { useListGoodOption } from "../hooks/useListGoodOption";
import { AlertComponent } from "../components/alert";
import {
  CrBasketItemInput,
  CrBasketItemMutation,
  CrReceiptInput,
  CrReceiptMutation,
  DetailCrBasketInput,
  DetailCrReceiptInput,
  GetGoodInput,
  GetGoodQuery,
  GetLinkServiceInput,
  GetLinkServiceQuery,
  GoodDetailType,
  GoodStatus,
  ListGoodOptionQuery,
  ListGoodReviewInput,
  ListGoodReviewQuery,
  ListRelatedGoodInput,
  ListRelatedGoodsQuery,
} from "../gql/graphql";
import { IGoodPosterItem } from "../constants/types";
import { useReactiveVar } from "@apollo/client";
import { useGetLinkService } from "../hooks/useGetLinkService";
export interface State {
  open: boolean;
}

export const GoodDetail = () => {
  const params = useParams();
  const [minAmount, setMinAmount] = useState<number>(0);
  const [tab, setTab] = useState(0);
  const [OpenPhoto, setOpenPhoto] = React.useState(false);
  const [photo, setPhoto] = React.useState("");
  const { data: me } = useMe();
  const [attr, setAttr] = useState<saveJson>();
  const [scrollOver, setScrollOver] = useState<boolean>(false);
  const [basketIds, setBaksetIds] = useState<number[]>([Number(params.key)]);
  const [page, setPage] = useState<number>(1);
  const [attrInfo, setAttrInfo] = useState<AttrInfoSet[]>();
  const [mapLimit, setMapLimit] = useState<number>(5);
  const [selectName, setSelectName] = useState<string>("");
  const [boxOpen, setBoxOpen] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const closeAlert = () => {
    setAlertOpen(false);
    if (alertText === "로그인이 필요합니다. <br/> 로그인 하시겠습니까?") {
      navigate("/login");
    } else if (alertText === "잘못된 경로입니다.") {
      navigate(-1);
    } else {
      setAlertOpen(true);
      window.location.reload();
    }
  };

  // snack bar
  const [state, setState] = useState<State>({
    open: false,
  });
  const { open } = state;

  const handleClick = () => {
    setState({ open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const snacks = (
    <React.Fragment>
      <Button onClick={handleClose}>닫기</Button>
    </React.Fragment>
  );
  const onCompleted = (data: GetGoodQuery) => {
    const {
      getGood: { ok, good, err },
    } = data;
    console.log(ok);
    if (ok && good) {
      const listRelatedGoodInput: ListRelatedGoodInput = {
        goodId: Number(good?.id),
      };
      listGoodReviewHandler(Number(good?.id));
      listRelatedGoods({ variables: { input: listRelatedGoodInput } });
      const AttrObject = JSON.parse(String(good?.attributeSet));
      const AttrInfoObject = JSON.parse(String(good?.goodAttrInfo));
      if (AttrInfoObject && AttrInfoObject.length) {
        setAttrInfo(AttrInfoObject);
      }
      setAttr(AttrObject);
    } else {
      setAlertText("잘못된 경로 입니다.");
      setAlertOpen(true);
    }
  };
  const navigate = useNavigate();
  const [getGood, { data }] = useGetGood(onCompleted);
  const [listBasketItem, { refetch }] = useListBaksetItem();
  const endDate = new Date();
  const today = new Date();
  const deliveryDate = new Date(today.setDate(today.getDate() + 1));
  const [float, setFloat] = useState<boolean>(false);

  const onCrReceipt = (data: CrReceiptMutation) => {
    const {
      crReceipt: { ok, receipt, err },
    } = data;
    if (ok && receipt) {
      navigate(`/selectReceiveWays/${receipt.uuid}`);
    } else {
      if (err) {
        setAlertText(err);
      } else {
        setAlertText("구매 진행에 실패했습니다");
      }

      setAlertOpen(true);
    }
  };
  const [crReceipt, { loading: crReceiptLoading }] = useCrReceipt(onCrReceipt);
  // const crReceiptHandler1 = () => {
  //   const goodId = data?.getGood.good?.id;
  //   var buyInfos: DetailCrReceiptInput[] = [];
  //   var info: DetailCrReceiptInput = {
  //     goodId,
  //     quantity: 1,
  //   };
  //   buyInfos.push(info);
  //   const input: CrReceiptInput = {
  //     buyInfos,
  //   };
  //   crReceipt({ variables: { input } });
  // };
  const crReceiptHandler = () => {
    if (optionsSelected.length === 0) {
      alert("상품을 선택해주세요");
      return;
    }
    if (totalAmount < minAmount) {
      setAlertText(`주문 최소 금액은 ${minAmount}원 입니다`);
      setAlertOpen(true);
      return;
    }
    if (!crReceiptLoading) {
      const goodId = data?.getGood.good?.id;
      var buyInfos: DetailCrReceiptInput[] = [];
      for (var option of optionsSelected) {
        var info: DetailCrReceiptInput = {
          goodId,
          goodOptionId: option.goodOptionId ? option.goodOptionId : null,
          quantity: option.quantity,
        };
        buyInfos.push(info);
      }
      const input: CrReceiptInput = {
        buyInfos,
      };
      crReceipt({ variables: { input } });
    }
  };
  // list review
  const onListReview = (data: ListGoodReviewQuery) => {
    const {
      listGoodReview: { ok, err, goodReviews },
    } = data;
    if (ok && goodReviews) {
    }
  };
  const [
    ilstGoodReview,
    { data: listGoodReviewData, loading: listReviewLoading },
  ] = useListGoodReview(onListReview);
  const listGoodReviewHandler = (goodId: number) => {
    const input: ListGoodReviewInput = {
      goodId: goodId,
      page: page,
      row: 6,
    };
    ilstGoodReview({ variables: { input } });
  };
  // list relation
  const goodRelationCompleted = (data: ListRelatedGoodsQuery) => {
    const {
      listRelatedGoods: { ok, err },
    } = data;
    if (ok) {
    }
  };
  const [listRelatedGoods, { data: relatedGoods, loading: relationLoading }] =
    useListRelatedGoods(goodRelationCompleted);

  const scrollHandler = (idname: string, tabNumber?: number) => {
    var scrollowner = document.querySelector(".scroll-smooth");
    var element = document.getElementById(idname);
    scrollowner?.scrollTo(0, Number(element?.offsetTop) - 100);
  };

  const IncludeHandler = (goodId: number) => {
    var remain = basketIds.findIndex((el) => el === goodId);
    var _arrays = [...basketIds];
    if (remain >= 0) {
      _arrays.splice(remain);
    } else {
      _arrays.push(goodId);
    }
    setBaksetIds(_arrays);
  };

  //  copy url
  const CopyUrlToClipboard = () => {
    var dummy = document.createElement("input");
    var text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    handleClick();
  };

  useLayoutEffect(() => {
    if (!Number(params.key)) {
      setAlertText("잘못된 경로 입니다.");
      setAlertOpen(true);
    }
    const getGoodInput: GetGoodInput = {
      goodId: Number(params.key),
    };
    getGood({ variables: { input: getGoodInput } });
    var el = document.querySelector(".scroll-smooth");
    // if (el) el.scrollTo(0, 0);
    el?.addEventListener("scroll", () => {
      if (el?.scrollTop && el?.scrollTop > 100) {
        setScrollOver(true);
      } else {
        setScrollOver(false);
      }
      var review = document.getElementById("review");
      var detail = document.getElementById("detail");
      var refund = document.getElementById("refund");
      var AttributeInfo = document.getElementById("AttributeInfo");
      if (el?.scrollTop && el.scrollTop > Number(review?.offsetTop) - 300)
        setTab(1);
      if (el?.scrollTop && el.scrollTop > Number(detail?.offsetTop) - 300)
        setTab(2);
      if (el?.scrollTop && el.scrollTop > Number(refund?.offsetTop) - 300)
        setTab(3);
      if (
        el?.scrollTop &&
        el.scrollTop > Number(AttributeInfo?.offsetTop) - 300
      )
        setTab(4);
    });
  }, []);

  // 옵션 선택 구매
  interface optionSelected {
    name?: string;
    price?: number;
    discountRate?: number;
    goodOptionId?: number;
    quantity?: number;
  }
  const [slideUp, setSlideUp] = useState<boolean>(false);
  const [optionsSelected, setOptionsSelected] = useState<optionSelected[]>([]);
  const limitOrderNumHandler = () => {
    var sum = optionsSelected.reduce(
      (partitial, option) => partitial + Number(option.quantity),
      1
    );
    if (
      Number(data?.getGood.good?.limitNumOrders) > 0 &&
      sum > Number(data?.getGood.good?.limitNumOrders)
    ) {
      setAlertText(
        `최대 주문가능 수량은 ${Number(
          data?.getGood.good?.limitNumOrders
        )}개 입니다.`
      );
      setAlertOpen(true);

      return false;
    } else {
      return true;
    }
  };
  const optionsSelectedHandler = (
    value: any,
    name: string,
    price: number | null,
    discountRate: number | null
  ) => {
    var index;
    if (!Number(value)) {
      index = optionsSelected.findIndex((option) => {
        return !option.goodOptionId;
      });
    } else {
      index = optionsSelected.findIndex((option) => {
        return option.goodOptionId === Number(value);
      });
    }
    if (index < 0) {
      var newInput: optionSelected = {
        name: name,
        goodOptionId: Number(value),
        quantity: 1,
        price: price ? price : undefined,
        discountRate: discountRate ? discountRate : 0,
      };
      setOptionsSelected([...optionsSelected, newInput]);
    }
  };
  const optionsSelectedQuantityHandler = (
    goodOptionName: string,
    cal: boolean
  ) => {
    var newOptions: optionSelected[];
    if (cal) {
      if (!limitOrderNumHandler()) {
        return;
      }
      newOptions = optionsSelected.map((option) => {
        if (option.name === goodOptionName) {
          return { ...option, quantity: Number(option.quantity) + 1 };
        }
        return option;
      });
    } else {
      newOptions = optionsSelected.map((option) => {
        if (option.name === goodOptionName && Number(option.quantity) > 1) {
          return { ...option, quantity: Number(option.quantity) - 1 };
        }
        return option;
      });
    }
    setOptionsSelected(newOptions);
  };

  const optionsSelectedRemoveHandler = (goodOptionId: number) => {
    if (!goodOptionId) {
      setOptionsSelected(
        optionsSelected.filter((option) => option.name !== "기본 배송")
      );
      return;
    }
    setOptionsSelected(
      optionsSelected.filter((option) => option.goodOptionId !== goodOptionId)
    );
  };
  const totalPriceCalculater = () => {
    var total: number = 0;
    var unitPrice = Number(data?.getGood.good?.price);
    optionsSelected.map((object) => {
      total = total + Number(object.quantity) * unitPrice;
    });
    return total;
  };

  const onListGoodOption = (data: ListGoodOptionQuery) => {
    const {
      listGoodOption: { ok, err },
    } = data;
    if (ok) {
      // if (data.listGoodOption.goodOptions?.length !== 0) {
      //   setSlideUp(true);
      // } else {
      //   crReceiptHandler1();
      // }
    } else {
      alert(err);
    }
  };
  const [listGoodOption, { data: listGoodOptionData }] =
    useListGoodOption(onListGoodOption);
  const listOptionsHandler = (goodId: number) => {
    listGoodOption({ variables: { input: { goodId } } });
  };

  // 옵션 선택 장바구니
  const onMakeBasketItem = async (data: CrBasketItemMutation) => {
    const {
      crBasketItem: { ok, err },
    } = data;
    if (ok) {
      await refetch();
      setAlertText("장바구니에 추가 되었습니다.");
      setAlertOpen(true);
      // alert("장바구니에 추가 되었습니다.");
    } else {
      if (err) {
        setAlertText(err);
      } else {
        setAlertText("이미 장바구니에 추가 되어있습니다.");
      }

      setAlertOpen(true);
    }
  };
  const [crBasketItem] = useCrBaksetItem(onMakeBasketItem);
  const crBasketItemHandler = () => {
    if (optionsSelected.length === 0) {
      alert("상품을 선택해주세요.");
      return;
    }

    if (!crReceiptLoading) {
      const goodId = data?.getGood.good?.id;
      var basketInfos: DetailCrBasketInput[] = [];
      for (var option of optionsSelected) {
        var info: DetailCrBasketInput = {
          goodId,
          goodOptionId: option.goodOptionId ? option.goodOptionId : null,
          quantity: option.quantity,
        };
        basketInfos.push(info);
      }
      const input: CrBasketItemInput = {
        basketInfos,
      };
      crBasketItem({ variables: { input } });
    }
  };
  const service = useReactiveVar(serviceId);
  const onGetComplete = (data: GetLinkServiceQuery) => {
    const {
      getLinkService: { ok, linkService },
    } = data;
    if (ok) {
      if (data.getLinkService.linkService?.minAmount) {
        setMinAmount(data.getLinkService.linkService.minAmount);
      }
    }
  };
  const [getLinkService, { data: linkservice, loading }] =
    useGetLinkService(onGetComplete);
  const getLinkServiceHandler = (linkServiceId: number) => {
    const getLinkServiceInput: GetLinkServiceInput = {
      linkServiceId: linkServiceId ? +linkServiceId : 0,
    };
    getLinkService({ variables: { input: getLinkServiceInput } });
  };
  useEffect(() => {
    getLinkServiceHandler(service);
  }, []);

  useEffect(() => {
    let sum = 0;
    for (const selectOption of optionsSelected) {
      if (selectOption.price) {
        if (selectOption.discountRate) {
          sum +=
            calculate(selectOption.price, selectOption.discountRate) *
            Number(selectOption.quantity);
        } else {
          sum += selectOption.price * Number(selectOption.quantity);
        }
      } else {
        if (data?.getGood.good?.discountRate) {
          sum +=
            calculate(
              Number(data?.getGood.good?.price),
              data?.getGood.good?.discountRate
            ) * Number(selectOption.quantity);
        } else {
          sum +=
            Number(data?.getGood.good?.price) * Number(selectOption.quantity);
        }
      }
    }
    setTotalAmount(sum);
  }, [optionsSelected]);

  return (
    <div
      className={`wrap max-w-screen-break mx-auto w-full h-full overflow-auto removeScroll scroll-smooth `}
    >
      <div
        className={`header absolute top-0 left-0 w-full z-20 transition ${
          scrollOver ? "bg-white shadow" : "transparent"
        } `}
      >
        <div>
          <TopBar
            arrow={true}
            home={true}
            shop={true}
            shopColor={"mainIcon"}
            backColor={"transparent"}
            homeColor={"#00b0eb"}
            arrowColor={"#00b0eb"}
          />
        </div>
      </div>
      <main className="max-w-screen-break relative z-10">
        <div className="headInfo">
          <div className="imgBox relative max-w-screen-fit mx-auto">
            <Thumbnails
              posters={data?.getGood?.good?.goodPosters as IGoodPosterItem[]}
            ></Thumbnails>
          </div>
          <div className="infoBox relative pt-4 max-w-screen-fit mx-auto">
            {/* <div className="absolute -top-8 left-1/2 transfrom -translate-x-1/2 w-16 h-16 bg-block2 rounded-full flex flex-wrap content-center justify-center">
              <img
                src={food && food.brandImg}
                alt={food && food.brand + "로고 이미지"}
                className="w-12 h-12"
              />
            </div> */}
            <div className="px-4">
              <div className="text-[13px] leading-4 break:text-large text-[#60B0DB]  flex flex-wrap justify-between">
                <p className="pb-2">
                  <span className="">
                    {data?.getGood.good?.corporations &&
                      data?.getGood.good?.corporations?.map(
                        (cor, index) => cor.name + " "
                      )}
                  </span>
                </p>
                <button onClick={CopyUrlToClipboard}>
                  <img src={share} alt="공유하기" />
                </button>
              </div>
              <p className="font-medium text-[16px] leading-4 break:text-large my-1 ellipsis1">
                {data?.getGood.good?.title && data?.getGood.good?.title}
              </p>
              <div className="deliveryPrice mt-2 py-2">
                <div>
                  {data?.getGood.good?.discountRate !== 0 && (
                    <div className="flex flex-col">
                      <div className="flex items-center gap-x-2">
                        <span className="text-[18px] leading-4 break:text-Xlarge font-bold text-shade1 align-middle ">
                          {data?.getGood.good?.discountRate &&
                            data?.getGood.good?.discountRate + "%"}
                        </span>
                        <span className=" text-[20px] leading-4 font-bold align-middle">
                          {data?.getGood.good?.price &&
                            comma(
                              calculate(
                                Number(data?.getGood.good?.price),
                                Number(data?.getGood.good?.discountRate)
                              )
                            ) + "원"}
                        </span>
                      </div>
                      <span className="line-through text-[#979797] text-[13px] leading-4 break:text-standardall align-middle">
                        {data?.getGood.good?.price &&
                          comma(
                            data?.getGood.good?.price
                              ? data.getGood.good.price
                              : 1
                          )}
                      </span>
                    </div>
                  )}
                  {data?.getGood.good?.discountRate === 0 && (
                    <p className="flex flex-wrap content-center">
                      <span className=" text-Xlarge font-bold ">
                        {comma(
                          calculate(
                            Number(data?.getGood.good?.price),
                            Number(data?.getGood.good?.discountRate)
                          )
                        )}
                        원
                      </span>
                    </p>
                  )}
                </div>
              </div>
              <div className="py-4">
                <p className="max-h-12 text-standard break:text-large text-grayFont ellipsis2 overflow-hidden">
                  {data?.getGood.good?.summary}
                </p>
              </div>
            </div>

            {/* <div className="alertDelDate py-4 flex flex-wrap content-center justify-center flex-col space-y-2 bg-[#FAFAFF]">
              <p className="py-1 font-bold text-small text-center">
                닐리버리 배송 공지{" "}
              </p>
              <p className="text-center text-small">
                15시 전까지 주문은 당일 배송이 되며, <br />그 이후 주문은 다음
                날 배송됩니다.
              </p>
              {(moment().day() === 5 ||
                moment().day() === 6 ||
                moment().day() === 0) && (
                <p className="text-center text-small font-bold">
                  금요일 15시 이후 주문은 월요일날 배송됩니다.
                </p>
              )}
            </div> */}

            {/* 관련상품 */}
            {/* {relatedGoods?.listRelatedGoods.relatedGoods &&
              relatedGoods?.listRelatedGoods.relatedGoods.length > 0 && (
                <div className="p-4 overflow-hidden ">
                  <p className="py-2 font-bold text-small break:text-standard">
                    추가 선택
                  </p>
                  <Swiper
                    modules={[Pagination, Autoplay]}
                    slidesPerView={3}
                    pagination={{
                      clickable: true,
                      el: ".swiper-pagination",
                      type: "bullets",
                    }}
                    loop={true}
                    autoplay={{
                      delay: 10000,
                    }}
                    className="relative h-full"
                  >
                    {}
                    <div className="swiper-pagination"></div>
                    {relatedGoods?.listRelatedGoods.relatedGoods.map(
                      (related) => (
                        <SwiperSlide key={`relation - ${related.id}`}>
                          <RelatedGoods
                            goodId={Number(related.id)}
                            IncludeHandler={IncludeHandler}
                          />
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </div>
              )} */}
          </div>
        </div>
        <div className="bodyInfo pb-[400px] border-hintFont">
          <div className="w-full grid grid-cols-4 sticky top-12 left-0 bg-white z-10">
            <button
              onClick={() => {
                scrollHandler("review");
              }}
              className={`${
                tab === 1
                  ? "text-mainIcon border-mainIcon"
                  : "text-grayFont border-btnDarkLine"
              } border-b-[2px] h-10`}
            >
              리뷰{" "}
              {listGoodReviewData?.listGoodReview.totalResults && (
                <span>
                  {listGoodReviewData?.listGoodReview.totalResults > 99
                    ? `99+`
                    : listGoodReviewData?.listGoodReview.totalResults}
                </span>
              )}
            </button>
            <button
              onClick={() => {
                scrollHandler("detail");
              }}
              className={`${
                tab === 2
                  ? "text-mainIcon border-mainIcon"
                  : "text-grayFont border-btnDarkLine"
              } border-b-[2px] h-10`}
            >
              상세정보
            </button>
            <button
              onClick={() => {
                scrollHandler("refund");
              }}
              className={`${
                tab === 3
                  ? "text-mainIcon border-mainIcon"
                  : "text-grayFont border-btnDarkLine"
              } border-b-[2px] h-10`}
            >
              교환/환불
            </button>
            <button
              onClick={() => {
                scrollHandler("AttributeInfo", 4);
              }}
              className={`${
                tab === 4
                  ? "text-mainIcon border-mainIcon"
                  : "text-grayFont border-btnDarkLine"
              } border-b-[2px] h-10`}
            >
              상품정보
            </button>
          </div>
          <div id="firstVideo" className="p-4">
            {data?.getGood.good?.goodDetails &&
              data.getGood.good.goodDetails
                .filter((detail) => detail.detailType === GoodDetailType.Video)
                .map((details, index) => (
                  <div key={`good-detail-${index + 1}`}>
                    {details.url && (
                      <div className="mx-auto max-w-screen-break w-full p-4">
                        <iframe
                          src={details.url + "?autoplay=1&mute=1"}
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="w-full aspect-video"
                        ></iframe>
                      </div>
                    )}
                  </div>
                ))}
          </div>
          <div id="review" className="p-4">
            <div className="p-4 bg-main rounded-2xl">
              <p className="text-[20px] leading-[30px] py-1 font-bold text-center text-mainIcon">
                상품 후기
              </p>
              {listGoodReviewData?.listGoodReview?.goodReviews?.length ===
                0 && <p className="py-10 text-center">현재 후기가 없습니다.</p>}
              <div className="reviewList pt-4 flex flex-wrap gap-4 justify-between">
                {listGoodReviewData?.listGoodReview?.goodReviews &&
                  listGoodReviewData?.listGoodReview?.goodReviews.map(
                    (review) => (
                      <div
                        className="py-2 px-2 bg-white basis-80 mx-auto rounded-2xl border border-btnLightLine"
                        key={`review-${review.id}`}
                      >
                        <div className="review rounded-lg p-4">
                          <div className="text-small leading-4 flex flex-wrap content-center justify-between">
                            <p className="flex flex-wrap content-center justify-center">
                              {review.createdAt && (
                                <span className="font-bold">
                                  {review.good.title}
                                </span>
                              )}
                            </p>
                            {review?.author?.id === me?.me.id && (
                              <button
                                onClick={() => {
                                  navigate(`/editReview/${review.id}`);
                                }}
                                className="text-grayFont rounded-lg border border-btnLightLine px-2 py-1"
                              >
                                수정
                              </button>
                            )}
                          </div>
                          <div className="flex flex-wrap gap-x-1 pt-1">
                            <p className="text-grayFont text-[10px] leading-3 flex flex-wrap content-center px-1">{`${moment(
                              review.createdAt
                            ).format("YYYY.MM.DD")}`}</p>
                            <p className="py-1 px-4 flex flex-wrap content-center gap-x-1">
                              <img
                                className="w-4 h-4"
                                src={
                                  review.rating >= 1 ? star_yellow : star_gray
                                }
                                alt="별점"
                              />
                              <img
                                className="w-4 h-4"
                                src={
                                  review.rating >= 2 ? star_yellow : star_gray
                                }
                                alt="별점"
                              />
                              <img
                                className="w-4 h-4"
                                src={
                                  review.rating >= 3 ? star_yellow : star_gray
                                }
                                alt="별점"
                              />
                              <img
                                className="w-4 h-4"
                                src={
                                  review.rating >= 4 ? star_yellow : star_gray
                                }
                                alt="별점"
                              />
                              <img
                                className="w-4 h-4"
                                src={
                                  review.rating >= 5 ? star_yellow : star_gray
                                }
                                alt="별점"
                              />
                            </p>
                          </div>
                          <div className="orderBox space-y-4">
                            {/* map component order */}
                            <div>
                              <div
                                className={`pt-1 grid  gap-x-2 overflow-hidden grid-cols-2`}
                              >
                                {review?.goodReviewPosters &&
                                  review?.goodReviewPosters.map(
                                    (poster, index, posterArray) => (
                                      <div
                                        key={`review-poster-${index}`}
                                        className={`p-1 relative  ${
                                          index > 1 && "hidden"
                                        }`}
                                      >
                                        <img
                                          src={`${poster.upload?.url}`}
                                          alt={`${review.author} 리뷰`}
                                          className={`rounded-lg aspect-[140/100]`}
                                        />
                                        {posterArray.length > 2 &&
                                          index == 1 && (
                                            <div
                                              onClick={() => {
                                                navigate(
                                                  `/listGoodReview/${review.good.id}`
                                                );
                                              }}
                                              className="p-1 w-full h-full absolute left-0 top-0 cursor-pointer "
                                            >
                                              <div className="text-white text-[28px] rounded-lg flex-wrap flex content-center justify-center bg-black bg-opacity-30 w-full h-full">
                                                +{posterArray.length - 2}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    )
                                  )}
                              </div>
                              <div className="mt-1 py-2">
                                <p
                                  className={`px-1 text-small overflow-hidden whitespace-pre-wrap ellipsis2`}
                                >{`${review.note}`}</p>
                              </div>
                              <div className="mt-1">
                                <span
                                  onClick={() => {
                                    navigate(
                                      `/listGoodReview/${review.good.id}`
                                    );
                                  }}
                                  className="cursor-pointer text-xs px-2 font-bold underline text-darkFont"
                                >
                                  자세히보기
                                </span>
                              </div>
                            </div>
                            {/* order end */}
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
          <div id="detail" className="pb-4">
            {/* 상단표시 */}
            {/* <div className="firstOfAll">
              <div className="FirstInfo p-4">
                <table className="mx-auto max-w-screen-sm">
                  <thead></thead>
                  <tbody className="text-left">
                    {attrInfo &&
                      attrInfo.map((obj, index) => (
                        <tr key={`attrInfo-${obj.label}${index}}`}>
                          <th className="py-1 font-normal text-grayFont text-base w-[100px]">
                            {obj.label}
                          </th>
                          <td className="pl-1 min-w-[200px]">{obj.value}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
           
            </div> */}
            <div className="pt-4 DeailContents">
              {data?.getGood?.good?.goodDetails &&
                data.getGood.good.goodDetails
                  .filter(
                    (detail) => detail.detailType !== GoodDetailType.Video
                  )
                  .map((goodDetail, index) => (
                    <div
                      key={`good-detail-${index + 1}`}
                      className="mx-auto max-w-screen-break w-full px-4 "
                    >
                      {goodDetail.detailType == GoodDetailType.Video &&
                        goodDetail.url && (
                          <iframe
                            src={goodDetail.url}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="w-full aspect-video mb-16"
                          ></iframe>
                        )}
                      {goodDetail.detailType === GoodDetailType.Image && (
                        <img
                          src={`${goodDetail.upload?.url}`}
                          alt={`good-detail-image-${index + 1}`}
                          onClick={() => {
                            if (goodDetail.url) {
                              window.location.href = `${goodDetail.url}`;
                            }
                          }}
                          className={`w-full ${
                            goodDetail.url
                              ? "hover:opacity-50 hover:cursor-pointer "
                              : ""
                          } mb-16`}
                        />
                      )}
                      {goodDetail.detailType === GoodDetailType.ContentArea && (
                        <div className="mb-16">
                          <p
                            onClick={() => {
                              if (goodDetail.url) {
                                window.location.href = `${goodDetail.url}`;
                              }
                            }}
                            className={`p-4 text-large break:text-Xlarge font-bold text-darkFont w-full ${
                              goodDetail.url
                                ? "hover:underline hover:opacity-50 hover:cursor-pointer"
                                : ""
                            }`}
                          >
                            {goodDetail.note}
                          </p>
                          <img
                            src={`${goodDetail.upload?.url}`}
                            alt={`good-detail-image-${index + 1}`}
                            onClick={() => {
                              if (goodDetail.url) {
                                window.location.href = `${goodDetail.url}`;
                              }
                            }}
                            className={`w-full ${
                              goodDetail.url
                                ? "hover:opacity-50 hover:cursor-pointer"
                                : ""
                            }`}
                          />
                        </div>
                      )}
                      {goodDetail.detailType === GoodDetailType.TextArea && (
                        <div
                          onClick={() => {
                            if (goodDetail.url) {
                              window.location.href = `${goodDetail.url}`;
                            }
                          }}
                          className="px-4 text-small break:text-standardall text-grayFont w-full mb-16"
                        >
                          {goodDetail.note}
                        </div>
                      )}
                    </div>
                  ))}
            </div>
          </div>
          <div id="inquiry" className="px-4 py-5 bg-bg">
            <div className="text-large break:text-Xlarge py-2 text-center">
              <p>
                <span className="font-bold">{data?.getGood.good?.title}</span>
              </p>
              <p>에 궁금한게 있다면?</p>
            </div>
            <p className="py-4 text-center">
              <button
                onClick={() => {
                  if (!me?.me.id) {
                    setAlertText(
                      "로그인이 필요합니다. <br/> 로그인 하시겠습니까?"
                    );
                    setAlertOpen(true);
                  } else {
                    navigate("/mypage/enrollInquiry");
                  }
                }}
                className="w-[200px] py-2 bg-white text-grayFont border border-hintFont rounded-full"
              >
                문의하러가기
              </button>
            </p>
          </div>

          <div id="refund" className="p-4">
            <div className="border p-4 rounded-xl">
              <p className="text-large break:text-Xlarge py-2">
                <span className="font-bold">배송 및 교환/환불</span>
              </p>
              <ul className=" p-4 min-h-[40px] text-small break:text-standardall space-y-2">
                <li className="list-disc">
                  혁신도시 율곡동은 출발 당일에서 하루, 산내들 오토캠핑장은
                  금요일 토요일만 배송이 가능합니다. 이 외 지역은 현재 서비스
                  예정입니다.
                </li>
                <li className="list-disc">
                  15시 이전의 주문 건까지는 당일 출고됩니다. (주말 및 공휴일
                  제외)
                </li>
                <li className="list-disc">
                  환불/교환 신청은 배송 완료일로부터 3일이내 가능합니다. (3일
                  이후 자동 배송확정)
                </li>
                <li className="list-disc">
                  {" "}
                  배송확정 상품은 교환/환불이 불가능합니다.
                </li>
                <li className="list-disc">
                  상품 및 구성품, 포장 훼손, 분실 시 교환/환불이 불가합니다.
                </li>
                <li className="list-disc">
                  반품 신청시, 당사에서 직접 도와드립니다. 문의에 교환문의를
                  남겨주세요.
                </li>

                <li className="list-disc">
                  자세한 내용은{" "}
                  <a
                    href="/deliveryRefundInfo"
                    className="text-darkFont font-bold underline"
                  >
                    배송/교환환불 정책
                  </a>{" "}
                  을 참조해주세요
                </li>
              </ul>
            </div>
          </div>
          {attr && (
            <div id="AttributeInfo" className="px-4 py-5">
              <div className="p-4 border rounded-xl">
                <p className="text-large break:text-Xlarge py-2 flex flex-wrap justify-between">
                  <span className="font-bold">상품필수 정보</span>
                  {attr && (
                    <button
                      onClick={() => {
                        if (mapLimit === attr?.attrs.length) {
                          setMapLimit(5);
                        } else {
                          setMapLimit(attr?.attrs.length);
                        }
                      }}
                      className="w-10 h-6 border rounded-lg text-xs"
                    >
                      {mapLimit === attr?.attrs.length ? "닫기" : "더보기"}
                    </button>
                  )}
                </p>
                <div className=" p-4 min-h-[40px] text-small break:text-standardall">
                  <ul className="transition-all">
                    {attr &&
                      attr?.attrs.slice(0, mapLimit).map((val) => (
                        <li
                          className="list-disc py-1 text-grayFont text-left flex gap-x-4"
                          key={`Attribute-${val.name}`}
                        >
                          <span className="inline-block w-[130px] self-center">
                            {val.name}
                          </span>
                          <span className="flex-1">{val.value}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
      <div className="absolute bottom-0 left-0 w-full z-10">
        <div className="break:sticky absolute  bottom-0 left-1/2 transform -translate-x-1/2 break:transform break:translate-x-0  w-full flex flex-wrap h-[72px]">
          <button
            onClick={() => {
              if (!me?.me.id) {
                setAlertText("로그인이 필요합니다. <br/> 로그인 하시겠습니까?");
                setAlertOpen(true);
              } else {
                if (data?.getGood.good?.status === GoodStatus.GoodReady) {
                  setAlertText("판매중인 상품이 아닙니다.");
                  setAlertOpen(true);
                } else {
                  listOptionsHandler(Number(data?.getGood.good?.id));
                  setSlideUp(true);
                }
              }

              // const BasketInput = data?.getGood.good?.id;
              // crBasketItems({
              //   variables: { input: { goodId: Number(BasketInput) } },
              // });
            }}
            className="w-[72px] h-full bg-hintFont"
          >
            <img src={cart} alt="장바구니 담기" className="inline w-10" />
          </button>

          <button
            onClick={() => {
              if (!me?.me.id) {
                setAlertText("로그인이 필요합니다. <br/> 로그인 하시겠습니까?");
                setAlertOpen(true);
              } else {
                if (data?.getGood.good?.status === GoodStatus.GoodReady) {
                  setAlertText("판매중인 상품이 아닙니다.");
                  setAlertOpen(true);
                  // alert("판매중인 상품이 아닙니다.");
                } else {
                  listOptionsHandler(Number(data?.getGood.good?.id));
                  setSlideUp(true);
                }
              }
              // crReceiptHandler(basketIds)
            }}
            className="h-full flex-1 bg-mainIcon text-white text-standard break:text-large font-bold"
          >
            구매하기
          </button>
        </div>
      </div>
      <div className={`w-full h-full absolute left-0 top-0`}>
        <div
          onClick={() => {
            setSlideUp(false);
          }}
          className={`w-full h-full absolute left-0 top-0 bg-black bg-opacity-30 transition-opacity ${
            slideUp ? "z-20 opacity-100" : "z-0 opacity-0"
          }`}
        ></div>
        {/* 슬라이드 업 */}
        <div
          className={`absolute bottom-0 left-0 w-full  transform transition-transform z-20 ${
            slideUp ? "translate-y-0" : "translate-y-full"
          }`}
        >
          <div className=" rounded-t-2xl relative ">
            {/* 옵션 수량선택 */}

            <div>
              <div
                onClick={() => {
                  setSlideUp(false);
                }}
                className="h-8 bg-white rounded-t-2xl border border-b-0"
              >
                <img className="mx-auto py-2" src={toast}></img>
              </div>
              <div className="pt-2 px-4 py-[22px] space-y-2 bg-white h-[400px] overflow-auto text-left flex flex-col">
                <div className="relative">
                  <div
                    onClick={() => setBoxOpen(!boxOpen)}
                    className={`relative text-standard text-darkFont w-full  h-14 py-4 px-3 border border-btnDarkLine ${
                      boxOpen
                        ? "bg-main rounded-t-lg border-b-0"
                        : "bg-white rounded-lg"
                    }`}
                  >
                    {selectName ? selectName : "옵션을 선택해주세요"}
                    <img
                      src={drop_down}
                      alt="박스 오픈 아이콘"
                      className={`absolute right-2 top-1/2 -translate-y-1/2 transition-transform ${
                        boxOpen ? "rotate-0" : "rotate-180"
                      }`}
                    />
                  </div>
                  {boxOpen && (
                    <ul className="absolute left-0 top-14 w-full border border-btnDarkLine roudned-b-lg bg-white ">
                      {listGoodOptionData?.listGoodOption.goodOptions?.map(
                        (option, index) => (
                          <li
                            onClick={() => {
                              setSelectName(option.name + "");
                              setBoxOpen(false);
                              if (option.price) {
                                optionsSelectedHandler(
                                  option.id,
                                  String(option.name),
                                  option.price,
                                  option.discountRate != undefined
                                    ? option.discountRate
                                    : null
                                );
                              } else {
                                optionsSelectedHandler(
                                  option.id,
                                  String(option.name),
                                  null,
                                  null
                                );
                              }
                            }}
                            className="text-sm leading-normal h-14 py-4 px-3 hover:bg-bg"
                            key={"option.name" + index}
                          >
                            {option.name}
                          </li>
                        )
                      )}
                      {/* <li
                        onClick={() => {
                          optionsSelectedHandler("", "기본 배송");
                          setSelectName("기본배송");
                          setBoxOpen(false);
                        }}
                        className="text-sm leading-normal h-14 py-4 px-3 hover:bg-bg"
                      >
                        기본배송
                      </li> */}
                    </ul>
                  )}
                </div>
                {/* 고른 옵션 */}
                <div className="space-y-2">
                  {optionsSelected.length > 0 &&
                    optionsSelected.map((selectOption) => (
                      <div
                        key={`selectedOption-${selectOption.goodOptionId}`}
                        className="p-4 border w-full border-mainIcon rounded-lg"
                      >
                        <div className="flex flex-col gap-y-2">
                          <div className="flex flex-wrap w-full">
                            <div className="flex flex-col flex-1 w-full">
                              <p className="py-[2px] px-1 flex-1">
                                {data?.getGood.good?.title}
                              </p>
                              <p className="py-[2px] px-1">
                                {selectOption.name}
                              </p>
                              {/* <p className="py-[2px] px-1">
                                {selectOption.price}
                              </p> */}
                            </div>
                            <div className="py-[7px]">
                              <button
                                onClick={() => {
                                  optionsSelectedRemoveHandler(
                                    Number(selectOption.goodOptionId)
                                  );
                                }}
                                className="px-[8.5px] py-1 rounded-lg border border-btnLightLine"
                              >
                                삭제
                              </button>
                            </div>
                          </div>
                          <div className="flex flex-wrap justify-between px-2 py-1 bg-[#FAFAFF] rounded-[20px] ">
                            <div className="flex flex-wrap gap-x-2 py-1">
                              <span className="py-[3px] font-[#777B7D] text-Xsmall">
                                수량
                              </span>
                              <div className="gap-x-1 flex flex-wrap">
                                <button
                                  onClick={() => {
                                    optionsSelectedQuantityHandler(
                                      String(selectOption.name),
                                      false
                                    );
                                  }}
                                >
                                  <img
                                    src={pay_minus}
                                    className="w-4 h-4"
                                  ></img>
                                </button>

                                <span className="font-bold py-[1px] px-[13px] rounded-[20px] bg-[#fff] text-smallBtn">
                                  {selectOption.quantity}
                                </span>
                                <button
                                  onClick={() => {
                                    optionsSelectedQuantityHandler(
                                      String(selectOption.name),
                                      true
                                    );
                                  }}
                                >
                                  <img src={pay_plus} className="w-4 h-4"></img>
                                </button>
                              </div>
                            </div>
                            <span className="py-[5px] text-smallBtn font-bold">
                              {selectOption.price
                                ? selectOption.discountRate
                                  ? calculate(
                                      selectOption.price,
                                      selectOption.discountRate
                                    ) *
                                      Number(selectOption.quantity) +
                                    "원"
                                  : selectOption.price *
                                      Number(selectOption.quantity) +
                                    "원"
                                : data?.getGood.good?.discountRate
                                ? calculate(
                                    Number(data?.getGood.good?.price),
                                    data?.getGood.good?.discountRate
                                  ) *
                                    Number(selectOption.quantity) +
                                  "원"
                                : Number(data?.getGood.good?.price) *
                                    Number(selectOption.quantity) +
                                  "원"}
                              {/* {data?.getGood.good?.discountRate
                                ? selectOption.price
                                  ? calculate(
                                      selectOption.price,
                                      data?.getGood.good?.discountRate
                                    ) *
                                      Number(selectOption.quantity) +
                                    "원"
                                  : calculate(
                                      Number(data?.getGood.good?.price),
                                      data?.getGood.good?.discountRate
                                    ) *
                                      Number(selectOption.quantity) +
                                    "원"
                                : selectOption.price
                                ? selectOption.price *
                                    Number(selectOption.quantity) +
                                  "원"
                                : Number(data?.getGood.good?.price) *
                                    Number(selectOption.quantity) +
                                  "원"} */}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <Dialog open={alertOpen} className="relative">
                <AlertComponent
                  title={`알림`}
                  desc={alertText}
                  closeFunction={closeAlert}
                ></AlertComponent>
              </Dialog>
            </div>
            <div className="flex flex-wrap w-full">
              <button
                onClick={() => {
                  crBasketItemHandler();
                }}
                className="w-[72px] h-[72px] bg-hintFont"
              >
                <img src={cart} alt="장바구니 담기" className="inline w-10" />
              </button>
              <button
                onClick={() => {
                  setSlideUp(!slideUp);
                  crReceiptHandler();
                }}
                className="h-[72px] flex-1 bg-mainIcon text-white text-standard break:text-large font-bold"
              >
                바로 구매하기
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        onClose={handleClose}
        message={"클립보드에 URL이 복사되었습니다."}
        action={snacks}
      ></Snackbar>
      <Dialog
        open={OpenPhoto}
        onClose={() => {
          setOpenPhoto(false);
        }}
      >
        <div className="w-full max-w-[320px] px-2 transform">
          <img
            src={photo}
            alt="사진 확대"
            className="w-full h-full object-fill"
          />
        </div>
      </Dialog>
    </div>
  );
};
