import { ApolloError, gql, useMutation } from "@apollo/client";
import { GetJoinUserCertDataMutation, GetJoinUserCertDataMutationVariables } from "../gql/graphql";

const GET_JOIN_USER_CERT_MUTATION = gql`
  mutation getJoinUserCertData($input: GetJoinUserCertInput!) {
    getJoinUserCertData(input: $input) {
      ok
      err
      token
    }
  }
`;

export const useGetJoinUserCertData = (onCompleted?: (data: GetJoinUserCertDataMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<GetJoinUserCertDataMutation, GetJoinUserCertDataMutationVariables>(GET_JOIN_USER_CERT_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
