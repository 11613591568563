import { gql, useLazyQuery } from "@apollo/client";
import { ListGoodReviewQuery } from "../gql/graphql";

const LIST_GOOD_REVIEW_QUERY = gql`
  query listGoodReview($input: ListGoodReviewInput!) {
    listGoodReview(input: $input) {
      ok
      err
      totalResults
      goodReviews {
        id
        createdAt
        note
        rating
        order {
          price
          amount
        }
        author {
          id
          name
          username
        }
        good {
          id
          title
        }
        goodReviewPosters {
          upload {
            url
          }
        }
      }
    }
  }
`;

export const useListGoodReview = (onCompleted?: (data: ListGoodReviewQuery) => void) => {
  return useLazyQuery<ListGoodReviewQuery>(LIST_GOOD_REVIEW_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
