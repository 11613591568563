import { ApolloError, gql, useMutation } from "@apollo/client";
import { SnsLoginMutation, SnsLoginMutationVariables } from "../gql/graphql";

const SNS_LOGIN_MUTATION = gql`
  mutation snsLogin($input: SnsLoginInput!) {
    snsLogin(input: $input) {
      ok
      token
      exist
      id
      err
      username
    }
  }
`;

export const useSnsLogIn = (onCompleted?: (data: SnsLoginMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<SnsLoginMutation, SnsLoginMutationVariables>(SNS_LOGIN_MUTATION, {
    onCompleted,
    onError,
  });
};
