import { gql, useLazyQuery } from "@apollo/client";
import {
  ListBasketItemServiceQuery,
  ListBasketItemServiceQueryVariables,
} from "../gql/graphql";

const LIST_BASKET_ITEM_SERVICE_QYERY = gql`
  query listBasketItemService($input: ListBasketItemServiceInput!) {
    listBasketItemService(input: $input) {
      ok
      err
      basketItems {
        id
        quantity
        user {
          id
        }
        good {
          id
          title
          price
          limitNumOrders
          discountRate
          status
          goodPosters {
            upload {
              url
            }
          }
          corporations {
            name
            regionKeyWord
            service
            linkService {
              id
              name
              minAmount
            }
          }
        }
        goodOption {
          name
          id
          deliveryDate
          isActive
          dateActive
          price
          discountRate
        }
      }
    }
  }
`;

export const useListBasketItemService = (
  onCompleted?: (data: ListBasketItemServiceQuery) => void
) => {
  return useLazyQuery<
    ListBasketItemServiceQuery,
    ListBasketItemServiceQueryVariables
  >(LIST_BASKET_ITEM_SERVICE_QYERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
