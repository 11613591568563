import { useEffect, useLayoutEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { pageIndicator } from "../apollo";
import { Footer } from "../components/footer";
import { FootMenu } from "../components/FootMenu";
import { TopBar } from "../components/topBar";
import { useGetBannerMainEvent } from "../hooks/useGetBannerMainEvent";
import { useMe } from "../hooks/useME";
import { GetBannerMainEventInput, GetBannerMainEventQuery } from "../gql/graphql";

export const Event = () => {
  const { data: me } = useMe();
  var params = useParams();
  const onCompleted = (data: GetBannerMainEventQuery) => {
    const {
      getBannerMainEvent: { ok, err, bannerMainEvent },
    } = data;
    if (ok) {
      if (data.getBannerMainEvent.bannerMainEvent?.title) pageIndicator(data.getBannerMainEvent.bannerMainEvent?.title);
    } else {
      alert(err);
    }
  };
  const [getBannerMainEvent, { data, loading }] = useGetBannerMainEvent(onCompleted);
  const getEventHandler = () => {
    const getBannerMainEventInput: GetBannerMainEventInput = {
      eventId: Number(params.eventId),
    };
    getBannerMainEvent({ variables: { input: getBannerMainEventInput } });
  };
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    getEventHandler();
  }, []);
  return (
    <div className="max-w-screen-break mx-auto h-full overflow-auto removeScroll">
      <TopBar arrow={true} heading={true} />
      <div className="flex-1 pt-4 mb-[100px] ">
        <div className="contentsBox">
          <img src={`${data?.getBannerMainEvent.bannerMainEvent?.upload?.url}`} className="w-full"></img>
        </div>
      </div>
      {/* <div className="absolute bottom-0 left-0 w-full z-10">
        <div className="px-4 pb-4 w-full break:sticky absolute  bottom-0 left-1/2 transform -translate-x-1/2 break:transform break:translate-x-0">
          <button className="bg-[#ffe400] font-bold text-btn block w-full max-w-screen-fit text-[#363a3d] text-[20px] leading-6  py-[9px] mx-auto rounded-[20px]">
            {me?.me.id && (
              <a
                href={`${data?.getBannerMainEvent.bannerMainEvent?.link}`}
                className="block w-full"
              >
                구매하러 가기
              </a>
            )}
            {!me?.me.id && (
              <Link to="/join/agree" className="block w-full">
                회원가입하러가기
              </Link>
            )}
          </button>
        </div>
      </div> */}
      <FootMenu />
    </div>
  );
};
