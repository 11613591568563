import { Slide } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { TopBar } from "../components/topBar";

export const Finded = () => {
  const navigate = useNavigate();
  const data = useLocation().state;
  return (
    <div className="w-full h-full relative overflow-hidden">
      <Helmet title="닐리버리 - 아이디 찾기" />
      <div className="flex flex-col h-full">
        <TopBar arrow={true} arrowColor={`#B5C1D0`} heading={true} exit={true} />
        <nav className="w-full h-10 grid grid-cols-2">
          <button className="h-full w-full border-b-2 border-mainIcon">아이디 찾기</button>
          <button
            onClick={() => {
              navigate("/findPwd");
            }}
            className="h-full w-full border-b-2 border-btnDarkLine"
          >
            비밀번호 찾기
          </button>
        </nav>
        <div className="flex-1 flex flex-wrap justify-center content-center">
          <Slide direction="left" in={true}>
            <div className="w-full">
              <div className="mx-auto w-full px-6 py-2 mb-20 max-w-screen-break">
                <div className="p-4 h-[150px] w-full bg-white  border border-btnDarkLine rounded-2xl text-standard break:text-large text-center flex flex-wrap content-center justify-center">
                  <p className="py-2 w-full">고객님의 메일 주소는 </p>
                  <p className="py-2 w-full">
                    <span className="text-grayFont text-large break:text-Xlarge font-bold">{String(data)}</span>
                  </p>
                  입니다.
                </div>
              </div>
              <div className="py-10">
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="bg-mainIcon block w-full max-w-screen-fit text-white text-xl font-bold h-[56px] mx-auto rounded-full"
                >
                  로그인하러 가기
                </button>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  );
};
