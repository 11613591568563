import { ApolloError, gql, useLazyQuery } from "@apollo/client";
import { GetReceiptQuery, GetReceiptQueryVariables } from "../gql/graphql";

const GET_RECEIPT_MUTATION = gql`
  query getReceipt($input: GetReceiptInput!) {
    getReceipt(input: $input) {
      ok
      err
      receipt {
        id
        howToReceive
        address
        message
        pass
        phone
        receiver
        port {
          name
          usageTime
          phone
          address
          author {
            id
            name
            phone
          }
        }
        basketItems {
          id
          quantity
          good {
            title
            price
            discountRate
            goodPosters {
              isRep
              index
              upload {
                url
              }
            }
          }
          goodOption {
            name
            price
            discountRate
          }
        }
        orders {
          quantity
          price
          amount
          goodName
          goodOptionName
          good {
            price
            discountRate
            title
            goodPosters {
              isRep
              index
              upload {
                url
              }
            }
            goodOptions {
              id
            }
          }
        }
      }
    }
  }
`;

export const useGetReceipt = (onCompleted?: (data: GetReceiptQuery) => void, onError?: (error: ApolloError) => void) => {
  return useLazyQuery<GetReceiptQuery, GetReceiptQueryVariables>(GET_RECEIPT_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
