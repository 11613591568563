import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { pageIndicator, test } from "../apollo";
import help_circle from "../assets/icons/help-circle.png";
import { TopBar } from "../components/topBar";
import {
  RequestPayParams,
  payParams,
  RequestPayResponse,
  IMP_ID,
} from "../constants/imp";
import { useGetReceipt } from "../hooks/useGetReceipt";
import { useMe } from "../hooks/useME";
import { usePayReceipt } from "../hooks/usePayReceipt";
import { useUpReceiptAddress } from "../hooks/useUpReceiptAddress";
import { useUpAddress } from "../hooks/useUpAddress";
import { messageText } from "../constants/texts";
import { HowToReceive } from "../constants/props";
import {
  UpReceiptAddressInput,
  GetReceiptInput,
  PayReceiptInput,
  PgOptionInput,
  UpAddressInput,
  GetReceiptQuery,
  UpReceiptAddressMutation,
  PayReceiptMutation,
  UpAddressMutation,
  UserType,
} from "../gql/graphql";
import { IGetReceiptBasketItem } from "../constants/types";

export const Payment = () => {
  const navigate = useNavigate();
  const state = useLocation().state;
  const [textFieldOpen, setTextFieldOpen] = useState(false);
  const params = useParams();
  const IMP = window.IMP;
  const base = window.location.origin;
  const [pass, setPass] = useState<string | undefined>("");
  const [message, setMessage] = useState<string | undefined>("");
  const [messageField, setMessageField] = useState<string | undefined>("");
  const { data: me } = useMe();
  const getTotalAMount = (basketItems: IGetReceiptBasketItem[]): number => {
    let amount = 0;
    basketItems.forEach((basketItem) => {
      if (basketItem.good.price) {
        if (basketItem.goodOption?.price) {
          const _amount = basketItem.quantity * basketItem.goodOption.price;
          amount += _amount;
        } else {
          const _amount = basketItem.quantity * basketItem.good.price;
          amount += _amount;
        }
      }
    });
    return amount;
  };

  const getDelivery = (basketItems: IGetReceiptBasketItem[]): number => {
    let amount = 0;
    basketItems.forEach((basketItem) => {
      if (basketItem.goodOption?.price) {
        if (basketItem.goodOption.discountRate) {
          const _amount =
            basketItem.quantity *
            getDiscountedPrice(
              basketItem.goodOption.price,
              Number(basketItem.goodOption.discountRate)
            );
          amount += _amount;
        } else {
          const _amount =
            basketItem.quantity *
            getDiscountedPrice(basketItem.goodOption.price, 0);
          amount += _amount;
        }
      } else if (basketItem.good.price) {
        const _amount =
          basketItem.quantity *
          getDiscountedPrice(
            basketItem.good.price,
            Number(basketItem.good.discountRate)
          );
        amount += _amount;
      }
    });

    return amount;
  };

  const getAmount = (basketItems: IGetReceiptBasketItem[]): number => {
    let amount = 0;
    basketItems.forEach((basketItem) => {
      if (basketItem.goodOption?.price) {
        if (basketItem.goodOption.discountRate) {
          const _amount =
            basketItem.quantity *
            getDiscountedPrice(
              basketItem.goodOption.price,
              Number(basketItem.goodOption.discountRate)
            );
          amount += _amount;
        } else {
          const _amount =
            basketItem.quantity *
            getDiscountedPrice(basketItem.goodOption.price, 0);
          amount += _amount;
        }
      } else if (basketItem.good.price) {
        const _amount =
          basketItem.quantity *
          getDiscountedPrice(
            basketItem.good.price,
            Number(basketItem.good.discountRate)
          );
        amount += _amount;
      }
      // if (basketItem.good.price) {
      //   if (basketItem.goodOption?.price) {
      //     const _amount =
      //       basketItem.quantity *
      //       getDiscountedPrice(
      //         basketItem.goodOption.price,
      //         Number(basketItem.good.discountRate)
      //       );
      //     amount += _amount;
      //   } else {
      //     const _amount =
      //       basketItem.quantity *
      //       getDiscountedPrice(
      //         basketItem.good.price,
      //         Number(basketItem.good.discountRate)
      //       );
      //     amount += _amount;
      //   }
      // }
    });

    // if (
    // data?.getReceipt.receipt?.howToReceive === HowToReceive.Delivery &&
    //   amount < 30000
    // ) {
    //   amount += 3000;
    // }
    return amount;
  };

  const getDiscountMoney = (basketItems: IGetReceiptBasketItem[]): number => {
    let amount = 0;
    basketItems.forEach((basketItem) => {
      if (basketItem.goodOption?.price) {
        if (basketItem.goodOption.discountRate) {
          const _amount =
            basketItem.quantity *
            getDiscounted(
              basketItem.goodOption.price,
              Number(basketItem.goodOption.discountRate)
            );
          amount += _amount;
        }
      } else if (basketItem.good.price) {
        const _amount =
          basketItem.quantity *
          getDiscounted(
            basketItem.good.price,
            Number(basketItem.good.discountRate)
          );
        amount += _amount;
      }

      // if (basketItem.good.price) {
      //   if (basketItem.goodOption?.price) {
      //     const _amount =
      //       basketItem.quantity *
      //       getDiscounted(
      //         basketItem.goodOption.price,
      //         Number(basketItem.good.discountRate)
      //       );
      //     amount += _amount;
      //   } else {
      //     const _amount =
      //       basketItem.quantity *
      //       getDiscounted(
      //         basketItem.good.price,
      //         Number(basketItem.good.discountRate)
      //       );
      //     amount += _amount;
      //   }
      // }
    });
    return amount;
  };

  const getDiscountedPrice = (price: number, discountRate: number): number => {
    return (price * (100 - discountRate)) / 100;
  };
  const getDiscounted = (price: number, discountRate: number): number => {
    return (price * discountRate) / 100;
  };

  const onGetReceipt = (data: GetReceiptQuery) => {
    const {
      getReceipt: { ok, err, receipt },
    } = data;
    console.log(data);
    if (ok && receipt) {
      setPass(receipt.pass ? receipt.pass : "");
      if (
        messageText.findIndex((text) => text.value === receipt.message) >= 0
      ) {
        setMessage(receipt.message ? receipt.message : "");
      } else {
        setTextFieldOpen(true);
        setMessage("직접 입력하기");
        setMessageField(receipt.message ? receipt.message : "");
      }
      // setMessage(String(receipt.message));
    } else {
      console.log(err);
    }
  };
  const [getReceipt, { loading: getReceiptLoading, data, refetch }] =
    useGetReceipt(onGetReceipt);
  const getReceiptHandler = (receiptUuid: string) => {
    const input: GetReceiptInput = {
      receiptUuid,
    };
    getReceipt({ variables: { input } });
  };

  const onUpReceiptAddress = (data: UpReceiptAddressMutation) => {};
  const [upReceiptAddress] = useUpReceiptAddress(onUpReceiptAddress);
  const upReceiptAddressHandler = (input: UpReceiptAddressInput) => {
    upReceiptAddress({ variables: { input } });
  };

  const onPayReceipt = (data: PayReceiptMutation) => {
    const {
      payReceipt: { ok },
    } = data;
    if (ok) {
      navigate(`/paymentComplete/${params.receiptUuid}`);
    }
  };
  const [payReceipt, { loading: payReceiptLoading }] =
    usePayReceipt(onPayReceipt);
  const payReceiptHandler = (input: PayReceiptInput) => {
    if (payReceiptLoading) return;
    payReceipt({ variables: { input } });
  };

  const onPayment = (response: RequestPayResponse) => {
    const {
      success,
      merchant_uid,
      error_msg,
      imp_uid,
      receipt_url,
      status,
      paid_amount,
      paid_at,
      apply_num,
      pay_method,
      pg_tid,
      pg_type,
      name,
    } = response;
    if (!success) {
      navigate("/paymentFail");
      return;
    }

    const pgOptionInput: PgOptionInput = {
      apply_num: `${apply_num}`,
      receipt_url,
      imp_uid,
      merchant_uid,
      name: `${name}`,
      pay_method: `${pay_method}`,
      pg_tid: `${pg_tid}`,
      pg_type: `${pg_type}`,
    };

    const payReceiptInput: PayReceiptInput = {
      status,
      paid_amount,
      paid_at: `${paid_at}`,
      // receiver: selectedAddress?.username,
      // phone: selectedAddress?.phone,
      // address: `${selectedAddress?.mainAddress} ${selectedAddress?.subAddress}`,
      // message: message,
      // pass: pass,
      scheduledAt: new Date(),
      receiptUuid: `${params.receiptUuid}`,
      pgOption: pgOptionInput,
    };
    payReceiptHandler(payReceiptInput);
  };

  // --- 비밀번호 , 배송메시지 변경 ---
  const onAddressCompleted = async (data: UpAddressMutation) => {
    const {
      upAddress: { ok, err },
    } = data;
    if (ok) {
    } else {
      alert(err);
    }
  };

  const [upAddress] = useUpAddress(onAddressCompleted);

  const paymentHandler = () => {
    if (payReceiptLoading) return;
    if (!data?.getReceipt.receipt?.address) {
      alert("배송지를 선택해주세요");
      return;
    }
    if (
      state &&
      data?.getReceipt.receipt?.howToReceive === HowToReceive.Delivery
    ) {
      const upAddressInput: UpAddressInput = {
        upAddressId: Number(state),
        message: message === "직접 입력하기" ? messageField : message,
        pass: pass,
      };
      upAddress({ variables: { input: upAddressInput } });
    }

    IMP?.init(IMP_ID);
    if (
      data?.getReceipt.receipt?.basketItems !== null &&
      data?.getReceipt.receipt?.basketItems !== undefined
    ) {
      const amount = getAmount(
        data?.getReceipt.receipt?.basketItems as IGetReceiptBasketItem[]
      );
      // const amount = 100;
      const m_redirect_url = `${base}/mobileGetData/${params.receiptUuid}`;
      const payData: RequestPayParams = {
        ...payParams,
        name: `${data.getReceipt.receipt.basketItems[0].good?.title}`,
        pay_method: "card",
        m_redirect_url,
        merchant_uid: `RID${moment().format(`YYMMDDHHmmssSSS`)}`,
        amount,
        buyer_tel: String(me?.me.phone),
        buyer_email: `${me?.me.username}`,
        buyer_name: `${me?.me.name}`,
        buyer_addr: `${data.getReceipt.receipt}`,
      };
      const payReceiptAddressInput: UpReceiptAddressInput = {
        receiptUuid: `${params.receiptUuid}`,
        address:
          data.getReceipt.receipt.howToReceive === HowToReceive.PickUp
            ? `닐리포트 - ${data.getReceipt.receipt.port?.name}`
            : data.getReceipt.receipt.address,
        // receiver: selectedAddress?.username,
        // phone: selectedAddress?.phone,
        pass: pass,
        message: message === "직접 입력하기" ? messageField : message,
      };
      upReceiptAddressHandler(payReceiptAddressInput);
      // IMP?.request_pay(payData, onPayment);
      ////////////
      if (me?.me.type == UserType.Test) {
        const payReceiptInput: PayReceiptInput = {
          status: "paid",
          paid_amount: getAmount(
            data?.getReceipt.receipt?.basketItems as IGetReceiptBasketItem[]
          ),
          paid_at: null,
          // receiver: me.me.name,
          // phone: me.me.phone,
          address:
            data.getReceipt.receipt.howToReceive === HowToReceive.PickUp
              ? `닐리포트 - ${data.getReceipt.receipt.port?.name}`
              : data.getReceipt.receipt.address,
          // message: message,
          // pass: pass,
          scheduledAt: new Date(),
          receiptUuid: `${params.receiptUuid}`,
          pgOption: null,
        };
        payReceiptHandler(payReceiptInput);
      } else {
        IMP?.request_pay(payData, onPayment);
      }
      /////////////////////////
    } else {
      alert("잘못된 접근입니다 구매할 수 없습니다");
    }
  };

  const messageHandler = (e: any) => {
    setMessageField(e.target.value);
  };
  const passHandler = (e: any) => {
    setPass(e.target.value);
  };
  useEffect(() => {
    pageIndicator("주문/결제");
    const receiptUuid = params.receiptUuid ? params.receiptUuid : "";
    if (receiptUuid === "") alert("잘못된 접근입니다");
    getReceiptHandler(receiptUuid);
    // listAddressHandler();
  }, []);
  return (
    <div className="wrap h-full overflow-auto removeScroll">
      <main className="max-w-screen-break mx-auto min-h-full pb-20 bg-[#F3F7FF]">
        <TopBar arrow={true} heading={true} />
        <section
          id="pay"
          className="p-4 border-t-[0.5px] border-t-btnLightLine flex flex-wrap flex-col gap-y-4 "
        >
          <div className="listGood">
            <div className="flex h-[40px] px-4 py-[7.25px] bg-[#fff] content-center rounded-t-2xl border-x border-t border-b-[0.5px] border-btnLightLine ">
              <div className="flex flex-wrap flex-1 gap-x-1 h-full">
                <span className="font-bold flex-1 px-1 py-[2px] text-btn">
                  주문상품
                </span>
              </div>
            </div>
            <div className="orderBox space-y-4 p-4 border-b border-x rounded-b-2xl bg-[#fff] border-btnLightLine">
              {data?.getReceipt.ok &&
                data.getReceipt.receipt?.basketItems?.map(
                  (basketItem, index) => (
                    <div key={`receipt-basket-item-${index}`}>
                      <div className="basketCard">
                        <div className="cardTop flex gap-x-2 w-full">
                          <div className="flex-1 flex gap-x-4">
                            <img
                              src={`${
                                basketItem.good?.goodPosters?.find(
                                  (goodPoster) => goodPoster.index === 1
                                )?.upload?.url
                              }`}
                              alt="이미지"
                              className="w-[72px] h-[72px] rounded-lg border border-btnLightLine"
                            />
                            <div className=" flex-1 flex flex-col ">
                              <div className="w-full py-2 px-1 flex-1">
                                <p className="text-darkFont text-smallBtn ellipsis2 overflow-hidden">
                                  {`${basketItem.good?.title}-${basketItem.goodOption?.name}`}
                                </p>
                              </div>
                              <div className="flex flex-wrap px-2 gap-y-2 justify-between content-center">
                                <div className="flex flex-wrap gap-x-2 py-1">
                                  <span className="py-[3px] text-Xsmall text-grayFont ">
                                    수량
                                  </span>
                                  <span className="py-[1px] px-4 text-smallBtn font-bold font-darkFont">
                                    {Number(basketItem.quantity)}
                                  </span>
                                </div>
                                {basketItem.good.price && (
                                  <span className="text-darkFont font-bold text-smallBtn py-[5px] ">
                                    {basketItem.goodOption?.price
                                      ? basketItem.goodOption.discountRate
                                        ? (
                                            getDiscountedPrice(
                                              basketItem.goodOption.price,
                                              Number(
                                                basketItem.goodOption
                                                  .discountRate
                                              )
                                            ) * basketItem.quantity
                                          ).toLocaleString()
                                        : (
                                            getDiscountedPrice(
                                              basketItem.goodOption.price,
                                              Number(
                                                basketItem.goodOption
                                                  .discountRate
                                              )
                                            ) * basketItem.quantity
                                          ).toLocaleString()
                                      : (
                                          getDiscountedPrice(
                                            basketItem.good.price,
                                            Number(basketItem.good.discountRate)
                                          ) * basketItem.quantity
                                        ).toLocaleString()}
                                    원
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>

            {/* <p className="mt-2 px-4 font-bold py-1 text-standard break:text-large text-darkFont">
              주문상품
            </p>
            {data?.getReceipt.ok &&
              data.getReceipt.receipt?.basketItems?.map((basketItem, index) => (
                <div className="pt-4" key={`receipt-basket-item-${index}`}>
                  <div className="basketCard">
                    <div className="cardTop flex gap-x-2 w-full">
                      <div className="flex-1 flex">
                        <img
                          src={`${
                            basketItem.good?.goodPosters?.find(
                              (goodPoster) => goodPoster.index === 1
                            )?.upload?.url
                          }`}
                          alt="이미지"
                          className="w-[72px] h-[72px] rounded-lg border border-btnLightLine"
                        />
                        <div className="px-2 flex-1">
                          <div className="w-full py-1">
                            <p className="text-darkFont h-9 text-small break:text-standardall ellipsis2 overflow-hidden">
                              {`${basketItem.good?.title}`}
                            </p>
                          </div>
                          <div className="h-7 flex flex-wrap gap-y-2 justify-between content-center">
                            <span className="text-small break:text-standardall text-hintFont">
                              {basketItem.goodOption
                                ? `${basketItem.goodOption.name}`
                                : "기본 배송"}
                            </span>
                            <div className="flex flex-wrap content-center w-32">
                              <button
                                onClick={() => {
                                  upBasketItemHandlerMinus(
                                    basketItem.id,
                                    Number(basketItem.quantity)
                                  );
                                }}
                                className="w-8 h-full"
                              >
                                <img
                                  src={minus}
                                  alt="수량 하나 빼기"
                                  className="w-4 mx-auto"
                                />
                              </button>
                              <div className="flex-1 h-full flex flex-wrap content-center justify-center">
                                <input
                                  type="number"
                                  value={Number(basketItem.quantity)}
                                  disabled
                                  className="text-center h-[18px] w-12 bg-white rounded-full border border-btnLightLine text-small break:text-standardall "
                                />
                              </div>
                              <button
                                onClick={() => {
                                  upBasketItemHandlerPlus(
                                    basketItem.id,
                                    Number(basketItem.quantity)
                                  );
                                }}
                                className="w-8 h-full"
                              >
                                <img
                                  src={plus}
                                  alt="수량 하나 더하기"
                                  className="w-4 mx-auto"
                                />
                              </button>
                            </div>
                            {basketItem.good.price && (
                              <span className="text-darkFont font-bold text-small break:text-standardall">
                                {(
                                  getDiscountedPrice(
                                    basketItem.good.price,
                                    Number(basketItem.good.discountRate)
                                  ) * basketItem.quantity
                                ).toLocaleString()}
                                원
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))} */}
          </div>
          {data?.getReceipt.receipt?.address && (
            <div className="listAddress">
              <div className="flex h-[40px] px-4 py-[7.25px] bg-[#fff] content-center rounded-t-2xl border-x border-t border-b-[0.5px] border-btnLightLine ">
                <div className="flex flex-wrap flex-1 gap-x-1 h-full">
                  <span className="font-bold flex-1 px-1 py-[2px] text-btn">
                    배송지
                  </span>
                  <button
                    onClick={() =>
                      navigate(`/selectReceiveWays/${params.receiptUuid}`)
                    }
                    className="py-1 px-[8.5px] text-smallBtn text-grayFont border border-btnLightLine rounded-lg"
                  >
                    변경
                  </button>
                </div>
              </div>
              {data?.getReceipt.receipt?.howToReceive ===
              HowToReceive.Delivery ? (
                <div className="orderBox space-y-1 p-4 border-b border-x rounded-b-2xl bg-[#fff] border-btnLightLine">
                  <p className="flex flex-wrap gap-x-4  font-bold">
                    <span className="px-1 py-[2px] text-btn  text-darkFont ">
                      {data?.getReceipt.receipt?.receiver}
                    </span>
                    <span className="w-[1px] my-[6px] bg-hintFont"></span>
                    <span className="px-1 py-[2px] text-btn text-darkFont ">{`${data?.getReceipt.receipt?.phone}`}</span>
                  </p>
                  <p className="py-[2px] pl-1 text-btn">
                    {data?.getReceipt.receipt?.address}
                  </p>
                  <p className="p-1 text-smallBtn text-grayFont">배송메세지</p>
                  <select
                    onChange={(e) => {
                      var value = e.target.value;
                      if (value === "직접 입력하기") {
                        setTextFieldOpen(true);
                        setMessage(value);
                      } else {
                        setMessage(value);
                        setTextFieldOpen(false);
                      }
                    }}
                    // onChange={messageHandler}
                    className="text-standard  text-darkFont w-full rounded-lg h-10 py-2 px-3 border border-btnDarkLine focus:outline-mainIcon"
                  >
                    <option className="text-standard  text-hintFont" value="">
                      배송 요청사항을 선택해주세요
                    </option>
                    {messageText.map((obj) => (
                      <option
                        key={obj.text}
                        selected={message === obj.value}
                        className="text-darkFont text-standard "
                        value={obj.value}
                      >
                        {obj.text}
                      </option>
                    ))}
                  </select>
                  <div className="pt-2">
                    <input
                      type="text"
                      value={messageField ? messageField : ""}
                      onChange={messageHandler}
                      className={`${
                        !textFieldOpen && "hidden"
                      } text-standard break:text-large text-darkFont w-full rounded-lg h-10 py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon`}
                    />
                  </div>
                  <div className="flex flex-wrap gap-x-1">
                    <span className="p-1 text-smallBtn text-grayFont">
                      공동현관 비밀번호 안내
                    </span>
                    <div className="p-1">
                      <img src={help_circle} className="w-4 h-4"></img>
                    </div>
                  </div>

                  <input
                    type="text"
                    placeholder="#1234*,경비 열쇠1234 (최대30자)"
                    value={pass}
                    onChange={passHandler}
                    className="text-standard text-darkFont w-full rounded-lg h-10 py-2 px-3  border border-btnDarkLine focus:outline-mainIcon"
                  />
                </div>
              ) : (
                <div className="orderBox space-y-1 p-4 border-b border-x rounded-b-2xl bg-[#fff] border-btnLightLine">
                  <div className="flex flex-wrap gap-x-2 content-center ">
                    <div className="flex flex-wrap content-center ">
                      <div className="text-Xsmall h-5 leading-5 px-2 rounded-full bg-mainIcon text-white ">
                        닐리포트
                      </div>
                    </div>
                    <p className="font-bold text-sm leading-8 flex-1">
                      {data?.getReceipt.receipt?.port?.name}
                    </p>
                    <div className="flex flex-wrap content-center ">
                      <div className="font-bold text-xs h-6 leading-6 px-4 rounded-full bg-block2 text-mainIcon ">
                        직접픽업
                      </div>
                    </div>
                  </div>
                  <div className="border rounded-lg py-1">
                    <p className="py-1 text-sm flex flex-wrap gap-x-2">
                      <span className="min-w-[60px] text-right px-1">주소</span>
                      <span className="px-1 flex-1">
                        {data?.getReceipt.receipt?.port?.address}
                      </span>
                    </p>
                    <p className="py-1 text-sm flex flex-wrap gap-x-2">
                      <span className="min-w-[60px] text-right px-1">
                        연락처
                      </span>
                      <span className="px-1 flex-1">
                        {data?.getReceipt.receipt?.port?.phone}
                      </span>
                    </p>
                    <p className="py-1 text-sm flex flex-wrap gap-x-2">
                      <span className="min-w-[60px] text-right px-1">
                        이용시간
                      </span>
                      <span className="px-1 flex-1">
                        {data?.getReceipt.receipt?.port?.usageTime}
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          {!data?.getReceipt.receipt?.address && (
            <div className="listAddress">
              <div className="flex h-[40px] px-4 py-[7.25px] bg-[#fff] content-center rounded-t-2xl border-x border-t border-b-[0.5px] border-btnLightLine ">
                <div className="flex flex-wrap flex-1 gap-x-1 h-full">
                  <span className="font-bold flex-1 px-1 py-[2px] text-btn">
                    배송지
                  </span>
                </div>
              </div>
              <div className="orderBox space-y-1 p-4 border-b border-x rounded-b-2xl bg-[#fff] border-btnLightLine">
                <div className="px-4 text-small break:text-standardall text-center">
                  <p className="text-hintFont font-bold">배송지가 없습니다.</p>
                  <p className="pt-4 text-mainIcon underline">
                    <Link to="/address/enroll">등록하러가기</Link>
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!지우지 말것!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
          {/* <div className="listResult">
            <div className="flex h-[40px] px-4 py-[7.25px] bg-[#fff] content-center rounded-t-2xl border-x border-t border-b-[0.5px] border-btnLightLine ">
              <div className="flex flex-wrap flex-1 gap-x-1 h-full">
                <span className="font-bold flex-1 px-1 py-[2px] text-btn">
                  결제수단
                </span>
              </div>
            </div>
            <div className="ResultBox space-y-1 p-4 border-b border-x rounded-b-2xl bg-[#fff] border-btnLightLine">
              <div className="flex flex-col gap-y-4">
                <div className="flex flex-wrap h-12 rounded-lg ">
                  <button
                    type="button"
                    className={`flex-1 h-full text-white bg-mainIcon font-bold rounded-lg border border-mainIcon`}
                  >
                    일반결제
                  </button>
                </div>
                <div className="flex flex-wrap h-12 rounded-lg ">
                  <button
                    type="button"
                    className={`flex-1 h-full text-darkFont bg-[#fff] font-bold rounded-lg border border-toast`}
                  >
                    간편결제
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          <div className="payment">
            <div className="flex h-[48px] px-4 py-1 bg-[#fff] content-center rounded-t-2xl border-x border-t border-b-[0.5px] border-btnLightLine ">
              <div className="flex flex-wrap flex-1 h-full">
                <span className="font-bold flex-auto px-1 py-[9px] text-btn">
                  최종 결제 금액
                </span>

                {data?.getReceipt.receipt?.basketItems && (
                  <span className="font-bold  flex-auto px-1 py-[7px] text-[20px] leading-6 text-right">
                    {`${getAmount(
                      data?.getReceipt.receipt
                        ?.basketItems as IGetReceiptBasketItem[]
                    ).toLocaleString()}원`}
                  </span>
                )}
              </div>
            </div>
            <div className="payBox flex flex-col space-y-1 p-4 border-b border-x rounded-b-2xl bg-[#fff] border-btnLightLine">
              <p className="flex flex-wrap justify-between py-1 text-btn">
                <span className="py-[2px] px-1">총 상품금액</span>
                {data?.getReceipt.receipt?.basketItems && (
                  <span className="py-[2px] px-1">{`${getTotalAMount(
                    data?.getReceipt.receipt
                      ?.basketItems as IGetReceiptBasketItem[]
                  ).toLocaleString()}원`}</span>
                )}
              </p>
              <p className="flex flex-wrap justify-between py-1 text-btn">
                <span className="py-[2px] px-1">총 할인금액</span>
                {data?.getReceipt.receipt?.basketItems && (
                  <span className="py-[2px] px-1">
                    {`- `}
                    {`${getDiscountMoney(
                      data?.getReceipt.receipt
                        ?.basketItems as IGetReceiptBasketItem[]
                    ).toLocaleString()}원`}
                  </span>
                )}
              </p>
              <p className="flex flex-wrap justify-between py-1  text-btn">
                <span className="py-[2px] px-1">총 배송비</span>
                {data?.getReceipt.receipt?.basketItems && (
                  // <span className="py-[2px] px-1">{`${
                  //   getDelivery(data?.getReceipt.receipt?.basketItems as IGetReceiptBasketItem[]) < 30000 ? "+3000" : "+0"
                  // } 원`}</span>
                  <span className="py-[2px] px-1">{`${
                    getDelivery(
                      data?.getReceipt.receipt
                        ?.basketItems as IGetReceiptBasketItem[]
                    ) < 30000
                      ? "0"
                      : "+0"
                  } 원`}</span>
                )}
                {/* <span className="py-[2px] px-1">+ 0원</span> */}
              </p>
            </div>
          </div>
          {/* <div className="listResult pt-4 px-4">
            <p className="px-4 font-bold py-1 text-standard break:text-large text-darkFont">
              결제수단
            </p>
            <div className=" pt-4">
              <div className="flex flex-wrap h-12 rounded-lg ">
                <button
                  type="button"
                  className={`flex-1 h-full text-white bg-mainIcon font-bold rounded-lg border border-mainIcon`}
                >
                  신용카드
                </button>
                <button
                  onClick={() => alert("준비중입니다.")}
                  className={`flex-1 h-full text-toast font-bold border border-btnLightLine`}
                >
                  카카오페이
                </button>
                <button
                  onClick={() => alert("준비중입니다.")}
                  className={`flex-1 h-full text-toast font-bold rounded-r-lg border-r border-t border-b border-btnLightLine `}
                >
                  네이버페이
                </button>
              </div>
            </div>
            <div className="pt-4">
              <select
                disabled
                className="text-standard break:text-large text-darkFont w-full rounded-lg py-2 px-4 font-bold border border-btnDarkLine focus:outline-mainIcon"
              >
                <option value="" className="text-hintFont">
                  카드선택
                </option>
              </select>
            </div>
            <div className="pt-4">
              <div className="text-center flex flex-col flex-wrap justify-center content-center w-full">
                <div className="py-1">
                  <img
                  src={data?.good.goodPosters[0].upload.url}
                  className="min-w-[100px] max-w-[200px] aspect-square mx-auto"
                />
                </div>
                <div className="pt-2 space-y-1 w-full max-w-screen-fit">
                  <p className="flex flex-wrap justify-between py-1">
                    <span className="text-small break:text-standardall">
                      총 상품금액
                    </span>
                    {data?.getReceipt.receipt?.basketItems && (
                      <span className="text-small break:text-standardall">
                        {`${getAmount(
                          data?.getReceipt.receipt?.basketItems
                        ).toLocaleString()}원`}
                      </span>
                    )}
                  </p>
                  <p className="flex flex-wrap justify-between py-1">
                    <span className="text-small break:text-standardall">
                      총 배송비
                    </span>
                    <span className="text-small break:text-standardall">
                      + 0원
                    </span>
                  </p>
                  <div className="py-1">
                    <div className="bar w-full h-[1px] bg-btnLightLine"></div>
                  </div>
                  <p className="flex flex-wrap justify-between py-1">
                    <span className="text-small break:text-standardall font-bold">
                      최종 결제 금액
                    </span>
                    {data?.getReceipt.receipt?.basketItems && (
                      <span className="text-small break:text-standardall font-bold">
                        {`${getAmount(
                          data?.getReceipt.receipt?.basketItems
                        ).toLocaleString()}원`}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      </main>
      <div className="absolute left-0 bottom-0 w-full">
        <div className="w-full pb-[15px] px-4 ">
          <button
            onClick={paymentHandler}
            className=" h-10 block w-full max-w-screen-fit text-[#fff] bg-mainIcon text-[20px] leading-6 font-bold  mx-auto rounded-[20px]"
          >
            결제하기
          </button>
        </div>
      </div>
    </div>
  );
};
