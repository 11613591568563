import { gql, useLazyQuery } from "@apollo/client";
import { GetInquiryQuery } from "../gql/graphql";

const GET_INQUIRY_QUERY = gql`
  query getInquiry($input: GetInquiryInput!) {
    getInquiry(input: $input) {
      ok
      err
      inquiry {
        id
        title
        note
        status
        type
        author {
          id
          name
          username
        }
        comments {
          id
          createdAt
          note
        }
        inquiryPosters {
          id
          index
          upload {
            url
          }
        }
      }
    }
  }
`;

export const useGetInquiry = (onCompleted?: (data: GetInquiryQuery) => void) => {
  return useLazyQuery<GetInquiryQuery>(GET_INQUIRY_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
