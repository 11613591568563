import { authTokenVar, isLoggedInVar } from "./apollo";
import moment from "moment";

export const LOCALSTORAGE_TOKEN = "mfc-jwt";

export const getToken = () => localStorage.getItem(LOCALSTORAGE_TOKEN);

export const setToken = (token: string) => {
  localStorage.setItem(LOCALSTORAGE_TOKEN, token);
};

export const setPopupToken = (eventName: string, token: string) => {
  localStorage.setItem(eventName, token);
};
export const expirePopupToken = (eventName: string) => {
  localStorage.removeItem(eventName);
};

export const setHomeEventToken = (eventName: string) => {
  setPopupToken(eventName, `${new Date()}`);
};
export const expireHomeEventToken = (eventName: string) => {
  if (localStorage.getItem(eventName)) {
    var thatDay = moment(new Date(`${localStorage.getItem(eventName)}`)).format(
      "YYYY-MM-DD"
    );
    var today = moment(new Date()).format("YYYY-MM-DD");
    if (thatDay === today) {
      return;
    } else {
      expirePopupToken(eventName);
    }
  }
};

export const logIn = (token: string) => {
  setToken(token);
  authTokenVar(token);
  window.location.href = "/";
};

export const removeToken = () => {
  localStorage.removeItem(LOCALSTORAGE_TOKEN);
};

export const logOut = () => {
  removeToken();
  authTokenVar("");
  window.location.href = "/";
};
