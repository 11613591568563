import { ApolloError, gql, useMutation } from "@apollo/client";
import { EditOrderStatusMutation } from "../gql/graphql";

const EDIT_INQUIRY_MUTATION = gql`
  mutation editOrderStatus($input: EditOrderStatusInput!) {
    editOrderStatus(input: $input) {
      ok
      err
    }
  }
`;

export const useEditOrderStatus = (
  onCompleted?: (data: EditOrderStatusMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditOrderStatusMutation>(EDIT_INQUIRY_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
