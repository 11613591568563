import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css";
import { useGetBannerMainActive } from "../hooks/useGetBannerMainActive";
import { Dialog, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMe } from "../hooks/useME";
import { useNavigate } from "react-router-dom";
import { AlertComponent } from "./alert";
import back from "../assets/icons/chevron-left.png";
import {
  GetBannerMainActiveInput,
  GetBannerMainActiveQuery,
} from "../gql/graphql";

import { BannerMainType } from "../gql/graphql";
export interface BannerMain {
  serviceName: string | null | undefined;
}

export const Banner: React.FC<BannerMain> = ({ serviceName }) => {
  var useOnce = false;
  const [alertOpen, setAlertOpen] = useState(false);
  const closeAlert = () => {
    setAlertOpen(false);
  };
  const onGetComplete = (data: GetBannerMainActiveQuery) => {
    const {
      getBannerMainActive: { ok, err },
    } = data;
  };
  const [getBannerMainActive, { data, loading }] =
    useGetBannerMainActive(onGetComplete);
  const getBannerMainActiveHandler = (serviceName: string) => {
    const getBannerMainActiveInput: GetBannerMainActiveInput = {
      serviceName,
    };
    getBannerMainActive({ variables: { input: getBannerMainActiveInput } });
  };
  const { data: me } = useMe();
  const navigate = useNavigate();
  const [loginAlert, setLoginAlert] = React.useState(false);
  const linkHandler = (link: string) => {
    if (me?.me.id && link == "/login") {
      setAlertOpen(true);
      setLoginAlert(true);
    } else {
      navigate(link);
    }
  };

  useEffect(() => {
    if (useOnce === false) {
      if (serviceName) getBannerMainActiveHandler(serviceName);
      useOnce = true;
    }
  }, [serviceName]);
  return (
    <div className="bannerBox w-full aspect-square">
      <div className="innerBanner relative">
        <Swiper
          modules={[Navigation, Autoplay, Pagination]}
          slidesPerView={1}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            type: "fraction",
          }}
          loop={true}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          className="relative"
          id="banner_swiper"
        >
          <button className="swiper-button-prev w-8 h-8 absolute left-0 bottom-44 transform -translate-y-1/2 hidden lg:flex flex-wrap content-center justify-center  z-10">
            <img src={back} alt="뒤로가기" />
          </button>
          <button className="swiper-button-next w-8 h-8 absolute right-0 bottom-44 transform -translate-y-1/2 hidden lg:flex flex-wrap content-center justify-center  z-10">
            <img
              src={back}
              alt="앞으로 가기"
              className="transform rotate-180"
            />
          </button>
          <div className="swiper-pagination"></div>
          {data?.getBannerMainActive.bannerMain
            ?.filter(
              (banner: any) =>
                banner.mainType === BannerMainType.Image && banner.upload?.uuid
            )
            .map((bannerMain: any) => (
              <SwiperSlide
                key={`main-swiper-${bannerMain.id}`}
                className="w-full relative overflow-hidden aspect-square"
              >
                <div className="absolute left-0 top-0 w-full h-full ">
                  {/* <a href={`${bannerMain.link ? bannerMain.link : ``}`}> */}
                  <a
                    onClick={() => {
                      if (bannerMain.event?.isActive) {
                        navigate(`/event/${bannerMain.event.id}`);
                      } else if (bannerMain.link) {
                        linkHandler(bannerMain.link);
                      }
                    }}
                    // onClick={() => {
                    //   bannerMain.link && linkHandler(bannerMain.link);
                    // }}
                  >
                    {/* {bannerMain.mainType === BannerMainType.Video &&
                    bannerMain.link && (
                      <iframe
                        src={bannerMain.link ? bannerMain.link : ""}
                        title="YouTub= GoodDetailType.Ve video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className="w-full aspect-square"
                      ></iframe>
                    )} */}
                    {bannerMain.mainType === BannerMainType.Image && (
                      <>
                        <img
                          src={`${bannerMain.upload?.url}`}
                          alt={`${bannerMain.alt} 배너 이미지`}
                          className="w-full h-full object-cover "
                        />
                        {/* <div className="absolute left-0 bottom-0 h-[30%]  text-[#ffffff] w-full  banner-gradient "></div> */}
                        <div className="absolute left-0 bottom-0 ">
                          <p className=" font-bold text-[20px] pt-3 pl-6 text-shadow pb-3">
                            {bannerMain.title}
                          </p>

                          <p className="pl-6 pr-32 w-full text-[#fff] h-12 items-center flex">
                            {bannerMain.note}
                          </p>
                          <div className="flex flex-wrap py-3 pl-6 gap-x-2 ">
                            {bannerMain.isFree && (
                              <button
                                className={`bannerIcon bg-[#00B0EB] text-white`}
                              >
                                무료배송
                              </button>
                            )}

                            {bannerMain.isRes && (
                              <button
                                className={`bannerIcon bg-[#FFE400] text-[#363A3D] `}
                              >
                                예약배송
                              </button>
                            )}

                            {bannerMain.isEvent && (
                              <button
                                className={`bannerIcon bg-[#fff] text-mainIcon `}
                              >
                                이벤트
                              </button>
                            )}

                            {bannerMain.isOnePlusOne && (
                              <button
                                className={`bannerIcon bg-[#00B0EB] text-white `}
                              >
                                1+1
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </a>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        {/* <Modal
          open={loginAlert}
          onClose={() => {
            setLoginAlert(false);
          }}
        >
          <div className="w-[304px]  bg-white absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-2xl">
            <div className="flex flex-wrap">
              <h1 className="w-full text-center py-6 text-[#00B0EB] text-[16px] leading-5 font-bold  ">
                회원가입 안내
              </h1>
              <div className="w-full py-4 px-7">
                <p className="w-full text-center p-4 text-[16px] leading-5 font-bold">
                  이미 로그인 된 계정입니다.
                </p>
              </div>
              <button
                className="w-full flex-1 py-4  text-[#00B0EB] text-[15px] leading-5 font-bold border-t-[1px] border-[#DCDCE7]"
                onClick={() => setLoginAlert(false)}
              >
                확인
              </button>
            </div>
          </div>
        </Modal> */}
        <Dialog
          open={alertOpen}
          onClose={() => {
            setAlertOpen(false);
          }}
          className="relative"
        >
          <AlertComponent
            title={`회원가입 안내`}
            desc={`이미 로그인 된 계정입니다.`}
            closeFunction={closeAlert}
          ></AlertComponent>
        </Dialog>
      </div>
    </div>
  );
};
