import { gql, useLazyQuery } from "@apollo/client";
import { ListPortPickupQuery } from "../gql/graphql";

const LIST_PICK_UP_QUERY = gql`
  query listPortPickup {
    listPortPickup {
      ok
      err
      ports {
        id
        name
        address
        usageTime
        phone
        author {
          id
          name
          phone
        }
        regionKeyWord
      }
    }
  }
`;

export const useListPortPickup = (onCompleted?: (data: ListPortPickupQuery) => void) => {
  return useLazyQuery<ListPortPickupQuery>(LIST_PICK_UP_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
