import { ApolloError, gql, useMutation } from "@apollo/client";
import { CrInquiryPosterMutation, CrInquiryPosterMutationVariables } from "../gql/graphql";

const CR_INQUIRY_POSETER_MUTATION = gql`
  mutation crInquiryPoster($input: CrInquiryPosterInput!) {
    crInquiryPoster(input: $input) {
      ok
      err
      inquiryPoster {
        id
        index
        upload {
          url
          uuid
        }
      }
    }
  }
`;

export const useCrInquiryPoster = (onCompleted?: (data: CrInquiryPosterMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<CrInquiryPosterMutation, CrInquiryPosterMutationVariables>(CR_INQUIRY_POSETER_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
