import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo";
import { GoogleOAuthProvider } from "@react-oauth/google";

declare global {
  // interface Window {
  //   webkit?: {
  //     messageHandlers: {
  //       cordova_iab: {
  //         postMessage: (parameter: string) => void;
  //       };
  //     };
  //   };
  // }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
    <React.StrictMode>
      <HelmetProvider>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </HelmetProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
