import { gql, useLazyQuery } from "@apollo/client";
import { GetLinkServiceQuery } from "../gql/graphql";

const GET_LINKSERVICE_QUERY = gql`
  query getLinkService($input: GetLinkServiceInput!) {
    getLinkService(input: $input) {
      ok
      err
      linkService {
        id
        name
        minAmount
      }
    }
  }
`;

export const useGetLinkService = (
  onCompleted?: (data: GetLinkServiceQuery) => void
) => {
  return useLazyQuery<GetLinkServiceQuery>(GET_LINKSERVICE_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
