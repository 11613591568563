import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pageIndicator } from "../apollo";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import logo from "../assets/icons/logo_without_cloud.png";
import { useListInquiryComplete } from "../hooks/useListInquiryComplete";
import { InquiryStatus, ListInquiryCompleteOutput, ListInquiryCompleteQuery } from "../gql/graphql";
import { IInquiryComplete } from "../constants/types";

type ListInquiryFull = IInquiryComplete & { full?: boolean };

export const SubListInquiryComplete = () => {
  const navigate = useNavigate();
  const [listInquiryState, setListInquiryState] = useState<ListInquiryFull[]>();

  const onListInquiryComplete = (data: ListInquiryCompleteQuery) => {
    const {
      listInquiryComplete: { ok, err, inquiries },
    } = data;
    if (ok && inquiries) {
      setListInquiryState(inquiries as ListInquiryFull[]);
    } else {
      console.log(err);
    }
  };

  const [listInquiryComplete, { data, loading, refetch }] = useListInquiryComplete(onListInquiryComplete);
  const [openCollapse, setOpenCollapse] = useState<number>(-1);
  const [page, setPage] = useState<number>(1);
  const pageHandler = (event: any, value: any) => {
    setPage(value);
  };
  const openCollapseHandler = (index: number) => {
    if (index === openCollapse) {
      setOpenCollapse(-1);
    } else {
      setOpenCollapse(index);
    }
  };

  const inquiryShowAllHandler = (index: number) => {
    if (listInquiryState) {
      const stateList = [...listInquiryState];
      const indexPart = stateList[index];
      if (indexPart.full) {
        indexPart.full = false;
      } else {
        indexPart.full = true;
      }
      setListInquiryState(stateList);
    }
  };

  useEffect(() => {
    pageIndicator("나의 문의내역");
    listInquiryComplete({
      variables: { input: { page, status: InquiryStatus.Complete } },
    });
  }, []);

  return (
    <div
      className={`w-full h-full flex flex-wrap flex-col gap-y-4 ${
        data?.listInquiryComplete?.inquiries && data?.listInquiryComplete?.inquiries?.length > 0 && "pb-20"
      }`}
    >
      {!loading &&
        listInquiryState?.map((inquiry: any, inquiryIndex: number) => (
          <div className="bg-white border rounded-2xl" key={inquiry.title + inquiry.author.name + inquiry.id}>
            <div className="inquiry  p-4">
              <div className="text-small leading-4 flex flex-wrap content-center justify-between">
                <p className="flex flex-wrap content-center justify-center">
                  {inquiry.createdAt && <span className="font-bold">{inquiry.title}</span>}
                </p>
                <div className="text-xs rounded-lg w-12 h-6 bg-white text-mainIcon border border-btnLightLine flex flex-wrap content-center justify-center">
                  완료
                </div>
              </div>
              <div className="flex flex-wrap gap-x-1 pt-1">
                <p className="text-grayFont text-[10px] leading-3 flex flex-wrap content-center px-1">{`${moment(inquiry.createdAt).format(
                  "YYYY.MM.DD"
                )}`}</p>
              </div>
              <div className="inquiryBox space-y-4">
                <div>
                  <div className={`pt-1 grid  gap-x-2 overflow-hidden ${inquiry.full ? "grid-cols-1" : "grid-cols-2"}`}>
                    {inquiry.inquiryPosters &&
                      inquiry.inquiryPosters.map((poster: any, index: number, posterArray: any) => (
                        <div key={`inquiry-poster-${index}`} className={`p-1 relative ${index > 1 && !inquiry.full && "hidden"}`}>
                          <img
                            src={`${poster.upload?.url}`}
                            alt={`${inquiry.author} 리뷰`}
                            className={`rounded-lg ${inquiry.full ? "" : "aspect-[140/100]"}`}
                          />
                          {posterArray.length > 2 && index == 1 && !inquiry.full && (
                            <div
                              onClick={() => {
                                inquiryShowAllHandler(inquiryIndex);
                              }}
                              className="p-1 w-full h-full absolute left-0 top-0 cursor-pointer "
                            >
                              <div className="text-white text-[28px] rounded-lg flex-wrap flex content-center justify-center bg-black bg-opacity-30 w-full h-full">
                                +{posterArray.length - 2}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                  <div className="mt-1">
                    <p className="py-[2px] px-1 text-darkFont text-btn">문의내용 : </p>
                  </div>
                  <div className="mt-1 py-2">
                    <p className={`px-1 text-small overflow-hidden whitespace-pre-wrap ${!inquiry.full && "ellipsis2 "}`}>{`${inquiry.note}`}</p>
                  </div>
                  {!inquiry.full && (
                    <div className="mt-1">
                      <span
                        onClick={() => {
                          inquiryShowAllHandler(inquiryIndex);
                        }}
                        className="cursor-pointer text-xs px-2 font-bold underline text-darkFont"
                      >
                        자세히 보기
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {inquiry.full && (
              <div className="p-4 bg-[#E8E8F4] rounded-b-xl">
                <div className="flex flex-col gap-y-2">
                  <p className="py-[2px] px-1 text-darkFont text-btn">답변 : </p>
                  <p className={`p-4 bg-[#fff] rounded-lg text-small overflow-hidden whitespace-pre-wrap ${!inquiry.full && "ellipsis2 "}`}>
                    {inquiry.comments && inquiry.comments[0].note}
                  </p>
                </div>
                <div className="mt-1">
                  <span
                    onClick={() => {
                      inquiryShowAllHandler(inquiryIndex);
                    }}
                    className="cursor-pointer text-xs px-2 font-bold underline text-darkFont"
                  >
                    닫기
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
      {/* {data?.listInquiryComplete.inquiries?.map((inquiry, index) => (
        <div key={inquiry.title + inquiry.author.name + inquiry.id}>
          <div
            onClick={() => {
              openCollapseHandler(index);
            }}
            className={`p-4 border border-btnLightLine bg-white flex flex-col w-full gap-y-2 cursor-pointer ${
              openCollapse === index ? "rounded-t-2xl" : "rounded-2xl"
            }`}
          >
            <div className="gap-x-2 flex flex-wrap content-center break:text-standardall text-darkFont font-bold">
              <p className="p-1 text-xs flex-1">
                {moment(inquiry.createdAt).format("Y.MM.DD")}
              </p>
              {inquiry.status === InquiryStatus.Complete ? (
                <div className="text-xs rounded-lg w-12 h-6 bg-white text-mainIcon border border-btnLightLine flex flex-wrap content-center justify-center">
                  완료
                </div>
              ) : (
                <div className="text-xs rounded-lg w-12 h-6 bg-mainIcon text-white flex flex-wrap content-center justify-center">
                  처리중
                </div>
              )}
            </div>
            <div className="flex flex-wrap content-center px-1 py-2">
              <p className="h-4 text-small cursor-pointer flex-1 text-grayFont flex flex-wrap content-center overflow-hidden ellipsis1">
                {inquiry.title}
              </p>
            </div>
          </div>
          <Collapse in={openCollapse === index}>
            <div className="pt-1">
              <div
                className={`p-4 bg-white border border-btnLightLine rounded-b-2xl flex flex-col gap-y-2`}
              >
                {inquiry.inquiryPosters && (
                  <div className="flex flex-wrap gap-x-1">
                    {inquiry.inquiryPosters.map(
                      (poster: any, posterIndex: number) => (
                        <figure
                          key={`${inquiry.author} - ${posterIndex}`}
                          className="p-1 max-w-[72px] w-1/4"
                        >
                          <img
                            src={`${poster.upload?.url}`}
                            alt={`${inquiry.author} 님의 문의사진 ${posterIndex}`}
                            className="aspect-square w-full object-cover rounded-lg border border-btnLightLine"
                          />
                        </figure>
                      )
                    )}
                  </div>
                )}
                <div className="px-1 py-2 text-small whitespace-pre-wrap">
                  {inquiry.comments && inquiry.comments[0].note}
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      ))} */}

      {/* door end */}
      {data?.listInquiryComplete?.inquiries?.length === 0 && (
        <div className="w-full h-full flex flex-wrap flex-col content-center justify-center">
          <img src={logo} alt="니나노로고" className="px-6" />
          <p className="mt-2 text-sm leading-4 px-1 py-2 text-center text-darkFont">에 궁금한게 있다면 ?</p>
          <button onClick={() => navigate("/mypage/enrollInquiry")} className="w-[200px] mx-auto mt-2 h-10 py-1">
            <span className="text-grayFont font-bold flex flex-wrap content-center justify-center h-full w-full bg-white  border border-[#B5C1D0] rounded-full">
              문의 하기
            </span>
          </button>
        </div>
      )}
      <Stack
        spacing={2}
        className={`flex-wrap content-center pt-8 ${
          data?.listInquiryComplete?.inquiries && data?.listInquiryComplete?.inquiries?.length > 0 ? "flex" : "hidden"
        }`}
      >
        <Pagination
          onChange={pageHandler}
          count={data?.listInquiryComplete?.totalPages ? data?.listInquiryComplete?.totalPages : 1}
          color={`primary`}
        ></Pagination>
      </Stack>
      {data?.listInquiryComplete?.inquiries && data?.listInquiryComplete?.inquiries?.length > 0 && (
        <div className="absolute left-1/2 bottom-20 transform -translate-x-1/2">
          <button
            onClick={() => {
              navigate("/mypage/enrollInquiry");
            }}
            className="sticky left-1/2 bottom-20 transform bg-white text-grayFont w-[200px] h-8 rounded-full text-sm border border-hintFont font-bold"
          >
            문의등록
          </button>
        </div>
      )}
    </div>
  );
};
