import { ApolloError, gql, useMutation } from "@apollo/client";
import { CrAddressMutation, CrAddressMutationVariables } from "../gql/graphql";

const CR_ADDRESS_MUTATION = gql`
  mutation crAddress($input: CrAddressInput!) {
    crAddress(input: $input) {
      ok
      err
      addressId
    }
  }
`;

export const useCrAddress = (onCompleted?: (data: CrAddressMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<CrAddressMutation, CrAddressMutationVariables>(CR_ADDRESS_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
