import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { TopBar } from "../components/topBar";
import logo from "../assets/icons/logo.png";
import TriangleArrow from "../assets/icons/TriangleArrow.svg";
import { GoodsNiliveryIn } from "../components/goodsNilivery";
import { SelectOne } from "../components/selectOne";
import { FootMenu } from "../components/FootMenu";
import { Footer } from "../components/footer";
import { useListGood } from "../hooks/useListGood";
import { ListGoodInput, GoodStatus } from "../gql/graphql";

export const NiliveryItem = () => {
  const [listGood, { data: listgoods, loading }] = useListGood();
  const [page] = useState<number>(1);
  const [search] = useState<string | null>(null);
  const [status] = useState<string | null>(null);
  const [category] = useState<string | null>(null);
  const listGoodOptionHandler = (
    page: number | null,
    search: string | null,
    status: string | null,
    category: string | null
  ) => {
    const input: ListGoodInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      status: GoodStatus.GoodSales,
    };
    listGood({ variables: { input } });
  };
  useEffect(() => {
    pageIndicator("닐리버리 아이템");
    listGoodOptionHandler(page, search, status, category);
  }, []);
  return (
    <div className="overflow-auto h-full removeScroll pb-9">
      <TopBar shop={true} />
      <main className="content pb-4">
        <SelectOne select={1} />
        <div className="max-w-screen-fit mx-auto w-full h-[150px] px-4 mt-5 relative">
          <div className="absolute left-1/2 bottom-1 transform -translate-x-1/2 w-24 h-24 rounded-full bg-[#A9E5FF]"></div>
          <div className="absolute right-[9%] top-[4.5%] w-12 h-12 rounded-full bg-[#A9E5FF]"></div>
          <div className="w-full absolute left-1/2 top-[10%] transform -translate-x-1/2">
            <img src={logo} alt="닐리버리" className="w-1/3 mx-auto" />
          </div>
          <span className="absolute left-1/2 bottom-6  transform -translate-x-1/2">
            <img src={TriangleArrow} alt="아래 화살 표시" />
          </span>
        </div>
        <div className="goods transform -translate-y-4 space-y-6 break:block sm:grid sm:grid-cols-2 sm:gap-x-4 ">
          {!loading &&
            listgoods?.listGood.goods &&
            listgoods?.listGood?.goods.map((obj) => (
              <GoodsNiliveryIn
                key={obj.title + obj.id}
                id={obj.id}
                title={obj.title}
                subTitle={obj.summary}
                image={obj.goodPosters}
                brand={obj.corporation?.name}
                desc={obj.summary}
                classes={obj.title}
                price={obj.price ? obj.price : 1}
                discountRate={obj.discountRate}
              />
            ))}
        </div>
      </main>
      <Footer />
      <div className="absolute left-0 bottom-0 w-full">
        <FootMenu />
      </div>
    </div>
  );
};
