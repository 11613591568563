import { gql, useLazyQuery } from "@apollo/client";
import { GetGoodReviewQuery } from "../gql/graphql";

const GET_GOOD_REVIEW_QUERY = gql`
  query getGoodReview($input: GetGoodReviewInput!) {
    getGoodReview(input: $input) {
      ok
      err
      goodReview {
        id
        createdAt
        note
        rating
        author {
          id
          name
          username
        }
        good {
          corporations {
            name
          }
        }
        order {
          id
          orderReceipt {
            good_poster_url
            good_title
          }
        }
        goodReviewPosters {
          id
          upload {
            url
          }
        }
      }
    }
  }
`;

export const useGetGoodReview = (
  onCompleted?: (data: GetGoodReviewQuery) => void
) => {
  return useLazyQuery<GetGoodReviewQuery>(GET_GOOD_REVIEW_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
