import { useForm } from "react-hook-form";
import { useGetUserEmail } from "../hooks/useGetUserEmail";
import { Slide } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import { GetUserEmailInput, GetUserEmailMutation } from "../gql/graphql";
export const FindMail = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const onCompleted = (data: GetUserEmailMutation) => {
    const {
      getUserEmail: { ok, users, err },
    } = data;
    if (ok) {
    } else {
      alert(err);
    }
  };
  const onInvalid = () => {};
  const onSubmit = () => {
    if (!loading) {
      const { name, phone } = getValues();
      const getUserEmailInput: GetUserEmailInput = { name, phone };
      getUserEmail({
        variables: {
          input: getUserEmailInput,
        },
      });
    }
  };
  useEffect(() => {
    pageIndicator("아이디 찾기");
  }, []);
  const [getUserEmail, { loading }] = useGetUserEmail(onCompleted);
  return (
    <div className="w-full h-full relative overflow-hidden">
      <Helmet title="닐리버리 - 아이디 찾기" />
      <div className="flex flex-col h-full">
        <TopBar arrow={true} arrowColor={`#B5C1D0`} heading={true} exit={true} />
        <nav className="w-full h-10 grid grid-cols-2">
          <button className="h-full w-full border-b-2 border-mainIcon">아이디 찾기</button>
          <button
            onClick={() => {
              navigate("/findPwd");
            }}
            className="h-full w-full border-b-2 border-btnDarkLine"
          >
            비밀번호 찾기
          </button>
        </nav>
        <div className="flex-1 flex flex-wrap justify-center content-center">
          <Slide direction="left" in={true}>
            <div className="w-full">
              <form onSubmit={handleSubmit(onSubmit, onInvalid)} className="w-full">
                <div className="mx-auto w-full px-5 py-2 mb-4 max-w-screen-break">
                  <h3 className="px-4 font-bold text-xl py-4">아이디 찾기</h3>
                  <div className="fieldBox px-4 w-full pt-4">
                    <label htmlFor="findMail_name" className="text-h3 w-full block text-darkFont px-2 py-1">
                      이름
                    </label>
                    <input
                      {...register("name", {
                        required: "이름을 입력해주세요",
                      })}
                      type="text"
                      className="px-4 py-2 border border-btnDarkLine text-h3 w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                      placeholder="이름을 입력해주세요"
                      id="findMail_name"
                    />
                    <div className=" h-5"> {errors.name?.message && <p className="text-shade1 text-Xsmall py-1 px-2">{errors.name?.message}</p>}</div>
                  </div>
                  <div className="fieldBox px-4 w-full pb-8">
                    <label htmlFor="findMail_phone" className="text-h3 w-full block text-darkFont px-2 py-1">
                      전화번호
                    </label>
                    <input
                      {...register("phone", {
                        pattern: {
                          value: /^[0-9]{10,11}$/,
                          message: "숫자만 10-11자리 입력해주세요",
                        },
                        required: "전화번호를 입력해주세요",
                      })}
                      type="text"
                      className="px-4 py-2 border border-btnDarkLine text-h3 w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                      placeholder='"-" 빼고 숫자만 입력해주세요'
                      maxLength={11}
                      onKeyUp={(e) => (e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, ""))}
                      id="findMail_phone"
                    />
                    <div className="h-5">{errors.phone?.message && <p className="text-shade1 text-Xsmall py-1 px-2">{errors.phone?.message}</p>}</div>
                  </div>
                </div>
                <div className="p-4 flex flex-wrap justify-center gap-x-4">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="bg-white block border h-10 w-[136px] border-btnLightLine text-hintFont text-base font-bold rounded-full"
                  >
                    취소
                  </button>
                  <button
                    type="submit"
                    className="bg-mainIcon block border h-10 w-[136px] border-mainIcon text-white text-base font-bold rounded-full"
                  >
                    찾기
                  </button>
                </div>
              </form>
            </div>
          </Slide>
        </div>
      </div>
      {loading && (
        <div className="absolute left-0 top-0 w-full h-full bg-slate-200 flex flex-wrap content-center justify-center">
          <p className="text-standar font-bold">메일을 찾고 있습니다</p>
        </div>
      )}
    </div>
  );
};
