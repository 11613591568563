import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  UpReceiptAddressMutation,
  UpReceiptAddressMutationVariables,
} from "../gql/graphql";

const UP_RECEIPT_ADDRESS_MUTATION = gql`
  mutation upReceiptAddress($input: UpReceiptAddressInput!) {
    upReceiptAddress(input: $input) {
      ok
      err
    }
  }
`;

export const useUpReceiptAddress = (
  onCompleted?: (data: UpReceiptAddressMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    UpReceiptAddressMutation,
    UpReceiptAddressMutationVariables
  >(UP_RECEIPT_ADDRESS_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
