import { ApolloError, gql, useMutation } from "@apollo/client";
import { UpAddressMutation, UpAddressMutationVariables } from "../gql/graphql";

const UP_ADDRESS_MUTATION = gql`
  mutation upAddress($input: UpAddressInput!) {
    upAddress(input: $input) {
      ok
      err
    }
  }
`;
export const useUpAddress = (onCompleted?: (data: UpAddressMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<UpAddressMutation, UpAddressMutationVariables>(UP_ADDRESS_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
