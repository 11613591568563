import { useForm } from "react-hook-form";
import { useGetUserEmail } from "../hooks/useGetUserEmail";
import { Dialog, Slide } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import { ISendChangePasswordEmailForm, useSendChangePasswordEmail } from "../hooks/useSendChangePasswordEmail";
import { AlertComponent } from "../components/alert";
import { emailMasking } from "../constants/returnFunction";
import { GetUserEmailInput, GetUserEmailMutation, SendChangePasswordEmailInput, SendChangePasswordEmailMutation } from "../gql/graphql";

export const GetUserInfo = () => {
  const [findWhat, setFindWhat] = useState<number>(1);
  const [headingText, setHeadingText] = useState<string>("아이디 찾기");
  const [findedSlide, setFindSlide] = useState<boolean>(false);
  const navigate = useNavigate();
  const state = useLocation().state;
  // 아이디 찾기
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const onCompleted = (data: GetUserEmailMutation) => {
    const {
      getUserEmail: { ok, users, err },
    } = data;
    if (ok && users && users.length > 0) {
      setFindSlide(true);
    } else {
      alert(err);
    }
  };
  const onSubmit = () => {
    if (!loading) {
      const { name, phone } = getValues();
      const getUserEmailInput: GetUserEmailInput = { name, phone };
      getUserEmail({
        variables: {
          input: getUserEmailInput,
        },
      });
    }
  };
  const onInvalid = () => {};
  const [getUserEmail, { data, loading }] = useGetUserEmail(onCompleted);

  // 비밀번호 찾기
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const closeAlert = () => {
    setAlertOpen(false);
  };
  const {
    register: pwdRegister,
    handleSubmit: pwdHandleSubmit,
    getValues: pwdGetValues,
    formState: { errors: pwdErrors },
  } = useForm<ISendChangePasswordEmailForm>({ mode: `all` });

  const onPwdCompleted = (data: SendChangePasswordEmailMutation) => {
    const {
      sendChangePasswordEmail: { ok, err },
    } = data;
    if (ok) {
      setAlertText(`임시 비밀번호를 전달하였습니다. <br/> 이메일을 확인해주세요`);
      setAlertOpen(true);
    } else {
      alert(err);
    }
  };

  const [sendChangePasswordEmail, { loading: pwdLoading }] = useSendChangePasswordEmail(onPwdCompleted);

  const onPwdSubmit = () => {
    if (!loading) {
      const { username } = pwdGetValues();

      const sendChangePasswordEmailInput: SendChangePasswordEmailInput = {
        username,
      };

      sendChangePasswordEmail({
        variables: { input: sendChangePasswordEmailInput },
      });
    }
  };
  useEffect(() => {
    if (state) {
      setFindWhat(Number(state));
      if (state === 2) {
        setHeadingText("비밀번호 찾기");
      }
    }
  }, []);
  useEffect(() => {
    pageIndicator(headingText);
  }, [headingText]);
  return (
    <div className="w-full h-full relative overflow-hidden">
      <Helmet title="닐리버리 - 아이디 찾기" />
      <div className="flex flex-col h-full">
        <TopBar arrow={true} arrowColor={`#B5C1D0`} heading={true} exit={true} />
        <nav className="w-full h-10 grid grid-cols-2">
          <button
            onClick={() => {
              setHeadingText("아이디 찾기");
              setFindSlide(false);
              setFindWhat(1);
            }}
            className={`h-full w-full border-b-2 ${findWhat === 1 ? "border-mainIcon" : "border-btnDarkLine"}`}
          >
            아이디 찾기
          </button>
          <button
            onClick={() => {
              setHeadingText("비밀번호 찾기");
              setFindSlide(false);
              setFindWhat(2);
            }}
            className={`h-full w-full border-b-2 ${findWhat === 2 ? "border-mainIcon" : "border-btnDarkLine"}`}
          >
            비밀번호 찾기
          </button>
        </nav>
        <div className="flex-1 w-full relative">
          <Slide direction="left" in={findWhat === 1}>
            <div className="absolute left-0 top-0 flex flex-wrap justify-center content-center w-full h-full">
              <form onSubmit={handleSubmit(onSubmit, onInvalid)} className="w-full">
                <div className="mx-auto w-full px-5 py-2 mb-4 max-w-screen-break">
                  <h3 className="px-4 font-bold text-xl py-4">아이디 찾기</h3>
                  <div className="fieldBox px-4 w-full pt-4">
                    <label htmlFor="findMail_name" className="text-h3 w-full block text-darkFont px-2 py-1">
                      이름
                    </label>
                    <input
                      {...register("name", {
                        required: "이름을 입력해주세요",
                      })}
                      type="text"
                      className="px-4 py-2 border border-btnDarkLine text-h3 w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                      placeholder="이름을 입력해주세요"
                      id="findMail_name"
                    />
                    <div className=" h-5"> {errors.name?.message && <p className="text-shade1 text-Xsmall py-1 px-2">{errors.name?.message}</p>}</div>
                  </div>
                  <div className="fieldBox px-4 w-full pb-8">
                    <label htmlFor="findMail_phone" className="text-h3 w-full block text-darkFont px-2 py-1">
                      전화번호
                    </label>
                    <input
                      {...register("phone", {
                        pattern: {
                          value: /^[0-9]{10,11}$/,
                          message: "숫자만 10-11자리 입력해주세요",
                        },
                        required: "전화번호를 입력해주세요",
                      })}
                      type="text"
                      className="px-4 py-2 border border-btnDarkLine text-h3 w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                      placeholder='"-" 빼고 숫자만 입력해주세요'
                      maxLength={11}
                      onKeyUp={(e) => (e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, ""))}
                      id="findMail_phone"
                    />
                    <div className="h-5">{errors.phone?.message && <p className="text-shade1 text-Xsmall py-1 px-2">{errors.phone?.message}</p>}</div>
                  </div>
                </div>
                <div className="p-4 flex flex-wrap justify-center gap-x-4">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="bg-white block border h-10 w-[136px] border-btnLightLine text-hintFont text-base font-bold rounded-full"
                  >
                    취소
                  </button>
                  <button
                    type="submit"
                    className="bg-mainIcon block border h-10 w-[136px] border-mainIcon text-white text-base font-bold rounded-full"
                  >
                    찾기
                  </button>
                </div>
              </form>
            </div>
          </Slide>
          <Slide direction="left" in={findWhat === 2}>
            <div className="absolute left-0 top-0 flex flex-wrap justify-center content-center w-full h-full">
              <form onSubmit={pwdHandleSubmit(onPwdSubmit)} className="w-full">
                <div className="mx-auto w-full px-6 py-2 pb-20 max-w-screen-break">
                  <h3 className="px-4 font-bold text-xl py-4">비밀번호 찾기</h3>
                  <div className="fieldBox px-4 mt-10 w-full">
                    <label htmlFor="findPwd_username" className="text-h3 w-full block text-darkFont px-2 py-1">
                      이메일 주소
                    </label>
                    <input
                      {...pwdRegister("username", {
                        required: "가입하신 이메일 주소를 입력해주세요",
                      })}
                      type="text"
                      placeholder="이메일을 입력해주세요"
                      className="px-4 py-2 border border-btnDarkLine text-h3 w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                      id="findPwd_username"
                    />
                    <div className="h-5">
                      {pwdErrors.username?.message && <p className="text-shade1 text-Xsmall py-1 px-2">{pwdErrors.username?.message}</p>}
                    </div>
                  </div>
                </div>
                <div className="p-4 flex flex-wrap justify-center gap-x-4">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="bg-white block border h-10 w-[136px] border-btnLightLine text-hintFont text-base font-bold rounded-full"
                  >
                    취소
                  </button>
                  <button
                    type="submit"
                    className="bg-mainIcon block border h-10 w-[136px] border-mainIcon text-white text-base font-bold rounded-full"
                  >
                    확인
                  </button>
                </div>
              </form>
            </div>
          </Slide>
          <Slide direction="left" in={findedSlide}>
            <div className="absolute left-0 top-0 flex flex-wrap justify-center content-center w-full h-full z-10 bg-white">
              <div className="mx-auto w-full px-6 py-2 mb-20 max-w-screen-break">
                <div className="p-4 min-h-[150px] w-full bg-white  border border-btnDarkLine rounded-2xl text-standard break:text-large text-center flex flex-wrap content-center justify-center">
                  <p className="py-2 w-full">고객님의 메일 주소는 </p>
                  {data?.getUserEmail.users &&
                    data?.getUserEmail.users.length > 0 &&
                    data?.getUserEmail.users.map((user) => (
                      <p key={`user-email-${user.id}`} className="py-2 w-full">
                        <span className="text-grayFont text-large break:text-Xlarge font-bold">{emailMasking(String(user.username))}</span>
                      </p>
                    ))}
                  입니다.
                </div>
              </div>
              <div className="py-10 px-6 w-full">
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="bg-mainIcon py-2 px-3 block w-full max-w-screen-fit text-white text-xl font-bold h-[40px] mx-auto rounded-[20px]"
                >
                  로그인하러 가기
                </button>
              </div>
            </div>
          </Slide>
        </div>
      </div>
      {loading && (
        <div className="absolute left-0 top-0 w-full h-full bg-slate-200 flex flex-wrap content-center justify-center">
          <p className="text-standar font-bold">메일을 찾고 있습니다</p>
        </div>
      )}
      {pwdLoading && (
        <div className="absolute left-0 top-0 w-full h-full bg-slate-200 flex flex-wrap content-center justify-center">
          <p className="text-standar font-bold">메일을 전송 중입니다.</p>
        </div>
      )}
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        className="relative"
      >
        <AlertComponent title={`알림`} desc={alertText} closeFunction={closeAlert}></AlertComponent>
      </Dialog>
    </div>
  );
};
