import { useEffect, useLayoutEffect } from "react";
import { pageIndicator } from "../apollo";

import whatIsNilivery from "../assets/images/whatisNilivery.png";
import whatIsNilivery1 from "../assets/images/whatisNilivery1.png";
import NiliveryIntro from "../assets/video/niliveryIntro.mov";
import { FootMenu } from "../components/FootMenu";
import { TopBar } from "../components/topBar";

export const WhatIsNilivery = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    pageIndicator("닐리버리란?");
  }, []);
  return (
    <div className="max-w-screen-break mx-auto h-full overflow-auto removeScroll">
      <TopBar arrow={true} heading={true} />
      <div className="flex-1 mb-[100px] ">
        <div className="contentsBox py-4 px-4">
          {/* <video
            src={NiliveryIntro}
            muted
            loop
            autoPlay
            playsInline
            className="w-full h-full object-cover bg-white"
          /> */}
          <img src={whatIsNilivery} className="w-full"></img>
          <img src={whatIsNilivery1} className="w-full pt-4" />
        </div>
      </div>
      <FootMenu />
      {/* <div className="absolute bottom-0 left-0 w-full z-10">
        <div className="px-4 pb-4 w-full break:sticky absolute  bottom-0 left-1/2 transform -translate-x-1/2 break:transform break:translate-x-0">
          <button className="bg-[#ffe400] font-bold text-btn block w-full max-w-screen-fit text-[#363a3d] text-[20px] leading-6  py-[9px] mx-auto rounded-[20px]">
            {me?.me.id && (
              <a
                href={`${data?.getBannerMainEvent.bannerMainEvent?.link}`}
                className="block w-full"
              >
                구매하러 가기
              </a>
            )}
            {!me?.me.id && (
              <Link to="/join/agree" className="block w-full">
                회원가입하러가기
              </Link>
            )}
          </button>
        </div>
      </div> */}
    </div>
  );
};
