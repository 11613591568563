import { ApolloError, gql, useMutation } from "@apollo/client";
import { DelGoodReviewMutation } from "../gql/graphql";

const DEL_GOOD_REVIEW_MUTATION = gql`
  mutation delGoodReview($input: DelGoodReviewInput!) {
    delGoodReview(input: $input) {
      ok
      err
    }
  }
`;

export const useDelGoodReview = (onCompleted?: (data: DelGoodReviewMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<DelGoodReviewMutation>(DEL_GOOD_REVIEW_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
