import { useEffect, useState } from "react";
import { isLoggedInVar, pageIndicator } from "../apollo";
import { TopBar } from "../components/topBar";
import { useMe } from "../hooks/useME";
import logo from "../assets/icons/logo.png";
import { getToken } from "../token";
import { useNavigate } from "react-router-dom";

export const JoinComplete = () => {
  const [seconds, setSeconds] = useState<number>(3);
  const { data: me } = useMe();
  isLoggedInVar(Boolean(getToken()));
  const navigate = useNavigate();
  const secondsHandler = () => {
    if (seconds !== 0) {
      setSeconds(seconds - 1);
    } else {
      window.location.href = "/";
    }
  };

  setInterval(secondsHandler, 1000);
  useEffect(() => {
    pageIndicator("회원가입");
  }, []);
  return (
    <div className="w-full bg-bg h-full grid grid-rows-1 relative">
      <div className="flex flex-col flex-wrap h-full">
        <TopBar arrow={true} heading={true} />
        <div className="px-4 flex flex-wrap content-center flex-1">
          <div className="contentBox w-full">
            <figure>
              <img
                src={logo}
                alt="닐리버리 이미지"
                className="w-full max-w-screen-fit mx-auto px-4"
              />
              <div className="text-center pt-10">
                <p className="text-standard break:text-large font-bold">
                  {me?.me.name}님
                </p>
                <p className="text-standard break:text-large">
                  닐리버리 회원가입을 환영합니다!
                </p>
              </div>
            </figure>
          </div>
        </div>
      </div>
      <div className="py-10 px-4">
        <button
          onClick={() => navigate("/")}
          className="bg-mainIcon block w-full max-w-screen-fit text-white text-xl font-bold h-[56px] mx-auto rounded-full"
        >
          {seconds}초후 홈으로 이동
        </button>
      </div>
    </div>
  );
};
