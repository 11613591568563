import { ApolloError, gql, useMutation } from "@apollo/client";

import { UpUserPasswordInput, UpUserPasswordMutation } from "../gql/graphql";

export interface IChangePasswordForm extends UpUserPasswordInput {
  confirm_password: string;
}

const UP_USER_PASSWORD_MUTATION = gql`
  mutation upUserPassword($input: UpUserPasswordInput!) {
    upUserPassword(input: $input) {
      ok
      err
    }
  }
`;

export const useUpUserPassword = (
  onCompleted?: (data: UpUserPasswordMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<UpUserPasswordMutation>(UP_USER_PASSWORD_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
