import { gql, useQuery } from "@apollo/client";
import { MeQuery } from "../gql/graphql";

const ME_QUERY = gql`
  query me {
    me {
      id
      username
      name
      phone
      role
      type
      addresses {
        isRep
        region
      }
    }
  }
`;

export const useMe = (onCompleted?: (data: MeQuery) => void) => {
  return useQuery<MeQuery>(ME_QUERY, { onCompleted });
};
