import { ApolloError, gql, useMutation } from "@apollo/client";
import { CrReceiptMutation, CrReceiptMutationVariables } from "../gql/graphql";

const CR_RECEIPT_MUTATION = gql`
  mutation crReceipt($input: CrReceiptInput!) {
    crReceipt(input: $input) {
      ok
      err
      receipt {
        id
        uuid
      }
    }
  }
`;

export const useCrReceipt = (onCompleted?: (data: CrReceiptMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<CrReceiptMutation, CrReceiptMutationVariables>(CR_RECEIPT_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
