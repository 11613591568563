import { ApolloError, gql, useMutation } from "@apollo/client";
import { GetUpUserCertDataMutation, GetUpUserCertDataMutationVariables } from "../gql/graphql";

const GET_UP_USER_CERT_MUTATION = gql`
  mutation getUpUserCertData($input: GetUpUserCertInput!) {
    getUpUserCertData(input: $input) {
      ok
      err
      user {
        name
        phone
        username
        id
      }
    }
  }
`;

export const useGetUpUserCertData = (onCompleted?: (data: GetUpUserCertDataMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<GetUpUserCertDataMutation, GetUpUserCertDataMutationVariables>(GET_UP_USER_CERT_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
