import { useEffect, useState } from "react";
import { ArrowRight } from "../components/Icons";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import imageBox from "../assets/images/imageBox.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCrGoodReviewPoster } from "../hooks/useCrGoodReviewPoster";
import { uploadDetailImage } from "../functions/uploads";
import star_yellow from "../assets/icons/star_yellow.svg";
import star_gray from "../assets/icons/star_gray.svg";
import exit_white from "../assets/icons/exit_white.svg";
import plus from "../assets/icons/plus.svg";
import { useDelReviewPoster } from "../hooks/useDelGoodReviewPoster";
import { useCrGoodReview } from "../hooks/useCrGoodReview";
import { useGetGoodReview } from "../hooks/useGetGoodReview";
import { useUpGoodReview } from "../hooks/useUpGoodReview";
import { useDelGoodReview } from "../hooks/useDelGoodReview";
import { Dialog, Modal } from "@mui/material";
import { AlertComponent, ConfirmComponent } from "../components/alert";
import {
  CrGoodReviewPosterInput,
  CrGoodReviewPosterMutation,
  DelGoodReviewInput,
  DelGoodReviewMutation,
  DelGoodReviewPosterInput,
  DelGoodReviewPosterMutation,
  EditGoodReviewInput,
  EditGoodReviewMutation,
  GetGoodReviewInput,
  GetGoodReviewQuery,
} from "../gql/graphql";
export interface dateFilterBtnList {
  text: string;
  active: boolean;
}

export const EditReview = () => {
  const navigate = useNavigate();
  const param = useParams();
  const [reviewPosterIndex, setReviewPosterIndex] = useState<number>(0);
  const [GoodPosters, setGoodReviewPosters] = useState<number[]>([]);
  const [GoodPostersURL, setGoodReviewPostersURL] = useState<any[]>([]);
  const [rating, setRating] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const closeAlert = () => {
    setAlertOpen(false);
    navigate(-1);
  };
  const closeConfirm = () => {
    setConfirmOpen(false);
  };
  const confirmFunction = () => {
    setConfirmOpen(false);
    delGoodReviewHandler(Number(param.reviewId));
  };
  // crReviewPoster
  const onCrReviewPoster = async (data: CrGoodReviewPosterMutation) => {
    const {
      crGoodReviewPoster: { ok, err, goodReviewPoster },
    } = data;
    if (ok) {
      var reviewPosters = GoodPosters;
      var reviewPostersURL = GoodPostersURL;

      if (goodReviewPoster) {
        reviewPostersURL.push(goodReviewPoster?.upload?.url);
        reviewPosters.push(goodReviewPoster.id);
      }
      setGoodReviewPosters(reviewPosters);
      setGoodReviewPostersURL(reviewPostersURL);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [crGoodReviewPoster, { loading: reviewPosterLoading }] = useCrGoodReviewPoster(onCrReviewPoster);
  const CrGoodReviewPosterHandler = (imageUuid?: string | null | undefined) => {
    const input: CrGoodReviewPosterInput = {
      index: reviewPosterIndex,
      imageUuid,
    };
    crGoodReviewPoster({ variables: { input } });
  };

  const uploadGoodReviewImageHandler = async (file?: any, uuid?: string | null | undefined) => {
    if (file) {
      const result = await uploadDetailImage(file);
      if (result.data.ok) {
        CrGoodReviewPosterHandler(result.data.upload.uuid);
      } else {
        alert("잘못된 파일");
        return;
      }
    } else {
      CrGoodReviewPosterHandler(uuid);
    }
  };
  // del Review Poster
  const onDelReviewPoster = async (data: DelGoodReviewPosterMutation) => {
    const {
      delGoodReviewPoster: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };

  const [delGoodReviewPoster, { loading: delGoodReviewLoading }] = useDelReviewPoster(onDelReviewPoster);
  const delGoodReviewPosterHandler = (index: number) => {
    const input: DelGoodReviewPosterInput = {
      reviewPosterId: GoodPosters[index],
    };
    delGoodReviewPoster({ variables: { input } });
    var reviewPosters = GoodPosters;
    var reviewPostersURL = GoodPostersURL;
    reviewPostersURL.splice(index, 1);
    reviewPosters.splice(index, 1);
    setGoodReviewPosters(reviewPosters);
    setGoodReviewPostersURL(reviewPostersURL);
  };

  // editReview
  const onUpReviewCompleted = (data: EditGoodReviewMutation) => {
    const {
      editGoodReview: { ok, err },
    } = data;
    if (ok) {
      setAlertText("리뷰가 수정 되었습니다.");
      setAlertOpen(true);
      // alert("리뷰가 수정 되었습니다.");
      // navigate(-1);
    } else {
      alert(err);
    }
  };
  const [upGoodReview] = useUpGoodReview(onUpReviewCompleted);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<EditGoodReviewInput>({
    mode: "onChange",
  });

  const upGoodReviewHandler = (reviewId: number) => {
    const { note } = getValues();
    const editGoodReviewInput: EditGoodReviewInput = {
      reviewId,
      rating,
      note,
      goodReviewPosterIds: GoodPosters,
    };
    upGoodReview({ variables: { input: editGoodReviewInput } });
  };

  // call goodReview
  const onGetGoodReview = (data: GetGoodReviewQuery) => {
    const {
      getGoodReview: { ok, err, goodReview },
    } = data;
    if (ok && goodReview) {
      setRating(goodReview.rating);
      setValue("note", goodReview.note);
      var reviewPosterUrl = [];
      var reviewPosterId: number[] = [];
      if (goodReview.goodReviewPosters && goodReview.goodReviewPosters.length > 0) {
        for (const poster of goodReview.goodReviewPosters) {
          reviewPosterUrl.push(poster.upload?.url);
          reviewPosterId.push(poster.id);
        }
        setGoodReviewPostersURL(reviewPosterUrl);
        setGoodReviewPosters(reviewPosterId);
      }
    } else {
      alert(err);
    }
  };
  const [getGoodReview, { data, loading: reviewLoading, refetch }] = useGetGoodReview(onGetGoodReview);
  const getGoodReviewHandler = (id: number) => {
    const input: GetGoodReviewInput = {
      reviewId: id,
    };
    getGoodReview({ variables: { input } });
  };
  // delete
  const onDelGoodReview = (data: DelGoodReviewMutation) => {
    const {
      delGoodReview: { ok, err },
    } = data;
    if (ok) {
      setAlertText("삭제 되었습니다.");
      setAlertOpen(true);
    } else {
      console.log(err);
    }
  };
  const [delGoodReview] = useDelGoodReview(onDelGoodReview);
  const delGoodReviewHandler = (reviewId: number) => {
    const delGoodReviewInput: DelGoodReviewInput = {
      reviewId,
    };
    delGoodReview({ variables: { input: delGoodReviewInput } });
  };
  // useeffect
  useEffect(() => {
    pageIndicator("나의 리뷰");
    if (param.reviewId) {
      getGoodReviewHandler(Number(param.reviewId));
    }
  }, [page]);
  return (
    <div className="wrap overflow-auto h-full removeScroll relative flex flex-col  w-full ">
      <header className="border-b border-btnLightLine">
        <TopBar arrow={true} heading={true} shop={true} />
      </header>
      <main className="max-w-screen-break mx-auto flex-1 w-full">
        <section className="p-4">
          <div className="border border-btnLightLine rounded-2xl">
            <div className="receipt border-b border-btnLightLine px-4">
              <div className="orderBox space-y-4 pt-2">
                {/* map component order */}
                <div className="py-4">
                  <div className="flex flex-warp gap-x-4 overflow-hidden">
                    <img
                      src={`${
                        data?.getGoodReview.goodReview?.order?.orderReceipt?.good_poster_url
                          ? data?.getGoodReview.goodReview?.order?.orderReceipt?.good_poster_url
                          : imageBox
                      }`}
                      alt={`${data?.getGoodReview.goodReview?.order?.orderReceipt?.good_title}`}
                      className="w-20 aspect-square  rounded-lg cursor-pointer"
                    />
                    <div className="flex-1 flex flex-wrap flex-col py-3">
                      <p className="px-1 text-[10px] leading-3">{`${data?.getGoodReview.goodReview?.good.corporation?.name}`}</p>
                      <p className="px-1 py-0.5 text-small overflow-hidden ellipsis2">
                        {`${data?.getGoodReview.goodReview?.order?.orderReceipt?.good_title}`}
                      </p>
                    </div>
                  </div>
                </div>
                {/* order end */}
              </div>
            </div>
            <div className="pt-4 px-4">
              <p className="text-standard break:text-large text-darkFont px-3 h-8 flex flex-wrap content-center">나의 별점</p>
              <div className="flex justify-center">
                <div className="p-4 border-b border-btnDarkLine w-auto flex justify-center content-center gap-x-2">
                  <button className="w-10" onClick={() => setRating(1)}>
                    <img src={rating >= 1 ? star_yellow : star_gray} alt={rating >= 1 ? "꽉찬 별" : "빈 별"} className="w-full" />
                  </button>
                  <button className="w-10" onClick={() => setRating(2)}>
                    <img src={rating >= 2 ? star_yellow : star_gray} alt={rating >= 2 ? "꽉찬 별" : "빈 별"} className="w-full" />
                  </button>
                  <button className="w-10" onClick={() => setRating(3)}>
                    <img src={rating >= 3 ? star_yellow : star_gray} alt={rating >= 3 ? "꽉찬 별" : "빈 별"} className="w-full" />
                  </button>
                  <button className="w-10" onClick={() => setRating(4)}>
                    <img src={rating >= 4 ? star_yellow : star_gray} alt={rating >= 4 ? "꽉찬 별" : "빈 별"} className="w-full" />
                  </button>
                  <button className="w-10" onClick={() => setRating(5)}>
                    <img src={rating == 5 ? star_yellow : star_gray} alt={rating == 5 ? "꽉찬 별" : "빈 별"} className="w-full" />
                  </button>
                </div>
              </div>
              <div className="pt-4">
                <p className="px-2 py-1">
                  <span className="h-6 leading-6">나의 리뷰</span>
                </p>
                <textarea
                  {...register(`note`)}
                  placeholder="고객님의 소중한 피드백을 남겨주세요."
                  className="px-4 py-2 text-standard text-darkFont placeholder:text-center placeholder:leading-8 border border-btnDarkLine rounded-lg w-full min-h-[192px]  resize-none focus:outline-mainIcon whitespace-pre-wrap"
                ></textarea>
              </div>
            </div>
            <div className="py-4 px-4 ">
              <p className="px-2 py-1">
                <span className="text-sm leading-6">사진 등록</span>
              </p>
              <div className="flex flex-wrap">
                {GoodPostersURL.length > 0 &&
                  GoodPostersURL.map((url, index) => (
                    <div key={`postes-${url}`} className="inline-block p-1 w-20 h-20  relative">
                      <button onClick={() => delGoodReviewPosterHandler(index)} className="w-5 h-5 rounded-full bg-gray-500 absolute right-0 top-0">
                        <img src={exit_white} alt="delete" />
                      </button>
                      <img src={url} alt="리뷰사진" className="w-full h-full object-cover border rounded-md" />
                    </div>
                  ))}
                {GoodPostersURL.length < 4 && (
                  <div className="inline-block w-20 h-20 p-1">
                    <label className="border rounded-md pointer bg-gray-200 flex flex-wrap content-center justify-center h-full w-full">
                      <img src={plus} alt="사진추가" className="w-8" />
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(event: any) => {
                          uploadGoodReviewImageHandler(event.target.files[0]);
                        }}
                        className="hidden"
                      />
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full py-3">
            <div className="flex flex-wrap justify-center py-2 gap-x-4">
              <button
                onClick={() => {
                  setConfirmOpen(true);
                }}
                className="bg-white block w-[156px] h-10  text-hintFont text-standard font-bold rounded-full border border-btnLightLine"
              >
                삭제
              </button>
              <button
                onClick={() => upGoodReviewHandler(Number(param.reviewId))}
                className="bg-mainIcon block w-[156px]  text-white text-standard font-bold rounded-full border border-mainIcon"
              >
                수정
              </button>
            </div>
          </div>
        </section>
        <Dialog
          open={alertOpen}
          onClose={() => {
            setAlertOpen(false);
          }}
          className="relative"
        >
          <AlertComponent title={`알림`} desc={alertText} closeFunction={closeAlert}></AlertComponent>
        </Dialog>
        <Dialog
          open={confirmOpen}
          onClose={() => {
            setConfirmOpen(false);
          }}
          className="relative"
        >
          <ConfirmComponent
            title={`안내`}
            desc={`정말로 삭제하시겠습니까?`}
            closeFunction={closeConfirm}
            confirmFunction={confirmFunction}
          ></ConfirmComponent>
        </Dialog>
      </main>
      {reviewLoading && <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-20"></div>}
    </div>
  );
};
