import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useListReceipt } from "../hooks/useListReceipt";
import { pageIndicator } from "../apollo";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { Collapse, Dialog } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { DeliveryTracking } from "../components/deliveryTracking";
import { useCrReceipt } from "../hooks/useCrReceipt";
import { AlertComponent } from "../components/alert";
import {
  CrReceiptInput,
  CrReceiptMutation,
  DetailCrReceiptInput,
  GoodStatus,
  ListReceiptInput,
  ListReceiptQuery,
  ReceiptStatus,
  Redelivery,
} from "../gql/graphql";
export const SubListOrderCancel = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [selectOrderId, setSelectOrderId] = useState<number>();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const closeAlert = () => {
    setAlertOpen(false);
  };

  // collapse
  const setSelectedOrderIdHandler = (orderId: number) => {
    setSelectOrderId(orderId);
  };

  const pageHandler = (event: any, value: any) => {
    setPage(value);
  };

  // listreceipt by date
  const [listByDate, setListByDate] = useState<string[]>([]);
  const onListReceipt = (data: ListReceiptQuery) => {
    const {
      listReceipt: { ok, err, receipts },
    } = data;
    if (ok && receipts) {
      let uniqueDate: string[] = [];
      receipts.forEach((receipt) => {
        let payDates =
          receipt.paid_at && moment(+receipt.paid_at).format("YYYY.MM.DD");
        if (payDates && !uniqueDate.includes(payDates)) {
          uniqueDate.push(payDates);
        }
      });
      setListByDate(
        uniqueDate.sort((a, b) => {
          if (a > b) return -1;
          else if (b > a) return 1;
          else return 0;
        })
      );
    } else {
      console.log(err);
    }
  };
  const [
    listReceipt,
    { data: listReceiptData, loading: listReceiptLoading, refetch },
  ] = useListReceipt(onListReceipt);
  const listReceiptHandler = (page: number) => {
    const input: ListReceiptInput = {
      page,
      status: ReceiptStatus.Canceled,
    };
    listReceipt({ variables: { input } });
  };

  // 재구매
  const onCrReceipt = (data: CrReceiptMutation) => {
    const {
      crReceipt: { ok, receipt },
    } = data;
    if (ok && receipt) {
      navigate(`/selectReceiveWays/${receipt.uuid}`);
    } else {
      setAlertText("구매 진행에 실패했습니다");
      setAlertOpen(true);
      // alert("구매 진행에 실패했습니다");
    }
  };
  const [crReceipt, { loading: crReceiptLoading }] = useCrReceipt(onCrReceipt);
  const crReceiptHandler = (info: DetailCrReceiptInput) => {
    if (!crReceiptLoading) {
      var buyInfos: DetailCrReceiptInput[] = [];
      buyInfos.push(info);
      const input: CrReceiptInput = {
        buyInfos,
      };
      crReceipt({ variables: { input } });
    }
  };

  // useeffect
  useEffect(() => {
    pageIndicator("주문 / 배송 내역조회");
    listReceiptHandler(page);
  }, [page]);

  return (
    <section id="orderList" className="pb-8 pt-4">
      {!listReceiptLoading &&
        listByDate.map((date) => (
          <div className="px-4 pb-4" key={`receiptDate-${date}`}>
            <div className="receipt ">
              <div className="flex py-[3.25px] h-[40px] content-center bg-[#E8E8F4] rounded-t-2xl border-2  ">
                <p className="flex flex-wrap  content-center flex-1 gap-x-1  px-6">
                  <span className="font-bold  px-1 py-2">{`${date}`}</span>
                </p>
              </div>
              <div className="orderBox space-y-4 p-4 border-b border-x rounded-b-2xl">
                {/* map component order */}
                {listReceiptData?.listReceipt.receipts &&
                  listReceiptData?.listReceipt.receipts
                    .filter((list) => {
                      return (
                        moment(Number(list.paid_at)).format("YYYY.MM.DD") ===
                        date
                      );
                    })
                    .map((receipt) => (
                      <div key={`receiptmap-${receipt.receiptNum}`}>
                        {receipt &&
                          receipt.orders?.map((order, index, array) => (
                            <div
                              key={`receipt-order-${order.id}`}
                              className="flex flex-col gap-y-2"
                            >
                              <div
                                className={`flex flex-warp gap-x-4 overflow-hidden ${
                                  index + 1 !== array.length &&
                                  "border-b border-[#F0F0F4]"
                                }
                              ${
                                (order.orderReceipt?.goodOption_name &&
                                  !order.goodOption &&
                                  "opacity-50") ||
                                (order.goodOption &&
                                  order.goodOption?.name !==
                                    order.orderReceipt?.goodOption_name &&
                                  "opacity-50") ||
                                (order.good?.status !== GoodStatus.GoodSales &&
                                  "opacity-50")
                              }
                                `}
                              >
                                {order.orderReceipt && (
                                  <img
                                    src={`${order.orderReceipt?.good_poster_url}`}
                                    alt={`${order.orderReceipt?.good_title} 이미지`}
                                    onClick={() => {
                                      navigate(`/detail/${order.good?.id}`);
                                    }}
                                    className="h-[72px] aspect-square rounded-lg cursor-pointer"
                                  />
                                )}
                                <div className="flex-1 flex flex-wrap flex-col ">
                                  <p className="h-[46px] px-1 py-2 text-small break:text-standard overflow-hidden ellipsis2">
                                    {`${order.orderReceipt?.good_title}`}
                                    {/* {`${order.orderReceipt?.goodOption_name}`} */}
                                  </p>
                                  <div className="flex flex-wrap justify-between px-2 py-1 bg-[#FAFAFF] rounded-[20px]">
                                    <p className="flex flex-wrap gap-x-2">
                                      <span className="py-[3px] font-[#777B7D] text-Xsmall">
                                        수량
                                      </span>
                                      <span className="font-bold py-[1px] px-4 rounded-[20px] bg-[#fff] text-smallBtn">
                                        {order.orderReceipt?.order_quantity}
                                      </span>
                                      <span className="py-[3px] font-[#777B7D] text-Xsmall">
                                        개
                                      </span>
                                    </p>
                                    <p className=" text-smallBtn font-darkFont">
                                      {order.redelivery ===
                                      Redelivery.Redelivery
                                        ? "재배송"
                                        : order.amount.toLocaleString() + " 원"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {(order.orderReceipt?.goodOption_name &&
                                !order.goodOption && (
                                  <div className="flex flex-wrap gap-x-4">
                                    <p className="text-center py-1 flex-1 border border-btnLightLine rounded-lg text-xs leading-tight text-darkFont">
                                      옵션/재고가 변경되어 주문이 불가합니다.
                                    </p>
                                  </div>
                                )) ||
                                (order.goodOption &&
                                  order.goodOption?.name !==
                                    order.orderReceipt?.goodOption_name && (
                                    <div className="flex flex-wrap gap-x-4">
                                      <p className="text-center py-1 flex-1 border border-btnLightLine rounded-lg text-xs leading-tight text-darkFont">
                                        옵션/재고가 변경되어 주문이 불가합니다.
                                      </p>
                                    </div>
                                  )) ||
                                (order.good?.status !==
                                  GoodStatus.GoodSales && (
                                  <div className="flex flex-wrap gap-x-4">
                                    <p className="text-center py-1 flex-1 border border-btnLightLine rounded-lg text-xs leading-tight text-darkFont">
                                      옵션/재고가 변경되어 주문이 불가합니다.
                                    </p>
                                  </div>
                                )) || (
                                  <div className="flex flex-wrap gap-x-4">
                                    <button
                                      onClick={() => {
                                        var info: DetailCrReceiptInput = {
                                          goodId: order.good?.id,
                                          goodOptionId: order.goodOption
                                            ? order.goodOption.id
                                            : null,
                                          quantity: order.quantity,
                                        };
                                        crReceiptHandler(info);
                                      }}
                                      className="py-1 flex-1 border border-btnLightLine rounded-lg text-xs leading-tight text-grayFont"
                                    >
                                      재 구매하기
                                    </button>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                    ))}

                {/* order end */}
              </div>
            </div>
          </div>
        ))}
      {!listReceiptLoading &&
        listReceiptData?.listReceipt.receipts?.length === 0 && (
          <p className="py-10 text-darkFont text-standard break:text-large text-center font-bold">
            취소한 주문이 없습니다.
          </p>
        )}
      <Stack spacing={2} className="flex-wrap content-center pt-8">
        <Pagination
          onChange={pageHandler}
          count={
            listReceiptData?.listReceipt?.totalPages
              ? listReceiptData?.listReceipt?.totalPages
              : 1
          }
          color={`primary`}
        ></Pagination>
      </Stack>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        className="relative"
      >
        <AlertComponent
          title={`알림`}
          desc={alertText}
          closeFunction={closeAlert}
        ></AlertComponent>
      </Dialog>
    </section>
  );
};
