import { ApolloError, gql, useMutation } from "@apollo/client";
import { GetUserEmailMutation } from "../gql/graphql";

const GET_USER_EMAIL_MUTATION = gql`
  mutation getUserEmail($input: GetUserEmailInput!) {
    getUserEmail(input: $input) {
      ok
      err
      users {
        id
        username
      }
    }
  }
`;

export const useGetUserEmail = (onCompleted?: (data: GetUserEmailMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<GetUserEmailMutation>(GET_USER_EMAIL_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
