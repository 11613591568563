import React, { useEffect, useState } from "react";
import logo from "../assets/icons/logo.png";
import { pageIndicator } from "../apollo";
import { logIn } from "../token";
import { useGetJoinUserCertData } from "../hooks/useGetJoinUserCert";
import { IMP_ID } from "../constants/imp";
import { useParams } from "react-router-dom";
import { TopBar } from "../components/topBar";
import { Dialog } from "@mui/material";
import { AlertComponent } from "../components/alert";
import {
  GetJoinUserCertDataMutation,
  GetJoinUserCertInput,
} from "../gql/graphql";
export const JoinCertification = () => {
  const params = useParams();

  // 인증

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [alertToken, setAlertToken] = useState<string>("");
  const closeAlert = () => {
    setAlertOpen(false);
    if (alertText === "인증이 확인되었습니다.") {
      logIn(alertToken);
      window.location.href = "/join/complete";
    }
  };

  const onCertCompleted = (data: GetJoinUserCertDataMutation) => {
    const {
      getJoinUserCertData: { ok, err, token },
    } = data;
    if (ok && token) {
      setAlertText("인증이 확인되었습니다.");
      setAlertToken(token);
      setAlertOpen(true);

      // alert("인증이 확인되었습니다.");
      // logIn(token);
      // window.location.href = "/join/complete";
    } else {
      alert(err);
    }
  };
  const [getJoinUserCertData] = useGetJoinUserCertData(onCertCompleted);
  const getJoinUserDataHandler = (imp_uid: string) => {
    const getJoinUserCertDataInput: GetJoinUserCertInput = {
      imp_uid,
      id: Number(params.id),
    };
    getJoinUserCertData({
      variables: {
        input: getJoinUserCertDataInput,
      },
    });
  };
  const certificationHandler = () => {
    const IMP = window.IMP;
    IMP?.init(IMP_ID);
    IMP?.certification(
      {
        popup: false,
        m_redirect_url:
          process.env.REACT_APP_URI === "dev"
            ? `http://localhost:3000/mobileCertData/${params.id}`
            : process.env.REACT_APP_URI === "test"
            ? `https://usertest.nilivery.com/mobileCertData/${params.id}`
            : `https://www.nilivery.com/mobileCertData/${params.id}`,
      },
      (rsp) => {
        if (rsp.success) {
          getJoinUserDataHandler(rsp.imp_uid);
        } else {
          setAlertText("본인인증에 실패하셨습니다.<br/> 다시 진행해주세요");
          setAlertOpen(true);
        }
      }
    );
  };
  useEffect(() => {
    pageIndicator("본인인증");
  }, []);

  return (
    <div className="w-full bg-bg h-full overflow-auto">
      <div className="relative h-full flex flex-col">
        <div className="relative flex-1">
          <TopBar arrow={true} heading={true} />
          <div className="px-4 flex-col flex-wrap flex-1 py-10">
            <img
              src={logo}
              alt="결제완료 닐리버리 로고"
              className="mx-auto p-4"
            />
            <p className="pt-20 text-center font-bold text-large">
              본인인증을 진행해주세요
            </p>
          </div>
        </div>
        <div className="w-full py-10 px-4 max-w-screen-fit mx-auto">
          <button
            onClick={certificationHandler}
            className="bg-mainIcon block w-full max-w-screen-fit text-white text-xl font-bold h-[56px] mx-auto rounded-full"
          >
            본인인증하기
          </button>
        </div>
      </div>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        className="relative"
      >
        <AlertComponent
          title={`알림`}
          desc={alertText}
          closeFunction={closeAlert}
        ></AlertComponent>
      </Dialog>
    </div>
  );
};
