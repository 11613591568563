import { useEffect, useState } from "react";
import {
  isLoggedInVar,
  isMobileVar,
  pageIndicator,
  serviceId,
} from "../apollo";
import { TopBar } from "../components/topBar";
import main_h_back from "../assets/images/main_h_back.svg";
import { GoodsNiliveryIn } from "../components/goodsNilivery";
import { FootMenu } from "../components/FootMenu";
import { Footer } from "../components/footer";
import { getToken } from "../token";

import { useNavigate, useParams } from "react-router-dom";
import { Banner } from "../components/slideBanner";
import { useListGood } from "../hooks/useListGood";
import { useReactiveVar } from "@apollo/client";
import { useGetBannerOptionActive } from "../hooks/useGetBannerOptionActive";
import { useListAddress } from "../hooks/useListAddress";
import { Fade } from "@mui/material";
import { ArrowRight } from "../components/Icons";
import { useListLinkServiceSelect } from "../hooks/useListLinkServiceSelect";
import { useListCorporationSelect } from "../hooks/useListCorporationSelect";
import {
  GoodCategoryFirst,
  GoodStatus,
  ListAddressQuery,
  ListCorporationSelectQuery,
  ListGoodInput,
  ListGoodQuery,
  Tags,
} from "../gql/graphql";

export const HomeEmart = () => {
  var useOnce = false;
  var params = useParams();
  const { data } = useGetBannerOptionActive();
  const [listLinkServiceSelect, { data: services }] =
    useListLinkServiceSelect();
  const isMobile = useReactiveVar(isMobileVar);
  // const [open, setOpen] = React.useState(false);
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const onCompleted = (data: ListGoodQuery) => {
    const {
      listGood: { ok, err, goods },
    } = data;
    if (ok && goods) {
    }
  };
  const [listGood, { data: listGoodData, loading, refetch }] =
    useListGood(onCompleted);
  const [
    listGood1,
    { data: listGoodData1, loading: SoldoutLoading, refetch: SoldoutRefetch },
  ] = useListGood();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string | null>(null);
  const [test, setTest] = useState<string | null>(null);
  const [category, setCategory] = useState<GoodCategoryFirst | null>(null);
  const [service, setService] = useState<string | null>(null);
  const [tag, setTag] = useState<Tags | null>(null);
  const [myAddress, setMyAddress] = useState<boolean>();
  const [region, setRegion] = useState<string[]>([]);
  const navigate = useNavigate();

  const onGetRegionKeyWord = (data: ListCorporationSelectQuery) => {
    const {
      listCorporationSelect: { ok, err, corporations },
    } = data;
    if (ok && corporations) {
      let regionList: string[] = [];
      corporations.forEach((item) => {
        item.regionKeyWord?.forEach((region) => {
          if (region && !regionList.includes(region)) {
            regionList.push(region);
          }
        });
      });

      setRegion(regionList);
    }
  };
  const [listCorporationSelect, { data: corporations }] =
    useListCorporationSelect(onGetRegionKeyWord);

  const scorllHandler = () => {
    var element = document.getElementById("categoryWrapper");

    var left = element?.scrollLeft;
    if (element?.offsetWidth) {
      if (element.offsetWidth + element.scrollLeft >= element.scrollWidth) {
        element.scrollLeft = 0;
      } else {
        element?.scrollTo({ left: Number(left) + 200, behavior: "smooth" });
      }
    }
    // if (element?.offsetWidth + element?.scrollLeft >= element?.scrollWidth) {
    // }
  };
  const serviceHandler = (service: string) => {};

  const tagHandler = (varTag: Tags) => {
    setCategory(null);

    if (tag === varTag) {
      setTag(null);
    } else {
      setTag(varTag);
    }
  };
  const totalHandler = () => {
    setCategory(null);
    setTag(null);
  };
  const categoryHandler = (varCategory: GoodCategoryFirst) => {
    setTag(null);
    if (category === varCategory) {
      setCategory(null);
    } else {
      setCategory(varCategory);
    }
  };
  const addCompleted = (data: ListAddressQuery) => {
    const {
      listAddress: { ok, err, address },
    } = data;
    if (ok && address) {
      const repAddress = address.find((list) => list.isRep);
      if (repAddress) {
        setMyAddress(true);
        setTest(repAddress.region != undefined ? repAddress.region : null);
      } else {
        setMyAddress(false);
      }
    }
  };
  const [listAddress, { data: addressData }] = useListAddress(addCompleted);
  const listGoodHandler = (
    page: number | null,
    search: string | null,
    categoryFirst?: GoodCategoryFirst | null,
    tag?: Tags | null
  ) => {
    const input: ListGoodInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      status: GoodStatus.GoodSales,
      ...(categoryFirst && { categoryFirst }),
      service: params.service,

      ...(tag && { tag: tag }),
    };
    listGood({ variables: { input } });
  };
  const listGoodHandler1 = (
    page: number | null,
    search: string | null,
    categoryFirst?: GoodCategoryFirst | null,
    tag?: Tags | null
  ) => {
    const input: ListGoodInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      status: GoodStatus.GoodReady,
      ...(categoryFirst && { categoryFirst }),
      service: params.service,
      ...(tag && { tag: tag }),
    };
    listGood1({ variables: { input } });
  };
  useEffect(() => {
    isLoggedInVar(Boolean(getToken()));
    pageIndicator(params.service);
    // pageIndicator(params.keyword);

    listAddress();
    // setTag(Tags.GroupPurchase);
    listGoodHandler(page, search, category, tag);
    listGoodHandler1(page, search, category, tag);
    listLinkServiceSelect();
    listCorporationSelect();

    // if (isMobile) setOpen(true);
  }, []);

  useEffect(() => {
    listGoodHandler(page, search, category, tag);
    listGoodHandler1(page, search, category, tag);
    if (params.service === "도공촌 · 산내들") {
      pageIndicator("이마트24");
    } else {
      pageIndicator(params.service);
    }
  }, [category, tag, params.service]);
  return (
    <div className="overflow-auto h-full removeScroll pb-9" id="parent">
      <TopBar
        heading={true}
        main={true}
        search={true}
        headingColor={"text-mainIcon"}
        shop={true}
      />
      <main className="content pb-4 relative">
        <div className="h-10 relative">
          <div
            id="serviceWrapper"
            className="border-t border-btnDarkLine flex flex-wrap whitespace-nowrap overflow-auto h-full"
          >
            {services?.listLinkServiceSelect.linkservice &&
              services.listLinkServiceSelect.linkservice.map((service) => (
                <button
                  key={`service-${service.id}`}
                  onClick={() => {
                    if (service.name && service.name !== "김천혁신") {
                      serviceId(service.id);
                      navigate(`/${service.name}`);
                      // window.location.href = `/${service.link}`;
                    } else {
                      navigate("/");
                    }
                  }}
                  className={`flex-1 h-full inline-block border-b-2 text-sm ${
                    params.service === service.name
                      ? " text-mainIcon border-mainIcon font-bold"
                      : "text-grayFont border-btnDarkLine"
                  }
                }`}
                >
                  {service.name}
                </button>
              ))}
            {/* {region &&
              region.map((keyword) => (
                <button
                  onClick={() => {
                    if (keyword) {
                      navigate(`/${keyword}`);
                      // window.location.href = `/${service.link}`;
                    } else {
                      navigate("/");
                    }
                  }}
                  className={`flex-1 h-full inline-block border-b-2 text-sm ${
                    params.keyword === keyword
                      ? " text-mainIcon border-mainIcon font-bold"
                      : "text-grayFont border-btnDarkLine"
                  }
                
                 
                }`}
                >
                  {keyword}
                </button>
              ))} */}
            {/* <button
              onClick={() => {
                navigate("/emart");
              }}
              className={`flex-1 h-full inline-block border-b-2 text-sm
               text-mainIcon border-mainIcon font-bold
              }`}
            >
              이마트24
            </button>
            <button
              onClick={() => {
                navigate("/");
              }}
              className={`flex-1 h-full inline-block border-b-2 text-sm 
              text-grayFont border-btnDarkLine
              }`}
            >
              닐리버리
            </button> */}
          </div>
        </div>
        <div className="max-w-scrrent-fit w-full aspect-square">
          <Banner serviceName={params.service} />
        </div>
        {/* 카테고리 */}
        <div className="h-10 relative">
          <div
            id="categoryWrapper"
            className="whitespace-nowrap overflow-auto h-full removeScroll pr-6"
          >
            <button
              onClick={() => {
                totalHandler();
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === null && category === null
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              전체
            </button>
            <button
              onClick={() => {
                tagHandler(Tags.Suggestion);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === Tags.Suggestion
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              추천상품
            </button>
            <button
              onClick={() => {
                tagHandler(Tags.Emart);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === Tags.Emart
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              이마트24
            </button>
            <button
              onClick={() => {
                tagHandler(Tags.GroupPurchase);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === Tags.GroupPurchase
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              공동구매
            </button>
            <button
              onClick={() => {
                tagHandler(Tags.Camping);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === Tags.Camping
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              캠핑이벤트
            </button>
            {/* <button
              onClick={() => {
                tagHandler(Tags.Accessories);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === Tags.Accessories
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              패션잡화
            </button> */}
            {/* <button
              onClick={() => {
                tagHandler(Tags.Mealkit);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === Tags.Mealkit
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              밀키트
            </button> */}
            {/* <button
              onClick={() => {
                tagHandler(Tags.LocalFood);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === Tags.LocalFood
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              로컬푸드
            </button>
            <button
              onClick={() => {
                tagHandler(Tags.NationalFood);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                tag === Tags.NationalFood
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              전국맛집
            </button> */}
            {/* <button
              onClick={() => {
                categoryHandler(GoodCategoryFirst.Meal);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                category === GoodCategoryFirst.Meal
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              간편식/요리
            </button> */}
            {/* <button
              onClick={() => {
                categoryHandler(GoodCategoryFirst.Snack);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                category === GoodCategoryFirst.Snack
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              디저트/간식
            </button> */}
            <button
              onClick={() => {
                categoryHandler(GoodCategoryFirst.Toys);
              }}
              className={`w-20 h-full inline-block border-b-2 text-sm ${
                category === GoodCategoryFirst.Toys
                  ? "text-mainIcon border-mainIcon font-bold"
                  : "text-grayFont border-btnDarkLine"
              }`}
            >
              장난감/완구
            </button>
          </div>
          <button
            onClick={scorllHandler}
            className="absolute right-0 top-0 h-full bg-white"
          >
            <ArrowRight color={`#00B0EB`}></ArrowRight>
          </button>
        </div>
        {/* 상품리스트 */}
        <section id="niliveryItems" className="relative">
          <div className="niliveryHeaderBack absolute left-0 top-0 w-full h-[296px] bg-mainIcon"></div>
          <div className="relative px-[10px] yeonL py-5">
            <div className="relative">
              <h2 className=" left-0 bottom-0 w-full text-center text-white font-bold text-2xl">
                이마트
              </h2>
            </div>
            <h3 className="mt-4 py-2 px-1 text-center text-white text-sm">
              <span className="font-bold">리얼퀵배송</span> 드론으로 경험하는{" "}
              <br />
              스마트한 배송의 시작
            </h3>
          </div>
          <div className="goods transform gap-y-10 grid grid-cols-2 gap-x-2 sm:gap-x-4 p-2 ">
            {listGoodData?.listGood.goods &&
              listGoodData?.listGood.goods.map((obj, index) => {
                return (
                  <GoodsNiliveryIn
                    key={`good-option-${index}`}
                    id={obj.id}
                    title={obj.title}
                    subTitle={obj.summary}
                    image={obj.goodPosters}
                    brand={obj}
                    desc={obj.summary}
                    status={obj.status}
                    classes={obj.title}
                    price={obj.price ? obj.price : 1}
                    discountRate={obj.discountRate}
                    service={"이마트24"}
                  />
                );
              })}
            {listGoodData1?.listGood.goods &&
              listGoodData1?.listGood.goods.map((obj, index) => {
                return (
                  <GoodsNiliveryIn
                    key={`good-option-${index}`}
                    id={obj.id}
                    title={obj.title}
                    subTitle={obj.summary}
                    image={obj.goodPosters}
                    brand={obj}
                    desc={obj.summary}
                    status={obj.status}
                    classes={obj.title}
                    price={obj.price ? obj.price : 1}
                    discountRate={obj.discountRate}
                  />
                );
              })}
          </div>
          {listGoodData1?.listGood.goods?.length === 0 &&
            listGoodData?.listGood.goods?.length === 0 && (
              <div className="max-w-screen-fit mx-auto px-4 relative pt-4 ">
                <p className="text-center text-large break:text-Xlarge py-10 font-bold">
                  현재 판매하는 상품이 없습니다.
                </p>
              </div>
            )}
        </section>
      </main>
      <Footer />
      {(!isLoggedIn && (
        <Fade in={true}>
          <div
            onClick={() => {
              if (!isLoggedIn) {
                navigate("/login");
              }
              if (addressData?.listAddress.address?.length === 0) {
                navigate("/address/enroll");
              }
            }}
            className="absolute left-0 bottom-20 botom-20 text-center w-full"
          >
            <button className="w-[200px] h-8 inner-border inner-border-mainIcon text-mainIcon bg-white rounded-full text-sm">
              받으실 주소를 입력해주세요
            </button>
          </div>
        </Fade>
      )) ||
        (!myAddress && (
          <Fade in={true}>
            <div
              onClick={() => {
                if (!isLoggedIn) {
                  navigate("/login");
                }
                if (addressData?.listAddress.address?.length === 0) {
                  navigate("/address/enroll");
                } else {
                  navigate("address");
                }
              }}
              className="absolute left-0 bottom-20 botom-20 text-center w-full"
            >
              <button className="w-[200px] h-8 inner-border inner-border-mainIcon text-mainIcon bg-white rounded-full text-sm">
                받으실 주소를 입력해주세요
              </button>
            </div>
          </Fade>
        ))}
      <FootMenu />
    </div>
  );
};
