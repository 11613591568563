import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  UpBasketItemMutation,
  UpBasketItemMutationVariables,
} from "../gql/graphql";

const UP_BASKET_ITEM_MUTATION = gql`
  mutation upBasketItem($input: UpBasketItemInput!) {
    upBasketItem(input: $input) {
      ok
      err
    }
  }
`;

export const useUpBaksetItem = (
  onCompleted?: (data: UpBasketItemMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<UpBasketItemMutation, UpBasketItemMutationVariables>(
    UP_BASKET_ITEM_MUTATION,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
