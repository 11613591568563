import { ApolloError, gql, useMutation } from "@apollo/client";
import { EditInquiryMutation } from "../gql/graphql";

const EDIT_INQUIRY_MUTATION = gql`
  mutation editInquiry($input: EditInquiryInput!) {
    editInquiry(input: $input) {
      ok
      err
    }
  }
`;

export const useEditInquiry = (onCompleted?: (data: EditInquiryMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<EditInquiryMutation>(EDIT_INQUIRY_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
