import logo from "../assets/icons/payComplete.png";
import { useGetReceipt } from "../hooks/useGetReceipt";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { calculate } from "../apollo";
import { GetReceiptInput, GetReceiptQuery } from "../gql/graphql";
export const PaymentCompleted = () => {
  const navigate = useNavigate();
  const params = useParams();

  // get receipt
  const onGetReceipt = (data: GetReceiptQuery) => {
    const {
      getReceipt: { ok, err },
    } = data;
    if (ok) {
    }
  };
  const [
    getReceipt,
    { loading: getReceiptLoading, called: getReceiptCalled, data },
  ] = useGetReceipt(onGetReceipt);
  const getReceiptHandler = (receiptUuid: string) => {
    if (!getReceiptLoading) {
      const input: GetReceiptInput = {
        receiptUuid,
      };
      getReceipt({ variables: { input } });
    }
  };
  useEffect(() => {
    if (params) {
      const receiptUuid = params.receiptUuid
        ? params.receiptUuid.split("?")[0]
        : "";
      if (receiptUuid === "") alert("잘못된 접근입니다");
      getReceiptHandler(receiptUuid);
    }
  }, []);
  return (
    <div className="wrap max-w-screen-break mx-auto w-full h-full">
      <main className="max-w-screen-break relative pt-12 pb-32 overflow-auto">
        <img src={logo} alt="결제완료 닐리버리 로고" className="mx-auto" />
        <div className="pt-14">
          <p className="px-8 py-1 text-standard break:text-large text-darkFont font-bold">
            주문상품
          </p>
          <div className="px-4">
            {!getReceiptLoading &&
              data?.getReceipt.ok &&
              data.getReceipt.receipt?.orders?.map((order, index) => (
                <div className="pt-4" key={`receipt-gets-item-${index}`}>
                  <div className="basketCard">
                    <div className="cardTop flex gap-x-2 w-full">
                      <div className="flex-1 flex">
                        <img
                          src={`${
                            order?.good?.goodPosters?.find(
                              (goodPoster) => goodPoster.index === 1
                            )?.upload?.url
                          }`}
                          alt="이미지"
                          className="w-[72px] h-[72px] rounded-lg border border-btnLightLine"
                        />
                        <div className="px-2 flex-1">
                          <div className="w-full py-1">
                            <p className="text-darkFont h-9 text-small break:text-standardall ellipsis2 overflow-hidden">{`${order?.good?.title}`}</p>
                          </div>
                          <div className="h-7 flex flex-wrap justify-between content-center">
                            <span className="text-small break:text-standardall text-hintFont">{`${order?.good?.title}`}</span>
                            {order?.good?.price && (
                              <span className="text-darkFont font-bold text-small break:text-standardall">
                                {Number(order.amount).toLocaleString()}원
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="py-10 px-8 w-full max-w-screen-fit mx-auto">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="bg-mainIcon  block w-full max-w-screen-fit text-white text-xl font-bold h-[56px] mx-auto rounded-full"
          >
            확인
          </button>
        </div>
      </main>
    </div>
  );
};
