import { gql, useLazyQuery } from "@apollo/client";
import { ListCorporationSelectQuery } from "../gql/graphql";

const LIST_CORPORATION_SELECT = gql`
  query listCorporationSelect {
    listCorporationSelect {
      ok
      err
      corporations {
        id
        name
        regionKeyWord
      }
    }
  }
`;

export const useListCorporationSelect = (onCompleted?: (data: ListCorporationSelectQuery) => void) => {
  return useLazyQuery<ListCorporationSelectQuery>(LIST_CORPORATION_SELECT, {
    onCompleted,
  });
};
