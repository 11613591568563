import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DOMRoutes } from "../constants/props";
import { Home } from "../routes/home";
import { JoinDataform } from "../routes/join-dataform";
import { LogIn } from "../routes/login";
import { JoinAgree } from "../routes/join-agree";
import { NotFounded } from "../routes/404";
import { JoinComplete } from "../routes/join-complete";
import { Test } from "../routes/test";
import { NiliveryItem } from "../routes/niliveryItem";
import { MustEatItem } from "../routes/musteatItem";
import { GoodDetail } from "../routes/good-detail";
import { UseInfo } from "../routes/useInfo";
import { PersonalInfo } from "../routes/personalInfo";
import { DeliveryRefundInfo } from "../routes/delivery-refundInfo";
import { FindMail } from "../routes/get-email";
import { Finded } from "../routes/get-email-completed";
import { FindPassword } from "../routes/up-password";
import { ListCategory } from "../components/list-category";
import { WhatIsNilivery } from "../routes/whatIsNilivery";
import { Loading } from "../routes/loading";
import { JoinCertification } from "../routes/join-certification";
import { MobileCertification } from "../routes/join-mobile-certification";
import { CertificationFail } from "../routes/certification-fail";
import { ListGoodSearch } from "../routes/list-good-search";
import { KaKaoLogin } from "../components/kakaoLogin";
import { GetDataFail } from "../routes/get-data-fail";
import { JoinSNSDataform } from "../routes/join-sns-dataform";
import { Event } from "../routes/event";
import { ListGoodReview } from "../routes/list-good-review";
import { GetUserInfo } from "../routes/get-user-info";
import { HomeEmart } from "../routes/home-emart";
const LoggedOutRoutes: DOMRoutes[] = [
  { path: "/join/agree", key: "/join/agree", component: <JoinAgree /> },
  {
    path: "/join/dataform/",
    key: "/join/dataform/",
    component: <JoinDataform />,
  },
  {
    path: "/join/sns",
    key: "/join/sns",
    component: <JoinSNSDataform />,
  },
  {
    path: "/join/certification/:id",
    key: "/join/certification/:id",
    component: <JoinCertification />,
  },
  {
    path: "/mobileCertData/:cert",
    key: "/mobileCertData/:cert",
    component: <MobileCertification />,
  },
  { path: "/login", key: "/login", component: <LogIn /> },
  { path: "/findMail", key: "/findMail", component: <FindMail /> },
  { path: "/findPwd", key: "/findPwd", component: <FindPassword /> },
  { path: "/findUserInfo", key: "/findUserInfo", component: <GetUserInfo /> },
  { path: "/finded", key: "/finded", component: <Finded /> },
];

export const CommonRoutes: DOMRoutes[] = [
  { path: "/", key: "/", component: <Home /> },
  { path: "/:service", key: "/:service", component: <HomeEmart /> },
  {
    path: "/join/complete",
    key: "/join/complete",
    component: <JoinComplete />,
  },
  {
    path: "/certificationFail",
    key: "/certificationFail",
    component: <CertificationFail />,
  },
  {
    path: "/getDataFail",
    key: "/getDataFail",
    component: <GetDataFail />,
  },
  {
    path: "/join/complete",
    key: "/join/complete",
    component: <JoinComplete />,
  },
  {
    path: "/listGood/:search",
    key: "/listGood/:search",
    component: <ListGoodSearch />,
  },
  {
    path: "/niliveryitem",
    key: "/niliveryitem",
    component: <NiliveryItem />,
  },
  {
    path: "/musteatitem",
    key: "/musteatitem",
    component: <MustEatItem />,
  },
  {
    path: "/detail/:key",
    key: "/detail/:key",
    component: <GoodDetail />,
  },
  {
    path: "/useInfo",
    key: "/useInfo",
    component: <UseInfo />,
  },
  {
    path: "/personalInfo",
    key: "/personalInfo",
    component: <PersonalInfo />,
  },
  {
    path: "/deliveryRefundInfo",
    key: "/deliveryRefundInfo",
    component: <DeliveryRefundInfo />,
  },
  {
    path: "/category",
    key: "/category",
    component: <ListCategory />,
  },
  {
    path: "/whatIsNilivery",
    key: "/whatIsNilivery",
    component: <WhatIsNilivery />,
  },
  {
    path: "/event/:eventId",
    key: "/event/:eventId",
    component: <Event />,
  },
  {
    path: "/loading",
    key: "/loading",
    component: <Loading />,
  },
  {
    path: "/test/",
    key: "/test/",
    component: <Test />,
  },
  {
    path: "/kakaoLogin",
    key: "/kakaoLogin",
    component: <KaKaoLogin />,
  },
  {
    path: "/listGoodReview/:goodId",
    key: "/listGoodReview/:goodId",
    component: <ListGoodReview />,
  },
  { path: "/*", key: "/*", component: <NotFounded /> },
];

export const LoggedOutRouter = () => {
  return (
    <Router>
      <Routes>
        {CommonRoutes.map((route) => (
          <Route key={route.key} path={route.path} element={route.component} />
        ))}
        {LoggedOutRoutes.map((route) => (
          <Route key={route.key} path={route.path} element={route.component} />
        ))}
      </Routes>
    </Router>
  );
};
