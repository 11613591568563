import { HowToReceive } from "../constants/props";
import { OrderStatus } from "../gql/graphql";

export interface tracking {
  orderStatus: OrderStatus | undefined;
  howTo?: HowToReceive | null;
}
export const DeliveryTracking = ({ orderStatus, howTo }: tracking) => {
  return (
    <div className="box rounded-2xl border border-btnLightLine bg-[#fff] px-4">
      <div className="py-4 px-7 flex gap-x-[19px]">
        <div className="leftLine  mt-2 w-8">
          <div className="innerBox relative">
            <div className="line bg-mainIcon absolute h-full w-[1px] top-0 left-1/2 -translate-x-1/2"></div>
            <div className="w-full h-full flex flex-col flex-wrap content-center gap-y-16">
              <div
                className={`w-2 h-2  border-2  rounded-full  relative ${
                  orderStatus === OrderStatus.Pending || orderStatus === OrderStatus.Inspect || orderStatus === OrderStatus.Bound
                    ? "bg-mainIcon border-mainIcon "
                    : "bg-white border-toast"
                }`}
              >
                {(orderStatus === OrderStatus.Pending || orderStatus === OrderStatus.Inspect || orderStatus === OrderStatus.Bound) && (
                  <div className="w-3 h-3 absolute -left-1 -top-1 rounded-full bg-mainIcon animate-ping"></div>
                )}
              </div>

              <div
                className={`w-2 h-2  border-2  rounded-full  relative ${
                  orderStatus === OrderStatus.Drone || orderStatus === OrderStatus.Port ? "bg-mainIcon border-mainIcon " : "bg-white border-toast"
                }`}
              >
                {(orderStatus === OrderStatus.Drone || orderStatus === OrderStatus.Port) && (
                  <div className="w-3 h-3 absolute -left-1 -top-1 rounded-full bg-mainIcon animate-ping"></div>
                )}
              </div>
              <div
                className={`w-2 h-2  border-2  rounded-full  relative ${
                  orderStatus === OrderStatus.Stored ? "bg-mainIcon border-mainIcon " : "bg-white border-toast"
                }`}
              >
                {orderStatus === OrderStatus.Stored && <div className="w-3 h-3 absolute -left-1 -top-1 rounded-full bg-mainIcon animate-ping"></div>}
              </div>
              {howTo !== HowToReceive.PickUp && (
                <div
                  className={`w-2 h-2  border-2  rounded-full  relative ${
                    orderStatus === OrderStatus.HandOut || orderStatus === OrderStatus.PendingDelivered
                      ? "bg-mainIcon border-mainIcon "
                      : "bg-white border-toast"
                  }`}
                >
                  {(orderStatus === OrderStatus.HandOut || orderStatus === OrderStatus.PendingDelivered) && (
                    <div className="w-3 h-3 absolute -left-1 -top-1 rounded-full bg-mainIcon animate-ping"></div>
                  )}
                </div>
              )}

              <div
                className={`w-2 h-2  border-2  rounded-full  relative ${
                  orderStatus === OrderStatus.Delivered ? "bg-mainIcon border-mainIcon " : "bg-white border-toast"
                }`}
              >
                {orderStatus === OrderStatus.Delivered && (
                  <div className="w-3 h-3 absolute -left-1 -top-1 rounded-full bg-mainIcon animate-ping"></div>
                )}
              </div>

              {/* {droneDeliveryProcess.map((obj, index) => (
                <div
                  key={Math.random()}
                  className={`w-2 h-2  border-2  rounded-full  relative ${
                    obj.status === orderStatus
                      ? "bg-mainIcon border-mainIcon "
                      : "bg-white border-toast"
                  }`}
                >
                  {obj.status === orderStatus && (
                    <div className="w-3 h-3 absolute -left-1 -top-1 rounded-full bg-mainIcon animate-ping"></div>
                  )}
                </div>
              ))} */}
            </div>
          </div>
        </div>
        <div className="rightContent flex-1 flex flex-col flex-wrap gap-y-6">
          <div className="flex flex-col flex-wrap h-12">
            <div className="flex flex-wrap gap-x-2">
              <span
                className={`px-4 py-1 rounded-[20px] text-smallBtn  ${
                  orderStatus === OrderStatus.Pending || orderStatus === OrderStatus.Inspect || orderStatus === OrderStatus.Bound
                    ? "bg-mainIcon text-[#fff] font-bold "
                    : " bg-btnLightLine text-darkFont"
                }`}
              >
                물류센터
              </span>
              <p className="flex pb-1">
                <span className="py-[2px]  pl-1 text-darkFont text-appSmall">주문확인중</span>
              </p>
            </div>
          </div>
          <div className="flex flex-col flex-wrap h-12">
            <div className="flex flex-wrap gap-x-2">
              <span
                className={`px-4 py-1 rounded-[20px] text-smallBtn  ${
                  orderStatus === OrderStatus.Drone || orderStatus === OrderStatus.Port
                    ? "bg-mainIcon text-[#fff] font-bold"
                    : " bg-btnLightLine text-darkFont"
                }`}
              >
                드론
              </span>
              <p className="flex pb-1">
                <span className="py-[2px]  pl-1 text-darkFont text-appSmall">비행 시작</span>
              </p>
            </div>
          </div>
          <div className="flex flex-col flex-wrap h-12">
            <div className="flex flex-wrap gap-x-2">
              <span
                className={`px-4 py-1 rounded-[20px] text-smallBtn  ${
                  orderStatus === OrderStatus.Stored ? "bg-mainIcon text-[#fff] font-bold" : " bg-btnLightLine text-darkFont"
                }`}
              >
                버티포트
              </span>
              <p className="flex pb-1">
                <span className="py-[2px]  pl-1 text-darkFont text-appSmall">집하중</span>
              </p>
            </div>
          </div>
          {howTo !== HowToReceive.PickUp && (
            <div className="flex flex-col flex-wrap h-12">
              <div className="flex flex-wrap gap-x-2">
                <span
                  className={`px-4 py-1 rounded-[20px] text-smallBtn  ${
                    orderStatus === OrderStatus.HandOut || orderStatus === OrderStatus.PendingDelivered
                      ? "bg-mainIcon text-[#fff] font-bold"
                      : " bg-btnLightLine text-darkFont"
                  }`}
                >
                  라스트배송
                </span>
                <p className="flex pb-1">
                  <span className="py-[2px]  pl-1 text-darkFont text-appSmall">배송중</span>
                </p>
              </div>
            </div>
          )}

          <div className="flex flex-col flex-wrap h-12">
            <div className="flex flex-wrap gap-x-2">
              <span
                className={`px-4 py-1 rounded-[20px] text-smallBtn  ${
                  orderStatus === OrderStatus.Delivered ? "bg-mainIcon text-[#fff] font-bold " : " bg-btnLightLine text-darkFont"
                }`}
              >
                {howTo === HowToReceive.PickUp ? "픽업완료" : "배송완료"}
              </span>
            </div>
          </div>
          {/* {droneDeliveryProcess.map((obj, index) => (
            <div key={obj.work + index} className="h-12 text-grayFont">
              <div className="text-small break:text-standardall h-6 pl-4 flex flex-wrap  content-center">
                <p
                  className={`${
                    obj.status === orderStatus
                      ? "text-mainIcon"
                      : "text-grayFont"
                  }`}
                >
                  <span className="font-bold">{obj.location}</span>{" "}
                  <span>{obj.work}</span>
                </p>
              </div>
              <div className="text-small break:text-standardall h-6 pl-4 flex flex-wrap content-center">
                <span>
                  {moment(obj.date).format("M월 D일 ") +
                    "(" +
                    getDayKorean(obj.date.getDay()) +
                    ") 요일"}
                </span>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
};
