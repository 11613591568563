import { gql, useLazyQuery } from "@apollo/client";
import { ListOrderReviewQuery } from "../gql/graphql";

const LIST_ORDER_REVIEW = gql`
  query listOrderReview {
    listOrderReview {
      ok
      err
      orders {
        id
        review {
          id
        }
        goodName
        goodOptionName
        quantity
        amount
        status
        exchangeStatus
        redelivery
        goodOption {
          id
          name
          good {
            title
            goodPosters {
              upload {
                url
              }
            }
          }
        }
      }
    }
  }
`;
export const useListOrderReview = (onCompleted?: (data: ListOrderReviewQuery) => void) => {
  return useLazyQuery<ListOrderReviewQuery>(LIST_ORDER_REVIEW, {
    onCompleted,
  });
};
