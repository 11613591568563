import home from "../assets/icons/home.svg";
import search from "../assets/icons/search_foot.svg";
import my_page from "../assets/icons/my_page.svg";
import order from "../assets/icons/order.svg";
import niliveryIcon from "../assets/icons/NiliveryIcon.png";
import { useNavigate } from "react-router-dom";
import { useMe } from "../hooks/useME";
import { searchText } from "../apollo";
import { useReactiveVar } from "@apollo/client";
import { Dialog, Fade, Modal, Slide } from "@mui/material";
import { GoodSearch } from "./good-search";
import { useState } from "react";
import { AlertComponent } from "./alert";
interface footMenuProps {
  MenuCallback?: (state: boolean) => void;
}

export const FootMenu: React.FC<footMenuProps> = ({ MenuCallback }) => {
  const { data: me } = useMe();
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [searchUp, setSearchUp] = useState(false);
  const searchDownHandler = () => {
    setSearchUp(false);
  };
  const closeModal = () => {
    setAlertOpen(false);
    navigate("/login");
  };
  const useSearchText = useReactiveVar(searchText);
  return (
    <>
      <div
        id="menuBar"
        className="w-full max-w-screen-break break:max-w-screen-fit h-[72px] flex flex-wrap flex-col justify-end transform break:translate-x-0 absolute left-0 bottom-0 z-10"
      >
        <div className="abeezee bg-mainIcon rounded-t-3xl h-[64px] flex justify-between px-6  ">
          <button onClick={() => navigate("/")} className="w-[46px] pt-[2px]">
            <img src={home} alt="홈으로 가기" className="w-8 mx-auto" />
            <p className="text-center text-[11px] leading-4  text-white">홈</p>
          </button>
          <button
            onClick={() => {
              if (!searchUp) {
                setSearchUp(true);
              }
            }}
            className="w-[46px] pt-[2px]"
          >
            <img src={search} alt="검색창으로 가기" className="w-8 mx-auto" />
            <p className="text-center text-[11px] leading-4  text-white">
              검색
            </p>
          </button>
          <div className="w-[70px] relative">
            <button
              onClick={() => {
                navigate("/whatIsNilivery");
              }}
              className="absolute -top-4 left-0 w-full"
            >
              <img
                src={niliveryIcon}
                alt="이벤트?"
                className="w-full rounded-full"
              />
            </button>
          </div>
          {me ? (
            <button
              onClick={() => {
                if (me) {
                  navigate("/mypage");
                } else {
                  setAlertOpen(true);
                }
              }}
              className="w-[46px] pt-[2px]"
            >
              <img src={my_page} alt="마이페이지" className="w-8 mx-auto" />
              <p className="text-center text-[11px] leading-4  text-white">
                마이
              </p>
            </button>
          ) : (
            <button
              onClick={() => {
                navigate("/login");
              }}
              className="w-[46px] pt-[2px]"
            >
              <img src={my_page} alt="로그인" className="w-8 mx-auto" />
              <p className="text-center text-[11px] leading-4  text-white">
                로그인
              </p>
            </button>
          )}

          <button
            onClick={() => {
              if (me) {
                navigate("/mypage/orderList");
              } else {
                setAlertOpen(true);
              }
            }}
            className="w-[60px] pt-[2px]"
          >
            <img src={order} alt="주문내역 가기" className="w-8 mx-auto" />
            <p className="text-center text-[11px] leading-4  text-white">
              주문내역
            </p>
          </button>
        </div>
      </div>
      {/* alert */}
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <AlertComponent
          title={`안내`}
          desc={`로그인/회원가입 후 이용해주세요.`}
          closeFunction={closeModal}
        ></AlertComponent>
      </Dialog>
      {/* 검색 */}
      <Fade in={searchUp === true}>
        <div
          onClick={() => {
            setSearchUp(false);
          }}
          className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-30 z-10"
        ></div>
      </Fade>
      <Slide
        in={searchUp}
        direction={"up"}
        className="absolute bottom-0 left-0 w-full h-search z-10"
      >
        <div id="searchToast">
          <GoodSearch searchDown={searchDownHandler} search={useSearchText} />
        </div>
      </Slide>
    </>
  );
};
