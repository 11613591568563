import { Sns } from "../gql/graphql";

export interface DOMRoutes {
  path: string;
  key?: string;
  component: JSX.Element;
}

export interface AttrInfoSet {
  label?: string;
  value?: string;
}

export interface saveJson {
  typeName: string;
  attrs: ProductAttrTypes[];
}
export interface ProductAttrTypes {
  value: string;
  name: string;
}

export interface SnsState {
  email?: string;
  sns?: Sns;
}

export interface AddressInfo {
  regionKey?: string;
  mainAddress?: string;
  zoneCode?: string;
}

export enum HowToReceive {
  Delivery = "Delivery",
  PickUp = "PickUp",
}
