import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import left from "../assets/icons/left.svg";
import right from "../assets/icons/right.svg";
import { IGoodPosterItem } from "../constants/types";

interface thumbnailProps {
  posters?: IGoodPosterItem[] | null;
}
export const Thumbnails: React.FC<thumbnailProps> = ({ posters }) => {
  if (posters && posters.length === 1) {
    var pagination = document.getElementsByClassName("swiper-pagination")[0] as HTMLElement;
    pagination.innerHTML = "";
    var navigation_left = document.getElementsByClassName("swiper-navigation-left")[0] as HTMLElement;
    var navigation_right = document.getElementsByClassName("swiper-navigation-right")[0] as HTMLElement;

    navigation_left.innerHTML = "";
    navigation_right.innerHTML = "";
  }

  return (
    <div className="w-full h-full">
      <Swiper
        modules={[Pagination, Autoplay, Navigation]}
        slidesPerView={1}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
          type: "bullets",
        }}
        navigation={{
          nextEl: ".swiper-navigation-right",
          prevEl: ".swiper-navigation-left",
        }}
        className="relative h-full"
      >
        <div className="swiper-pagination"></div>
        <button className="absolute z-[3] top-1/2 transform -translate-y-1/2 left-0 swiper-navigation-left">
          <img src={left} alt="지난 것" className="w-16" />
        </button>
        <button className="absolute z-[3] top-1/2 transform -translate-y-1/2 right-0 swiper-navigation-right">
          <img src={right} alt="다음 것" className="w-16" />
        </button>
        {posters &&
          posters.length > 0 &&
          posters.map((poster) => (
            <SwiperSlide key={`posters - ${poster.id}`}>
              <img src={String(poster?.upload?.url)} alt={"썸네일" + poster.id} className="w-full aspect-square object-cover" />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
