import logo from "../assets/icons/logo.png";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const CertificationFail = () => {
  const navigate = useNavigate();
  const success = useLocation().state;

  // get receipt

  useEffect(() => {}, []);
  return (
    <div className="wrap max-w-screen-break min-h-screen mx-auto w-full pb-32 bg-red-100 bg-opacity-100">
      <main className="max-w-screen-break relative pt-12 px-8">
        <img src={logo} alt="결제완료 닐리버리 로고" className="mx-auto " />
        <p className="pt-20 text-center text-red-400 font-bold text-large">인증에 실패하였습니다.</p>
      </main>
      <div className="w-full flex flex-wrap absolute z-10 bottom-[80px] justify-center">
        <button
          onClick={() => {
            if (Number(success) === 1) {
              navigate("/mypage");
            } else {
              navigate("/login");
            }
          }}
          className="w-[200px] text-center py-2  text-[#fff]  bg-mainIcon font-bold text-btn break:text-standardall rounded-[20px] "
        >
          뒤로
        </button>
      </div>
    </div>
  );
};
