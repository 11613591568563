import gql from "graphql-tag";
import { ApolloError, useMutation } from "@apollo/client";
import { SendChangePasswordEmailInput, SendChangePasswordEmailMutation, SendChangePasswordEmailMutationVariables } from "../gql/graphql";

export interface ISendChangePasswordEmailForm extends SendChangePasswordEmailInput {}

const SEND_CHANGE_PASSWORD_EMAIL_MUTATION = gql`
  mutation sendChangePasswordEmail($input: SendChangePasswordEmailInput!) {
    sendChangePasswordEmail(input: $input) {
      ok
      err
    }
  }
`;

export const useSendChangePasswordEmail = (onCompleted?: (data: SendChangePasswordEmailMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<SendChangePasswordEmailMutation, SendChangePasswordEmailMutationVariables>(SEND_CHANGE_PASSWORD_EMAIL_MUTATION, {
    onCompleted,
    onError,
  });
};
