import { gql, useLazyQuery } from "@apollo/client";
import { ListGoodQuery, ListGoodQueryVariables } from "../gql/graphql";

const LIST_GOOD_QUERY = gql`
  query listGood($input: ListGoodInput!) {
    listGood(input: $input) {
      ok
      err
      goods {
        id
        stock
        price
        status
        discountRate
        title
        summary
        categoryFirst
        categorySecond
        tags
        bounds {
          status
        }
        corporations {
          id
          name
        }
        goodPosters {
          id
          upload {
            url
          }
        }
        goodReviews {
          id
        }
        goodOptions {
          id
          createdAt
          name
          stock
          price
          isActive
          discountRate
          desc
          corporation {
            id
            name
          }
          bounds {
            status
          }
        }
      }
    }
  }
`;

export const useListGood = (onCompleted?: (data: ListGoodQuery) => void) => {
  return useLazyQuery<ListGoodQuery, ListGoodQueryVariables>(LIST_GOOD_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
