import { gql, useLazyQuery } from "@apollo/client";
import {
  ListGoodOptionQuery,
  ListGoodOptionQueryVariables,
} from "../gql/graphql";

const LIST_GOOD_OPTION_QUERY = gql`
  query listGoodOption($input: ListGoodOptionInput!) {
    listGoodOption(input: $input) {
      ok
      err
      goodOptions {
        id
        createdAt
        name

        stock
        price
        isActive
        discountRate
        deliveryDate
        desc
        corporation {
          id
          name
        }
        bounds {
          status
        }
        good {
          id
          category
          stock
          price
          status
          discountRate
          title
          summary
          bounds {
            status
          }
          corporations {
            id
            name
          }
          goodPosters {
            id
            upload {
              url
            }
          }
        }
      }
    }
  }
`;

export const useListGoodOption = (
  onCompleted?: (data: ListGoodOptionQuery) => void
) => {
  return useLazyQuery<ListGoodOptionQuery, ListGoodOptionQueryVariables>(
    LIST_GOOD_OPTION_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
