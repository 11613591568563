import { gql, useLazyQuery } from "@apollo/client";
import { GetPgOptionQuery, GetPgOptionQueryVariables } from "../gql/graphql";

const GET_PGOPTION_QUERY = gql`
  query getPgOption($input: GetPgOptionInput!) {
    getPgOption(input: $input) {
      ok
      err
      pgOption {
        id
        receipt_url
        receipt {
          id
        }
      }
    }
  }
`;

export const useGetPgOption = (
  onCompleted?: (data: GetPgOptionQuery) => void
) => {
  return useLazyQuery<GetPgOptionQuery, GetPgOptionQueryVariables>(
    GET_PGOPTION_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
