import { ApolloError, gql, useQuery } from "@apollo/client";
import { GetBannerOptionActiveQuery } from "../gql/graphql";

const GET_BANNER_OPTION_ACTIVE_QUERY = gql`
  query getBannerOptionActive {
    getBannerOptionActive {
      ok
      err
      bannerOption {
        id
        index
        link
        title
        note
        alt
        isFree
        isRes
        isEvent
        isOnePlusOne
        upload {
          id
          uuid
          url
        }
      }
    }
  }
`;

export const useGetBannerOptionActive = (onCompleted?: (data: GetBannerOptionActiveQuery) => void, onError?: (error: ApolloError) => void) => {
  return useQuery<GetBannerOptionActiveQuery>(GET_BANNER_OPTION_ACTIVE_QUERY, {
    onCompleted,
    onError,
  });
};
