import { useListReceiptReview } from "../hooks/useListReceiptReview";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import {
  ListReceiptReviewInput,
  ListReceiptReviewQuery,
  Redelivery,
} from "../gql/graphql";

export const SubListReview = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  // pagination
  const pageHandler = (event: any, value: any) => {
    setPage(value);
  };

  // listreceipt
  const onListReceiptReview = (data: ListReceiptReviewQuery) => {};
  const [
    listReceiptReview,
    { data, loading: listReceiptReviewLoading, refetch },
  ] = useListReceiptReview(onListReceiptReview);
  const listReceiptReviewHandler = (page: number) => {
    const input: ListReceiptReviewInput = {
      page,
    };
    listReceiptReview({ variables: { input } });
  };
  // useeffect
  useEffect(() => {
    listReceiptReviewHandler(page);
  }, [page]);
  return (
    <section
      id="reviewList"
      className="p-4 pb-10  flex flex-wrap flex-col gap-y-4 "
    >
      {!listReceiptReviewLoading &&
        data?.listReceiptReview.receipts?.map((review) => (
          <div className="w-full" key={`review-${review.id}`}>
            <div className="review border border-btnLightLine rounded-2xl bg-white">
              <div className="h-10 py-2 px-4 text-xs flex flex-wrap content-center ">
                <p className="flex flex-wrap justify-between content-center flex-1 gap-x-1">
                  {review.paid_at && (
                    <span className="font-bold">
                      {`${moment(+review?.paid_at).format("YYYY.MM.DD")}`}
                    </span>
                  )}
                  <span>주문번호 {review.receiptNum}</span>
                </p>
              </div>
              <div className="orderBox space-y-4 p-4">
                {review.orders?.map((order, index, reviewArray) => (
                  <div
                    key={`receipt-order-${order.id}`}
                    className="flex flex-col gap-y-2"
                  >
                    <div
                      className={`flex flex-warp gap-x-4 overflow-hidden ${
                        index + 1 !== reviewArray.length &&
                        "border-b border-[#F0F0F4]"
                      }`}
                    >
                      {order.good?.goodPosters && (
                        <img
                          src={`${order.orderReceipt?.good_poster_url}`}
                          alt={`${order.goodName} 이미지`}
                          onClick={() => {
                            navigate(`/detail/${order.good?.id}`);
                          }}
                          className="h-[72px] aspect-square rounded-lg cursor-pointer"
                        />
                      )}
                      <div className="flex-1 flex flex-wrap flex-col ">
                        <p className="h-[46px] px-1 py-2 text-small break:text-standard overflow-hidden ellipsis2">
                          {`${order.orderReceipt?.good_title}`}
                        </p>
                        <div className="flex flex-wrap justify-between px-2 py-1 bg-[#FAFAFF] rounded-[20px]">
                          <p className="flex flex-wrap gap-x-2">
                            <span className="py-[3px] font-[#777B7D] text-Xsmall">
                              수량
                            </span>
                            <span className="font-bold py-[1px] px-4 rounded-[20px] bg-[#fff] text-smallBtn">
                              {order.quantity}
                            </span>
                            <span className="py-[3px] font-[#777B7D] text-Xsmall">
                              개
                            </span>
                          </p>
                          <p className=" text-smallBtn font-darkFont">
                            {order.redelivery === Redelivery.Redelivery
                              ? "재배송"
                              : order.amount.toLocaleString() + " 원"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-x-4">
                      <div className="flex flex-wrap flex-1">
                        <button
                          onClick={() => {
                            navigate("/crReview", {
                              state: order.id,
                            });
                          }}
                          className="content-center text-smallBtn py-1 rounded-lg flex-auto border-mainIcon border text-mainIcon cursor-pointer"
                        >
                          상품 잘 받아보셨나요? 리뷰를 작성해주세요.
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      {(!listReceiptReviewLoading &&
        data?.listReceiptReview.receipts?.length === 0) ||
        (!data?.listReceiptReview.receipts && (
          <p className="py-10 text-darkFont text-standard break:text-large text-center font-bold">
            쓸 수 있는 리뷰 내역이 없습니다.
          </p>
        ))}
      <Stack
        spacing={2}
        className="w-full absolute left-0 content-center bottom-[72px] flex flex-wrap  pt-8   "
      >
        <Pagination
          onChange={pageHandler}
          count={
            data?.listReceiptReview?.totalPages
              ? data?.listReceiptReview?.totalPages
              : 1
          }
          color={`primary`}
        ></Pagination>
      </Stack>
    </section>
  );
};
