import { Slide } from "@mui/material";
import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { FootMenu } from "./FootMenu";
import { ArrowLeft, CloseIcon } from "./Icons";
import pizza from "../assets/icons/Icon_pizza.svg";
import junkfood from "../assets/icons/Icon_burger.svg";
import chicken from "../assets/icons/Icon_chicken.svg";
import beefsteak from "../assets/icons/Icon_beefsteak.svg";
import vegetables from "../assets/icons/Icon_vegetables.svg";
import fruits from "../assets/icons/Icon_fruits.svg";
import donuts from "../assets/icons/Icon_donuts.svg";
import biscuits from "../assets/icons/Icon_biscuits.svg";
import cake from "../assets/icons/Icon_cake.svg";
import bread from "../assets/icons/Icon_bread.svg";
import coffee from "../assets/icons/Icon_coffee.svg";
import soda from "../assets/icons/Icon_soda.svg";
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { GoodsNiliveryIn } from "./goodsNilivery";
import { useListGood } from "../hooks/useListGood";
import { GoodCategory, ListGoodInput, GoodStatus } from "../gql/graphql";
export const categories = [
  {
    title: "음식 · 푸드",
    list: [
      { name: "피자", category: GoodCategory.Pizza, icon: pizza },
      { name: "패스트푸트", category: GoodCategory.JunkFood, icon: junkfood },
      { name: "치킨", category: GoodCategory.Chicken, icon: chicken },
      { name: "고기·육류", category: GoodCategory.Meat, icon: beefsteak },
    ],
  },
  {
    title: "로컬푸드",
    list: [
      {
        name: "로컬야채",
        category: GoodCategory.LocalVegitable,
        icon: vegetables,
      },
      { name: "로컬과일", category: GoodCategory.LocalFruits, icon: fruits },
    ],
  },
  {
    title: "카페 · 디저트 · 베이커리",
    list: [
      { name: "디저트", category: GoodCategory.Dessert, icon: donuts },
      { name: "과자·쿠키", category: GoodCategory.Cookie, icon: biscuits },
      { name: "케이크", category: GoodCategory.Cake, icon: cake },
      { name: "베이커리", category: GoodCategory.Bakery, icon: bread },
    ],
  },
  {
    title: "음료",
    list: [
      { name: "커피", category: GoodCategory.Coffee, icon: coffee },
      { name: "음료", category: GoodCategory.Drink, icon: soda },
    ],
  },
];

export const ListCategory = () => {
  const [slides, setSlides] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);

  const navigate = useNavigate();
  const usePageIndicator = useReactiveVar(pageIndicator);
  const [listGood, { data: listgoods, loading, refetch }] = useListGood();
  const listGoodHandler = (category: GoodCategory | null) => {
    const input: ListGoodInput = {
      ...(page && { page: +page }),
      ...(category && { category: category }),
      status: GoodStatus.GoodSales,
    };
    listGood({ variables: { input } });
    setSlides(!slides);
  };
  useEffect(() => {
    pageIndicator("메뉴 카테고리별 보기");
  }, []);
  return (
    <div className="h-full overflow-x-hidden relative flex flex-col">
      <div className="max-w-screen-break mx-auto w-full h-[48px] flex flex-wrap content-center px-4 relative z-10">
        {slides && (
          <div className="w-8 h-10 flex flex-wrap content-center mr-[14px]">
            <button onClick={() => navigate(-1)} className="w-full h-8 ">
              <ArrowLeft />
            </button>
          </div>
        )}
        {!slides && (
          <div className="w-8 h-10 flex flex-wrap content-center mr-[14px]">
            <button onClick={() => setSlides(!slides)} className="w-full h-8 ">
              <CloseIcon />
            </button>
          </div>
        )}
        <div className="h-10 flex-1 p-2 mr-[9px]">
          <h2
            className={`text-h2 font-bold
                text-mainIcon
              `}
          >
            {usePageIndicator}
          </h2>
        </div>
      </div>
      <main className="content pb-4 max-w-screen-break relative overflow-y-auto overflow-x-hidden removeScroll flex-1">
        <Slide in={slides} direction={"right"}>
          <div className="relative pb-20">
            <div className="back z-[0] rounded-full absolute opacity-[40%] bg-pointIcon w-[120px] h-[120px] left-[21%] top-[28%]"></div>
            <div className="back z-[0] rounded-full absolute opacity-[40%] bg-pointIcon w-10 h-10 right-[33%] top-[22%]"></div>
            <div className="back z-[0] rounded-full absolute opacity-[40%] bg-pointIcon w-10 h-10 left-[37%] top-[66%]"></div>
            <div className="back z-[0] rounded-full absolute opacity-[40%] bg-pointIcon w-24 h-24 right-[25%] top-[75%]"></div>
            {categories.map((obj) => (
              <div key={obj.title} className="pt-8 px-4 relative z-[1]">
                <p className="gilbert text-center text-titlePoint leading-8">{obj.title}</p>
                <div className="mt-2 flex flex-wrap justify-center gap-x-4">
                  {obj.list.map((list) => (
                    <button
                      onClick={() => {
                        listGoodHandler(list.category);
                      }}
                      key={list.name}
                      className="w-16 h-16"
                    >
                      <img src={list.icon} alt={list.name + " 아이콘"} className="mx-auto" />
                      <p className="text-center text-small break:text-standardall py-[2px] font-bold text-darkFont">{list.name}</p>
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Slide>
        <Slide in={!slides} direction={"left"}>
          <div className="absolute left-0 top-0 w-full h-full bg-white px-4 pt-4 pb-24 overflow-auto">
            <div className=" w-full overflow-auto removeScroll">
              {loading && (
                <div className="w-full h-full bg-[rgba(0,0,0,0)]">
                  <p className="py-10">검색 중입니다.</p>
                </div>
              )}
              {!loading && listgoods?.listGood?.goods?.length == 0 && (
                <div className="w-full h-full ">
                  <p className="py-10 text-center">검색 결과가 없습니다.</p>
                </div>
              )}
              {!loading &&
                listgoods?.listGood.goods &&
                listgoods?.listGood.goods.map((obj) => (
                  <GoodsNiliveryIn
                    key={obj.title + obj.id}
                    id={obj.id}
                    title={obj.title}
                    subTitle={obj.summary}
                    image={obj.goodPosters}
                    brand={obj.corporation?.name}
                    desc={obj.summary}
                    classes={obj.title}
                    price={obj.price ? obj.price : 1}
                    discountRate={obj.discountRate}
                  />
                ))}
            </div>
          </div>
        </Slide>
      </main>
      <div className="w-full relative z-[2]">
        <FootMenu MenuCallback={setSlides} />
      </div>
    </div>
  );
};
