import { gql, useLazyQuery } from "@apollo/client";
import { GetBannerMainEventQuery, GetBannerMainEventQueryVariables } from "../gql/graphql";

const GET_BANNER_MAIN_EVENT_QUERY = gql`
  query getBannerMainEvent($input: GetBannerMainEventInput!) {
    getBannerMainEvent(input: $input) {
      ok
      err
      bannerMainEvent {
        id
        title
        link
        upload {
          id
          uuid
          url
        }
      }
    }
  }
`;

export const useGetBannerMainEvent = (onCompleted?: (data: GetBannerMainEventQuery) => void) => {
  return useLazyQuery<GetBannerMainEventQuery, GetBannerMainEventQueryVariables>(GET_BANNER_MAIN_EVENT_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
