import { TopBar } from "../components/topBar";
import setting from "../assets/icons/setting.svg";
import arrow_right from "../assets/icons/arrow_right.svg";
import { useMe } from "../hooks/useME";
import { FootMenu } from "../components/FootMenu";
import { Link, useNavigate } from "react-router-dom";
import { useListReceipt } from "../hooks/useListReceipt";
import { useEffect, useState } from "react";
import drone from "../assets/icons/mypage_drone.svg";
import { useListBaksetItem } from "../hooks/useListBaksetMine";
import { useListInquiry } from "../hooks/useListInquiry";
import { useListMyGoodReview } from "../hooks/useListMyGoodReview";
import { useListOrderReview } from "../hooks/useListOrderReview";
import { logOut } from "../token";
import {
  ListBasketItemQuery,
  ListInquiryQuery,
  ListMyGoodReviewInput,
  ListMyGoodReviewQuery,
  ListOrderReviewQuery,
  ListReceiptInput,
  ListReceiptQuery,
} from "../gql/graphql";

export const Mypage = () => {
  var today = new Date();
  const navigate = useNavigate();
  const { data: me } = useMe();
  const [recentReceipts, setRecentReceipts] = useState<number>(0);
  const [MyReview, setMyReview] = useState<number>(0);
  const [basketNumber, setBasketNumber] = useState<number>(0);
  const [inquiryNumber, setInquiryNumber] = useState<number>(0);
  const [orderRev, setOrderRev] = useState<number>(0);
  const onListReceipt = (data: ListReceiptQuery) => {
    const {
      listReceipt: { ok, err, receipts },
    } = data;
    if (ok) {
      setRecentReceipts(Number(receipts?.length));
    }
  };
  const [listReceipt, { data: listReceiptData }] =
    useListReceipt(onListReceipt);
  const listReceiptHandler = () => {
    const input: ListReceiptInput = {
      page: 1,
      all: true,
      from: new Date(today.setMonth(today.getMonth() - 1)),
    };
    listReceipt({ variables: { input } });
  };
  const onCompleted = async (data: ListBasketItemQuery) => {
    const {
      listBasketItem: { ok, err, basketItems },
    } = data;
    if (ok) {
      setBasketNumber(Number(basketItems?.length));
    }
  };
  const [listBasketItem, { data: listBasketItemData, loading, refetch }] =
    useListBaksetItem(onCompleted);
  const onListsCompleted = (data: ListInquiryQuery) => {
    const {
      listInquiry: { ok, err, inquiries },
    } = data;
    if (ok) {
      setInquiryNumber(Number(inquiries?.length));
    }
  };
  const [listInquiry, { data }] = useListInquiry(onListsCompleted);

  // 쓴 리뷰 리스트 수
  // listMyReview
  const onListMyReview = (data: ListMyGoodReviewQuery) => {
    const {
      listMyGoodReview: { ok, err, goodReviews },
    } = data;
    if (ok) {
      setMyReview(Number(goodReviews?.length));
    } else {
    }
  };
  const [listMyGoodReview, {}] = useListMyGoodReview(onListMyReview);
  const ListMyGoodReviewHandler = () => {
    const input: ListMyGoodReviewInput = {
      page: 1,
    };
    listMyGoodReview({ variables: { input } });
  };
  const onListOrderReview = (data: ListOrderReviewQuery) => {
    const {
      listOrderReview: { ok, err, orders },
    } = data;
    if (ok) {
      setOrderRev(Number(orders?.length));
    }
  };
  // -----     전체 리뷰 수     -----
  const [listOrderReview, { data: orderReview }] =
    useListOrderReview(onListOrderReview);
  const mypageMenu = [
    {
      main: "나의 주문",
      sub: [
        { subtitle: "주문 / 배송 내역 조회", link: "/mypage/orderList" },
        { subtitle: "나의 리뷰", link: "/listReview" },
        { subtitle: "문의하기", link: "/mypage/inquiryList" },
      ],
    },
    {
      main: "개인 정보 설정",
      sub: [
        // { subtitle: "카드 등록", link: "/" },
        { subtitle: "배송지 등록", link: "/address" },
        { subtitle: "개인정보수정", link: "/mypage/change" },
      ],
    },
    {
      main: "닐리버리 방침",
      sub: [
        { subtitle: "서비스 이용약관", link: "/useInfo" },
        { subtitle: "개인정보취급방침", link: "/personalInfo" },
        { subtitle: "배송/교환환불 약관", link: "/deliveryRefundInfo" },
      ],
    },
  ];

  useEffect(() => {
    listReceiptHandler();
    listBasketItem();
    listInquiry({ variables: { input: { page: 1 } } });
    ListMyGoodReviewHandler();
    listOrderReview();
  }, []);
  return (
    <main className="max-w-screen-break mx-auto overflow-auto h-full removeScroll flex flex-col">
      <div id="myHead" className="min-h-[194px] h-[194px] w-full relative">
        <div className="w-full h-[168px] bg-mainIcon rounded-br-[120px] absolute left-0 top-0"></div>
        <TopBar
          arrow={true}
          arrowColor={"#ffffff"}
          home={true}
          // homeColor={"#fff"}
          shop={true}
          // shopColor={"#fff"}
          backColor={`#00B0EB`}
        />
        <div className="px-4 pt-4 relative z-10">
          <div className="pl-4">
            <div className="h-8 py-1 px-2 flex flex-wrap justify-between content-center">
              <p className="text-white text-standard break:text-large font-bold tracking-[4px]">
                {me?.me.name}
              </p>
              <button>
                <Link to="/mypage/change">
                  <img src={setting} alt="개인정보 수정" />
                </Link>
              </button>
            </div>
            <div className="h-[26px] py-1 px-2">
              <p className="text-white text-small break:text-standardall">
                {me?.me.username}
              </p>
            </div>
            <div className="flex flex-wrap gap-x-2 mt-4 relative ">
              <img
                src={drone}
                alt="드론"
                className="absolute top-[-70%] right-[20%]"
              />
              <div
                onClick={() => navigate("/mypage/orderList")}
                className="flex-1 h-14 rounded-lg px-2 pt-2 bg-white border-2 border-lightFont hover:underline cursor-pointer"
              >
                <p className="text-center text-darkFont text-small break:text-standardall">
                  최근주문
                </p>
                <p
                  className={`text-center text-standard break:text-large font-bold ${
                    recentReceipts === 0 ? "text-hintFont" : "text-mainIcon"
                  }`}
                >
                  {recentReceipts}
                </p>
              </div>
              {/* <div className="flex-1 h-14 rounded-lg px-2 pt-2 bg-white border-2 border-lightFont ">
                <p className="text-center text-darkFont text-small break:text-standardall">
                  리뷰
                </p>
                <p className="text-center text-hintFont text-standard break:text-large font-bold">
                  0
                </p>
              </div> */}
              <div
                onClick={() => navigate("/baskets/2")}
                className="flex-1 h-14 rounded-lg px-2 pt-2 bg-white border-2 border-lightFont hover:underline cursor-pointer"
              >
                <p className="text-center text-darkFont text-small break:text-standardall">
                  장바구니
                </p>
                <p
                  className={`text-center text-standard break:text-large font-bold ${
                    basketNumber === 0 ? "text-hintFont" : "text-mainIcon"
                  }`}
                >
                  {basketNumber}
                </p>
              </div>
              <div
                onClick={() => navigate("/mypage/inquiryList")}
                className="flex-1 h-14 rounded-lg px-2 pt-2 bg-white border-2 border-lightFont cursor-pointer hover:underline"
              >
                <p className="text-center text-darkFont text-small break:text-standardall">
                  문의내역
                </p>
                <p
                  className={`text-center text-standard break:text-large font-bold ${
                    inquiryNumber === 0 ? "text-hintFont" : "text-mainIcon"
                  }`}
                >
                  {inquiryNumber}
                </p>
              </div>
              <div
                onClick={() => navigate("/listReview")}
                className="flex-1 h-14 rounded-lg px-2 pt-2 bg-white border-2 border-lightFont cursor-pointer hover:underline"
              >
                <p className="text-center text-darkFont text-small break:text-standardall">
                  리뷰
                </p>
                <p
                  className={`text-center text-standard break:text-large font-bold text-mainIcon`}
                >
                  <span
                    className={`${
                      MyReview === 0 ? "text-hintFont" : "text-mainIcon"
                    }`}
                  >
                    {MyReview}
                  </span>
                  /
                  <span
                    className={` ${
                      orderRev === 0 ? "text-hintFont" : "text-mainIcon"
                    }`}
                  >
                    {orderRev}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="myBody" className="flex-1 pb-20 relative">
        {mypageMenu.map((menu) => (
          <div key={menu.main} className="pt-4">
            <div className="h-[34px] py-1 px-4">
              <p className="text-small break:text-standardall text-hintFont px-4 py-1">
                {menu.main}
              </p>
            </div>
            {menu.sub.map((sub) => (
              <button
                onClick={() => navigate(sub.link)}
                key={sub.subtitle}
                className="w-full h-10 px-4 py-1 flex flex-wrap justify-between content-center"
              >
                <p className="text-grayFont text-standard break:text-large px-4">
                  {sub.subtitle}
                </p>
                <img src={arrow_right} alt={sub.subtitle + "바로가기"} />
              </button>
            ))}
          </div>
        ))}
        <div className="w-full px-6 py-2">
          <button
            onClick={logOut}
            className="border border-btnDarkLine block w-full max-w-screen-fit text-grayFont text-[20px] leading-6 font-bold  py-[9px] mx-auto rounded-[20px]"
          >
            로그아웃
          </button>
        </div>
      </div>

      <FootMenu />
    </main>
  );
};
