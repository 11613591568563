import { ApolloError, gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  GetBannerMainActiveQuery,
  GetBannerMainActiveQueryVariables,
} from "../gql/graphql";

const GET_BANNER_MAIN_ACTIVE_QUERY = gql`
  query getBannerMainActive($input: GetBannerMainActiveInput!) {
    getBannerMainActive(input: $input) {
      ok
      err
      bannerMain {
        id
        index
        link
        mainType
        title
        note
        alt
        isFree
        isRes
        isEvent
        isOnePlusOne
        linkServices {
          id
          name
          link
        }
        upload {
          id
          uuid
          url
        }
        event {
          id
          title
          link
          isActive
          upload {
            id
            uuid
            url
          }
        }
      }
    }
  }
`;

export const useGetBannerMainActive = (
  onCompleted?: (data: GetBannerMainActiveQuery) => void,
  onError?: (error: ApolloError) => void
) => {
  return useLazyQuery<
    GetBannerMainActiveQuery,
    GetBannerMainActiveQueryVariables
  >(GET_BANNER_MAIN_ACTIVE_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
    onError,
  });
};
