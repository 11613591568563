import { gql, useLazyQuery } from "@apollo/client";
import { ListAddressQuery } from "../gql/graphql";

const LIST_ADDRESS_QUERY = gql`
  query listAddress {
    listAddress {
      ok
      err
      address {
        id
        username
        name
        phone
        zoneCode
        mainAddress
        subAddress
        message
        pass
        region
        isRep
      }
    }
  }
`;

export const useListAddress = (onCompleted?: (data: ListAddressQuery) => void) => {
  return useLazyQuery<ListAddressQuery>(LIST_ADDRESS_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
