import { ApolloError, gql, useMutation } from "@apollo/client";
import { DelBasketItemMutation, DelBasketItemMutationVariables } from "../gql/graphql";

const DEL_BASKET_ITEM_MUTATION = gql`
  mutation delBasketItem($input: DelBasketItemInput!) {
    delBasketItem(input: $input) {
      ok
      err
    }
  }
`;

export const useDelBaksetItem = (onCompleted?: (data: DelBasketItemMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<DelBasketItemMutation, DelBasketItemMutationVariables>(DEL_BASKET_ITEM_MUTATION, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
