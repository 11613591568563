import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { useListMyGoodReview } from "../hooks/useListMyGoodReview";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import moment from "moment";
import star_yellow from "../assets/icons/star_yellow.svg";
import star_gray from "../assets/icons/star_gray.svg";
import trash from "../assets/icons/trash.svg";
import { useNavigate } from "react-router-dom";
import { ListMyGoodReviewInput, ListMyGoodReviewQuery } from "../gql/graphql";
import { IGoodReviewFull } from "../constants/types";

type ListGoodReviewFull = IGoodReviewFull & { full?: boolean };
export const SubMyReview = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  // collapse

  // pagination
  const pageHandler = (event: any, value: any) => {
    setPage(value);
  };
  // listMyReview

  const [listReviewState, setListReviewState] = useState<ListGoodReviewFull[]>();
  const onListMyReview = (data: ListMyGoodReviewQuery) => {
    const {
      listMyGoodReview: { ok, err, goodReviews },
    } = data;
    if (ok && goodReviews) {
      setListReviewState(goodReviews as ListGoodReviewFull[]);
    } else {
      console.log(err);
    }
  };
  const [listMyGoodReview, { data, loading: listMyGoodReviewLoading, refetch }] = useListMyGoodReview(onListMyReview);
  const ListMyGoodReviewHandler = (page: number) => {
    const input: ListMyGoodReviewInput = {
      page,
    };
    listMyGoodReview({ variables: { input } });
  };

  // useeffect
  useEffect(() => {
    pageIndicator("나의 리뷰");
    ListMyGoodReviewHandler(page);
  }, [page]);

  // reviewState Edit handler
  const reviewShowAllHandler = (index: number) => {
    if (listReviewState) {
      const stateList = [...listReviewState];
      const indexPart = stateList[index];
      if (indexPart.full) {
        indexPart.full = false;
      } else {
        indexPart.full = true;
      }
      setListReviewState(stateList);
    }
  };
  return (
    <section id="writtenReviewList" className=" flex flex-wrap flex-col gap-y-4 p-4 pb-10">
      {!listMyGoodReviewLoading &&
        listReviewState?.map((review, reviewIndex) => (
          <div className="bg-white" key={`review-${review.id}`}>
            <div className="review border-2 rounded-lg p-4">
              <div className="text-small leading-4 flex flex-wrap content-center justify-between">
                <p className="flex flex-wrap content-center justify-center">
                  {review.createdAt && <span className="font-bold">{review.good.title}</span>}
                </p>
                <button
                  onClick={() => {
                    navigate(`/editReview/${review.id}`);
                  }}
                  className="text-grayFont rounded-lg border border-btnLightLine px-2 py-1"
                >
                  수정
                </button>
              </div>
              <div className="flex flex-wrap gap-x-1 pt-1">
                <p className="text-grayFont text-[10px] leading-3 flex flex-wrap content-center px-1">{`${moment(review.createdAt).format(
                  "YYYY.MM.DD"
                )}`}</p>
                <p className="py-1 px-4 flex flex-wrap content-center gap-x-1">
                  <img className="w-4 h-4" src={review.rating >= 1 ? star_yellow : star_gray} alt="별점" />
                  <img className="w-4 h-4" src={review.rating >= 2 ? star_yellow : star_gray} alt="별점" />
                  <img className="w-4 h-4" src={review.rating >= 3 ? star_yellow : star_gray} alt="별점" />
                  <img className="w-4 h-4" src={review.rating >= 4 ? star_yellow : star_gray} alt="별점" />
                  <img className="w-4 h-4" src={review.rating >= 5 ? star_yellow : star_gray} alt="별점" />
                </p>
              </div>
              <div className="orderBox space-y-4">
                {/* map component order */}
                <div>
                  <div className={`pt-1 grid  gap-x-2 overflow-hidden ${review.full ? "grid-cols-1" : "grid-cols-2"}`}>
                    {review?.goodReviewPosters &&
                      review?.goodReviewPosters.map((poster, index, posterArray) => (
                        <div key={`review-poster-${index}`} className={`p-1 relative  ${index > 1 && !review.full && "hidden"}`}>
                          <img
                            src={`${poster.upload?.url}`}
                            alt={`${review.author} 리뷰`}
                            className={`rounded-lg ${review.full ? "" : "aspect-[140/100]"}`}
                          />
                          {posterArray.length > 2 && index == 1 && !review.full && (
                            <div
                              onClick={() => {
                                reviewShowAllHandler(reviewIndex);
                              }}
                              className="p-1 w-full h-full absolute left-0 top-0 cursor-pointer "
                            >
                              <div className="text-white text-[28px] rounded-lg flex-wrap flex content-center justify-center bg-black bg-opacity-30 w-full h-full">
                                +{posterArray.length - 2}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                  <div className="mt-1 py-2">
                    <p className={`px-1 text-small overflow-hidden whitespace-pre-wrap ${!review.full && "ellipsis2 "}`}>{`${review.note}`}</p>
                  </div>
                  <div className="mt-1">
                    <span
                      onClick={() => {
                        reviewShowAllHandler(reviewIndex);
                      }}
                      className="cursor-pointer text-xs px-2 font-bold underline text-darkFont"
                    >
                      {review.full ? "닫기" : "자세히보기"}
                    </span>
                  </div>
                </div>
                {/* order end */}
              </div>
            </div>
          </div>
        ))}
      {!listMyGoodReviewLoading && listReviewState && listReviewState.length === 0 && (
        <p className="py-10 text-darkFont text-standard break:text-large text-center font-bold">리뷰 내역이 비어있어요..</p>
      )}
      <Stack spacing={2} className="w-full absolute left-0 content-center bottom-[72px] flex flex-wrap  pt-8 ">
        <Pagination
          onChange={pageHandler}
          count={data?.listMyGoodReview?.totalPages ? data?.listMyGoodReview?.totalPages : 1}
          color={`primary`}
        ></Pagination>
      </Stack>
    </section>
  );
};
