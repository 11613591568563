import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import { logIn } from "../token";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCrSnsUser } from "../hooks/useCrSnsUser";
import { CrSnsUserInput, CrSnsUserMutation } from "../gql/graphql";

export const JoinSNSDataform = () => {
  const navigate = useNavigate();
  const state = useLocation().state;
  const info = JSON.parse(String(state));
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors, isValid },
  } = useForm<CrSnsUserInput>({
    mode: "onChange",
  });

  const onCompleted = async (data: CrSnsUserMutation) => {
    const {
      crSnsUser: { ok, err, id, token },
    } = data;
    if (ok && token) {
      logIn(token);
      window.location.href = "/join/complete";
    } else {
      alert(err);
    }
  };
  const [crSnsUser, { loading }] = useCrSnsUser(onCompleted);

  const onSubmit = () => {
    const { name, username, phone } = getValues();
    const crSnsUserInput: CrSnsUserInput = {
      name,
      phone,
      username,
      sns: info.sns,
    };
    crSnsUser({
      variables: {
        input: crSnsUserInput,
      },
    });
  };
  const onInvalid = () => {};

  useEffect(() => {
    pageIndicator("추가정보입력");
    setValue("username", info.email);
  }, []);

  return (
    <div className="w-full  h-full overflow-auto">
      <form onSubmit={handleSubmit(onSubmit, onInvalid)} className="relative h-full flex flex-col">
        <div className="relative flex-1 flex flex-wrap flex-col">
          <div className="border-b-[0.5px]">
            <TopBar arrow={true} heading={true} exit={true} />
          </div>
          <div className="px-9 flex flex-col flex-wrap flex-1 gap-y-5 pt-[34px]">
            <div className="fieldBox w-full">
              <label htmlFor="join_email" className="text-h3 w-full block text-darkFont pl-2 py-1">
                <div className="flex flex-wrap">
                  <span className="p-1  text-appSmall">이메일주소</span>
                  <span className=" text-[#EC8C7F] text-Xsmall align-top">*</span>
                </div>
              </label>
              <input
                {...register(`username`)}
                type="email"
                className="pl-5 pr-[6px] py-2 border border-btnDarkLine text-standard w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                disabled
                id="join_email"
              />
              <p className="text-mainIcon text-Xsmall py-1 px-2">• 아이디로 사용됩니다.</p>
            </div>
            <div className="fieldBox w-full">
              <label htmlFor="join_name" className="text-h3 w-full block text-darkFont pl-2 py-1">
                <div className="flex flex-wrap">
                  <span className="p-1  text-appSmall">이름</span>
                  <span className=" text-[#EC8C7F] text-Xsmall align-top">*</span>
                </div>
              </label>
              <input
                {...register(`name`, {
                  required: "• 이름은 필수 입력 정보입니다.",
                })}
                type="text"
                className="pl-5 pr-[6px] py-2 border border-btnDarkLine text-standard w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                id="join_name"
              />
              <div className="h-5">{errors.name?.message && <p className="text-shade1 text-Xsmall py-1 px-2">{errors.name?.message}</p>}</div>
            </div>
            <div className="fieldBox w-full">
              <label htmlFor="join_phone" className="text-h3 w-full block text-darkFont pl-2 py-1">
                <div className="flex flex-wrap">
                  <span className="p-1  text-appSmall">전화번호</span>
                  <span className=" text-[#EC8C7F] text-Xsmall align-top">*</span>
                </div>
              </label>
              <input
                {...register("phone", {
                  pattern: {
                    value: /^[0-9]{10,11}$/,
                    message: "-빼고 번호만 입력해주세요",
                  },
                  required: "전화번호는 필수 입력 정보입니다.",
                })}
                placeholder={`"-" 빼고 숫자만 입력해주세요`}
                type="tel"
                maxLength={11}
                onKeyUp={(e) => (e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, ""))}
                className="pl-5 pr-[6px] py-2 border border-btnDarkLine text-standard w-full rounded-lg focus:outline-mainIcon focus:border-mainIcon"
                id="join_phone"
              />
              <div className="h-5"> {errors.phone?.message && <p className="text-shade1 text-Xsmall py-1 px-2">{errors.phone.message}</p>}</div>
            </div>
          </div>
        </div>
        <div className="w-full px-5 py-2">
          <button
            type="submit"
            className="border border-mainIcon block w-full max-w-screen-fit text-mainIcon text-[20px] leading-6 font-bold  py-[7px] mx-auto rounded-[20px]"
          >
            회원가입
          </button>
        </div>
      </form>
    </div>
  );
};
