import { ApolloError, gql, useLazyQuery } from "@apollo/client";
import { GetKakaoEmailQuery, GetKakaoEmailQueryVariables } from "../gql/graphql";

const GET_KAKAO_EMAIL = gql`
  query getKakaoEmail($input: GetKakaoEmailInput!) {
    getKakaoEmail(input: $input) {
      ok
      err
      email
    }
  }
`;

export const useGetKakaoEmail = (onCompleted?: (data: GetKakaoEmailQuery) => void, onError?: (error: ApolloError) => void) => {
  return useLazyQuery<GetKakaoEmailQuery, GetKakaoEmailQueryVariables>(GET_KAKAO_EMAIL, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
