import { ReactNode, useEffect } from "react";

interface AlertModalProps {
  title?: string;
  desc?: string;
  closeFunction?: () => void;
}

interface ConfirmModalProps {
  title?: string;
  desc?: string;
  closeFunction?: () => void;
  confirmFunction?: () => void;
}
export const AlertComponent: React.FC<AlertModalProps> = ({
  title,
  desc,
  closeFunction,
}) => {
  useEffect(() => {}, []);

  return (
    <div className="max-w-[304px] w-full bg-white rounded-2xl">
      <div className="flex flex-wrap">
        <h1 className="w-full text-center py-6 text-[#00B0EB] text-[16px] leading-5   ">
          {title}
        </h1>
        <div className="w-full p-4">
          <p
            id="innerHtml"
            dangerouslySetInnerHTML={{ __html: String(desc) }}
            className="w-full text-center px-4 pb-6 text-[16px] leading-5 keep-all "
          ></p>
        </div>
        <button
          className="w-full flex-1 py-4  text-[#00B0EB] text-[15px] leading-5  border-t-[1px] border-[#DCDCE7]"
          onClick={closeFunction && closeFunction}
        >
          확인
        </button>
      </div>
    </div>
  );
};
export const ConfirmComponent: React.FC<ConfirmModalProps> = ({
  title,
  desc,
  closeFunction,
  confirmFunction,
}) => {
  return (
    <div className="max-w-[304px]  bg-white  rounded-2xl">
      <div className="flex flex-wrap">
        <h1 className="w-full text-center py-6 text-[#00B0EB] text-[16px] leading-5   ">
          {title}
        </h1>
        <div className="w-full p-4">
          <p
            id="innerHtml"
            dangerouslySetInnerHTML={{ __html: String(desc) }}
            className="w-full text-center px-4 pb-6 text-[16px] leading-5 keep-all "
          ></p>
        </div>
        <div className="w-full">
          <button
            className="w-1/2 py-4  text-grayFont text-[15px] leading-5  border-t-[1px] border-r-[1px] border-[#DCDCE7]"
            onClick={closeFunction && closeFunction}
          >
            아니오
          </button>
          <button
            className="w-1/2 py-4  text-[#00B0EB] text-[15px] leading-5  border-t-[1px] border-[#DCDCE7]"
            onClick={confirmFunction && confirmFunction}
          >
            예
          </button>
        </div>
      </div>
    </div>
  );
};
