import { gql, useLazyQuery } from "@apollo/client";
import { GetGoodQuery, GetGoodQueryVariables } from "../gql/graphql";

const GET_GOOD_QUERY = gql`
  query getGood($input: GetGoodInput!) {
    getGood(input: $input) {
      ok
      err
      good {
        id
        price
        discountRate
        attributeSet
        goodAttrInfo
        status
        limitNumOrders
        categoryFirst
        categorySecond
        tags
        title
        summary
        goodOptions {
          id
          name
          index
          deliveryDate
          price
          discountRate
        }
        corporations {
          id
          name
        }
        bounds {
          status
        }
        goodReviews {
          id
          note
          rating
          goodReviewPosters {
            upload {
              url
            }
          }
        }
        goodPosters {
          id
          upload {
            url
          }
        }
        goodDetails {
          id
          index
          note
          url
          detailType
          upload {
            url
          }
        }
        goodRelations {
          id
          index
          relatedGoods {
            id
            title
            goodPosters {
              id
              upload {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetGood = (onCompleted?: (data: GetGoodQuery) => void) => {
  return useLazyQuery<GetGoodQuery, GetGoodQueryVariables>(GET_GOOD_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
