import { gql, useLazyQuery } from "@apollo/client";
import { ListReceiptQuery, ListReceiptQueryVariables } from "../gql/graphql";

const LIST_RECEIPT_QUERY = gql`
  query listReceipt($input: ListReceiptInput!) {
    listReceipt(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      receipts {
        id
        uuid
        status
        paid_at
        receiptStatus
        howToReceive
        receiptNum
        orders {
          id
          review {
            id
          }
          goodName
          goodOptionName
          orderReceipt {
            id
            order_quantity
            good_discountRate
            order_price
            goodOption_name
            good_title
            good_poster_url
          }
          quantity
          amount
          status
          exchangeStatus
          redelivery
          manager {
            id
            name
          }
          good {
            id
            title
            status
            goodPosters {
              upload {
                url
              }
            }
          }
          goodOption {
            name
            id
          }
        }
      }
    }
  }
`;

export const useListReceipt = (onCompleted?: (data: ListReceiptQuery) => void) => {
  return useLazyQuery<ListReceiptQuery, ListReceiptQueryVariables>(LIST_RECEIPT_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
