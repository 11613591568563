import logo from "../assets/icons/logo.png";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPaymentData } from "../hooks/useGetPaymentData";
import { GetPaymentDataInput, GetPaymentDataMutation } from "../gql/graphql";

export const MobileGetData = () => {
  const navigate = useNavigate();
  const params = useParams();
  var useOnce = false;
  // getpaymentdata in mobile
  const onCompleted = (data: GetPaymentDataMutation) => {
    const {
      getPaymentData: { ok, err },
    } = data;
    if (ok) {
      navigate(`/paymentComplete/${params.receiptUuid}`);
    } else {
      alert(err);
    }
  };
  const [getPaymentData, { loading: getPaymentLoading }] = useGetPaymentData(onCompleted);
  const getPaymentDataHandler = (imp_uid: string, receiptUuid: string) => {
    if (!getPaymentLoading) {
      const input: GetPaymentDataInput = {
        imp_uid,
        receiptUuid,
      };
      getPaymentData({ variables: { input } });
    }
  };
  useEffect(() => {
    if (useOnce === false) {
      useOnce = true;
      var success = params.receiptUuid ? window.location.search.split("&")[2].split("=")[1] : "";
      if (success === "false") {
        document.location.href = "/paymentFail";
        return;
      } else {
        var imp_uid = params.receiptUuid ? window.location.search.split("&")[0].split("=")[1] : "";
        const receiptUuid = params.receiptUuid ? params.receiptUuid.split("?")[0] : "";
        if (receiptUuid === "") alert("잘못된 접근입니다");
        if (imp_uid) {
          getPaymentDataHandler(imp_uid, receiptUuid);
        }
      }
    }
  }, []);
  return (
    <div className="wrap max-w-screen-break min-h-screen mx-auto w-full pb-32 bg-slate-100 bg-opacity-100">
      <main className="max-w-screen-break relative pt-12 px-8">
        <img src={logo} alt="결제완료 닐리버리 로고" className="mx-auto " />
        <p className="pt-20 text-center text-mainIcon font-bold text-large">결제 데이터를 불러오고 있습니다.</p>
      </main>
    </div>
  );
};
