import { TopBar } from "../components/topBar";
import { pageIndicator } from "../apollo";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useInjectKakaoMapApi, Map, MapMarker } from "react-kakao-maps-sdk";
import { CircularProgress, Dialog } from "@mui/material";
import axios from "axios";
import myPosition from "../assets/icons/myPosition.svg";
import { AlertComponent } from "../components/alert";
import { makingLastDepthArea } from "../constants/returnFunction";
import { AddressInfo } from "../constants/props";
interface setMapComponent {
  closeFun?: () => void;
  callBack?: Dispatch<SetStateAction<AddressInfo | undefined>>;
}
export const SetAddressByMap: React.FC<setMapComponent> = ({
  closeFun,
  callBack,
}) => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [RestLoading, setRestLoading] = useState<boolean>(false);
  const [AddressType, setAddressType] = useState<boolean>(true);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [address, setAddress] = useState<string | undefined>("");
  const [roadAddress, setRoadAddress] = useState<string | undefined>("");
  const [info, setInfo] = useState<AddressInfo>({
    regionKey: "",
    mainAddress: "",
    zoneCode: "",
  });
  // const setInfoMainAddressHandler = () => {
  //   if (AddressType) {
  //     setInfo((prevInfo) => ({
  //       ...prevInfo,
  //       mainAddress: address,
  //     }));
  //   }
  //   if (!AddressType && roadAddress) {
  //     setInfo((prevInfo) => ({
  //       ...prevInfo,
  //       mainAddress: roadAddress,
  //     }));
  //   }
  // };
  const returnHandler = () => {
    if (callBack && closeFun) {
      callBack(info);
      closeFun();
    }
  };
  interface positions {
    lat: number;
    lng: number;
  }
  const [position, setPosition] = useState<positions>({
    lat: 37.9559,
    lng: 126.677,
  });
  const { loading, error } = useInjectKakaoMapApi({
    appkey: String(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY),
  });
  const closeModal = () => {
    setShowAlert(false);
  };
  async function getPlaces(coords: positions) {
    try {
      setRestLoading(true);
      const lng = coords.lng;
      const lat = coords.lat;
      const { data } = await axios(
        `https://dapi.kakao.com/v2/local/geo/coord2address.json?x=${lng}&y=${lat}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_CLIENT_ID}`,
          },
        }
      );
      if (await data.documents[0]) {
        console.log(data);
        setAddress(data.documents[0].address.address_name);
        setInfo((prevInfo) => ({
          ...prevInfo,
          regionKey: makingLastDepthArea(
            data.documents[0].address.region_3depth_name
          ),
          mainAddress: data.documents[0].address.address_name,
        }));
        if (data.documents[0].road_address) {
          setInfo((prevInfo) => ({
            ...prevInfo,
            zoneCode: data.documents[0].road_address.zone_no,
          }));

          setRoadAddress(data.documents[0].road_address.address_name);
        } else {
          setInfo((prevInfo) => ({
            ...prevInfo,
            zoneCode: "",
          }));
          setRoadAddress("");
        }
      }
      setRestLoading(false);
      return data.documents;
    } catch (err) {
      console.log(err);
      setAlertText("문제가 발생했습니다. 잠시 후 다시 시도해주세요");
      setShowAlert(true);
    }
  }

  const findMyLocation = () => {
    if (navigator.geolocation) {
      setRestLoading(true);
      var input: positions;
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          input = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setPosition(input);
        }
      );
    }
  };
  useEffect(() => {
    pageIndicator("지도에서 위치 확인");
    findMyLocation();
  }, []);
  useEffect(() => {
    getPlaces(position);
  }, [position]);
  useEffect(() => {
    if (AddressType) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        mainAddress: address,
      }));
    } else if (!AddressType && roadAddress) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        mainAddress: roadAddress,
      }));
    }
  }, [AddressType]);
  return (
    <div className="wrap h-full flex flex-col overflow-y-auto">
      <header>
        <TopBar
          arrow={true}
          arrowFunction={closeFun}
          headingColor={`darkFont`}
          heading={true}
        />
      </header>
      <main className="flex-1 flex-col flex w-full bg-white">
        <div id="maps" className="flex-1 w-full relative">
          <Map
            center={position}
            style={{ width: `100%`, height: `100%` }}
            isPanto
            level={2}
            onClick={(_t, mouseEvent) => {
              setPosition({
                lat: mouseEvent.latLng.getLat(),
                lng: mouseEvent.latLng.getLng(),
              });
            }}
          >
            <MapMarker position={position}></MapMarker>
          </Map>
          <div className="w-[260px] text-sm text-center py-2 rounded-xl border border-mainIcon bg-white absolute top-4 left-1/2 -translate-x-1/2 z-10">
            지도에서 정확한 위치를 클릭해주세요.
          </div>
          <div
            onClick={() => findMyLocation()}
            className="absolute right-5 bottom-5 p-2 rounded-full shadow-xl bg-white z-10"
          >
            <img src={myPosition} alt="내 위치 찾기" />
          </div>
        </div>
        <div id="address" className="py-8 px-4 border-t border-btnDarkLine">
          <p className="text-xl text-darkFont px-4 font-bold">
            {AddressType
              ? address
              : roadAddress
              ? roadAddress
              : "도로명 주소가 없습니다."}
          </p>
          <div className="py-2 pl-4">
            <button
              onClick={() => {
                if (!roadAddress && AddressType) {
                  setAlertText("이 지점은 도로명 주소가 없습니다.");
                  setShowAlert(true);
                } else {
                  setAddressType(!AddressType);
                }
              }}
              className="text-sm bg-btnLightLine px-4 py-2 rounded-full "
            >
              {AddressType ? "도로명으로 보기" : "지번으로 보기"}
            </button>
          </div>
          <div className="pt-4">
            <button
              onClick={returnHandler}
              className="w-full bg-mainIcon text-white font-bold text-lg py-4 rounded-md"
            >
              이 위치로 주소 설정
            </button>
          </div>
        </div>
      </main>
      {RestLoading && (
        <div className="absolute left-0 top-0 w-screen h-screen bg-black bg-opacity-20 z-10">
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <CircularProgress />
          </div>
        </div>
      )}
      <Dialog
        open={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
      >
        <AlertComponent
          title={`알림`}
          desc={alertText}
          closeFunction={closeModal}
        ></AlertComponent>
      </Dialog>
    </div>
  );
};
