import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  EditGoodReviewMutation,
  EditGoodReviewMutationVariables,
} from "../gql/graphql";

const UP_GOOD_REVIEW_MUTATION = gql`
  mutation editGoodReview($input: EditGoodReviewInput!) {
    editGoodReview(input: $input) {
      ok
      err
    }
  }
`;
export const useUpGoodReview = (
  onCompleted?: (data: EditGoodReviewMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditGoodReviewMutation, EditGoodReviewMutationVariables>(
    UP_GOOD_REVIEW_MUTATION,
    {
      onCompleted,
      onError,
      fetchPolicy: "network-only",
    }
  );
};
