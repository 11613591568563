import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getToken } from "./token";

const token = getToken();

export const pageIndicator = makeVar("");
export const isLoggedInVar = makeVar(Boolean(token));
export const authTokenVar = makeVar(token);
export const testVar = makeVar(1);
export const serviceId = makeVar(2);
export const searchText = makeVar("");
export const test = makeVar(0);
export const isMobileVar = makeVar(false);
//uri

// 라이브서버ㅓㅓㅓㅓㅓㅓㅓㅓㅓㅓㅓㅓㅓㅓ

export const UPLOAD_URI =
  process.env.REACT_APP_URI === "dev"
    ? "http://localhost:4977/uploads/file"
    : process.env.REACT_APP_URI === "test"
    ? "https://testback.nilivery.com/uploads/file" // ? "http://192.168.0.6:5001/uploads/file"
    : "https://mfc.nilivery.com/uploads/file";

const uri =
  process.env.REACT_APP_URI === "dev"
    ? "http://localhost:4977/graphql"
    : process.env.REACT_APP_URI === "test"
    ? "https://testback.nilivery.com/graphql" // ? "http://192.168.0.6:5001/graphql"
    : "https://mfc.nilivery.com/graphql";

const httpLink = createHttpLink({ uri });
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "mfc-jwt": authTokenVar() || "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  uri,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          isLoggedInVar: {
            read() {
              return isLoggedInVar();
            },
          },
          token: {
            read() {
              return authTokenVar();
            },
          },
        },
      },
    },
  }),
});

// comma
export const comma = (num: number | null) => {
  let str = num + "";
  var point = str.length % 3;
  var len = str.length;

  let thousand = str.substring(0, point);
  while (point < len) {
    if (thousand !== "") thousand += ",";
    thousand += str.substring(point, point + 3);
    point += 3;
  }
  return thousand;
};
//  calculate
export const calculate = (pr: number, per: number) => {
  if (per === 0) {
    return pr;
  } else {
    var discountedPrice = (Number(pr) * (100 - Number(per))) / 100;
    return discountedPrice;
  }
};
