import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { FootMenu } from "../components/FootMenu";
import { TopBar } from "../components/topBar";
import { useNavigate } from "react-router-dom";
import { useListAddress } from "../hooks/useListAddress";
import { useDelAddress } from "../hooks/useDelAddress";
import { useUpAddress } from "../hooks/useUpAddress";
import { Dialog, Modal } from "@mui/material";
import React from "react";
import { AlertComponent, ConfirmComponent } from "../components/alert";
import { IAddress } from "../constants/types";
import {
  DelAddressMutation,
  ListAddressQuery,
  UpAddressInput,
  UpAddressMutation,
} from "../gql/graphql";

export const ListAddress = () => {
  const [delAlert, setDelAlert] = React.useState(false);
  const [delId, setDelId] = React.useState<number>(1);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const closeAlert = () => {
    setAlertOpen(false);
  };
  const closeConfirm = () => {
    setConfirmOpen(false);
  };
  const confirmFunction = () => {
    setConfirmOpen(false);
    delAddressHandler(delId);
    // delGoodReviewHandler(Number(param.reviewId));
  };

  const [selectedAddress, setSelectedAddress] = useState<IAddress | null>();

  const onListAddress = (data: ListAddressQuery) => {
    const {
      listAddress: { ok, address },
    } = data;
    if (ok) {
      if (address) {
        const repAddress = address.find((a) => a.isRep === true) as
          | IAddress
          | undefined;
        setSelectedAddress(repAddress);
      }
    }
  };

  const [listAddress, { data: listAddressData, refetch }] =
    useListAddress(onListAddress);
  const listAddressHandler = () => {
    listAddress();
  };
  const onCompleted = async (data: DelAddressMutation) => {
    const {
      delAddress: { ok, err },
    } = data;
    if (ok) {
      setAlertText("삭제 되었습니다.");
      setAlertOpen(true);
      await refetch();
    }
  };
  const [delAddress] = useDelAddress(onCompleted);
  const delAddressHandler = (id: number) => {
    delAddress({
      variables: { input: { id: id } },
    });
  };

  const navigate = useNavigate();
  const backToMypage = () => {
    navigate(-1);
  };
  const onAddressCompleted = async (data: UpAddressMutation) => {
    const {
      upAddress: { ok, err },
    } = data;
    if (ok) {
      setAlertText("배송지가 수정되었습니다.");
      setAlertOpen(true);
      // alert("배송지가 수정되었습니다.");
      await refetch();
    } else {
      alert(err);
    }
  };

  const [upAddress] = useUpAddress(onAddressCompleted);

  const upAddressHandler = () => {
    const upAddressInput: UpAddressInput = {
      upAddressId: selectedAddress ? +selectedAddress?.id : 1,
      name: String(selectedAddress?.name),
      username: String(selectedAddress?.username),
      phone: String(selectedAddress?.phone),
      zoneCode: String(selectedAddress?.zoneCode),
      mainAddress: String(selectedAddress?.mainAddress),
      subAddress: String(selectedAddress?.subAddress),
      region: String(selectedAddress?.region),
      isRep: Boolean(true),
    };
    upAddress({ variables: { input: upAddressInput } });
  };
  const delHandler = (id: number) => {
    setDelId(id);
    setDelAlert(true);
  };
  useEffect(() => {
    pageIndicator("배송지목록");
    listAddressHandler();
  }, []);
  return (
    <div className="wrap h-full flex flex-wrap flex-col bg-[#F3F7FF]">
      <header>
        <TopBar
          arrow={true}
          heading={true}
          home={true}
          shop={true}
          arrowFunction={backToMypage}
        />
      </header>
      <main className="max-w-screen-break flex-1  overflow-auto removeScroll pb-32 relative">
        <div className="pt-4 px-4 overflow-auto ">
          {/* {listAddressData?.listAddress.address &&
            listAddressData.listAddress.address
              .sort((a, b) => (a.isRep && !b.isRep ? -1 : 1))
              .map((obj, index) => (
                <div key={String(obj.username) + index} className="py-6 px-4">
                  <div></div>
                </div>
              ))} */}
          {listAddressData?.listAddress.address &&
            listAddressData.listAddress.address
              .sort((a, b) => (a.isRep && !b.isRep ? -1 : 1))
              .map((obj, index) => (
                <div key={String(obj.username) + index} className="py-2  ">
                  <div
                    className="card   bg-[#F3F7FF] "
                    onClick={() => {
                      setSelectedAddress(obj as IAddress);
                    }}
                  >
                    <div
                      className={`flex gap-x-2 px-4 py-[3.25px] h-[40px]  rounded-t-2xl  ${
                        obj.isRep ? "bg-pointIcon" : "bg-[#E5F1FA]"
                      } `}
                    >
                      <p
                        className={` ${
                          !obj.isRep && "hidden"
                        } my-[6px] py-1 px-2 text-[10px] leading-3 font-bold bg-mainIcon text-[#fff] rounded-[20px]`}
                      >
                        대표 배송지
                      </p>
                      <p className=" text-[14px] leading-4 flex-1 py-2 px-1 font-bold text-darkFont break:text-standardall">
                        {obj.name}
                      </p>
                      <button
                        className={`w-[40px] my-[3px] px-[8.5px] py-[5px]  text-smallBtn rounded-lg inner-border inner-border-solid ${
                          obj === selectedAddress
                            ? "bg-mainIcon inner-border-mainIcon text-white "
                            : "bg-[#fff] text-[#777b7d] inner-border-[#F0F0F4]"
                        }`}
                        onClick={() => {
                          setSelectedAddress(obj as IAddress);
                        }}
                      >
                        선택
                      </button>
                    </div>
                    <div
                      className={`content  bg-[#fff] rounded-b-2xl p-4 
                        `}
                    >
                      <div className="flex flex-col gap-y-[9px]">
                        <p className="flex flex-wrap gap-x-4  font-bold">
                          <span className="px-1 py-[2px] text-btn text-darkFont ">
                            {obj.username}
                          </span>
                          <span className="w-[1px] my-[6px] bg-hintFont"></span>
                          <span className="px-1 py-[2px] text-btn text-darkFont ">
                            {obj.phone}
                          </span>
                        </p>
                        <p className="flex flex-wrap flex-col">
                          <span className="pl-1 py-[2px] text-btn text-darkFont ">
                            {obj.mainAddress}
                          </span>
                          <span className="pl-1 py-[2px] text-btn text-darkFont ">
                            {obj.subAddress}
                          </span>
                        </p>
                      </div>
                      <div className="mt-2 flex flex-wrap border border-[#F0F0F4] rounded-lg  text-btn">
                        <button
                          className="w-1/2 border-r border-[#F0F0F4] py-1 text-mainIcon"
                          onClick={() => navigate(`edit/${obj.id}`)}
                        >
                          수정
                        </button>
                        <button
                          className="w-1/2 py-1 text-darkFont"
                          onClick={() => {
                            setDelId(obj.id);
                            setConfirmOpen(true);
                            // delHandler(obj.id);
                            // if (
                            //   window.confirm("이 배송지를 삭제하시겠습니까?")
                            // ) {
                            //   delAddress({
                            //     variables: { input: { id: obj.id } },
                            //   });
                            // }
                          }}
                        >
                          삭제
                        </button>
                      </div>

                      {/* <div className="pt-2 flex flex-wrap gap-x-8">
                        <p className="h-7 text-darkFont text-small break:text-standardall leading-7 ellipsis1">
                          연락처 : {obj.phone}
                        </p>

                        <p className="h-7 text-darkFont text-small break:text-standardall leading-7 ellipsis1">
                          입구 비밀번호 : {obj.pass}
                        </p>
                      </div>
                      <div className="pt-2">
                        <p className="h-7 text-darkFont text-small break:text-standardall leading-7 ellipsis1">
                          배송메시지 : {obj.message}
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
        </div>
        <Modal
          open={delAlert}
          onClose={() => {
            setDelAlert(false);
          }}
        >
          <div className="w-[304px]  bg-white absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-2xl">
            <div className="flex flex-wrap">
              <h1 className="w-full text-center py-6 text-[#00B0EB] text-[16px] leading-5 font-bold  ">
                알림
              </h1>
              <div className="w-full pb-6 px-4 my">
                <p className="w-full text-center px-1 py-2 text-[16px] leading-5 font-bold">
                  등록된 배송지를 삭제 하시겠습니까?
                </p>
              </div>
              <div className="flex flex-wrap w-full border-t border-[#DCDCE7]">
                <button
                  className="w-1/2 py-4 border-r text-[#00B0EB] text-[15px] leading-5 font-bold  border-[#DCDCE7]"
                  onClick={() => setDelAlert(false)}
                >
                  취소
                </button>
                <button
                  className="w-1/2 py-4 text-[#00B0EB] text-[15px] leading-5 font-bold  border-[#DCDCE7]"
                  onClick={() => {
                    delAddress({
                      variables: { input: { id: delId } },
                    });
                    setDelAlert(false);
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Dialog
          open={alertOpen}
          onClose={() => {
            setAlertOpen(false);
          }}
          className="relative"
        >
          <AlertComponent
            title={`알림`}
            desc={alertText}
            closeFunction={closeAlert}
          ></AlertComponent>
        </Dialog>
        <Dialog
          open={confirmOpen}
          onClose={() => {
            setConfirmOpen(false);
          }}
          className="relative"
        >
          <ConfirmComponent
            title={`안내`}
            desc={`정말로 삭제하시겠습니까?`}
            closeFunction={closeConfirm}
            confirmFunction={confirmFunction}
          ></ConfirmComponent>
        </Dialog>
      </main>
      <div className="w-full flex flex-wrap justify-center gap-x-4 absolute z-10 bottom-[72px]">
        <button
          onClick={() => upAddressHandler()}
          className="w-[156px] text-center py-2 border border-mainIcon text-mainIcon  bg-white font-bold text-btn break:text-standardall rounded-[40px] "
        >
          대표 배송지로 선택
        </button>
        <button
          onClick={() => {
            navigate("/address/enroll");
          }}
          className="w-[156px] py-2 text-white bg-mainIcon font-bold text-btn break:text-standardall rounded-[40px] "
        >
          배송지 추가하기
        </button>
      </div>
      <div className="absolute bottom-0 left-0 w-full">
        <FootMenu />
      </div>
    </div>
  );
};
