import { ApolloError, gql, useMutation } from "@apollo/client";
import { LoginMutation, LoginMutationVariables } from "../gql/graphql";

const LOGIN_MUTATION = gql`
  mutation login($loginInput: LoginInput!) {
    login(input: $loginInput) {
      ok
      token
      id
      err
    }
  }
`;

export const useLogIn = (onCompleted?: (data: LoginMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<LoginMutation, LoginMutationVariables>(LOGIN_MUTATION, {
    onCompleted,
    onError,
  });
};
