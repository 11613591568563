import { gql, useLazyQuery } from "@apollo/client";
import { ListMyGoodReviewQuery } from "../gql/graphql";

const LIST_MY_GOOD_REVIEW_QUERY = gql`
  query listMyGoodReview($input: ListMyGoodReviewInput!) {
    listMyGoodReview(input: $input) {
      ok
      err
      totalPages
      goodReviews {
        id
        createdAt
        note
        rating
        order {
          price
          amount
        }
        author {
          id
          name
          username
        }
        good {
          id
          title
          goodPosters {
            upload {
              url
            }
          }
          goodOptions {
            name
            price
          }
        }
        goodReviewPosters {
          upload {
            url
          }
        }
      }
    }
  }
`;

export const useListMyGoodReview = (onCompleted?: (data: ListMyGoodReviewQuery) => void) => {
  return useLazyQuery<ListMyGoodReviewQuery>(LIST_MY_GOOD_REVIEW_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
