import { ApolloError, gql, useMutation } from "@apollo/client";

import { UpUserMutation, UpUserMutationVariables } from "../gql/graphql";

const UP_USER_MUTATION = gql`
  mutation upUser($input: UpUserInput!) {
    upUser(input: $input) {
      ok
      err
    }
  }
`;

export const useUpUser = (
  onCompleted?: (data: UpUserMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<UpUserMutation, UpUserMutationVariables>(
    UP_USER_MUTATION,
    {
      onCompleted,
      onError,
      fetchPolicy: "network-only",
    }
  );
};
