import { gql, useMutation } from "@apollo/client";
import { GetPaymentDataMutation, GetPaymentDataMutationVariables } from "../gql/graphql";

const GET_PAYMENT_DATA_MUTATION = gql`
  mutation getPaymentData($input: GetPaymentDataInput!) {
    getPaymentData(input: $input) {
      ok
      err
      receipt {
        basketItems {
          quantity
          good {
            title
            price
            discountRate
            goodPosters {
              isRep
              index
              upload {
                url
              }
            }
            goodOptions {
              id
            }
          }
        }
        orders {
          quantity
          price
          amount
          goodName
          goodOptionName
          good {
            title
            price
            discountRate
            goodPosters {
              isRep
              index
              upload {
                url
              }
            }
            goodOptions {
              id
            }
          }
        }
      }
    }
  }
`;

export const useGetPaymentData = (onCompleted?: (data: GetPaymentDataMutation) => void) => {
  return useMutation<GetPaymentDataMutation, GetPaymentDataMutationVariables>(GET_PAYMENT_DATA_MUTATION, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
