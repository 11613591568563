import logo from "../assets/icons/logo.png";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetJoinUserCertData } from "../hooks/useGetJoinUserCert";
import { logIn } from "../token";
import { Dialog } from "@mui/material";
import { AlertComponent } from "../components/alert";
import { GetJoinUserCertDataMutation, GetJoinUserCertInput } from "../gql/graphql";

export const MobileCertification = () => {
  const navigate = useNavigate();
  const params = useParams();
  var useOnce = false;
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState<string | undefined>("");
  const [alertToken, setAlertToken] = useState<string>("");
  const closeAlert = () => {
    setAlertOpen(false);
    if (alertText === "인증이 확인되었습니다.") {
      logIn(alertToken);
      window.location.href = "/join/complete";
    }
  };

  // getCertificationData in mobile
  const onCertCompleted = (data: GetJoinUserCertDataMutation) => {
    const {
      getJoinUserCertData: { ok, err, token },
    } = data;
    if (ok && token) {
      setAlertText("인증이 확인되었습니다.");
      setAlertToken(token);
      setAlertOpen(true);

      // alert("인증이 확인되었습니다.");
      // logIn(token);
      // window.location.href = "/join/complete";
    } else {
      alert(err);
      navigate(`/`);
      // navigate(`/join/certification/${params.id}`);
    }
  };
  const [getJoinUserCertData] = useGetJoinUserCertData(onCertCompleted);
  const getJoinUserDataHandler = (imp_uid: string, id: number) => {
    const getJoinUserCertDataInput: GetJoinUserCertInput = {
      imp_uid,
      id,
    };
    getJoinUserCertData({
      variables: {
        input: getJoinUserCertDataInput,
      },
    });
  };
  useEffect(() => {
    if (useOnce === false) {
      useOnce = true;
      var success = params.cert ? window.location.search.split("&")[1].split("=")[1] : "";
      if (success === "false") {
        document.location.href = "/certificationFail";
        return;
      } else {
        var imp_uid = params.cert ? window.location.search.split("&")[0].split("=")[1] : "";
        const userId = Number(params.cert);

        if (!userId || !imp_uid || imp_uid === "") alert("잘못된 접근입니다");
        if (imp_uid) {
          getJoinUserDataHandler(imp_uid, userId);
        }
      }
    }
  }, []);
  return (
    <div className="wrap max-w-screen-break min-h-screen mx-auto w-full pb-32 bg-slate-100 bg-opacity-100">
      <main className="max-w-screen-break relative pt-12 px-8">
        <img src={logo} alt="결제완료 닐리버리 로고" className="mx-auto " />
        <p className="pt-20 text-center text-mainIcon font-bold text-large">인증 데이터를 불러오고 있습니다.</p>
        <Dialog
          open={alertOpen}
          onClose={() => {
            setAlertOpen(false);
          }}
          className="relative"
        >
          <AlertComponent title={`알림`} desc={alertText} closeFunction={closeAlert}></AlertComponent>
        </Dialog>
      </main>
    </div>
  );
};
