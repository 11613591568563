import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { DelAddressMutation, DelAddressMutationVariables } from "../gql/graphql";

const DEL_ADDRESS_MUTATION = gql`
  mutation delAddress($input: DelAddressInput!) {
    delAddress(input: $input) {
      ok
      err
    }
  }
`;

export const useDelAddress = (onCompleted?: (data: DelAddressMutation) => void) => {
  return useMutation<DelAddressMutation, DelAddressMutationVariables>(DEL_ADDRESS_MUTATION, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
