export const useRules = `이 약관은 주식회사 니나노컴퍼니 (전자상거래 사업자)이 운영하는 사이버 몰(이하 “몰”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.

※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다」

제2조(정의)
① “몰” 이란 주식회사 니나노컴퍼니가 재화 또는 용역(이하 “재화등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터등 정보통신설비를 이용하여 재화등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.

② “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.

③ ‘회원’이라 함은 “몰”에 개인정보를 제공하여 회원등록을 한 자로서, “몰”의 정보를 지속적으로 제공받으며, “몰”이 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.

④ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를 이용하는 자를 말합니다.

제3조 (약관등의 명시와 설명 및 개정)
① “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 오늘회의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.

② “몰”은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임, 환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.

③ “몰”은 전자상거래등에서의소비자보호에관한법률, 약관의규제에관한법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진등에관한법률, 방문판매등에관한법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.

④ “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일이전부터 적용일자 전일까지 공지합니다.

다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "몰“은 개정전 내용과 개정후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.

⑤ “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간내에 ‘몰“에 송신하여 ”몰“의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.

⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의소비자보호에관한법률, 약관의규제등에관한법률, 공정거래위원회가 정하는 전자상거래등에서의소비자보호지침 및 관계법령 또는 상관례에 따릅니다.
제4조(서비스의 제공 및 변경)

① “몰”은 다음과 같은 업무를 수행합니다.
1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
2. 구매계약이 체결된 재화 또는 용역의 배송
3. 기타 “몰”이 정하는 업무

② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.

③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.

④ 전항의 경우 “몰”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
제5조(서비스의 중단)

① “몰”은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.

② “몰”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.

③ 사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “몰”은 제8조에 정한 방법으로 이용자에게 통지하고 당초 “몰”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “몰”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “몰”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.

제6조(회원가입)
① 이용자는 “몰”이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.

② “몰”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.
1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실후 3년이 경과한 자로서 “몰”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
3. 기타 회원으로 등록하는 것이 “몰”의 기술상 현저히 지장이 있다고 판단되는 경우
③ 회원가입계약의 성립시기는 “몰”의 승낙이 회원에게 도달한 시점으로 합니다.
④ 회원은 제15조제1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편 기타 방법으로 “몰”에 대하여 그 변경사항을 알려야 합니다.

제7조(회원 탈퇴 및 자격 상실 등)
① 회원은 “몰”에 언제든지 탈퇴를 요청할 수 있으며 “몰”은 즉시 회원탈퇴를 처리합니다.
② 회원이 다음 각호의 사유에 해당하는 경우, “몰”은 회원자격을 제한 및 정지시킬 수 있습니다.
1. 가입 신청시에 허위 내용을 등록한 경우
2. “몰”을 이용하여 구입한 재화등의 대금, 기타 “몰”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
3. 다른 사람의 “몰” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
4. “몰”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
③ “몰”이 회원 자격을 제한, 정지 시킨후, 동일한 행위가 2회이상 반복되거나 30일이내에 그 사유가 시정되지 아니하는 경우 “몰”은 회원자격을 상실시킬 수 있습니다.
④ “몰”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.


부 칙(시행일) 이 약관은 2022년 7월 8일부터 시행합니다.
`;

export const personalInfo = `주식회사 니나노컴퍼니는 (이하 '회사'는) 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.

회사는 개인정보취급방침을 통하여 고객님께서 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.

1.개인정보 수집 항목 및 수집 방법
① 수집하는 개인정보의 항목
회사는 회원가입, 서비스 제공 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
가. 회원 가입 시
- 이메일 회원 가입 : 로그인ID(이메일), 비밀번호, 이름, 휴대전화번호, 주소, 중복가입확인정
- 카카오계정 회원 가입 : 카카오계정, 이름, 휴대전화번호, 주소, 중복가입확인정보
- 구글 회원 가입 : 이메일(or이용자고유식별자), 이름, 휴대전화번호, 주소, 중복가입확인정보
나. 서비스 제공 시
- 결제 정보, 구매자 정보(이름, 휴대전화번호), 수령인 정보 (이름, 휴대전화번호, 배송지주소, 주소지 출입 정보)
다. 취소 및 환불 시
- 예금주, 은행명, 계좌번호
라. 비회원 구매 시
- 이름, 결제 정보, 구매자 정보(이름, 휴대전화번호), 수령인 정보 (이름, 휴대전화번호, 배송지주소, 주소지 출입 정보)
- 비회원 구매 기록은 구매 후 30일 동안 보관 됩니다.
② 개인정보 수집방법 : 개인정보 항목은 홈페이지, 모바일 애플리케이션, 이메일, 고객센터를 통한 전화와 온라인 상담을 통해 수집됩니다.
회사의 서비스 이용 과정에서 서비스 이용기록, 방문기록, 불량 이용기록, IP주소, 쿠키 MAC주소, 모바일 기기정보(앱 버전, OS 버전), ADID/IDFA(광고식별자) 등의 정보가 자동으로 생성되어 수집될 수 있습니다.

2.개인정보의 수집 및 이용목적
회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.

① 재화 또는 서비스 제공
물품 배송, 서비스 제공, 계약서 및 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 요금 결제 및 정산, 채권추심 등
② 회원 관리
회원제 서비스 이용에 따른 본인확인 , 개인 식별 , 연령확인 , 고지사항 전달
③ 마케팅 및 광고에 활용
회원의 서비스 이용에 대한 통계, 이벤트 등 광고성 정보 전달 등의 목적
3.개인정보의 보유 및 이용기간
회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다.

4.개인정보의 파기절차 및 방법
회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.

① 파기절차
회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다.
별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
② 파기방법
전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
5.개인정보 제공
회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.

- 이용자들이 사전에 동의한 경우
- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
6.수집한 개인정보의 위탁
회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.


회사는 위탁계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지, 기술적 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리 감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

7.개인정보의 처리 및 보유기간
회사는 법령에 따른 개인정보 보유·이용 기간 또는 이용자로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리 및 보유합니다.
각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.

① 홈페이지 회원 가입 및 관리 : 회원 탈퇴 시 부정이용 방지를 위하여 필요한 최소한의 정보를 이용약관에 명시된 기간동안 보관 후 파기하며, 재화 또는 서비스 제공에 해당하는 경우는 관련 법령에 의거하여 보유 기간 동안 보관 후 파기합니다. 다만, 다음의 사유에 해당하거나 법령에서 정한 기간이 있는 경우에는 해당 사유/기간 종료시까지 보관 후 파기합니다.
가. 관계 법령 위반에 따른 수사/조사 등이 진행중인 경우에는 해당 수사/조사 종료시까지
나. 서비스 이용에 다른 채권이나 채무관계 잔존시에는 해당 채권이나 채무관계 정산시까지

② 재화 또는 서비스 제공 : 재화 또는 서비스 공급완료 및 요금결제, 정산 완료시까지
다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지
가. 전자상거래 등에서의 소비자 보호에 관한 법률에 따름
- 계약 또는 청약철회 등에 관한 기록 : 5년
- 대금결제 및 재화 등의 공급에 관한 기록 : 5년
- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
나. 정보통신 이용촉진 및 정보보호 등에 관한 법률에 따름
- 본인 확인에 대한 기록 : 6개월
다. 통신비밀보호법에 따름
- 인터넷 로그기록자료, 접속지 추적자료 : 3개월

8.이용자 및 법정대리인의 권리와 그 행사방법
이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회, 수정 또는 수정을 요청할 수 있으며, 가입 해지를 요청할 수도 있습니다.

이용자는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을 통해 개인정보 조회, 수정할 수 있고, ‘회원 탈퇴'를 통해 개인정보의 수집 및 이용동의를 철회할 수 있습니다.

귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까 지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자 에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.

회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

9.개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 회사은(는) 다음과 같은 목적을 위해 쿠키를 사용합니다.

① 쿠키 등 사용 목적
회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공 귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
② 쿠키 설정 거부 방법
쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.

설정방법(인터넷 익스플로어의 경우)
웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보
단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
10.개인정보에 관한 민원서비스
회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.

개인정보관리책임자 성명	전화번호	이메일
김경민	070-4759-3048	ggangmi@ninanobiz.com  =>경민정보 교체
귀하께서는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

1.대검찰청 사이버수사과 (cybercid.spo.go.kr)
2.경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)

`;

export const Refund = `
배송/교환환불 정책


<배송정책>

1) 배송가능지역: 전국
2) 기본배송비:무료
3) 배송기간
가. 김천 지역은 출발 당일에서 하루, 이 외 전국 지역은 출발일 기준 2~5일 소요됩니다.
나. 오후 12시 주문 건까지 당일 출고됩니다. (주말 및 공휴일 제외)
다. 당사 일정에 따라 공휴일 전날에는 택배 발송이 어려울 수 있습니다.

<교환정책>

교환 배송 관련 안내 
* 배송확정 상품은 교환이 불가능합니다.
* 배송 완료일로부터 3일이내 교환 신청 가능합니다. (3일 이후 자동 배송확정)
* 단순 변심 등 고객 사유로 인한 교환의 경우 왕복배송비를 지불해야하며, 제품 및 포장이 재판매 가능한 상태여야 합니다. (상품 및 구성품,  포장 훼손,  분실 시 교환 불가)
* 반품 신청시, 당사에서 직접 도와드립니다. 문의에 교환문의를 남겨주세요.

<환불정책>

환불관련 안내
* 배송 완료일로부터 3일 이내 환불 요청 가능합니다. (3일 이후 자동 배송확정)
* 제품 또는 포장이 훼손되어 재판매가 불가한 경우, 환불을 거절할 수 있습니다.
* 환불 신청시, 당사에서 직접 도와드립니다. 문의에 환불문의를 남겨주세요.


반품/교환 불가능 사유

1. 반품요청기간이 지난 경우
2. 구매자의 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우 (단, 상품의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외)
3. 구매자의 책임있는 사유로 포장이 훼손되어 상품 가치가 현저히 상실된 경우
4. 구매자의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우
5. 시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우
6. 복제가 가능한 상품 등의 포장을 훼손한 경우
`;

export const messageText = [
  { text: "문 앞에 두고 가세요", value: "문 앞에 두고 가세요" },
  {
    text: "부재 시 경비실에 맡겨주세요",
    value: "부재 시 경비실에 맡겨주세요",
  },
  { text: "방문 전 연락 부탁드립니다", value: "방문 전 연락 부탁드립니다" },
  { text: "직접 입력하기", value: "직접 입력하기" },
];
