import { ApolloError, gql, useMutation } from "@apollo/client";
import { CrBasketItemMutation, CrBasketItemMutationVariables } from "../gql/graphql";

const CR_BASKET_ITEM_MUTATION = gql`
  mutation crBasketItem($input: CrBasketItemInput!) {
    crBasketItem(input: $input) {
      ok
      err
    }
  }
`;

export const useCrBaksetItem = (onCompleted?: (data: CrBasketItemMutation) => void, onError?: (error: ApolloError) => void) => {
  return useMutation<CrBasketItemMutation, CrBasketItemMutationVariables>(CR_BASKET_ITEM_MUTATION, {
    onCompleted,
    onError,
    fetchPolicy: "network-only",
  });
};
