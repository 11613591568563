import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DOMRoutes } from "../constants/props";
import { useMe } from "../hooks/useME";
import { Basket } from "../routes/list-basket";
import { Mypage } from "../routes/mypage";
import { CommonRoutes } from "./logged-out-router";
import { OrderList } from "../routes/order-list";
import { ListInquiry } from "../routes/list-inquiry";
import { CrInquiry } from "../routes/cr-inquiry";
import { UpUserData } from "../routes/up-user-data";
import { ListAddress } from "../routes/list-address";
import { CrAddress } from "../routes/cr-address";
import { Payment } from "../routes/payment";
import { UpAddress } from "../routes/up-address";
import { UpInquiry } from "../routes/up-inquiry";
import { GetInquiry } from "../routes/get-inquiry";
import { PaymentCompleted } from "../routes/payment-completed";
import { MobileGetData } from "../routes/payment-mobile-getData";
import { PaymentFail } from "../routes/payment-fail";
import { CrReview } from "../routes/cr-review";
import { ListReview } from "../routes/list-review";
import { PassMobileCertification } from "../routes/pass-mobile-certification";
import { CrAddressPay } from "../routes/cr-address-pay";
import { EditReview } from "../routes/edit-review";
import { GetOrderStatus } from "../routes/get-orderStatus";
import { ListGoodReview } from "../routes/list-good-review";
import { SelectReceiveWays } from "../routes/select-receive-ways";
import { SetAddressByMap } from "../routes/set-address-by-map";
import { GetOrderPickUpStatus } from "../routes/get-orderPickUpStatus";

const LoggedInRoutes: DOMRoutes[] = [
  {
    path: "/baskets/:serviceId",
    key: "/baskets/:serviceId",
    component: <Basket />,
  },
  {
    path: "/mypage",
    key: "/mypage",
    component: <Mypage />,
  },
  {
    path: "/mypage/orderList/",
    key: "/mypage/orderList/",
    component: <OrderList />,
  },
  {
    path: "/mypage/orderList/status/:orderId",
    key: "/mypage/orderList/status/:orderId",
    component: <GetOrderStatus />,
  },
  {
    path: "/mypage/orderList/pickup/:orderId",
    key: "/mypage/orderList/pickup/:orderId",
    component: <GetOrderPickUpStatus />,
  },
  {
    path: "/mypage/inquiryList",
    key: "/mypage/inquiryList",
    component: <ListInquiry />,
  },
  {
    path: "/mypage/enrollInquiry",
    key: "/mypage/enrollInquiry",
    component: <CrInquiry />,
  },
  {
    path: "/mypage/change",
    key: "/mypage/change",
    component: <UpUserData />,
  },
  {
    path: "/passMobileCertData/:cert",
    key: "/passMobileCertData/:cert",
    component: <PassMobileCertification />,
  },
  {
    path: "/address",
    key: "/address",
    component: <ListAddress />,
  },

  {
    path: "/address/edit/:key",
    key: "/address/edit/:key",
    component: <UpAddress />,
  },
  {
    path: "/address/enroll",
    key: "/address/enroll",
    component: <CrAddress />,
  },
  {
    path: "/payment/:receiptUuid",
    key: "/payment/:receiptUuid",
    component: <Payment />,
  },
  {
    path: "/mobileGetData/:receiptUuid",
    key: "/mobileGetData/:receiptUuid",
    component: <MobileGetData />,
  },
  {
    path: "/mypage/inquiry/:key",
    key: "/mypage/inquiry/:key",
    component: <GetInquiry />,
  },
  {
    path: "/mypage/inquiryEdit/:key",
    key: "/mypage/inquiryEdit/:key",
    component: <UpInquiry />,
  },
  {
    path: "/paymentComplete/:receiptUuid",
    key: "/paymentComplete/:receiptUuid",
    component: <PaymentCompleted />,
  },
  {
    path: "/addressInPay/:receiptUuid",
    key: "/addressInPay/:receiptUuid",
    component: <CrAddressPay />,
  },
  {
    path: "/paymentFail",
    key: "/paymentFail",
    component: <PaymentFail />,
  },
  {
    path: "/crReview",
    key: "/crReview",
    component: <CrReview />,
  },
  {
    path: "/editReview/:reviewId",
    key: "/editReview/:reviewId",
    component: <EditReview />,
  },
  {
    path: "/listReview",
    key: "/listReview",
    component: <ListReview />,
  },
  {
    path: "/listGoodReview/:goodId",
    key: "/listGoodReview/:goodId",
    component: <ListGoodReview />,
  },
  {
    path: "/selectReceiveWays/:receiptUuid",
    key: "/selectReceiveWays/:receiptUuid",
    component: <SelectReceiveWays />,
  },
];

export const LoggedInRouter = () => {
  const { data, loading } = useMe();
  return (
    <Router>
      {!loading && (
        <Routes>
          {CommonRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={route.component}
            />
          ))}

          {LoggedInRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={route.component}
            />
          ))}
        </Routes>
      )}
    </Router>
  );
};
