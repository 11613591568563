import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/footerNIliveryLogo.png";
import { useMe } from "../hooks/useME";
import { Dialog, Modal } from "@mui/material";
import { AlertComponent } from "./alert";

export const Footer = () => {
  const { data: me } = useMe();
  const [alertOpen, setAlertOpen] = useState(false);
  const closeModal = () => {
    setAlertOpen(false);
    navigate("/login");
  };
  const navigate = useNavigate();
  return (
    <footer className=" max-w-screen-break break:max-w-screen-fit mx-auto px-4 pt-6 pb-24 bg-[#FAF9F9]">
      <div className="top">
        <img
          src={logo}
          alt="닐리버리 로고"
          className="w-1/2 mx-auto max-w-[360px]"
        />
        <div className="text-center pt-6 space-x-4">
          <button
            onClick={() => {
              navigate("/whatIsNilivery");
            }}
            className="px-6 py-1 h-8 font-bold  text-[#6C6C6C] rounded-full border border-[#9B9B9B]"
          >
            닐리버리란?
          </button>
          <button
            onClick={() => {
              if (me) {
                navigate("/mypage/enrollInquiry");
              } else {
                setAlertOpen(true);
              }
            }}
            className="px-6 py-1 h-8 font-bold  text-[#6C6C6C] rounded-full border border-[#9B9B9B]"
          >
            1:1 문의하기
          </button>
        </div>
      </div>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <AlertComponent
          title={`안내`}
          desc={`로그인/회원가입 후 이용해주세요.`}
          closeFunction={closeModal}
        ></AlertComponent>
      </Dialog>
      <div className="bottom pt-4 px-4 text-small break:text-standardall leading-5 text-[#6c6c6c] ">
        <p className="font-bold text-center ">(주)니나노컴퍼니</p>
        <p className="text-center  ">
          <a
            href="https://ninano.company"
            target={"_blank"}
            className="hover:underline"
          >
            회사소개
          </a>{" "}
          |{" "}
          <Link to="/personalInfo" className="hover:underline">
            개인정보취급방침
          </Link>{" "}
          |{" "}
          <Link to="/useInfo" className="hover:underline">
            서비스이용약관
          </Link>
        </p>
        <p className="text-center keep-all">
          사업자번호 : 828-87-00831 | 대표 : 김건홍 | 연락처 : 070-4759-3025 |
          통신판매번호 : 2020-경북김천-0017호
        </p>
        <address className="block text-center not-italic">
          주소 : 경북 김천시 율곡동 1105 헥스테크노피아 2층
        </address>
      </div>
      <small className="block text-center text-[#bfbfbf]">
        Copyright (주)니나노컴퍼니 Inc. All Rights Reserved.
      </small>
    </footer>
  );
};
