import { useEffect, useState } from "react";
import { OrderStatus } from "../gql/graphql";

export const getDayKorean = (day: number | undefined) => {
  switch (day) {
    case 0:
      return "일";
    case 1:
      return "월";
    case 2:
      return "화";
    case 3:
      return "수";
    case 4:
      return "목";
    case 5:
      return "금";
    case 6:
      return "토";
    default:
      return "미정";
  }
};

export const endDayComment = (date: any) => {
  var currDay = 24 * 60 * 60 * 1000;
  var today = new Date().getTime();
  var diff = (date - today) / currDay;
  if (diff < 1) {
    // return "마감 임박";
    return "오늘까지";
  } else {
    return Math.ceil(diff) + "일 남음";
  }
};

export const comma = (num: number | null) => {
  let str = num + "";
  var point = str.length % 3;
  var len = str.length;

  let thousand = str.substring(0, point);
  while (point < len) {
    if (thousand !== "") thousand += ",";
    thousand += str.substring(point, point + 3);
    point += 3;
  }
  return thousand;
};

export const droneDeliveryProcess = [
  {
    location: "물류센터",
    work: "주문 확인 중",
    active: true,
    status: OrderStatus.Inspect,
  },
  {
    location: "물류센터",
    work: "상품 준비 중",
    active: false,
    status: OrderStatus.Bound,
  },
  {
    location: "물류센터",
    work: "배송 준비 중",
    active: false,
    status: OrderStatus.Pending,
  },
  {
    location: "물류센터",
    work: "드론 비행 중",
    active: false,
    status: OrderStatus.Drone,
  },
  {
    location: "버티포트",
    work: "최종 배송 준비 중",
    active: false,
    status: OrderStatus.Port,
  },
  {
    location: "MFC 센터",
    work: "최종 배송 준비 중",
    active: false,
    status: OrderStatus.Stored,
  },
  {
    location: "배송원",
    work: "최종 배송 중",
    active: false,
    status: OrderStatus.PendingDelivered,
  },
  {
    location: "배송지",
    work: "배송완료",
    active: false,
    status: OrderStatus.Delivered,
  },
];

export const truckDeliveryProcess = [
  { location: "물류센터", work: "주문 확인 중" },
  { location: "물류센터", work: "상품 준비 중" },
  { location: "배송원", work: " 배송 중" },
  { location: "배송지", work: "배송완료" },
];

export const checkNull = (str: string) => {
  if (typeof str == "undefined" || str == null || str == "") {
    return true;
  } else {
    return false;
  }
};

export const emailMasking = (str: string) => {
  let originStr = str;
  let emailStr = originStr.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
  let strLength;
  if (checkNull(originStr) == true || checkNull(String(emailStr)) == true) {
    return originStr;
  } else {
    strLength = String(emailStr).toString().split("@")[0].length - 3;
    // ex1) abcdefg12345@naver.com => ab**********@naver.com
    return originStr.toString().replace(new RegExp(".(?=.{0," + strLength + "}@)", "g"), "*");

    // ex2) abcdefg12345@naver.com => ab**********@nav******
    // return originStr.toString().replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*').replace(/.{6}$/, "******");
  }
};

export const makingLastDepthArea = (str: string) => {
  let strArray = str.split(" ");
  if (strArray.length > 1) {
    return strArray[1];
  } else {
    return strArray[0];
  }
};
