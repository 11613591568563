import { gql, useLazyQuery } from "@apollo/client";
import { ListInquiryQuery } from "../gql/graphql";

const LIST_INQUIRY_QUERY = gql`
  query listInquiry($input: ListInquiryInput!) {
    listInquiry(input: $input) {
      ok
      err
      totalPages
      totalResults
      inquiries {
        id
        title
        note
        status
        type
        createdAt
        author {
          id
          name
          username
        }
        comments {
          note
        }
        inquiryPosters {
          id
          upload {
            id
            url
          }
        }
      }
    }
  }
`;

export const useListInquiry = (onCompleted?: (data: ListInquiryQuery) => void) => {
  return useLazyQuery<ListInquiryQuery>(LIST_INQUIRY_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
